import { ValueOf } from '../utilities/types';

const transmodeTypes = {
  BANK_GIRO: 'bank-giro',
  MONEY_TRANSFER: 'money-transfer',
  CASH: 'cash',
  ACCOUNT_TRANSFER: 'account-transfer',
  CARD: 'card',
  COURIER: 'courier',
  UNKNOWN: 'unknown',
  GAMBLING_TRANSACTION: 'gambling-transaction',
  SWISH: 'swish',
  OTHER: 'other',
  TRANSFER: 'transfer',
  SECURITIES_TRANSACTIONS: 'securities-transactions',
} as const;

export const transmodeTypesChoices = [
  { id: transmodeTypes.BANK_GIRO, name: 'BankGiro' },
  { id: transmodeTypes.MONEY_TRANSFER, name: 'Money transfer' },
  { id: transmodeTypes.CASH, name: 'Cash' },
  { id: transmodeTypes.ACCOUNT_TRANSFER, name: 'Account transfer' },
  { id: transmodeTypes.CARD, name: 'Card' },
  { id: transmodeTypes.COURIER, name: 'Courier' },
  { id: transmodeTypes.GAMBLING_TRANSACTION, name: 'Gambling transaction' },
  { id: transmodeTypes.SWISH, name: 'Swish' },
  { id: transmodeTypes.OTHER, name: 'Other' },
  { id: transmodeTypes.TRANSFER, name: 'Transfer' },
  { id: transmodeTypes.SECURITIES_TRANSACTIONS, name: 'Securities transaction' },
];

export type TransmodeTypes = ValueOf<typeof transmodeTypes>;

export default transmodeTypes;
