import React, { useEffect } from 'react';

import {
  useListContext,
  usePermissions,
  useRedirect,
  useRefresh,
} from 'react-admin';

import {
  Box,
  Button,
} from '@mui/material';

import { isNil } from 'lodash';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import SortableTree from '@nosferatu500/react-sortable-tree';

import useRuleTreeState from '../../../../hooks/useRuleTreeState';

import hasAccess from '../../../../utilities/hasAccess';

import { RuleTypes } from '../../../../constants/ruleTypes';
import ruleViews from '../../../../constants/ruleView';
import { Rule } from '../../../../utilities/schemas/rules';
import { Actions } from '../../../../constants/actions';

const TreeView = ({
  ruleType,
  isDraft,
  version,
}: {
  ruleType: RuleTypes,
  isDraft: boolean,
  version: string;
}) => {
  const { data, setPerPage } = useListContext();
  const { permissions } = usePermissions<Actions[]>();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const {
    treeData,
    updateMove,
    setTreeData,
    removeNode,
  } = useRuleTreeState({ data });

  useEffect(() => {
    setPerPage(1000);
  }, [setPerPage]);

  if (!data) return null;

  return (
    <Box height="100%">
      <SortableTree
        getNodeKey={({ node }: { node: Rule }) => node.id}
        treeData={treeData.treeData}
        canDrag={() => isDraft}
        onChange={(item: Rule) => { if (isDraft) setTreeData({ treeData: item }); }}
        canDrop={({ node, nextParent }: { node: Rule, nextParent: Rule }) => {
          if (!isDraft) return false;
          if (!hasAccess(permissions, 'RuleUpdate')) return false;
          if (isNil(nextParent)) return true;
          return node?.process === nextParent.process;
        }}
        rowHeight={70}
        onMoveNode={
          ({
            node,
            nextParentNode,
          }: {
            node: Rule,
            nextParentNode: Rule
          }) => {
            if (isDraft) {
              const triggeredBy = isNil(nextParentNode) ? undefined : nextParentNode?.id;
              updateMove({
                ...data.find((item) => item.id === node.id),
                triggeredBy,
              });
            }
          }
        }
        generateNodeProps={({
          node,
        }: {
          node: Rule,
          path: number[]
        }) => ({
          buttons: [
            <Box>
              {(hasAccess(permissions, 'RuleUpdate') && isDraft) && (
                <>
                  <Button
                    startIcon={<SubdirectoryArrowRightIcon />}
                    color="secondary"
                    onClick={() => {
                      redirect(
                        'create',
                        'rules',
                        undefined,
                        undefined,
                        {
                          record: {
                            ruleType,
                            view: ruleViews.TREE,
                            parentRule: {
                              process: node.process,
                              id: node.id,
                            },
                            version,
                          },
                        },
                      );
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<EditIcon />}
                    onClick={() => redirect(
                      'edit',
                      `rules/${node?.id}`,
                      'summary',
                      undefined,
                      {
                        record: {
                          ruleType,
                          view: ruleViews.TREE,
                          version,
                        },
                      },
                    )}
                  >
                    Edit
                  </Button>
                  <Button
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      removeNode(node);
                      refresh();
                    }}
                  >
                    Delete
                  </Button>

                </>
              )}
              {(hasAccess(permissions, 'RuleGet') && !isDraft) && (
                <Button
                  color="secondary"
                  startIcon={<VisibilityIcon />}
                  onClick={() => redirect(
                    'edit',
                    `rules/${node?.id}`,
                    'summary',
                    undefined,
                    {
                      record: {
                        ruleType,
                        view: ruleViews.TREE,
                        version,
                      },
                    },
                  )}
                >
                  Show
                </Button>
              )}
            </Box>,
          ],
        })}
      />
    </Box>
  );
};

export default TreeView;
