import React from 'react';
import {
  SelectArrayInput,
  minLength,
  required,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import languages from '../../../../constants/languages';
import { fundSourceChoices } from '../../../../constants/fundSources';

const SourceOfFunds = () => (
  <Grid item xs={12} md={12}>
    <Box marginBottom={2}>
      <Typography variant="label">Unacceptable source of funds</Typography>
    </Box>
    <Box display="flex" flexWrap="wrap" gap={2}>
      <SelectArrayInput
        source="ruleParameters.unacceptableSourceOfFunds"
        label="Statuses"
        validate={[required(), minLength(1)]}
        choices={fundSourceChoices(languages.EN)}
      />
    </Box>
  </Grid>
);

export default SourceOfFunds;
