import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  DateField, RaRecord, useInfiniteGetList,
} from 'react-admin';

import {
  Typography,
  Box,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  Button,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  AppBar,
  Toolbar,
} from '@mui/material';

import {
  isBoolean,
  isEmpty, isNil, uniqBy,
} from 'lodash';

import RefreshIcon from '@mui/icons-material/Refresh';
import CircleIcon from '@mui/icons-material/Circle';
import ScienceIcon from '@mui/icons-material/Science';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import ruleTypes, { RuleTypes } from '../../../../constants/ruleTypes';
import NoResults from '../../../layout/NoResults';

const Custom = ({ vers } : {vers: RaRecord}) => (
  <Box display="flex">
    <Typography>
      {`Version ${vers?.number}, \xa0`}
    </Typography>
    <DateField record={vers} source="date" showTime />
    <Typography>
      {`,\xa0 ${vers?.description}`}
    </Typography>
  </Box>
);

const RuleVersion = ({
  versions,
  setVersions,
  isTest,
  setIsTest,
}: {
  isTest: boolean,
  setIsTest: React.Dispatch<boolean>;
  versions: string[];
  setVersions: React.Dispatch<string[]>;
}) => {
  const [ruleType, setRuleType] = useState<RuleTypes>('request');
  const [open, setOpen] = useState(false);
  const [choices, setChoices] = useState<{
    id: string;
    name: string;
    number: number;
    custom: React.JSX.Element
  }[]>([]);

  const ref = useRef(null);

  const {
    data, hasNextPage, fetchNextPage,
  } = useInfiniteGetList<{id: string, number: number}>('rule-version', { filter: { ruleType, isTestVersion: isTest } });

  const isChecked = useCallback(
    (value: string) => versions.includes(value),
    [versions],
  );

  useEffect(() => {
    setChoices([]);
  }, [isTest, ruleType]);

  const pages = useMemo(() => data?.pages.map((item) => item.data).flat(), [data?.pages]);

  const handelChangeOpen = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handelChangeIsTestVersion = useCallback((
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: boolean,
  ) => {
    e.preventDefault();
    if (isBoolean(newValue)) setIsTest(newValue);
  }, [setIsTest]);

  const handelChangeRuleType = useCallback((
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    newValue: string,
  ) => {
    e.preventDefault();
    if (Object.values(ruleTypes).includes(newValue)) {
      setRuleType(newValue);
    }
  }, []);

  const handelChange = useCallback((event: SelectChangeEvent) => {
    const nonNilVersions = (event.target.value as unknown as string[])
      .filter((item) => !isNil(item));

    setVersions(nonNilVersions);
  }, [setVersions]);

  useEffect(() => {
    setChoices((prev) => uniqBy([
      ...prev,
      ...(pages?.map((vers) => ({
        id: vers.id,
        name: `Version ${vers.number}`,
        custom: <Custom vers={vers} />,
        number: vers.number,
      })) ?? []),
    ], 'id').sort((a, b) => b.number - a.number));
  }, [pages]);

  const handleLoadMore = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    // prevent the select to be closed when button is clicked
    e.stopPropagation();
    fetchNextPage();
  }, [fetchNextPage]);

  if (!pages) {
    return null;
  }

  return (
    <Box>
      <Box ref={ref} height="100%">
        <Button
          sx={{ height: 'inherit' }}
          startIcon={<HistoryToggleOffIcon />}
          variant="text"
          color="primary"
          size="small"
          onClick={handelChangeOpen}
          fullWidth
        >
          Version
        </Button>
      </Box>
      <Select
        MenuProps={{
          anchorEl: ref.current,
          MenuListProps: {
            disablePadding: true,
          },
        }}
        style={{ display: 'none' }}
        open={open}
        onClose={handelChangeOpen}
        onOpen={handelChangeOpen}
        multiple
        value={versions as unknown as string}
        onChange={handelChange}
        fullWidth
        color="primary"
      >
        <AppBar position="sticky" color="inherit">
          <Toolbar>
            <Box display="flex" flexDirection="column" width="100%" gap={2} py={2}>
              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={ruleType}
                exclusive
                onChange={handelChangeRuleType}
              >
                <ToggleButton value="request">
                  <Typography>Request</Typography>
                </ToggleButton>
                <ToggleButton value="ongoing">Ongoing</ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                fullWidth
                color="primary"
                value={isTest}
                exclusive
                onChange={handelChangeIsTestVersion}
              >
                <ToggleButton value={false}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <CircleIcon color={isTest ? 'inherit' : 'red'} sx={{ fontSize: 10 }} />
                    <Typography>Live</Typography>
                  </Box>
                </ToggleButton>
                <ToggleButton value>
                  <Box display="flex" alignItems="center" gap={1}>
                    <ScienceIcon />
                    <Typography>Test</Typography>
                  </Box>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Toolbar>
        </AppBar>
        {choices.map((choice) => (
          <MenuItem
            key={choice.id}
            value={choice.id}
          >
            <Checkbox size="small" checked={isChecked(choice.id)} />
            {choice.custom}
          </MenuItem>
        ))}
        {isEmpty(choices) && <NoResults variant="body1" />}
        {(hasNextPage) && (
          <Button
            onClickCapture={handleLoadMore}
            fullWidth
            startIcon={<RefreshIcon />}
          >
            Load more
          </Button>
        )}
      </Select>
    </Box>
  );
};

export default RuleVersion;
