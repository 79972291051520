import { useMemo } from 'react';
import { usePermissions } from 'react-admin';
import requestTypesChoices from '../constants/requestTypes';
import hasAccess from '../utilities/hasAccess';
import actions, { Actions } from '../constants/actions';

const useRequestTypesChoices = () => {
  const { permissions } = usePermissions<Actions[]>();

  const haveCarModule = hasAccess(permissions, actions.CAR_GET);

  const choices = useMemo(() => Object.values(requestTypesChoices)
    .filter((type) => (haveCarModule ? true : !type?.id.includes('car'))), [haveCarModule]);

  return { choices };
};

export default useRequestTypesChoices;
