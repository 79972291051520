import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import companyAuditStatus from '../../../../constants/companyAuditorStatuses';

import NumberInput from '../../../layout/inputFields/NumberInput';
import SelectArrayInput from '../../../layout/inputFields/SelectArrayInput';
import InfoPopover from '../../../layout/InfoPopover';

const BusinessAuditor = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Data update frequency</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <NumberInput
            fullWidth
            source="ruleParameters.updateFreq"
            label="Days"
            validate={[required(), minValue(0)]}
            popups={[
              <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Triggering audit statuses</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectArrayInput
            fullWidth
            choices={companyAuditStatus}
            source="ruleParameters.auditorStatuses"
            label="Auditor statuses"
            validate={[required(), minValue(0)]}
            popups={[
              <InfoPopover key="trigger statues" title="Triggering audit statuses" content="If the underlying data have a status equal to any of the selected statuses." />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default BusinessAuditor;
