import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  useGetOne,
} from 'react-admin';

import {
  Box,
  Typography,
  Card,
} from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import hasAccess from '../../utilities/hasAccess';

import RiskClassCreate from './RiskClassCreate';
import RiskClassEdit from './RiskClassEdit';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import { Actions } from '../../constants/actions';

const RiskClassActions = () => {
  const { data } = useGetOne('risk-classes', { id: 'interval-coverage' });
  const hasErrors = data && (!data.isCoveringAll || !data.isNotOverlapping);
  return (
    <Box display="flex" width="100%" justifyContent="space-between" justifySelf="flex-end" alignItems="center">
      <Box display="flex" alignItems="center">
        {hasErrors && (
          <>
            <WarningIcon color="warning" />
            {!data.isCoveringAll && (
              <Typography marginLeft={2} color="error">
                Risk classes are not covering the full range 0-100.
              </Typography>
            )}
            {!data.isNotOverlapping && (
              <Typography marginLeft={2} color="error">
                Risk classes are overlapping.
              </Typography>
            )}
          </>
        )}
      </Box>
      <RiskClassCreate />
    </Box>
  );
};

const RiskClassList = () => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <List
      empty={false}
      actions={<RiskClassActions />}
      sort={{ field: 'min', order: 'ASC' }}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
          {hasAccess(permissions, 'RiskClassGet') && <RiskClassEdit />}
          <TextField sortable={false} source="name" />
          <TextField sortable source="min" />
          <TextField sortable source="max" />
        </Datagrid>
      </Card>
    </List>
  );
};

export default RiskClassList;
