import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';
import { IAccountEntity } from '../../utilities/schemas/accountEntity';

const AccountEntityBreadcrumb = () => {
  const record = useRecordContext<IAccountEntity>();
  useRegisterBreadcrumb({
    title: record?.bban ?? record?.iban ?? record?.customIds?.at(0) ?? record?.id,
    path: `/account-entities/${record?.id}`,
  });

  return null;
};

export default AccountEntityBreadcrumb;
