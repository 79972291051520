import { ValueOf } from '../utilities/types';

const ruleTypes = {
  REQUEST: 'request',
  ONGOING: 'ongoing',
};

export type RuleTypes = ValueOf<typeof ruleTypes >;

export default ruleTypes;
