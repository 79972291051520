import React from 'react';

import {
  FunctionField,
  useRecordContext,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import {
  isArray,
  isEmpty,
  isString,
  upperFirst,
} from 'lodash';

import { getName } from 'i18n-iso-countries';
import languages from '../../../../../../constants/languages';
import NoResults from '../../../../../layout/NoResults';
import { AdverseMediaMatches } from '../../../../../../utilities/schemas/adverseMediaMatches';

type AdverseMedia = AdverseMediaMatches['potentialMatches'][number]['adverseMedia'][number]

const getAdverseMedia = ({
  type,
  country,
  format,
  headline,
  publishedDate,
  createdAt,
}: AdverseMedia) => {
  const typeName = isArray(type) && !isEmpty(type) ? upperFirst(type.join(', ')) : '-';
  const countryName = isString(country) && !isEmpty(country) ? getName(country, languages.EN) : '-';
  const formatName = isString(format) && !isEmpty(format) ? upperFirst(format) : '-';
  const headlineName = isString(headline) && !isEmpty(headline) ? upperFirst(headline) : '-';
  const publishedAtText = isString(publishedDate) && !isEmpty(publishedDate) ? new Date(publishedDate).toLocaleDateString() : '-';
  const createdAtText = isString(createdAt) && !isEmpty(createdAt) ? new Date(createdAt).toLocaleDateString() : '-';

  return [
    `Type: ${typeName}`,
    `Country: ${countryName}`,
    `Format: ${formatName}`,
    `Headline: ${headlineName}`,
    `Published at: ${publishedAtText}`,
    `Created at: ${createdAtText}`,
  ].join('\n');
};

const AdverseMediaMedias = () => {
  const record = useRecordContext<AdverseMediaMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Adverse media" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {isEmpty(record.adverseMedia) && <NoResults text="No data to display." icon={null} />}
            {!isEmpty(record.adverseMedia) && (
              <Grid item xs={12} md={12}>
                <Box>
                  <FunctionField
                    sx={{ whiteSpace: 'pre' }}
                    source="adverseMedia"
                    render={(item: AdverseMediaMatches['potentialMatches'][number]) => {
                      if (isEmpty(item.adverseMedia)) return '-';
                      return (
                        <ul>
                          {record.adverseMedia
                            .map((media) => (
                              <li style={{ marginTop: 6 }}>
                                {getAdverseMedia(media)}
                              </li>
                            ))}
                        </ul>
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AdverseMediaMedias;
