import { useCallback, useState } from 'react';

const useDialogStatus = () => {
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => setOpen(true), []);

  const closeDialog = useCallback(() => setOpen(false), []);

  return { open, openDialog, closeDialog };
};

export default useDialogStatus;
