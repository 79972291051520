import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Slide,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import CloseIcon from '@mui/icons-material/Close';

import {
  ArrayField,
  Datagrid,
  DateField,
  RaRecord,
  ReferenceField,
  TextField,
  useGetList,
  useRecordContext,
} from 'react-admin';

import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex';

import { useParams } from 'react-router-dom';
import useDialogStatus from '../../hooks/useDialogStatus';
import usePagination from '../../hooks/usePagination';

import theme from '../../theme';

import ColoredScoreField from '../../customFields/ColoredScoreField';
import ShowButton from '../../customFields/ShowButton';
import Label from '../../customFields/Label';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { EntityTypes } from '../../constants/entityTypes';

type Data = {
  id: string
  entity: {
    id: string
    type: EntityTypes
  }
  score: number
  createdAt: string
  baselineComponent?: {
    score?: number
    groups: {
      id: string
      score: number
    }[]
  },
  pingComponent?: {
    score?: number
    pings: {
      id: string
      pingScore: number
      riskTimelineLeftInDays?: number
      currentScore?: number
    }[]
  },
  description: string
}

const ScoreInfo = () => (
  <Typography>
    The score of an entity
    {' '}
    <InlineMath math="S" />
    {' '}
    is computed with the following formula:
    <BlockMath math={'S = 1 - \\prod_{i=0}^n(1-s_i) \\prod_{k=0}^m(1-p_k)'} />
    where
    {' '}
    <InlineMath math="i" />
    {' '}
    indexes entity groups;
    {' '}
    <InlineMath math="s_i" />
    {' '}
    denotes score for group
    {' '}
    <InlineMath math="i" />
    ;
    {' '}
    <InlineMath math="k" />
    {' '}
    indexes entity pings and
    {' '}
    <InlineMath math="p_k" />
    {' '}
    denotes the pings score for ping
    {' '}
    <InlineMath math="k" />
    . Here, entity pings are not referring to all pings created on the customer.
    Instead, it is the collection of the latest pings.
  </Typography>
);

const PingScoreInfo = () => (
  <Typography>
    The ping score for a ping
    {' '}
    <InlineMath math="P" />
    {' '}
    is computed with the following formula:
    <BlockMath math={'P = rs \\cdot rt'} />
    where
    {' '}
    <InlineMath math="rs" />
    {' '}
    is the score assigned to the rule, and
    {' '}
    <InlineMath math="rt" />
    {' '}
    is the risk timeline factor, which is defined as:
    <BlockMath math={
      'rt = \\begin{cases} 1 & \\text{if there is no risk timeline} \\\\  \\frac{rd}{td} & \\text{if there is a risk timeline}\\end{cases}'
    }
    />
    Here,
    {' '}
    <InlineMath math="rd" />
    {' '}
    is the number of days remaining in the risk period, and
    {' '}
    <InlineMath math="td" />
    {' '}
    is the total duration of the risk period.
  </Typography>
);

const useGetGraphData = (
  data: Data[] | undefined,
  onClickGraph: (
    _event: unknown,
    _chartContext: unknown,
    config: { w: { config: { series: { data: Data[] }[] } }, dataPointIndex: number },
  ) => void,
  record: Data | undefined,
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();

  const series = useMemo(() => {
    if (!data) return null;

    const today = DateTime.now().toISO();
    if (!today) return null;

    const formattedData = data.slice().reverse().map(
      (item) => ({
        x: item.createdAt,
        y: item.score,
        z: item.description,
        ...item,
      }),
    );

    const lastScore = formattedData[formattedData.length - 1];

    if (lastScore) {
      formattedData.push({
        y: lastScore.y,
        x: today,
        z: 'As of today',
        id: '',
        entity: {
          id: '',
          type: 'Individual',
        },
        score: 0,
        createdAt: today,
        description: '',
      });
    }

    return [{
      name: 'Score',
      data: formattedData,
    }];
  }, [data]);

  useEffect(() => {
    if (!series) return;

    const newOptions = {
      colors: [theme.palette.secondary.main],
      chart: {
        redrawOnParentResize: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        events: {
          markerClick: onClickGraph,
        },
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'stepline',
      },
      tooltip: {
        shared: false,
        intersect: true,
        z: {
          title: 'Description',
        },
      },
      yaxis: {
        type: 'numeric',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
      markers: {
        size: 5,
        colors: [theme.palette.primary.main],
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(!isEmpty(series));
    setOptions(newOptions);
  }, [onClickGraph, record, series]);

  return {
    hasData,
    options,
    series,
  };
};

const postRowStyle = (id?: string) => (record: RaRecord) => ({ backgroundColor: record.id === id ? '#efe' : 'white' });

const Description = () => {
  const record = useRecordContext();

  return (
    <Tooltip title={record?.description}>
      <Box maxWidth={200} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        <TextField source="description" />
      </Box>
    </Tooltip>
  );
};

const PingComponent = ({ data }: {data: NonNullable<Data['pingComponent']>}) => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    calculatePageView,
  } = usePagination<NonNullable<Data['pingComponent']>['pings'] | undefined>({ data: data?.pings });

  return (
    <>
      <Typography p={2} fontWeight="bold" color="primary" variant="h6">
        Pings used in calculation of score
      </Typography>
      <Divider />
      <ArrayField record={calculatePageView(page, rowsPerPage)} fullWidth source="data">
        <>
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <TextField
              sortable={false}
              source="pingScore"
              label={(
                <Label
                  TypographyPropsSx={{ fontWeight: 'bold' }}
                  title="Ping score"
                  info={<PingScoreInfo />}
                />
              )}
            />
            <TextField
              sortable={false}
              source="ruleScore"
              label={(
                <Label
                  TypographyPropsSx={{ fontWeight: 'bold' }}
                  title="Rule score"
                  info="The score assigned to a ping from the rule set-up score parameter."
                />
              )}
            />
            <ReferenceField sortable={false} label="Description" source="id" reference="pings">
              <Description />
            </ReferenceField>
            <ShowButton resourceLink="pings" linkType="show" />
          </Datagrid>
          <Box p={4}>
            <Typography>{`${data.score} = Accumulated ping score`}</Typography>
          </Box>
          <Divider />
        </>
      </ArrayField>
      <TablePagination
        component="div"
        count={data.pings?.length ?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[3]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Divider />
    </>
  );
};

const BaselineComponent = ({ data }: {data: NonNullable<Data['baselineComponent']>}) => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    calculatePageView,
  } = usePagination<NonNullable<Data['baselineComponent']>['groups'] | undefined>({ data: data?.groups });

  return (
    <>
      <Typography p={2} fontWeight="bold" color="primary" variant="h6">
        Groups used in calculation of score
      </Typography>
      <Divider />
      <ArrayField fullWidth record={calculatePageView(page, rowsPerPage)} source="data">
        <>
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <TextField sortable={false} source="score" />
            <ReferenceField sortable={false} label="Group name" source="id" reference="groups">
              <TextField source="name" />
            </ReferenceField>
            <ShowButton resourceLink="groups" />
          </Datagrid>
          <Box p={4}>
            <Typography>{`${data.score} = Accumulated group score`}</Typography>
          </Box>
          <Divider />
        </>
      </ArrayField>
      <TablePagination
        component="div"
        count={data.groups?.length ?? 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[2]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Divider />
    </>
  );
};

const CalculationView = ({ record }: { record?: Data }) => {
  if (!record) {
    return null;
  }

  return (
    <Box width="100%" display="flex" flexDirection="column">
      {!isEmpty(record.pingComponent?.pings) && (
        <PingComponent data={record.pingComponent!} />
      )}
      {!isEmpty(record.baselineComponent?.groups) && (
        <BaselineComponent data={record.baselineComponent!} />
      )}
    </Box>
  );
};

const DialogBody = () => {
  const { id } = useParams();
  const [record, setRecord] = useState<Data | undefined>();

  const { data, isLoading } = useGetList<Data>(
    'scores',
    {
      pagination: { perPage: 300, page: 1 },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: { 'entity.id': id },
    },
  );

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    calculatePageView,
  } = usePagination<Data[] | undefined>({ data });

  const onClickGraph = useCallback((
    _event: unknown,
    _chartContext: unknown,
    config: { w: { config: { series: { data: Data[] }[] } }, dataPointIndex: number },
  ) => {
    const point = config.w.config.series[0].data[config.dataPointIndex];
    if (!isEmpty(point.id)) {
      setRecord(point);
    }
  }, []);

  const { hasData, options, series } = useGetGraphData(data, onClickGraph, record);

  if (isLoading) return <LinearProgress />;

  if (!hasData || !series) {
    return (
      <Box margin={10} width="100%">
        <Typography align="center"> No Score History Available </Typography>
      </Box>
    );
  }

  return (
    <Card sx={{ m: 0 }} variant="outlined">
      <Grid container>
        <Grid item xs={12} md={record ? 6 : 12}>
          <ReactApexChart options={options} series={series} type="line" height={600} />
        </Grid>
        <Slide in={!!record} direction="left">
          <Grid item xs={12} md={record ? 6 : 0} display="flex">
            <Divider orientation="vertical" />
            <CalculationView record={record} />
          </Grid>
        </Slide>
        <Grid item xs={12} md={12}>
          <Divider />
          <ArrayField
            record={calculatePageView(page, rowsPerPage)}
            source="data"
          >
            <Datagrid
              sx={{
                ...boldDataGridStyle,
                '.RaDatagrid-rowCell': {
                  maxWidth: 10,
                },
              }}
              bulkActionButtons={false}
              rowStyle={postRowStyle(record?.id)}
              rowClick={
                (rowId, resource, r) => {
                  setRecord((rec) => (rowId === rec?.id ? undefined : r as Data)); return false;
                }
              }
            >
              <DateField source="createdAt" showTime />
              <TextField source="description" />
              <TextField source="contributionType" />
              <ColoredScoreField source="score" label={<Label TypographyPropsSx={{ fontWeight: 'bold' }} title="Score" info={<ScoreInfo />} />} />
            </Datagrid>
          </ArrayField>
          <TablePagination
            component="div"
            count={data?.length ?? 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

const RiskScoreSeries = () => {
  const { open, closeDialog, openDialog } = useDialogStatus();

  return (
    <>
      <Button variant="contained" startIcon={<ShowChartIcon />} size="small" onClick={openDialog}>
        Show Score History
      </Button>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={closeDialog}
        fullWidth
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <DialogTitle variant="h5" color="secondary">
            Risk Score History
          </DialogTitle>
          <IconButton disableRipple onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogBody />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RiskScoreSeries;
