import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import countries from '../../../../constants/countries';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';
import SelectInput from '../../../layout/inputFields/SelectInput';

const BusinessSanctionList = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Data update frequency</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <NumberInput
            source="ruleParameters.updateFreq"
            label="Days"
            validate={[required(), minValue(0)]}
            popups={[
              <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Sanction list search parameters</Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectInput
            source="ruleParameters.hitRatio"
            label="Hit ratio"
            fullWidth
            choices={[
              { id: 85, name: '85%' },
              { id: 90, name: '90%' },
              { id: 95, name: '95%' },
              { id: 100, name: '100%' },
            ]}
            popups={[
              <InfoPopover
                key="hit ratio"
                title="Hit ratio"
                content="Specifies the hit rating ratio that must be reached in order for a sanctioned entity to be included in the response."
              />]}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectInput
            source="ruleParameters.countryCode"
            fullWidth
            choices={countries}
            label="Country"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>

);

export default BusinessSanctionList;
