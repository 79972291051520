import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';

type Listener = (value: BreadCrumb[]) => void;

type BreadCrumb = {
  title: string;
  path: string;
};

const makeBreadcrumbsObservable = (initialValue: BreadCrumb[] = []) => {
  let breadcrumbs = initialValue;
  let listeners: Listener[] = [];

  const get = () => breadcrumbs;

  const register = (path: BreadCrumb) => {
    breadcrumbs = [...breadcrumbs, path];
    listeners.forEach((l) => l(breadcrumbs));
    return () => {
      breadcrumbs = breadcrumbs.slice(0, -1);
      listeners.forEach((l) => l(breadcrumbs));
    };
  };

  const subscribe = (listener: Listener) => {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  };

  return {
    get,
    register,
    subscribe,
  };
};

const breadcrumbsObservable = makeBreadcrumbsObservable([{
  title: 'Home',
  path: '/',
}]);

const useBreadcrumbs = () => {
  const [breadcrumbs, setBreadCrumbs] = React.useState(breadcrumbsObservable.get());

  React.useEffect(() => {
    const unsubscribe = breadcrumbsObservable.subscribe(setBreadCrumbs);
    return unsubscribe;
  }, []);

  return breadcrumbs;
};

export const useRegisterBreadcrumb = (breadcrumb: Partial<BreadCrumb>) => {
  React.useEffect(() => {
    if (!breadcrumb.title || !breadcrumb.path) return () => {};

    const deregister = breadcrumbsObservable.register({
      title: breadcrumb.title,
      path: breadcrumb.path,
    });
    return deregister;
  }, [breadcrumb.title, breadcrumb.path]);
};

const BreadcrumbsManager = () => {
  const breadcrumbList = useBreadcrumbs();
  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ display: { xs: 'none', sm: 'block' } }}>
      {breadcrumbList.map((breadcrumb, index) => (
        <Link
          key={`${breadcrumb.title}-${breadcrumb.path}`}
          underline="hover"
          color={index === breadcrumbList.length - 1 ? 'text.primary' : 'inherit'}
          to={breadcrumb.path}
          component={NavLink}
        >
          {breadcrumb.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsManager;
