import { QueryClient } from 'react-query';
import { HttpError } from 'react-admin';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      retry: (failureCount, error) => {
        const hasNotFailedTooMuch = failureCount < 3;
        if (error instanceof HttpError) {
          const shouldNotBeRetried = !!error.status && error.status < 500;
          return !shouldNotBeRetried && hasNotFailedTooMuch;
        }
        return hasNotFailedTooMuch;
      },
    },
  },
});

export default queryClient;
