const defaultResponsiveStyle = {
  '& .RaDatagrid-tableWrapper': {
    overflowX: 'auto',
  },
};

export const boldDataGridStyle = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-headerCell': {
    fontWeight: 'bold',
    backgroundColor: 'transparent',
  },
};

export const noRowBorderDataGridStyle = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-thead': { borderBottom: 'solid', borderBottomColor: '#0000001f', borderBottomWidth: '2px' },
  '& .RaDatagrid-rowCell': {
    border: 'none',
  },
  '& .RaDatagrid-headerCell': {
    fontWeight: 'bold',
  },
};

export const listViewDatagridStyle = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-thead': { borderBottom: 'solid', borderBottomColor: '#0000001f', borderBottomWidth: '2px' },
  '& .RaDatagrid-headerCell': {
    fontWeight: 'bold',
  },
  '& .RaDatagrid-row': {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
};

export const ruleListDefaultStyle = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-thead': { borderBottom: 'solid', borderBottomColor: '#0000001f', borderBottomWidth: '2px' },
  '& .RaDatagrid-headerCell': {
    fontWeight: 'bold',
    paddingLeft: '0px',
  },
  '& .RaDatagrid-table': {
    'table-layout': 'fixed',
  },
  '& .RaDatagrid-tbody': {
    '& .MuiTableCell-sizeSmall': {
      padding: '0px 0px 0px 0px',
    },
  },

};

export const expandableDataGridStyles = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-thead': { borderBottom: 'solid', borderBottomColor: '#0000001f', borderBottomWidth: '2px' },
  '& .RaDatagrid-headerCell': {
    fontWeight: 'bold',
  },
  '& .RaDatagrid-table': {
    'table-layout': 'fixed',
  },
  '& .RaDatagrid-tbody': {
    '& .MuiTableCell-sizeSmall': {
      padding: '0px 0px 0px 0px',
    },
  },
};

export const expandedDatagridStyles = {
  ...defaultResponsiveStyle,
  '& .RaDatagrid-thead': {
    display: 'none',
  },
  '& .RaDatagrid-table': {
    'table-layout': 'fixed',
  },
  '& .RaDatagrid-tbody': {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: '1%',
      backgroundColor: '#F5F5F5',
    },
  },
  '& .RaDatagrid-row': {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },

};
