import { ValueOf } from '../utilities/types';

const bankAccountStatuses = {
  ACTIVE: 'active',
  CLOSED_BY_CLIENT: 'closed-by-client',
  CLOSED_BY_OPERATOR: 'closed-by-operator',
  CLOSED: 'closed',
  BLOCKED: 'blocked',
} as const;

export type BankAccountStatuses = ValueOf<typeof bankAccountStatuses>;

export const bankAccountStatusesChoices = [
  { id: bankAccountStatuses.ACTIVE, name: 'Active' },
  { id: bankAccountStatuses.CLOSED_BY_CLIENT, name: 'Closed By Client' },
  { id: bankAccountStatuses.CLOSED_BY_OPERATOR, name: 'Closed By Operator' },
  { id: bankAccountStatuses.CLOSED, name: 'Closed' },
  { id: bankAccountStatuses.BLOCKED, name: 'Blocked' },
];

export default bankAccountStatuses;
