import React, {
  useCallback,
} from 'react';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import {
  useGetOne,
  useRecordContext,
  useRedirect,
  ChipField,
  TextField,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

const RiskClassField = ({
  chip,
  source,
  label,
}: {
  chip?: boolean,
  source: string,
  label?: string
  sortable?: boolean,
}) => {
  const record = useRecordContext();

  const score = get(record, source);

  const { data } = useGetOne('risk-classes/from-score', { id: score }, { enabled: !isNil(score) });
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    redirect('list', 'risk-classes');
  }, [redirect]);

  if (!data) return <Typography>None</Typography>;

  return (
    <Box>
      {chip && <ChipField label={label} color="secondary" onClick={handleClick} record={data} source="name" />}
      {!chip && <TextField label={label} record={data} source="name" />}
    </Box>
  );
};

export default RiskClassField;
