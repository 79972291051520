import React, { useCallback, useState } from 'react';

import {
  useGetList,
  useListContext,
} from 'react-admin';

import SelectInputButton from '../../../layout/inputFields/SelectInputButton';

const AdditionalRuleFilters = () => {
  const { data } = useGetList('groups', { pagination: { page: 1, perPage: 250 } });
  const [value, setValue] = useState<string[]>([]);

  const { setFilters, filterValues } = useListContext();

  const onChange = useCallback((newValue: string[]) => {
    setValue(newValue);
    setFilters({
      ...filterValues,
      'custom.entitiesToCheck.groupsToInclude': newValue,
    }, {}, false);
  }, [filterValues, setFilters]);

  if (!data) return null;

  return (
    <SelectInputButton multiple label="Group Inclusion" choices={data} setValue={onChange} value={value} />
  );
};

const GroupsToInclude = () => (
  <AdditionalRuleFilters />

);

export default GroupsToInclude;
