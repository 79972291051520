import React from 'react';
import { get } from 'lodash';
import { TextField, useRecordContext } from 'react-admin';

import { Box, Grid, Typography } from '@mui/material';
import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Gatuadress',
  [languages.EN]: 'Address',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Address = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="address"
      datapoint={conflictFields.ADDRESS}
    >
      <ValueField />
    </Answer>
  );
};

Address.ValueField = ValueField;

Address.Nested = ({ source }: { source?: string}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{CONTENT[language]}</Typography>
        <TextField
          source={source ?? 'address'}
          emptyText="-"
          sx={sx}
        />
      </Box>
    </Grid>
  );
};

export default Address;
