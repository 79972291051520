import React from 'react';

import { SelectField, useRecordContext, TextField } from 'react-admin';

import {
  Grid, Box, Typography,
} from '@mui/material';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import roles, { roleChoices } from '../../../../constants/roles';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Roll',
  [languages.EN]: 'Role',
};

const OTHER_LABEL = {
  [languages.SV]: 'Annat',
  [languages.EN]: 'Other',
};

const ValueField = ({ language }: { language?: Languages }) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column">
            <Typography sx={sx} variant="label">Alternative</Typography>
            <SelectField sx={sx} choices={roleChoices(language)} record={{ value }} source="value.options" emptyText="-" fontWeight="bold" />
          </Box>
        </Grid>
        {value?.options === roles.OTHER && (
          <Grid item xs={12} md={6}>
            <Box display="flex" flexDirection="column" width="100%">
              <Typography sx={sx} variant="label">{OTHER_LABEL[language]}</Typography>
              <TextField sx={sx} record={{ value }} source="value.other" emptyText="-" fontWeight="bold" />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Role = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source={source ?? 'role'}
      datapoint={conflictFields.ROLE}
      isComplexType
    >
      <ValueField language={language} />
    </Answer>
  );
};

Role.ValueField = ValueField;

Role.Nested = ({ source }: { source?: string }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  const value = get(record, source ?? 'role');

  return (
    <>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{CONTENT[language]}</Typography>
          <SelectField
            choices={roleChoices(language)}
            source={`${source ?? 'role'}.options`}
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      {value?.options === roles.OTHER && (
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">{OTHER_LABEL[language]}</Typography>
            <TextField source={`${source ?? 'role'}.other`} emptyText="-" sx={sx} />
          </Box>
        </Grid>
      )}
    </>
  );
};

export default Role;
