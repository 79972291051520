import React from 'react';

import { Grid, Typography } from '@mui/material';

import { SelectInput, TextInput } from 'react-admin';

import { roleChoices } from '../../../constants/roles';
import languages from '../../../constants/languages';

const Role = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Role
      </Typography>
    </Grid>
    <Grid item xs={12} md={10}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectInput fullWidth source="value.options" choices={roleChoices(languages.EN)} label="Alternative" />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextInput fullWidth source="value.other" label="Other" />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={10}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default Role;
