import React from 'react';

import {
  ChipField,
  ReferenceArrayField,
  SelectField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import { recommendationsChoices } from '../../../../../constants/recommendations';
import { mapBinaryRule } from '../../../ruleFeatures';
import ruleTypes from '../../../../../constants/ruleTypes';
import { Rule } from '../../../../../utilities/schemas/rules';

const booleanChoices = [
  { id: true, name: 'Yes' },
  { id: false, name: 'No' },
];

const RuleResponse = () => {
  const record = useRecordContext<Rule>();
  const createPing = record?.createPing;
  const isAutoConfirmed = record?.isAutoConfirmed;

  return (
    <Grid container spacing={6}>
      {record?.ruleType === ruleTypes.REQUEST && (
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color="black" fontWeight="bold">Recommendation</Typography>
            <SelectField choices={recommendationsChoices} source="recommendation" />
          </Box>
        </Grid>
      )}
      {record?.ruleType === ruleTypes.REQUEST && (
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color="black" fontWeight="bold">Signals</Typography>
            {!isEmpty(record?.signals) && (
              <ReferenceArrayField source="signals" reference="signals" emptyText="-">
                <SingleFieldList>
                  <ChipField emptyText="-" size="small" source="name" />
                </SingleFieldList>
              </ReferenceArrayField>
            )}
            {isEmpty(record?.signals) && (
              <Typography>-</Typography>
            )}
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Create ping if error occurs</Typography>
          <SelectField choices={booleanChoices} source="pingOnError" />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Create Ping</Typography>
          <SelectField choices={booleanChoices} source="createPing" />
        </Box>
      </Grid>
      {createPing && (
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color="black" fontWeight="bold">Should auto confirm ping?</Typography>
            <SelectField choices={booleanChoices} source="isAutoConfirmed" />
          </Box>
        </Grid>
      )}
      {(createPing && isAutoConfirmed && mapBinaryRule[record?.name!]) && (
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label" color="black" fontWeight="bold">Number of days to ignore?</Typography>
            <TextField source="numberOfDaysIgnoreRule" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default RuleResponse;
