import React from 'react';

import {
  RecordContextProvider,
  ReferenceManyField, useRecordContext,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import { isEmpty } from 'lodash';
import Pagination from '../components/layout/Pagination';

import { IndividualListBody } from '../components/individual/IndividualList';
import NoResults from '../components/layout/NoResults';

const RelatedConductors = () => {
  const record = useRecordContext();

  const conductorIds = record?.conductors?.map((conductor: { id: string}) => conductor.id);

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader
          title="Related Conductors"
        />
        <Divider />

        {isEmpty(conductorIds) && <NoResults variant="h4" />}
        {!isEmpty(conductorIds) && (
          <RecordContextProvider value={{ ...record, conductorIds }}>
            <ReferenceManyField
              perPage={5}
              pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
              reference="individuals"
              source="conductorIds"
              target="id"
            >
              <IndividualListBody />
            </ReferenceManyField>
          </RecordContextProvider>
        )}
      </Card>
    </Grid>
  );
};

export default RelatedConductors;
