import React, { useCallback } from 'react';
import { Button, Form, SelectInput } from 'react-admin';
import FilterListIcon from '@mui/icons-material/FilterList';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import { Panel } from 'reactflow';

import useDialogStatus from '../../../../hooks/useDialogStatus';
import entityTypes, { EntityTypes, entityTypesChoices } from '../../../../constants/entityTypes';
import Params from '../../Params';

export const sortChoices = [
  { id: 'volume', name: 'Connection Volume' },
  { id: 'frequency', name: 'Connection Frequency' },
];

export const relatedEntityTypeChoices = entityTypesChoices
  .filter(({ id }) => id !== entityTypes.CAR);

export const relatedRolesChoices = [
  { id: 'initiator', name: 'Incoming' },
  { id: 'recipient', name: 'Outgoing' },
];

const FilterPane = ({
  params,
  setParams,
}: {
  params: Params,
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const {
    open,
    openDialog,
    closeDialog,
  } = useDialogStatus();

  const handleSubmit = useCallback((data: {
    flowSort?: 'volume' | 'frequency' | null;
    flowRelatedEntityType?: EntityTypes | null;
    flowRelatedRoles?: 'initiator' | 'recipient' | null;
    flowPageSize?: number;
  }) => {
    setParams((currentParams) => ({
      ...currentParams,
      flowSort: data.flowSort ?? undefined,
      flowRelatedEntityType: data.flowRelatedEntityType ?? undefined,
      flowRelatedRoles: data.flowRelatedRoles ?? undefined,
      flowPageSize: data.flowPageSize ?? 10,
    }));
    closeDialog();
  }, [setParams, closeDialog]);

  return (
    <>
      <Panel position="top-right">
        <Button
          startIcon={<FilterListIcon />}
          onClick={openDialog}
          size="medium"
          variant="contained"
          color="primary"
          label="Filter"
        />
      </Panel>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeDialog}
        variant="persistent"
        sx={{
          '& .MuiPaper-root': {
            position: 'absolute',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box
            px={4}
            py={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={4}
          >
            <Typography variant="h6" fontWeight={600}>Apply filters</Typography>
            <IconButton onClick={closeDialog}><CancelIcon /></IconButton>
          </Box>
          <Form
            values={{
              flowSort: params.flowSort ?? null,
              flowRelatedEntityType: params.flowRelatedEntityType ?? null,
              flowRelatedRoles: params.flowRelatedRoles ?? null,
              flowPageSize: params.flowPageSize ?? null,
            }}
            onSubmit={handleSubmit}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={8}
              padding={4}
            >
              <SelectInput
                source="flowSort"
                helperText={false}
                choices={sortChoices}
              />
              <SelectInput
                label="Entity type"
                source="flowRelatedEntityType"
                helperText={false}
                choices={relatedEntityTypeChoices}
              />
              <SelectInput
                label="Direction"
                source="flowRelatedRoles"
                choices={relatedRolesChoices}
                helperText={false}
              />
              <SelectInput
                label="Number of counterparties"
                source="flowPageSize"
                choices={[
                  { id: 5, name: '5' },
                  { id: 10, name: '10' },
                  { id: 25, name: '25' },
                  { id: 50, name: '50' },
                ]}
                helperText={false}
              />
              <Box display="flex" justifyContent="center">
                <Button type="submit" variant="contained" label="Save" />
              </Box>
            </Box>
          </Form>
        </Box>
      </Drawer>
    </>
  );
};

export default FilterPane;
