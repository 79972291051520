import React, { useCallback } from 'react';

import {
  ArrayField,
  Datagrid,
  TextField,
  usePermissions,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  styled,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import hasAccess from '../../../utilities/hasAccess';

import countries from '../../../constants/countries';
import { boldDataGridStyle } from '../../../constants/style/datagridStyles';

import DateFieldCheck from '../../../customFields/DateFieldCheck';
import EditField from '../../../customFields/EditField';
import EditSelectInput from '../../../customFields/EditSelectInput';
import LatestRelatedCases from '../../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../../customFields/LatestRelatedPings';
import LatestRelatedRequests from '../../../customFields/LatestRelatedRequests';
import PerformKyc from '../../../customFields/PerformKyc';

import RiskManagement from '../../riskManagment';
import RelatedConductions from '../../../customFields/RelatedConductions';
import NationalIdentifier from '../../../customFields/NationalIdentifier';
import NoResults from '../../layout/NoResults';
import RelatedCustomConnections from '../../connection/RelatedCustomConnections';
import { Actions } from '../../../constants/actions';
import EditArrayInput from '../../../customFields/EditArrayInput';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const Information = () => {
  const { id } = useParams();
  const { permissions } = usePermissions<Actions[]>();
  const redirect = useRedirect();

  const handleKycTabChange = useCallback(() => {
    redirect('kyc-data/business-association', 'individuals', id);
  }, [id, redirect]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={5}>
        <Card variant="outlined">
          <CardHeader
            title="General"
            action={
              hasAccess(permissions, 'RequestCreate') && (<PerformKyc />)
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box>
                  <Title>ID</Title>
                  <TextField source="id" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <EditArrayInput showEdit={hasAccess(permissions, 'IndividualUpdate')} source="customIds" label="Custom IDs" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'IndividualUpdate')} source="firstName" label="Name" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'IndividualUpdate')} source="lastName" label="Last name" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditSelectInput choices={countries} showEdit={hasAccess(permissions, 'BusinessUpdate')} source="residence" label="Residence" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'IndividualUpdate')} source="email" label="Email" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'IndividualUpdate')} source="phoneNumber" label="Phone number" />
              </Grid>
              <Grid item xs={12} md={12}>
                <EditField showEdit={hasAccess(permissions, 'IndividualUpdate')} type="date" source="birthDate" label="Birth date" />
              </Grid>
              <Grid item xs={4} md={4}>
                <Box>
                  <Title>KYC Onboarding</Title>
                  <DateFieldCheck source="kycOnboarding" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <NationalIdentifier showEdit={hasAccess(permissions, 'IndividualUpdate')} />
              </Grid>
              <Grid item xs={12} md={12}>
                <RiskManagement />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={7} container direction="column">
        <Grid item>
          <Card variant="outlined">
            <CardHeader
              title="Business associations"
              action={
                <Button onClick={handleKycTabChange} color="secondary">See more</Button>
              }
            />
            <Divider />
            <CardContent>
              <ArrayField label="Role(s)" source="relatedCompanies">
                <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults />}>
                  <TextField source="name" />
                  <TextField source="roles" />
                </Datagrid>
              </ArrayField>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <RelatedCustomConnections />
        </Grid>
      </Grid>
      <RelatedConductions />
      <LatestRelatedRequests />
      <LatestRelatedCases />
      <LatestRelatedPings />
    </Grid>
  );
};

export default Information;
