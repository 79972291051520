import React from 'react';
import {
  Create,
  FormTab,
  required,
  TabbedForm,
  TextInput,
} from 'react-admin';

import ColorPicker from '../layout/ColorPicker';

const TagCreate = () => (
  <Create>
    <TabbedForm defaultValues={{ color: '#000000' }}>
      <FormTab label="General">
        <TextInput validate={required()} source="name" />
        <ColorPicker />
      </FormTab>
    </TabbedForm>
  </Create>
);

export default TagCreate;
