import { Grid, Typography } from '@mui/material';
import React from 'react';

import {
  ReferenceArrayInput, SelectArrayInput,
} from 'react-admin';

const TagArraySelectInput = ({ edit }: { edit: boolean }) => (
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Typography variant="label">Tag assignment</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <ReferenceArrayInput source="tags" reference="tags" filter={{ active: true }}>
        <SelectArrayInput disabled={!edit} label="Tag names" fullWidth />
      </ReferenceArrayInput>
    </Grid>
  </Grid>
);

export default TagArraySelectInput;
