import React, { useContext } from 'react';
import { Button } from '@mui/material';
import LoginContext from './LoginContext';
import { mapSwitchLabel } from '../loginMethodsFeatures';

const SwitchLoginMethods = () => {
  const loginContext = useContext(LoginContext);

  if (!loginContext) return null;
  const { switchLoginMethod, accountDetails, loginMethods } = loginContext;

  const unselectedLoginMethod = (loginMethods ?? [])
    .filter(({ method }) => method !== accountDetails?.currentLoginMethod);

  if (!unselectedLoginMethod.length) return null;
  return unselectedLoginMethod.map(({ method }) => (
    <Button
      key={method}
      color="primary"
      onClick={() => switchLoginMethod(method)}
    >
      {mapSwitchLabel[method]}
    </Button>
  ));
};

export default SwitchLoginMethods;
