import React, { useCallback, useContext } from 'react';
import { Button } from '@mui/material';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import { useMutation } from 'react-query';

import ButtonGroup from '../../ButtonGroup';
import SwitchLoginMethods from '../SwitchLoginMethods';
import LoginContext from '../LoginContext';

type StartSsoResponse = {
  context: string;
};

const SSOPage = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const loginContext = useContext(LoginContext);

  const {
    mutateAsync: startSso,
  } = useMutation(async (alias: string): Promise<StartSsoResponse> => {
    const res = await dataProvider.create('auth/start-sso', {
      data: { alias },
    });
    return res.data;
  });

  const ssoLogin = useCallback(async () => {
    if (!loginContext) return;
    const { accountDetails } = loginContext;
    if (!accountDetails) return;
    const { alias } = accountDetails;

    try {
      const data = await startSso(alias);
      redirect(data.context);
    } catch (error: any) {
      notify(error.message, { type: 'error' });
    }
  }, [notify, redirect, startSso, loginContext]);

  return (
    <ButtonGroup sx={{ mt: 6 }}>
      <Button
        variant="contained"
        onClick={ssoLogin}
        color="primary"
      >
        Sign in with SSO
      </Button>
      <SwitchLoginMethods />
    </ButtonGroup>
  );
};

export default SSOPage;
