import React from 'react';

import { SelectField, useRecordContext } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import { industrySectionChoices } from '../../../../constants/industrySections';
import { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return <SelectField choices={industrySectionChoices(language)} record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Industry = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label="Industry of the Business"
      source="industry"
      datapoint={conflictFields.INDUSTRY}
    >
      <ValueField language={language} />
    </Answer>
  );
};

Industry.ValueField = ValueField;

export default Industry;
