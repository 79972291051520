import React from 'react';

import {
  TimeInput,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

const TimeWindow = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Time Window</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TimeInput parse={(time: Date) => time.toString()} fullWidth source="ruleParameters.timeWindow.from" label="From" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimeInput parse={(time: Date) => time.toString()} fullWidth source="ruleParameters.timeWindow.to" label="To" />
      </Grid>
    </Grid>
  </Grid>
);

export default TimeWindow;
