import ruleNames, { RuleNames } from '../../../constants/ruleNames';
import actions, { Actions } from '../../../constants/actions';

const rulePermissions: Record<RuleNames, Actions[]> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: [actions.INDIVIDUAL_CREATE_SPAR],
  [ruleNames.AFFORDABILITY]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.AGE_THRESHOLD]: [actions.INDIVIDUAL_CREATE],
  [ruleNames.AMOUNT_THRESHOLD]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.ANOMALY_DETECTION]: [actions.REQUEST_CREATE],
  [ruleNames.BUSINESS_ACTIVE]: [actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.BUSINESS_AUDITOR]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_DATA_CHANGED]: [actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.BUSINESS_EQUITY_SHARE]: [actions.BUSINESS_CREATE_FINANCIAL_DATA],
  [ruleNames.BUSINESS_EQUITY]: [actions.BUSINESS_CREATE_FINANCIAL_DATA],
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: [actions.BUSINESS_CREATE_FINANCIAL_DATA],
  [ruleNames.BUSINESS_FTAX]: [actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_PROVIDER_WARNING]: [actions.BUSINESS_CREATE_CREDIT],
  [ruleNames.BUSINESS_SANCTION_LIST]: [actions.BUSINESS_CREATE_EXPOSURE],
  [ruleNames.BUSINESS_VAT]: [actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: [actions.CAR_CREATE_PREVIOUS_OWNERS_DATA],
  [ruleNames.CAR_GUARANTOR_RELATED]: [actions.CAR_GET],
  [ruleNames.CAR_IN_CIRCULATION]: [actions.CAR_CREATE_BASIC_DATA],
  [ruleNames.CAR_VALUATION]: [actions.CAR_GET],
  [ruleNames.CASH_FLOW]: [actions.REQUEST_CREATE],
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.CO_ADDRESS]: [actions.INDIVIDUAL_CREATE_SPAR, actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.COLLECTOR]: [actions.INDIVIDUAL_CREATE_SPAR, actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.CONNECTED_BUYER_SELLER]:
    [actions.BUSINESS_CREATE_OWNERSHIP, actions.BUSINESS_GET_COMPANY_REP],
  [ruleNames.CONNECTED_ENTITY_PEP]: [
    actions.BUSINESS_CREATE_OWNERSHIP,
    actions.INDIVIDUAL_CREATE_PEP,
    actions.INDIVIDUAL_CREATE_SANCTION_LIST,
    actions.BUSINESS_CREATE_SANCTION_LIST_COMPANY,
  ],
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: [
    actions.BUSINESS_CREATE_OWNERSHIP,
    actions.INDIVIDUAL_CREATE_PEP,
    actions.INDIVIDUAL_CREATE_SANCTION_LIST,
    actions.BUSINESS_CREATE_SANCTION_LIST_COMPANY,
  ],
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: [actions.INDIVIDUAL_CREATE_SPAR],
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]:
    [actions.INDIVIDUAL_CREATE_SPAR, actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]:
    [actions.INDIVIDUAL_CREATE_SPAR, actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.EMPLOYMENT_TYPE]: [actions.INDIVIDUAL_GET],
  [ruleNames.FOREIGN_ADDRESS]: [actions.INDIVIDUAL_CREATE_SPAR],
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.INCOMING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]:
    [actions.BUSINESS_CREATE_OWNERSHIP, actions.INDIVIDUAL_CREATE_LEGAL_SCORE],
  [ruleNames.INDIVIDUAL_PEP]: [actions.INDIVIDUAL_CREATE_PEP],
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: [actions.INDIVIDUAL_CREATE_SANCTION_LIST],
  [ruleNames.IP_COUNTRY_BLACKLIST]:
    [actions.INDIVIDUAL_CREATE_IP_ADDRESS, actions.BUSINESS_CREATE_IP_ADDRESS],
  [ruleNames.KYC_VALID]: [actions.INDIVIDUAL_GET, actions.BUSINESS_GET],
  [ruleNames.LOW_INCOME]: [actions.INDIVIDUAL_GET],
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.OUTGOING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.PROCESSING_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]:
    [actions.BUSINESS_CREATE_OWNERSHIP, actions.INDIVIDUAL_CREATE_LEGAL_SCORE],
  [ruleNames.SELLER_OWNS_CAR]: [actions.CAR_CREATE_BASIC_DATA],
  [ruleNames.SNI_CODE_BLACKLIST]: [actions.BUSINESS_CREATE_GENERAL_INFO],
  [ruleNames.SOURCE_OF_FUNDS]: [actions.INDIVIDUAL_GET, actions.BUSINESS_GET],
  [ruleNames.SOURCE_OF_INCOME]: [actions.INDIVIDUAL_GET, actions.BUSINESS_GET],
  [ruleNames.SPAR_CHANGED]: [actions.INDIVIDUAL_CREATE_SPAR],
  [ruleNames.SUSPICIOUS_FUNDS]: [actions.INDIVIDUAL_GET, actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.TEST_BLOCK]: [actions.REQUEST_LIST],
  [ruleNames.TEST_REVIEW]: [actions.REQUEST_LIST],
  [ruleNames.TIME_WINDOW]: [actions.REQUEST_CREATE],
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: [actions.BANK_DATA_GET_CATEGORIZATION],
  [ruleNames.TOTAL_FREQUENCY]: [actions.REQUEST_LIST],
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: [actions.REQUEST_LIST],
  [ruleNames.ZIP_CODE_BLACKLIST]:
    [actions.INDIVIDUAL_CREATE_SPAR, actions.BUSINESS_CREATE_GENERAL_INFO],
};

export default rulePermissions;
