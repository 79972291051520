import {
  green, grey,
} from '@mui/material/colors';

const customVariables = {
  header: {
    color: grey[500],
    background: '#FFF',
    search: {
      color: grey[800],
    },
    indicator: {
      background: green[600],
    },
  },
  footer: {
    color: grey[500],
    background: '#FFF',
  },
  sidebar: {
    color: '#FFF',
    background: '#124131',
    header: {
      color: '#FFF',
      background: '#124131',
      brand: {
        color: '#FFFFFF',
      },
    },
    footer: {
      color: '#FFF',
      background: '#103a2c',
      online: {
        background: '#238061',
      },
    },
    badge: {
      color: '#000',
      background: '#FFF',
    },
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    header: {
      color: string;
      background: string;
      search: {
        color: string;
      };
      indicator: {
        background: string;
      };
    };
    footer: {
      color: string;
      background: string;
    };
    sidebar: {
      color: string;
      background: string;
      header: {
        color: string;
        background: string;
        brand: {
          color: string;
        };
      };
      footer: {
        color: string;
        background: string;
        online: {
          background: string;
        };
      };
      badge: {
        color: string;
        background: string;
      };
    };
  }
}

export default customVariables;
