import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const employmentStatuses = {
  FULL_TIME: 'fullTime',
  PART_TIME: 'partTime',
  STUDENT: 'student',
  UNEMPLOYED: 'unemployed',
  RETIRED: 'retired',
} as const;

const employmentStatusEnLabels = {
  [employmentStatuses.FULL_TIME]: 'Full-time employed',
  [employmentStatuses.PART_TIME]: 'Part-time employed',
  [employmentStatuses.STUDENT]: 'Student',
  [employmentStatuses.UNEMPLOYED]: 'Unemployed/job seeking',
  [employmentStatuses.RETIRED]: 'Retired',
} as const;

const employmentStatusSvLabels = {
  [employmentStatuses.FULL_TIME]: 'Fast anställd',
  [employmentStatuses.PART_TIME]: 'Deltidsanställd',
  [employmentStatuses.STUDENT]: 'Studerande',
  [employmentStatuses.UNEMPLOYED]: 'Arbetslös/arbetssökande',
  [employmentStatuses.RETIRED]: 'Pensionär',
} as const;

const employmentStatusLabels = {
  [languages.EN]: employmentStatusEnLabels,
  [languages.SV]: employmentStatusSvLabels,
} as const;

export const employmentStatusChoices = (
  language: Languages,
) => Object.values(employmentStatuses).map((section) => ({
  id: section,
  name: employmentStatusLabels[language][section],
}));

export type EmploymentStatuses = ValueOf<typeof employmentStatuses>;

export default employmentStatuses;
