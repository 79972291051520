import { useEffect } from 'react';
import { useNotify, useRedirect, useResourceContext } from 'react-admin';
import { useParams } from 'react-router-dom';

const useValidateId = (): {
  id: string;
  isValid: true;
} | {
  isValid: false;
} => {
  const { id } = useParams();
  const redirect = useRedirect();
  const notify = useNotify();
  const resource = useResourceContext();

  const isValid = !!id && id.length === 24;

  useEffect(() => {
    if (!isValid) {
      redirect('list', resource);
      notify('Invalid URL parameter', { type: 'error' });
    }
  }, [isValid, notify, redirect, resource]);

  if (isValid) {
    return {
      id,
      isValid,
    };
  }
  return {
    isValid,
  };
};

export default useValidateId;
