import React, { useCallback } from 'react';

import {
  ArrayField,
  ArrayInput,
  Datagrid,
  Form,
  SelectField,
  SelectInput,
  SimpleFormIterator,
  TextField,
  TextInput,
  required,
  useNotify,
  useRecordContext,
  useUpdate,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Divider,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
} from '@mui/material';

import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import CreateIcon from '@mui/icons-material/Create';

import { isEmpty } from 'lodash';
import useDialogStatus from '../hooks/useDialogStatus';

import { alpha2ToFlagEmoji } from './CountryFlag';

import { countryChoices } from '../constants/countries';
import languages from '../constants/languages';
import { boldDataGridStyle } from '../constants/style/datagridStyles';
import { identifierTypeChoices } from '../constants/identifierTypes';
import NoResults from '../components/layout/NoResults';

export const NationalIdentifierInputFields = ({ source }: { source?: string }) => (
  <>
    <Typography fontWeight="bold">National Identifiers</Typography>
    <ArrayInput source={source ?? 'nationalIdentifier'} label={false} fullWidth defaultValue={[{}]}>
      <SimpleFormIterator inline sx={{ '.RaSimpleFormIterator-form': { m: 2, alignItems: 'center' } }} disableReordering>
        <SelectInput source="country" helperText={false} choices={countryChoices(languages.EN)} validate={[required()]} />
        <SelectInput source="type" helperText={false} choices={identifierTypeChoices(languages.EN)} validate={[required()]} />
        <TextInput source="identifier" helperText={false} validate={[required()]} />
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

export const NationalIdentifierSingleField = ({
  limit = 2,
  underline = true,
  label,
  emptyText,
}: {
  limit?: number,
  underline?: boolean
  label?: string
  emptyText?: string
}) => {
  const record = useRecordContext();

  if (!record) return emptyText ?? null;

  const value = record.nationalIdentifier as Array<{ country: string, identifier: string }>;

  if (isEmpty(value)) return emptyText ?? null;

  const text = value
    .slice(0, limit)
    .map((item, index, array) => `${alpha2ToFlagEmoji(item.country)} ${item.identifier}${index < array.length - 1 ? ', ' : ''}`);

  return (
    <Typography key={label} sx={underline ? { textDecoration: 'underline' } : {}}>
      {text}
      {value.length > 2 && '...'}
    </Typography>

  );
};

const EditDialog = () => {
  const notify = useNotify();
  const record = useRecordContext();
  const { id } = useParams();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const [update] = useUpdate();

  const handelSave = useCallback((data: FieldValues) => {
    update('individuals', { id, data }, {
      onError: () => notify('Could not update national identifiers', { type: 'error' }),
      onSuccess: closeDialog,
      mutationMode: 'pessimistic',
    });
  }, [closeDialog, id, notify, update]);

  return (
    <>
      <Button startIcon={<CreateIcon color="secondary" />} size="small" onClick={openDialog}>
        Edit
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <Form
          values={{
            nationalIdentifier: record?.nationalIdentifier,
          }}
          onSubmit={handelSave}
        >
          <DialogTitle>National identifiers</DialogTitle>
          <DialogContent>
            <NationalIdentifierInputFields />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={closeDialog}>Cancel</Button>
            <Button type="submit" variant="contained">Save</Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

const Actions = ({ showEdit }: { showEdit: boolean }) => {
  if (!showEdit) return null;

  return (
    <EditDialog />
  );
};

export const NationalIdentifierDataGrid = () => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults text="No data to display." icon={null} />}>
    <SelectField choices={countryChoices(languages.EN)} source="country" />
    <TextField source="identifier" />
  </Datagrid>
);

const NationalIdentifier = ({ showEdit }: { showEdit: boolean }) => (
  <Card variant="outlined">
    <CardHeader
      sx={{
        color: 'black',
        fontWeight: 'bold',
        flexWrap: 'wrap',
      }}
      titleTypographyProps={{ variant: 'inherit' }}
      title="National identifiers"
      action={<Actions showEdit={showEdit} />}
    />
    <Divider />
    <ArrayField source="nationalIdentifier">
      <NationalIdentifierDataGrid />
    </ArrayField>
  </Card>
);
export default NationalIdentifier;
