import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const legalFormTypes = {
  LIMITED_LIABILITY_COMPANY: 'limited-liability-company',
  ECONOMIC_ASSOCIATION: 'economic-association',
  SOLE_PROPRIETORSHIP: 'sole-proprietorship',
  GENERAL_PARTNERSHIP: 'general-partnership',
  NON_PROFIT_MAKING_ASSOCIATION: 'non-profit-making-association',
  LIMITED_PARTNERSHIP: 'limited-partnership',
  FOUNDATION: 'foundation',
  OTHER: 'other',
} as const;

const legalFormTypeEnLabels = {
  [legalFormTypes.LIMITED_LIABILITY_COMPANY]: 'Limited Liability Company',
  [legalFormTypes.ECONOMIC_ASSOCIATION]: 'Economic Association',
  [legalFormTypes.SOLE_PROPRIETORSHIP]: 'Sole Proprietorship',
  [legalFormTypes.GENERAL_PARTNERSHIP]: 'General Partnership',
  [legalFormTypes.NON_PROFIT_MAKING_ASSOCIATION]: 'Non-Profit Making Association',
  [legalFormTypes.LIMITED_PARTNERSHIP]: 'Limited Partnership',
  [legalFormTypes.FOUNDATION]: 'Foundation',
  [legalFormTypes.OTHER]: 'Other',
} as const;

const legalFormTypeSvLabels = {
  [legalFormTypes.LIMITED_LIABILITY_COMPANY]: 'Aktiebolag',
  [legalFormTypes.ECONOMIC_ASSOCIATION]: 'Ekonomisk förening',
  [legalFormTypes.SOLE_PROPRIETORSHIP]: 'Enskild firma',
  [legalFormTypes.GENERAL_PARTNERSHIP]: 'Handelsbolag',
  [legalFormTypes.NON_PROFIT_MAKING_ASSOCIATION]: 'Ideell förening',
  [legalFormTypes.LIMITED_PARTNERSHIP]: 'Kommanditbolag',
  [legalFormTypes.FOUNDATION]: 'Stiftelse',
  [legalFormTypes.OTHER]: 'Annat',
} as const;

const legalFormTypeLabels = {
  [languages.EN]: legalFormTypeEnLabels,
  [languages.SV]: legalFormTypeSvLabels,
} as const;

export const legalFormTypeChoices = (
  language: Languages,
) => Object.values(legalFormTypes).map((type) => ({
  id: type,
  name: legalFormTypeLabels[language][type],
}));

export type LegalFormTypes = ValueOf<typeof legalFormTypes>;

export default legalFormTypes;
