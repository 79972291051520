import React, { useCallback } from 'react';

import {
  Identifier,
} from 'react-admin';

import {
  Button,
} from '@mui/material';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import useListFilter from '../../hooks/useListFilter';
import useDialogStatus from '../../hooks/useDialogStatus';

import EntitySelectionDialog from '../entities/EntitySelectionDialog';

const EntityFilter = ({ source, label }: {source: string, label: string}) => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const { handelChangeFilter, filterValues } = useListFilter({ source });

  const currentFilterValues = filterValues[source] as string[] | undefined;

  const handleSelect = useCallback((selectedData: { id: Identifier }, wasSelected: boolean) => {
    if (wasSelected) {
      handelChangeFilter({
        [source]: (currentFilterValues ?? []).filter((id) => id !== selectedData.id),
      });
    } else {
      handelChangeFilter({
        [source]: [...(currentFilterValues ?? []), selectedData.id],
      });
    }
  }, [currentFilterValues, handelChangeFilter, source]);

  return (
    <>
      <Button
        sx={{ width: '100%' }}
        startIcon={<PlaylistAddIcon />}
        variant="contained"
        onClick={openDialog}
      >
        {`Filter Entities (${currentFilterValues?.length ?? 0})`}
      </Button>
      <EntitySelectionDialog
        open={open}
        handleClose={closeDialog}
        multiple
        label={`${label} filter`}
        currentValue={currentFilterValues ?? []}
        handleSelect={handleSelect}
      />
    </>
  );
};

export default EntityFilter;
