import React from 'react';

import {
  TextField,
  useRecordContext,
  SelectField,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Divider,
  styled,
} from '@mui/material';

import { countryChoices } from '../../../../constants/countries';
import { IBusiness } from '../../../../utilities/schemas/business';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const EntityData = () => {
  const record = useRecordContext<IBusiness>();

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Entity Data" />
        <Divider />
        <CardContent>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Title>Name</Title>
              <TextField record={record} source="name" emptyText="-" />
            </Box>
            <Box>
              <Title>Residence</Title>
              <SelectField choices={countryChoices('en')} record={record} source="residence" emptyText="-" />
            </Box>
            <Box>
              <Title>Registration number</Title>
              <TextField record={record} source="registrationNumber" emptyText="-" />
            </Box>

          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EntityData;
