/* eslint-disable no-underscore-dangle */
import { ValueOf } from '../utilities/types';

export const sniCodes = {
  _01110: '01110',
  _01120: '01120',
  _01131: '01131',
  _01132: '01132',
  _01133: '01133',
  _01134: '01134',
  _01135: '01135',
  _01140: '01140',
  _01150: '01150',
  _01160: '01160',
  _01191: '01191',
  _01199: '01199',
  _01210: '01210',
  _01220: '01220',
  _01230: '01230',
  _01240: '01240',
  _01250: '01250',
  _01260: '01260',
  _01270: '01270',
  _01280: '01280',
  _01290: '01290',
  _01301: '01301',
  _01302: '01302',
  _01410: '01410',
  _01420: '01420',
  _01430: '01430',
  _01440: '01440',
  _01450: '01450',
  _01461: '01461',
  _01462: '01462',
  _01471: '01471',
  _01472: '01472',
  _01491: '01491',
  _01492: '01492',
  _01499: '01499',
  _01500: '01500',
  _01610: '01610',
  _01620: '01620',
  _01630: '01630',
  _01640: '01640',
  _01700: '01700',
  _02101: '02101',
  _02102: '02102',
  _02109: '02109',
  _02200: '02200',
  _02300: '02300',
  _02401: '02401',
  _02409: '02409',
  _03111: '03111',
  _03119: '03119',
  _03120: '03120',
  _03210: '03210',
  _03220: '03220',
  _05100: '05100',
  _05200: '05200',
  _06100: '06100',
  _06200: '06200',
  _07100: '07100',
  _07210: '07210',
  _07290: '07290',
  _08110: '08110',
  _08120: '08120',
  _08910: '08910',
  _08920: '08920',
  _08930: '08930',
  _08990: '08990',
  _09100: '09100',
  _09900: '09900',
  _10111: '10111',
  _10112: '10112',
  _10120: '10120',
  _10130: '10130',
  _10200: '10200',
  _10310: '10310',
  _10320: '10320',
  _10390: '10390',
  _10410: '10410',
  _10420: '10420',
  _10511: '10511',
  _10519: '10519',
  _10520: '10520',
  _10611: '10611',
  _10612: '10612',
  _10620: '10620',
  _10710: '10710',
  _10721: '10721',
  _10722: '10722',
  _10730: '10730',
  _10810: '10810',
  _10821: '10821',
  _10822: '10822',
  _10830: '10830',
  _10840: '10840',
  _10850: '10850',
  _10860: '10860',
  _10890: '10890',
  _10910: '10910',
  _10920: '10920',
  _11010: '11010',
  _11020: '11020',
  _11030: '11030',
  _11040: '11040',
  _11050: '11050',
  _11060: '11060',
  _11070: '11070',
  _12000: '12000',
  _13100: '13100',
  _13200: '13200',
  _13300: '13300',
  _13910: '13910',
  _13921: '13921',
  _13922: '13922',
  _13930: '13930',
  _13940: '13940',
  _13950: '13950',
  _13960: '13960',
  _13990: '13990',
  _14110: '14110',
  _14120: '14120',
  _14130: '14130',
  _14140: '14140',
  _14190: '14190',
  _14200: '14200',
  _14310: '14310',
  _14390: '14390',
  _15110: '15110',
  _15120: '15120',
  _15200: '15200',
  _16101: '16101',
  _16102: '16102',
  _16103: '16103',
  _16210: '16210',
  _16220: '16220',
  _16231: '16231',
  _16232: '16232',
  _16233: '16233',
  _16239: '16239',
  _16240: '16240',
  _16291: '16291',
  _16292: '16292',
  _16293: '16293',
  _17111: '17111',
  _17112: '17112',
  _17113: '17113',
  _17121: '17121',
  _17122: '17122',
  _17123: '17123',
  _17129: '17129',
  _17211: '17211',
  _17219: '17219',
  _17220: '17220',
  _17230: '17230',
  _17240: '17240',
  _17290: '17290',
  _18110: '18110',
  _18121: '18121',
  _18122: '18122',
  _18130: '18130',
  _18140: '18140',
  _18200: '18200',
  _19100: '19100',
  _19200: '19200',
  _20110: '20110',
  _20120: '20120',
  _20130: '20130',
  _20140: '20140',
  _20150: '20150',
  _20160: '20160',
  _20170: '20170',
  _20200: '20200',
  _20300: '20300',
  _20410: '20410',
  _20420: '20420',
  _20510: '20510',
  _20520: '20520',
  _20530: '20530',
  _20590: '20590',
  _20600: '20600',
  _21100: '21100',
  _21200: '21200',
  _22110: '22110',
  _22190: '22190',
  _22210: '22210',
  _22220: '22220',
  _22230: '22230',
  _22290: '22290',
  _23110: '23110',
  _23120: '23120',
  _23130: '23130',
  _23140: '23140',
  _23190: '23190',
  _23200: '23200',
  _23310: '23310',
  _23320: '23320',
  _23410: '23410',
  _23420: '23420',
  _23430: '23430',
  _23440: '23440',
  _23490: '23490',
  _23510: '23510',
  _23520: '23520',
  _23610: '23610',
  _23620: '23620',
  _23630: '23630',
  _23640: '23640',
  _23650: '23650',
  _23690: '23690',
  _23701: '23701',
  _23709: '23709',
  _23910: '23910',
  _23991: '23991',
  _23999: '23999',
  _24100: '24100',
  _24200: '24200',
  _24310: '24310',
  _24320: '24320',
  _24330: '24330',
  _24340: '24340',
  _24410: '24410',
  _24420: '24420',
  _24430: '24430',
  _24440: '24440',
  _24450: '24450',
  _24460: '24460',
  _24510: '24510',
  _24520: '24520',
  _24530: '24530',
  _24540: '24540',
  _25110: '25110',
  _25120: '25120',
  _25210: '25210',
  _25290: '25290',
  _25300: '25300',
  _25400: '25400',
  _25500: '25500',
  _25610: '25610',
  _25620: '25620',
  _25710: '25710',
  _25720: '25720',
  _25730: '25730',
  _25910: '25910',
  _25920: '25920',
  _25930: '25930',
  _25940: '25940',
  _25991: '25991',
  _25999: '25999',
  _26110: '26110',
  _26120: '26120',
  _26200: '26200',
  _26300: '26300',
  _26400: '26400',
  _26510: '26510',
  _26520: '26520',
  _26600: '26600',
  _26700: '26700',
  _26800: '26800',
  _27110: '27110',
  _27120: '27120',
  _27200: '27200',
  _27310: '27310',
  _27320: '27320',
  _27330: '27330',
  _27400: '27400',
  _27510: '27510',
  _27520: '27520',
  _27900: '27900',
  _28110: '28110',
  _28120: '28120',
  _28130: '28130',
  _28140: '28140',
  _28150: '28150',
  _28210: '28210',
  _28220: '28220',
  _28230: '28230',
  _28240: '28240',
  _28250: '28250',
  _28290: '28290',
  _28300: '28300',
  _28410: '28410',
  _28490: '28490',
  _28910: '28910',
  _28920: '28920',
  _28930: '28930',
  _28940: '28940',
  _28950: '28950',
  _28960: '28960',
  _28990: '28990',
  _29101: '29101',
  _29102: '29102',
  _29200: '29200',
  _29310: '29310',
  _29320: '29320',
  _30110: '30110',
  _30120: '30120',
  _30200: '30200',
  _30300: '30300',
  _30400: '30400',
  _30910: '30910',
  _30920: '30920',
  _30990: '30990',
  _31011: '31011',
  _31012: '31012',
  _31021: '31021',
  _31022: '31022',
  _31030: '31030',
  _31090: '31090',
  _32110: '32110',
  _32120: '32120',
  _32130: '32130',
  _32200: '32200',
  _32300: '32300',
  _32400: '32400',
  _32501: '32501',
  _32502: '32502',
  _32910: '32910',
  _32990: '32990',
  _33110: '33110',
  _33120: '33120',
  _33130: '33130',
  _33140: '33140',
  _33150: '33150',
  _33160: '33160',
  _33170: '33170',
  _33190: '33190',
  _33200: '33200',
  _35110: '35110',
  _35120: '35120',
  _35130: '35130',
  _35140: '35140',
  _35210: '35210',
  _35220: '35220',
  _35230: '35230',
  _35300: '35300',
  _36001: '36001',
  _36002: '36002',
  _37000: '37000',
  _38110: '38110',
  _38120: '38120',
  _38210: '38210',
  _38220: '38220',
  _38311: '38311',
  _38312: '38312',
  _38319: '38319',
  _38320: '38320',
  _39000: '39000',
  _41100: '41100',
  _41200: '41200',
  _42110: '42110',
  _42120: '42120',
  _42130: '42130',
  _42210: '42210',
  _42220: '42220',
  _42910: '42910',
  _42990: '42990',
  _43110: '43110',
  _43120: '43120',
  _43130: '43130',
  _43210: '43210',
  _43221: '43221',
  _43222: '43222',
  _43223: '43223',
  _43229: '43229',
  _43290: '43290',
  _43310: '43310',
  _43320: '43320',
  _43330: '43330',
  _43341: '43341',
  _43342: '43342',
  _43390: '43390',
  _43911: '43911',
  _43912: '43912',
  _43991: '43991',
  _43999: '43999',
  _45110: '45110',
  _45191: '45191',
  _45192: '45192',
  _45201: '45201',
  _45202: '45202',
  _45203: '45203',
  _45204: '45204',
  _45310: '45310',
  _45320: '45320',
  _45400: '45400',
  _46110: '46110',
  _46120: '46120',
  _46130: '46130',
  _46141: '46141',
  _46142: '46142',
  _46150: '46150',
  _46160: '46160',
  _46170: '46170',
  _46180: '46180',
  _46190: '46190',
  _46210: '46210',
  _46220: '46220',
  _46230: '46230',
  _46240: '46240',
  _46310: '46310',
  _46320: '46320',
  _46330: '46330',
  _46340: '46340',
  _46350: '46350',
  _46360: '46360',
  _46370: '46370',
  _46380: '46380',
  _46390: '46390',
  _46410: '46410',
  _46420: '46420',
  _46431: '46431',
  _46432: '46432',
  _46433: '46433',
  _46434: '46434',
  _46435: '46435',
  _46440: '46440',
  _46450: '46450',
  _46460: '46460',
  _46470: '46470',
  _46480: '46480',
  _46491: '46491',
  _46492: '46492',
  _46499: '46499',
  _46510: '46510',
  _46521: '46521',
  _46522: '46522',
  _46610: '46610',
  _46620: '46620',
  _46630: '46630',
  _46640: '46640',
  _46650: '46650',
  _46660: '46660',
  _46691: '46691',
  _46692: '46692',
  _46699: '46699',
  _46710: '46710',
  _46720: '46720',
  _46731: '46731',
  _46732: '46732',
  _46741: '46741',
  _46742: '46742',
  _46750: '46750',
  _46761: '46761',
  _46762: '46762',
  _46769: '46769',
  _46771: '46771',
  _46772: '46772',
  _46773: '46773',
  _46900: '46900',
  _47111: '47111',
  _47112: '47112',
  _47191: '47191',
  _47199: '47199',
  _47210: '47210',
  _47220: '47220',
  _47230: '47230',
  _47241: '47241',
  _47242: '47242',
  _47250: '47250',
  _47260: '47260',
  _47291: '47291',
  _47299: '47299',
  _47300: '47300',
  _47410: '47410',
  _47420: '47420',
  _47430: '47430',
  _47510: '47510',
  _47521: '47521',
  _47522: '47522',
  _47523: '47523',
  _47531: '47531',
  _47532: '47532',
  _47540: '47540',
  _47591: '47591',
  _47592: '47592',
  _47593: '47593',
  _47594: '47594',
  _47595: '47595',
  _47610: '47610',
  _47621: '47621',
  _47622: '47622',
  _47630: '47630',
  _47641: '47641',
  _47642: '47642',
  _47643: '47643',
  _47650: '47650',
  _47711: '47711',
  _47712: '47712',
  _47713: '47713',
  _47714: '47714',
  _47715: '47715',
  _47721: '47721',
  _47722: '47722',
  _47730: '47730',
  _47740: '47740',
  _47750: '47750',
  _47761: '47761',
  _47762: '47762',
  _47771: '47771',
  _47772: '47772',
  _47781: '47781',
  _47782: '47782',
  _47783: '47783',
  _47784: '47784',
  _47789: '47789',
  _47791: '47791',
  _47792: '47792',
  _47793: '47793',
  _47810: '47810',
  _47820: '47820',
  _47890: '47890',
  _47911: '47911',
  _47912: '47912',
  _47913: '47913',
  _47914: '47914',
  _47915: '47915',
  _47916: '47916',
  _47917: '47917',
  _47919: '47919',
  _47991: '47991',
  _47992: '47992',
  _47993: '47993',
  _47994: '47994',
  _47999: '47999',
  _49100: '49100',
  _49200: '49200',
  _49311: '49311',
  _49319: '49319',
  _49320: '49320',
  _49390: '49390',
  _49410: '49410',
  _49420: '49420',
  _49500: '49500',
  _50101: '50101',
  _50102: '50102',
  _50201: '50201',
  _50202: '50202',
  _50301: '50301',
  _50302: '50302',
  _50401: '50401',
  _50402: '50402',
  _51101: '51101',
  _51102: '51102',
  _51211: '51211',
  _51212: '51212',
  _51220: '51220',
  _52100: '52100',
  _52211: '52211',
  _52219: '52219',
  _52220: '52220',
  _52230: '52230',
  _52241: '52241',
  _52249: '52249',
  _52290: '52290',
  _53100: '53100',
  _53201: '53201',
  _53202: '53202',
  _53203: '53203',
  _55101: '55101',
  _55102: '55102',
  _55103: '55103',
  _55201: '55201',
  _55202: '55202',
  _55300: '55300',
  _55900: '55900',
  _56100: '56100',
  _56210: '56210',
  _56291: '56291',
  _56292: '56292',
  _56293: '56293',
  _56294: '56294',
  _56299: '56299',
  _56300: '56300',
  _58110: '58110',
  _58120: '58120',
  _58131: '58131',
  _58132: '58132',
  _58140: '58140',
  _58190: '58190',
  _58210: '58210',
  _58290: '58290',
  _59110: '59110',
  _59120: '59120',
  _59130: '59130',
  _59140: '59140',
  _59200: '59200',
  _60100: '60100',
  _60200: '60200',
  _61100: '61100',
  _61200: '61200',
  _61300: '61300',
  _61900: '61900',
  _62010: '62010',
  _62020: '62020',
  _62030: '62030',
  _62090: '62090',
  _63110: '63110',
  _63120: '63120',
  _63910: '63910',
  _63990: '63990',
  _64110: '64110',
  _64190: '64190',
  _64201: '64201',
  _64202: '64202',
  _64301: '64301',
  _64309: '64309',
  _64910: '64910',
  _64920: '64920',
  _64991: '64991',
  _64992: '64992',
  _64993: '64993',
  _64999: '64999',
  _65111: '65111',
  _65119: '65119',
  _65120: '65120',
  _65200: '65200',
  _65300: '65300',
  _66110: '66110',
  _66120: '66120',
  _66190: '66190',
  _66210: '66210',
  _66220: '66220',
  _66290: '66290',
  _66301: '66301',
  _66309: '66309',
  _68100: '68100',
  _68201: '68201',
  _68202: '68202',
  _68203: '68203',
  _68204: '68204',
  _68209: '68209',
  _68310: '68310',
  _68320: '68320',
  _69101: '69101',
  _69102: '69102',
  _69103: '69103',
  _69201: '69201',
  _69202: '69202',
  _69203: '69203',
  _70100: '70100',
  _70210: '70210',
  _70220: '70220',
  _71110: '71110',
  _71121: '71121',
  _71122: '71122',
  _71123: '71123',
  _71124: '71124',
  _71129: '71129',
  _71200: '71200',
  _72110: '72110',
  _72190: '72190',
  _72200: '72200',
  _73111: '73111',
  _73112: '73112',
  _73119: '73119',
  _73120: '73120',
  _73200: '73200',
  _74101: '74101',
  _74102: '74102',
  _74103: '74103',
  _74201: '74201',
  _74202: '74202',
  _74203: '74203',
  _74204: '74204',
  _74300: '74300',
  _74900: '74900',
  _75000: '75000',
  _77110: '77110',
  _77120: '77120',
  _77210: '77210',
  _77220: '77220',
  _77290: '77290',
  _77310: '77310',
  _77320: '77320',
  _77330: '77330',
  _77340: '77340',
  _77350: '77350',
  _77390: '77390',
  _77400: '77400',
  _78100: '78100',
  _78200: '78200',
  _78300: '78300',
  _79110: '79110',
  _79120: '79120',
  _79900: '79900',
  _80100: '80100',
  _80200: '80200',
  _80300: '80300',
  _81100: '81100',
  _81210: '81210',
  _81221: '81221',
  _81222: '81222',
  _81290: '81290',
  _81300: '81300',
  _82110: '82110',
  _82190: '82190',
  _82200: '82200',
  _82300: '82300',
  _82910: '82910',
  _82920: '82920',
  _82990: '82990',
  _84111: '84111',
  _84112: '84112',
  _84113: '84113',
  _84114: '84114',
  _84115: '84115',
  _84121: '84121',
  _84122: '84122',
  _84123: '84123',
  _84124: '84124',
  _84125: '84125',
  _84131: '84131',
  _84132: '84132',
  _84133: '84133',
  _84139: '84139',
  _84210: '84210',
  _84221: '84221',
  _84222: '84222',
  _84223: '84223',
  _84231: '84231',
  _84232: '84232',
  _84233: '84233',
  _84240: '84240',
  _84250: '84250',
  _84300: '84300',
  _85100: '85100',
  _85201: '85201',
  _85202: '85202',
  _85311: '85311',
  _85312: '85312',
  _85321: '85321',
  _85322: '85322',
  _85323: '85323',
  _85324: '85324',
  _85410: '85410',
  _85420: '85420',
  _85510: '85510',
  _85521: '85521',
  _85522: '85522',
  _85530: '85530',
  _85591: '85591',
  _85592: '85592',
  _85593: '85593',
  _85594: '85594',
  _85599: '85599',
  _85600: '85600',
  _86101: '86101',
  _86102: '86102',
  _86103: '86103',
  _86211: '86211',
  _86212: '86212',
  _86221: '86221',
  _86222: '86222',
  _86230: '86230',
  _86901: '86901',
  _86902: '86902',
  _86903: '86903',
  _86904: '86904',
  _86905: '86905',
  _86909: '86909',
  _87100: '87100',
  _87201: '87201',
  _87202: '87202',
  _87203: '87203',
  _87301: '87301',
  _87302: '87302',
  _87901: '87901',
  _87902: '87902',
  _88101: '88101',
  _88102: '88102',
  _88910: '88910',
  _88991: '88991',
  _88992: '88992',
  _88993: '88993',
  _88994: '88994',
  _88995: '88995',
  _90010: '90010',
  _90020: '90020',
  _90030: '90030',
  _90040: '90040',
  _91011: '91011',
  _91012: '91012',
  _91020: '91020',
  _91030: '91030',
  _91040: '91040',
  _92000: '92000',
  _93111: '93111',
  _93112: '93112',
  _93113: '93113',
  _93114: '93114',
  _93119: '93119',
  _93120: '93120',
  _93130: '93130',
  _93191: '93191',
  _93199: '93199',
  _93210: '93210',
  _93290: '93290',
  _94111: '94111',
  _94112: '94112',
  _94120: '94120',
  _94200: '94200',
  _94910: '94910',
  _94920: '94920',
  _94990: '94990',
  _95110: '95110',
  _95120: '95120',
  _95210: '95210',
  _95220: '95220',
  _95230: '95230',
  _95240: '95240',
  _95250: '95250',
  _95290: '95290',
  _96011: '96011',
  _96012: '96012',
  _96021: '96021',
  _96022: '96022',
  _96030: '96030',
  _96040: '96040',
  _96090: '96090',
  _97000: '97000',
  _98100: '98100',
  _98200: '98200',
  _99000: '99000',
} as const;

export const sniCodesDescriptions = {
  [sniCodes._01110]: 'A.01.110 Growing of cereals (except rice), leguminous crops and oil seeds',
  [sniCodes._01120]: 'A.01.120 Growing of rice',
  [sniCodes._01131]: 'A.01.131 Growing of potatoes',
  [sniCodes._01132]: 'A.01.132 Growing of sugar beet',
  [sniCodes._01133]: 'A.01.133 Growing of vegetables in the open',
  [sniCodes._01134]: 'A.01.134 Growing of vegetables in greenhouses',
  [sniCodes._01135]: 'A.01.135 Growing of mushrooms etc.',
  [sniCodes._01140]: 'A.01.140 Growing of sugar cane',
  [sniCodes._01150]: 'A.01.150 Growing of tobacco',
  [sniCodes._01160]: 'A.01.160 Growing of fibre crops',
  [sniCodes._01191]: 'A.01.191 Growing of flowers and ornamental plants in greenhouses',
  [sniCodes._01199]: 'A.01.199 Growing of other non-perennial crops n.e.c.',
  [sniCodes._01210]: 'A.01.210 Growing of grapes',
  [sniCodes._01220]: 'A.01.220 Growing of tropical and subtropical fruits',
  [sniCodes._01230]: 'A.01.230 Growing of citrus fruits',
  [sniCodes._01240]: 'A.01.240 Growing of pome fruits and stone fruits',
  [sniCodes._01250]: 'A.01.250 Growing of other tree and bush fruits and nuts',
  [sniCodes._01260]: 'A.01.260 Growing of oleaginous fruits',
  [sniCodes._01270]: 'A.01.270 Growing of beverage crops',
  [sniCodes._01280]: 'A.01.280 Growing of spices, aromatic, drug and pharmaceutical crops',
  [sniCodes._01290]: 'A.01.290 Growing of other perennial crops',
  [sniCodes._01301]: 'A.01.301 Plant propagation in greenhouses',
  [sniCodes._01302]: 'A.01.302 Plant propagation in the open',
  [sniCodes._01410]: 'A.01.410 Milk production and raising of dairy cattle',
  [sniCodes._01420]: 'A.01.420 Raising of other cattle and buffaloes',
  [sniCodes._01430]: 'A.01.430 Raising of horses and other equines',
  [sniCodes._01440]: 'A.01.440 Raising of camels and camelids',
  [sniCodes._01450]: 'A.01.450 Raising of sheep and  goats',
  [sniCodes._01461]: 'A.01.461 Raising of piglets',
  [sniCodes._01462]: 'A.01.462 Raising of swine for slaughter',
  [sniCodes._01471]: 'A.01.471 Egg production',
  [sniCodes._01472]: 'A.01.472 Raising of poultry',
  [sniCodes._01491]: 'A.01.491 Reindeer husbandry',
  [sniCodes._01492]: 'A.01.492 Breeding of pet animals',
  [sniCodes._01499]: 'A.01.499 Raising of other animals n.e.c.',
  [sniCodes._01500]: 'A.01.500 Mixed farming',
  [sniCodes._01610]: 'A.01.610 Support activities for crop production',
  [sniCodes._01620]: 'A.01.620 Support activities for animal production',
  [sniCodes._01630]: 'A.01.630 Post-harvest crop activities',
  [sniCodes._01640]: 'A.01.640 Seed processing for propagation',
  [sniCodes._01700]: 'A.01.700 Hunting, trapping and related service activities',
  [sniCodes._02101]: 'A.02.101 Forest management',
  [sniCodes._02102]: 'A.02.102 Silviculture',
  [sniCodes._02109]: 'A.02.109 Other forestry activities',
  [sniCodes._02200]: 'A.02.200 Logging',
  [sniCodes._02300]: 'A.02.300 Gathering of wild growing non-wood products',
  [sniCodes._02401]: 'A.02.401 Wood measurement',
  [sniCodes._02409]: 'A.02.409 Other support services to forestry',
  [sniCodes._03111]: 'A.03.111 Marine trawling',
  [sniCodes._03119]: 'A.03.119 Other marine fishing',
  [sniCodes._03120]: 'A.03.120 Freshwater fishing',
  [sniCodes._03210]: 'A.03.210 Marine aquaculture',
  [sniCodes._03220]: 'A.03.220 Freshwater aquaculture',
  [sniCodes._05100]: 'B.05.100 Mining of hard coal',
  [sniCodes._05200]: 'B.05.200 Mining of lignite',
  [sniCodes._06100]: 'B.06.100 Extraction of crude petroleum',
  [sniCodes._06200]: 'B.06.200 Extraction of natural gas',
  [sniCodes._07100]: 'B.07.100 Mining of iron ores',
  [sniCodes._07210]: 'B.07.210 Mining of uranium and thorium ores',
  [sniCodes._07290]: 'B.07.290 Mining of other non-ferrous metal ores',
  [sniCodes._08110]: 'B.08.110 Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
  [sniCodes._08120]: 'B.08.120 Operation of gravel and sand pits; mining of clays and kaolin',
  [sniCodes._08910]: 'B.08.910 Mining of chemical and fertiliser minerals',
  [sniCodes._08920]: 'B.08.920 Extraction of peat',
  [sniCodes._08930]: 'B.08.930 Extraction of salt',
  [sniCodes._08990]: 'B.08.990 Other mining and quarrying n.e.c.',
  [sniCodes._09100]: 'B.09.100 Support activities for petroleum and natural gas extraction',
  [sniCodes._09900]: 'B.09.900 Support activities for other mining and quarrying',
  [sniCodes._10111]: 'C.10.111 Livestock slaughtering',
  [sniCodes._10112]: 'C.10.112 Processing and preserving of meat in cuts',
  [sniCodes._10120]: 'C.10.120 Processing and preserving of poultry meat',
  [sniCodes._10130]: 'C.10.130 Production of meat and poultry meat products',
  [sniCodes._10200]: 'C.10.200 Processing and preserving of fish, crustaceans and molluscs',
  [sniCodes._10310]: 'C.10.310 Processing and preserving of potatoes',
  [sniCodes._10320]: 'C.10.320 Manufacture of fruit and vegetable juice',
  [sniCodes._10390]: 'C.10.390 Other processing and preserving of fruit and vegetables',
  [sniCodes._10410]: 'C.10.410 Manufacture of oils and fats',
  [sniCodes._10420]: 'C.10.420 Manufacture of margarine and similar edible fats',
  [sniCodes._10511]: 'C.10.511 Cheese production',
  [sniCodes._10519]: 'C.10.519 Other dairy production',
  [sniCodes._10520]: 'C.10.520 Manufacture of ice cream',
  [sniCodes._10611]: 'C.10.611 Production of flour',
  [sniCodes._10612]: 'C.10.612 Manufacture of breakfast cereals, blended flour mixes and other prepared grain mill products',
  [sniCodes._10620]: 'C.10.620 Manufacture of starches and starch products',
  [sniCodes._10710]: 'C.10.710 Manufacture of bread; manufacture of fresh pastry goods and cakes',
  [sniCodes._10721]: 'C.10.721 Manufacture of crispbread',
  [sniCodes._10722]: 'C.10.722 Manufacture of rusks, biscuits and preserved pastry goods and cakes',
  [sniCodes._10730]: 'C.10.730 Manufacture of macaroni, noodles, couscous and similar farinaceous products',
  [sniCodes._10810]: 'C.10.810 Manufacture of sugar',
  [sniCodes._10821]: 'C.10.821 Manufacture of sugar confectionery',
  [sniCodes._10822]: 'C.10.822 Manufacture of cocoa and chocolate confectionery',
  [sniCodes._10830]: 'C.10.830 Processing of tea and coffee',
  [sniCodes._10840]: 'C.10.840 Manufacture of condiments and seasonings',
  [sniCodes._10850]: 'C.10.850 Manufacture of prepared meals and dishes',
  [sniCodes._10860]: 'C.10.860 Manufacture of homogenised food preparations and dietetic food',
  [sniCodes._10890]: 'C.10.890 Manufacture of other food products n.e.c.',
  [sniCodes._10910]: 'C.10.910 Manufacture of prepared feeds for farm animals',
  [sniCodes._10920]: 'C.10.920 Manufacture of prepared pet foods',
  [sniCodes._11010]: 'C.11.010 Distilling, rectifying and blending of spirits',
  [sniCodes._11020]: 'C.11.020 Manufacture of wine from grape',
  [sniCodes._11030]: 'C.11.030 Manufacture of cider and other fruit wines',
  [sniCodes._11040]: 'C.11.040 Manufacture of other non-distilled fermented beverages',
  [sniCodes._11050]: 'C.11.050 Manufacture of beer',
  [sniCodes._11060]: 'C.11.060 Manufacture of malt',
  [sniCodes._11070]: 'C.11.070 Manufacture of soft drinks; production of mineral waters and other bottled waters',
  [sniCodes._12000]: 'C.12.000 Manufacture of tobacco products',
  [sniCodes._13100]: 'C.13.100 Preparation and spinning of textile fibres',
  [sniCodes._13200]: 'C.13.200 Weaving of textiles',
  [sniCodes._13300]: 'C.13.300 Finishing of textiles',
  [sniCodes._13910]: 'C.13.910 Manufacture of knitted and crocheted fabrics',
  [sniCodes._13921]: 'C.13.921 Manufacture of curtains, bed linen and other linen goods',
  [sniCodes._13922]: 'C.13.922 Manufacture of tarpaulins, tents, sails etc.',
  [sniCodes._13930]: 'C.13.930 Manufacture of carpets and rugs',
  [sniCodes._13940]: 'C.13.940 Manufacture of cordage, rope, twine and netting',
  [sniCodes._13950]: 'C.13.950 Manufacture of non-wovens and articles made from non-wovens, except apparel',
  [sniCodes._13960]: 'C.13.960 Manufacture of other technical and industrial textiles',
  [sniCodes._13990]: 'C.13.990 Manufacture of other textiles n.e.c.',
  [sniCodes._14110]: 'C.14.110 Manufacture of leather clothes',
  [sniCodes._14120]: 'C.14.120 Manufacture of workwear',
  [sniCodes._14130]: 'C.14.130 Manufacture of other outerwear',
  [sniCodes._14140]: 'C.14.140 Manufacture of underwear',
  [sniCodes._14190]: 'C.14.190 Manufacture of other wearing apparel and accessories',
  [sniCodes._14200]: 'C.14.200 Manufacture of articles of fur',
  [sniCodes._14310]: 'C.14.310 Manufacture of knitted and crocheted hosiery',
  [sniCodes._14390]: 'C.14.390 Manufacture of other knitted and crocheted apparel',
  [sniCodes._15110]: 'C.15.110 Tanning and dressing of leather; dressing and dyeing of fur',
  [sniCodes._15120]: 'C.15.120 Manufacture of luggage, handbags and the like, saddlery and harness',
  [sniCodes._15200]: 'C.15.200 Manufacture of footwear',
  [sniCodes._16101]: 'C.16.101 Sawmilling',
  [sniCodes._16102]: 'C.16.102 Planing of wood',
  [sniCodes._16103]: 'C.16.103 Impregnation of wood',
  [sniCodes._16210]: 'C.16.210 Manufacture of veneer sheets and wood-based panels',
  [sniCodes._16220]: 'C.16.220 Manufacture of assembled parquet floors',
  [sniCodes._16231]: 'C.16.231 Manufacture of prefabricated wooden buildings',
  [sniCodes._16232]: 'C.16.232 Manufacture of wooden doors',
  [sniCodes._16233]: 'C.16.233 Manufacture of wooden windows',
  [sniCodes._16239]: 'C.16.239 Manufacture of other builders carpentry and joinery n.e.c.',
  [sniCodes._16240]: 'C.16.240 Manufacture of wooden containers',
  [sniCodes._16291]: 'C.16.291 Manufacture of wood fuels',
  [sniCodes._16292]: 'C.16.292 Manufacture of other products of wood',
  [sniCodes._16293]: 'C.16.293 Manufacture of articles of cork, straw and plaiting materials',
  [sniCodes._17111]: 'C.17.111 Manufacture of mechanical or semi-chemical pulp',
  [sniCodes._17112]: 'C.17.112 Manufacture of sulphate pulp',
  [sniCodes._17113]: 'C.17.113 Manufacture of sulphite pulp',
  [sniCodes._17121]: 'C.17.121 Manufacture of newsprint',
  [sniCodes._17122]: 'C.17.122 Manufacture of other printing paper',
  [sniCodes._17123]: 'C.17.123 Manufacture of kraft paper and paperboard',
  [sniCodes._17129]: 'C.17.129 Manufacture of other paper and paperboard',
  [sniCodes._17211]: 'C.17.211 Manufacture of corrugated paper and paperboard and corrugated board containers',
  [sniCodes._17219]: 'C.17.219 Manufacture of other containers of paper and paperboard',
  [sniCodes._17220]: 'C.17.220 Manufacture of household and sanitary goods and of toilet requisites',
  [sniCodes._17230]: 'C.17.230 Manufacture of paper stationery',
  [sniCodes._17240]: 'C.17.240 Manufacture of wallpaper',
  [sniCodes._17290]: 'C.17.290 Manufacture of other articles of paper and paperboard',
  [sniCodes._18110]: 'C.18.110 Printing of newspapers',
  [sniCodes._18121]: 'C.18.121 Printing of periodicals',
  [sniCodes._18122]: 'C.18.122 Book printing and other printing',
  [sniCodes._18130]: 'C.18.130 Pre-press and pre-media services',
  [sniCodes._18140]: 'C.18.140 Binding and related services',
  [sniCodes._18200]: 'C.18.200 Reproduction of recorded media',
  [sniCodes._19100]: 'C.19.100 Manufacture of coke oven products',
  [sniCodes._19200]: 'C.19.200 Manufacture of refined petroleum products',
  [sniCodes._20110]: 'C.20.110 Manufacture of industrial gases',
  [sniCodes._20120]: 'C.20.120 Manufacture of dyes and pigments',
  [sniCodes._20130]: 'C.20.130 Manufacture of other inorganic basic chemicals',
  [sniCodes._20140]: 'C.20.140 Manufacture of other organic basic chemicals',
  [sniCodes._20150]: 'C.20.150 Manufacture of fertilisers and nitrogen compounds',
  [sniCodes._20160]: 'C.20.160 Manufacture of plastics in primary forms',
  [sniCodes._20170]: 'C.20.170 Manufacture of synthetic rubber in primary forms',
  [sniCodes._20200]: 'C.20.200 Manufacture of pesticides and other agrochemical products',
  [sniCodes._20300]: 'C.20.300 Manufacture of paints, varnishes and similar coatings, printing ink and mastics',
  [sniCodes._20410]: 'C.20.410 Manufacture of soap and detergents, cleaning and polishing preparations',
  [sniCodes._20420]: 'C.20.420 Manufacture of perfumes and toilet preparations',
  [sniCodes._20510]: 'C.20.510 Manufacture of explosives',
  [sniCodes._20520]: 'C.20.520 Manufacture of glues',
  [sniCodes._20530]: 'C.20.530 Manufacture of essential oils',
  [sniCodes._20590]: 'C.20.590 Manufacture of other chemical products n.e.c.',
  [sniCodes._20600]: 'C.20.600 Manufacture of man-made fibres',
  [sniCodes._21100]: 'C.21.100 Manufacture of basic pharmaceutical products',
  [sniCodes._21200]: 'C.21.200 Manufacture of pharmaceutical preparations',
  [sniCodes._22110]: 'C.22.110 Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
  [sniCodes._22190]: 'C.22.190 Manufacture of other rubber products',
  [sniCodes._22210]: 'C.22.210 Manufacture of plastic plates, sheets, tubes and profiles',
  [sniCodes._22220]: 'C.22.220 Manufacture of plastic packing goods',
  [sniCodes._22230]: 'C.22.230 Manufacture of builders’ ware of plastic',
  [sniCodes._22290]: 'C.22.290 Manufacture of other plastic products',
  [sniCodes._23110]: 'C.23.110 Manufacture of flat glass',
  [sniCodes._23120]: 'C.23.120 Shaping and processing of flat glass',
  [sniCodes._23130]: 'C.23.130 Manufacture of hollow glass',
  [sniCodes._23140]: 'C.23.140 Manufacture of glass fibres',
  [sniCodes._23190]: 'C.23.190 Manufacture and processing of other glass, including technical glassware',
  [sniCodes._23200]: 'C.23.200 Manufacture of refractory products',
  [sniCodes._23310]: 'C.23.310 Manufacture of ceramic tiles and flags',
  [sniCodes._23320]: 'C.23.320 Manufacture of bricks, tiles and construction products, in baked clay',
  [sniCodes._23410]: 'C.23.410 Manufacture of ceramic household and ornamental articles',
  [sniCodes._23420]: 'C.23.420 Manufacture of ceramic sanitary fixtures',
  [sniCodes._23430]: 'C.23.430 Manufacture of ceramic insulators and insulating fittings',
  [sniCodes._23440]: 'C.23.440 Manufacture of other technical ceramic products',
  [sniCodes._23490]: 'C.23.490 Manufacture of other ceramic products',
  [sniCodes._23510]: 'C.23.510 Manufacture of cement',
  [sniCodes._23520]: 'C.23.520 Manufacture of lime and plaster',
  [sniCodes._23610]: 'C.23.610 Manufacture of concrete products for construction purposes',
  [sniCodes._23620]: 'C.23.620 Manufacture of plaster products for construction purposes',
  [sniCodes._23630]: 'C.23.630 Manufacture of ready-mixed concrete',
  [sniCodes._23640]: 'C.23.640 Manufacture of mortars',
  [sniCodes._23650]: 'C.23.650 Manufacture of fibre cement',
  [sniCodes._23690]: 'C.23.690 Manufacture of other articles of concrete, plaster and cement',
  [sniCodes._23701]: 'C.23.701 Cutting, shaping and finishing of building stone',
  [sniCodes._23709]: 'C.23.709 Cutting, shaping and finishing of ornamental stone',
  [sniCodes._23910]: 'C.23.910 Production of abrasive products',
  [sniCodes._23991]: 'C.23.991 Manufacture of stone and mineral wool products',
  [sniCodes._23999]: 'C.23.999 Manufacture of various other non-metallic mineral products n.e.c.',
  [sniCodes._24100]: 'C.24.100 Manufacture of basic iron and steel and of ferro-alloys',
  [sniCodes._24200]: 'C.24.200 Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
  [sniCodes._24310]: 'C.24.310 Cold drawing of bars',
  [sniCodes._24320]: 'C.24.320 Cold rolling of narrow strip',
  [sniCodes._24330]: 'C.24.330 Cold forming or folding',
  [sniCodes._24340]: 'C.24.340 Cold drawing of wire',
  [sniCodes._24410]: 'C.24.410 Precious metals production',
  [sniCodes._24420]: 'C.24.420 Aluminium production',
  [sniCodes._24430]: 'C.24.430 Lead, zinc and tin production',
  [sniCodes._24440]: 'C.24.440 Copper production',
  [sniCodes._24450]: 'C.24.450 Other non-ferrous metal production',
  [sniCodes._24460]: 'C.24.460 Processing of nuclear fuel',
  [sniCodes._24510]: 'C.24.510 Casting of iron',
  [sniCodes._24520]: 'C.24.520 Casting of steel',
  [sniCodes._24530]: 'C.24.530 Casting of light metals',
  [sniCodes._24540]: 'C.24.540 Casting of other non-ferrous metals',
  [sniCodes._25110]: 'C.25.110 Manufacture of metal structures and parts of structures',
  [sniCodes._25120]: 'C.25.120 Manufacture of doors and windows of metal',
  [sniCodes._25210]: 'C.25.210 Manufacture of central heating radiators and boilers',
  [sniCodes._25290]: 'C.25.290 Manufacture of other tanks, reservoirs and containers of metal',
  [sniCodes._25300]: 'C.25.300 Manufacture of steam generators, except central heating hot water boilers',
  [sniCodes._25400]: 'C.25.400 Manufacture of weapons and ammunition',
  [sniCodes._25500]: 'C.25.500 Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
  [sniCodes._25610]: 'C.25.610 Treatment and coating of metals',
  [sniCodes._25620]: 'C.25.620 Machining',
  [sniCodes._25710]: 'C.25.710 Manufacture of cutlery',
  [sniCodes._25720]: 'C.25.720 Manufacture of locks and hinges',
  [sniCodes._25730]: 'C.25.730 Manufacture of tools',
  [sniCodes._25910]: 'C.25.910 Manufacture of steel drums and similar containers',
  [sniCodes._25920]: 'C.25.920 Manufacture of light metal packaging',
  [sniCodes._25930]: 'C.25.930 Manufacture of wire products, chain and springs',
  [sniCodes._25940]: 'C.25.940 Manufacture of fasteners and screw machine products',
  [sniCodes._25991]: 'C.25.991 Manufacture of sinks, sanitary ware etc. of metal for construction purposes',
  [sniCodes._25999]: 'C.25.999 Manufacture of various other fabricated metal products n.e.c.',
  [sniCodes._26110]: 'C.26.110 Manufacture of electronic components',
  [sniCodes._26120]: 'C.26.120 Manufacture of loaded electronic boards',
  [sniCodes._26200]: 'C.26.200 Manufacture of computers and peripheral equipment',
  [sniCodes._26300]: 'C.26.300 Manufacture of communication equipment',
  [sniCodes._26400]: 'C.26.400 Manufacture of consumer electronics',
  [sniCodes._26510]: 'C.26.510 Manufacture of instruments and appliances for measuring, testing and navigation',
  [sniCodes._26520]: 'C.26.520 Manufacture of watches and clocks',
  [sniCodes._26600]: 'C.26.600 Manufacture of irradiation, electromedical and electrotherapeutic equipment',
  [sniCodes._26700]: 'C.26.700 Manufacture of optical instruments and photographic equipment',
  [sniCodes._26800]: 'C.26.800 Manufacture of magnetic and optical media',
  [sniCodes._27110]: 'C.27.110 Manufacture of electric motors, generators and transformers',
  [sniCodes._27120]: 'C.27.120 Manufacture of electricity distribution and control apparatus',
  [sniCodes._27200]: 'C.27.200 Manufacture of batteries and accumulators',
  [sniCodes._27310]: 'C.27.310 Manufacture of fibre optic cables',
  [sniCodes._27320]: 'C.27.320 Manufacture of other electronic and electric wires and cables',
  [sniCodes._27330]: 'C.27.330 Manufacture of wiring devices',
  [sniCodes._27400]: 'C.27.400 Manufacture of electric lighting equipment',
  [sniCodes._27510]: 'C.27.510 Manufacture of electric domestic appliances',
  [sniCodes._27520]: 'C.27.520 Manufacture of non-electric domestic appliances',
  [sniCodes._27900]: 'C.27.900 Manufacture of other electrical equipment',
  [sniCodes._28110]: 'C.28.110 Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
  [sniCodes._28120]: 'C.28.120 Manufacture of fluid power equipment',
  [sniCodes._28130]: 'C.28.130 Manufacture of other pumps and compressors',
  [sniCodes._28140]: 'C.28.140 Manufacture of other taps and valves',
  [sniCodes._28150]: 'C.28.150 Manufacture of bearings, gears, gearing and driving elements',
  [sniCodes._28210]: 'C.28.210 Manufacture of ovens, furnaces and furnace burners',
  [sniCodes._28220]: 'C.28.220 Manufacture of lifting and handling equipment',
  [sniCodes._28230]: 'C.28.230 Manufacture of office machinery and equipment (except computers and peripheral equipment)',
  [sniCodes._28240]: 'C.28.240 Manufacture of power-driven hand tools',
  [sniCodes._28250]: 'C.28.250 Manufacture of non-domestic cooling and ventilation equipment',
  [sniCodes._28290]: 'C.28.290 Manufacture of other general-purpose machinery n.e.c.',
  [sniCodes._28300]: 'C.28.300 Manufacture of agricultural and forestry machinery',
  [sniCodes._28410]: 'C.28.410 Manufacture of metal forming machinery',
  [sniCodes._28490]: 'C.28.490 Manufacture of other machine tools',
  [sniCodes._28910]: 'C.28.910 Manufacture of machinery for metallurgy',
  [sniCodes._28920]: 'C.28.920 Manufacture of machinery for mining, quarrying and construction',
  [sniCodes._28930]: 'C.28.930 Manufacture of machinery for food, beverage and tobacco processing',
  [sniCodes._28940]: 'C.28.940 Manufacture of machinery for textile, apparel and leather production',
  [sniCodes._28950]: 'C.28.950 Manufacture of machinery for paper and paperboard production',
  [sniCodes._28960]: 'C.28.960 Manufacture of plastics and rubber machinery',
  [sniCodes._28990]: 'C.28.990 Manufacture of other special-purpose machinery n.e.c.',
  [sniCodes._29101]: 'C.29.101 Manufacture of passenger cars and other light motor vehicles',
  [sniCodes._29102]: 'C.29.102 Manufacture of trucks and other heavy motor vehicles',
  [sniCodes._29200]: 'C.29.200 Manufacture of bodies (coachwork) for motor vehicles; manufacture of trailers and semi-trailers',
  [sniCodes._29310]: 'C.29.310 Manufacture of electrical and electronic equipment for motor vehicles',
  [sniCodes._29320]: 'C.29.320 Manufacture of other parts and accessories for motor vehicles',
  [sniCodes._30110]: 'C.30.110 Building of ships and floating structures',
  [sniCodes._30120]: 'C.30.120 Building of pleasure and sporting boats',
  [sniCodes._30200]: 'C.30.200 Manufacture of railway locomotives and rolling stock',
  [sniCodes._30300]: 'C.30.300 Manufacture of air and spacecraft and related machinery',
  [sniCodes._30400]: 'C.30.400 Manufacture of military fighting vehicles',
  [sniCodes._30910]: 'C.30.910 Manufacture of motorcycles',
  [sniCodes._30920]: 'C.30.920 Manufacture of bicycles and invalid carriages',
  [sniCodes._30990]: 'C.30.990 Manufacture of other transport equipment n.e.c.',
  [sniCodes._31011]: 'C.31.011 Manufacture of office and shop furniture',
  [sniCodes._31012]: 'C.31.012 Manufacture of office and shop fittings',
  [sniCodes._31021]: 'C.31.021 Manufacture of kitchen furniture',
  [sniCodes._31022]: 'C.31.022 Manufacture of kitchen fittings',
  [sniCodes._31030]: 'C.31.030 Manufacture of mattresses',
  [sniCodes._31090]: 'C.31.090 Manufacture of other furniture',
  [sniCodes._32110]: 'C.32.110 Striking of coins',
  [sniCodes._32120]: 'C.32.120 Manufacture of jewellery and related articles',
  [sniCodes._32130]: 'C.32.130 Manufacture of imitation jewellery and related articles',
  [sniCodes._32200]: 'C.32.200 Manufacture of musical instruments',
  [sniCodes._32300]: 'C.32.300 Manufacture of sports goods',
  [sniCodes._32400]: 'C.32.400 Manufacture of games and toys',
  [sniCodes._32501]: 'C.32.501 Manufacture of medical and dental instruments and supplies',
  [sniCodes._32502]: 'C.32.502 Manufacture of artificial teeth, dentures, dental plates etc.',
  [sniCodes._32910]: 'C.32.910 Manufacture of brooms and brushes',
  [sniCodes._32990]: 'C.32.990 Other manufacturing n.e.c.',
  [sniCodes._33110]: 'C.33.110 Repair of fabricated metal products',
  [sniCodes._33120]: 'C.33.120 Repair of machinery',
  [sniCodes._33130]: 'C.33.130 Repair of electronic and optical equipment',
  [sniCodes._33140]: 'C.33.140 Repair of electrical equipment',
  [sniCodes._33150]: 'C.33.150 Repair and maintenance of ships and boats',
  [sniCodes._33160]: 'C.33.160 Repair and maintenance of aircraft and spacecraft',
  [sniCodes._33170]: 'C.33.170 Repair and maintenance of other transport equipment',
  [sniCodes._33190]: 'C.33.190 Repair of other equipment',
  [sniCodes._33200]: 'C.33.200 Installation of industrial machinery and equipment',
  [sniCodes._35110]: 'D.35.110 Production of electricity',
  [sniCodes._35120]: 'D.35.120 Transmission of electricity',
  [sniCodes._35130]: 'D.35.130 Distribution of electricity',
  [sniCodes._35140]: 'D.35.140 Trade of electricity',
  [sniCodes._35210]: 'D.35.210 Manufacture of gas',
  [sniCodes._35220]: 'D.35.220 Distribution of gaseous fuels through mains',
  [sniCodes._35230]: 'D.35.230 Trade of gas through mains',
  [sniCodes._35300]: 'D.35.300 Steam and air conditioning supply',
  [sniCodes._36001]: 'E.36.001 Collection, treatment and supply of groundwater',
  [sniCodes._36002]: 'E.36.002 Collection, treatment and supply of surface water',
  [sniCodes._37000]: 'E.37.000 Sewerage',
  [sniCodes._38110]: 'E.38.110 Collection of non-hazardous waste',
  [sniCodes._38120]: 'E.38.120 Collection of hazardous waste',
  [sniCodes._38210]: 'E.38.210 Treatment and disposal of non-hazardous waste',
  [sniCodes._38220]: 'E.38.220 Treatment and disposal of hazardous waste',
  [sniCodes._38311]: 'E.38.311 Dismantling of car wrecks',
  [sniCodes._38312]: 'E.38.312 Dismantling of electric and electronic equipment',
  [sniCodes._38319]: 'E.38.319 Dismantling of other wrecks',
  [sniCodes._38320]: 'E.38.320 Recovery of sorted materials',
  [sniCodes._39000]: 'E.39.000 Remediation activities and other waste management services',
  [sniCodes._41100]: 'F.41.100 Development of building projects',
  [sniCodes._41200]: 'F.41.200 Construction of residential and non-residential buildings',
  [sniCodes._42110]: 'F.42.110 Construction of roads and motorways',
  [sniCodes._42120]: 'F.42.120 Construction of railways and underground railways',
  [sniCodes._42130]: 'F.42.130 Construction of bridges and tunnels',
  [sniCodes._42210]: 'F.42.210 Construction of utility projects for fluids',
  [sniCodes._42220]: 'F.42.220 Construction of utility projects for electricity and telecommunications',
  [sniCodes._42910]: 'F.42.910 Construction of water projects',
  [sniCodes._42990]: 'F.42.990 Construction of other civil engineering projects n.e.c.',
  [sniCodes._43110]: 'F.43.110 Demolition',
  [sniCodes._43120]: 'F.43.120 Site preparation',
  [sniCodes._43130]: 'F.43.130 Test drilling and boring',
  [sniCodes._43210]: 'F.43.210 Electrical installation',
  [sniCodes._43221]: 'F.43.221 Installation of heating and sanitary equipment',
  [sniCodes._43222]: 'F.43.222 Installation of ventilation equipment',
  [sniCodes._43223]: 'F.43.223 Installation of refrigeration and freezing equipment',
  [sniCodes._43229]: 'F.43.229 Other plumbing',
  [sniCodes._43290]: 'F.43.290 Other construction installation',
  [sniCodes._43310]: 'F.43.310 Plastering',
  [sniCodes._43320]: 'F.43.320 Joinery installation',
  [sniCodes._43330]: 'F.43.330 Floor and wall covering',
  [sniCodes._43341]: 'F.43.341 Painting',
  [sniCodes._43342]: 'F.43.342 Glazing',
  [sniCodes._43390]: 'F.43.390 Other building completion and finishing',
  [sniCodes._43911]: 'F.43.911 Erection of sheet-metal roof covering',
  [sniCodes._43912]: 'F.43.912 Erection of other roof covering and frames',
  [sniCodes._43991]: 'F.43.991 Renting of construction or demolition equipment with operator',
  [sniCodes._43999]: 'F.43.999 Various other specialised construction activities n.e.c.',
  [sniCodes._45110]: 'G.45.110 Sale of cars and light motor vehicles',
  [sniCodes._45191]: 'G.45.191 Sale of lorries, buses and specialised motor vehicles',
  [sniCodes._45192]: 'G.45.192 Sale of caravans, motor homes, trailers and semi-trailers',
  [sniCodes._45201]: 'G.45.201 Non-specialised maintenance and repair of motor vehicles',
  [sniCodes._45202]: 'G.45.202 Bodywork repair and painting of motor vehicles',
  [sniCodes._45203]: 'G.45.203 Installation and repair and painting of electrical and electronic motor vehicle equipment',
  [sniCodes._45204]: 'G.45.204 Tyre service',
  [sniCodes._45310]: 'G.45.310 Wholesale trade of motor vehicle parts and accessories',
  [sniCodes._45320]: 'G.45.320 Retail trade of motor vehicle parts and accessories',
  [sniCodes._45400]: 'G.45.400 Sale, maintenance and repair of motorcycles and related parts and accessories',
  [sniCodes._46110]: 'G.46.110 Agents involved in the sale of agricultural raw materials, live animals, textile raw materials and semi-finished goods',
  [sniCodes._46120]: 'G.46.120 Agents involved in the sale of fuels, ores, metals and industrial chemicals',
  [sniCodes._46130]: 'G.46.130 Agents involved in the sale of timber and building materials',
  [sniCodes._46141]: 'G.46.141 Agents involved in the sale of machinery, industrial equipment, ships and aircraft except office machinery and computer equipment',
  [sniCodes._46142]: 'G.46.142 Agents involved in the sale of office machinery and computer equipment',
  [sniCodes._46150]: 'G.46.150 Agents involved in the sale of furniture, household goods, hardware and ironmongery',
  [sniCodes._46160]: 'G.46.160 Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
  [sniCodes._46170]: 'G.46.170 Agents involved in the sale of food, beverages and tobacco',
  [sniCodes._46180]: 'G.46.180 Agents specialised in the sale of other particular products',
  [sniCodes._46190]: 'G.46.190 Agents involved in the sale of a variety of goods',
  [sniCodes._46210]: 'G.46.210 Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
  [sniCodes._46220]: 'G.46.220 Wholesale of flowers and plants',
  [sniCodes._46230]: 'G.46.230 Wholesale of live animals',
  [sniCodes._46240]: 'G.46.240 Wholesale of hides, skins and leather',
  [sniCodes._46310]: 'G.46.310 Wholesale of fruit and vegetables',
  [sniCodes._46320]: 'G.46.320 Wholesale of meat and meat products',
  [sniCodes._46330]: 'G.46.330 Wholesale of dairy products, eggs and edible oils and fats',
  [sniCodes._46340]: 'G.46.340 Wholesale of beverages',
  [sniCodes._46350]: 'G.46.350 Wholesale of tobacco products',
  [sniCodes._46360]: 'G.46.360 Wholesale of sugar and chocolate and sugar confectionery',
  [sniCodes._46370]: 'G.46.370 Wholesale of coffee, tea, cocoa and spices',
  [sniCodes._46380]: 'G.46.380 Wholesale of other food, including fish, crustaceans and molluscs',
  [sniCodes._46390]: 'G.46.390 Non-specialised wholesale of food, beverages and tobacco',
  [sniCodes._46410]: 'G.46.410 Wholesale of textiles',
  [sniCodes._46420]: 'G.46.420 Wholesale of clothing and footwear',
  [sniCodes._46431]: 'G.46.431 Wholesale of electrical household appliances',
  [sniCodes._46432]: 'G.46.432 Wholesale of radio, television and video equipment',
  [sniCodes._46433]: 'G.46.433 Wholesale of recorded audio and video tapes, cds and dvds',
  [sniCodes._46434]: 'G.46.434 Wholesale of electrical equipment',
  [sniCodes._46435]: 'G.46.435 Wholesale of photographic and optical goods',
  [sniCodes._46440]: 'G.46.440 Wholesale of china and glassware and cleaning materials',
  [sniCodes._46450]: 'G.46.450 Wholesale of perfume and cosmetics',
  [sniCodes._46460]: 'G.46.460 Wholesale of pharmaceutical goods',
  [sniCodes._46470]: 'G.46.470 Wholesale of furniture, carpets and lighting equipment',
  [sniCodes._46480]: 'G.46.480 Wholesale of watches and jewellery',
  [sniCodes._46491]: 'G.46.491 Wholesale of sporting equipment',
  [sniCodes._46492]: 'G.46.492 Wholesale of stationary and other office goods',
  [sniCodes._46499]: 'G.46.499 Wholesale of other household goods n.e.c.',
  [sniCodes._46510]: 'G.46.510 Wholesale of computers, computer peripheral equipment and software',
  [sniCodes._46521]: 'G.46.521 Wholesale of electronic components',
  [sniCodes._46522]: 'G.46.522 Wholesale of telecommunications equipment and parts',
  [sniCodes._46610]: 'G.46.610 Wholesale of agricultural machinery, equipment and supplies',
  [sniCodes._46620]: 'G.46.620 Wholesale of machine tools',
  [sniCodes._46630]: 'G.46.630 Wholesale of mining, construction and civil engineering machinery',
  [sniCodes._46640]: 'G.46.640 Wholesale of machinery for the textile industry and of sewing and knitting machines',
  [sniCodes._46650]: 'G.46.650 Wholesale of office furniture',
  [sniCodes._46660]: 'G.46.660 Wholesale of other office machinery and equipment',
  [sniCodes._46691]: 'G.46.691 Wholesale of measuring and precision instruments',
  [sniCodes._46692]: 'G.46.692 Wholesale of computerized materials handling equipment',
  [sniCodes._46699]: 'G.46.699 Wholesale of other machinery and equipment n.e.c.',
  [sniCodes._46710]: 'G.46.710 Wholesale of solid, liquid and gaseous fuels and related products',
  [sniCodes._46720]: 'G.46.720 Wholesale of metals and metal ores',
  [sniCodes._46731]: 'G.46.731 Wholesale of wood and other construction materials',
  [sniCodes._46732]: 'G.46.732 Wholesale of sanitary equipment',
  [sniCodes._46741]: 'G.46.741 Wholesale of hardware',
  [sniCodes._46742]: 'G.46.742 Wholesale of plumbing and heating equipment',
  [sniCodes._46750]: 'G.46.750 Wholesale of chemical products',
  [sniCodes._46761]: 'G.46.761 Wholesale of industry supplies',
  [sniCodes._46762]: 'G.46.762 Wholesale of packaging materials',
  [sniCodes._46769]: 'G.46.769 Wholesale of other intermediate products n.e.c.',
  [sniCodes._46771]: 'G.46.771 Wholesale in car wrecks',
  [sniCodes._46772]: 'G.46.772 Wholesale of metal waste and scrap',
  [sniCodes._46773]: 'G.46.773 Wholesale of non-metal waste and scrap',
  [sniCodes._46900]: 'G.46.900 Non-specialised wholesale trade',
  [sniCodes._47111]: 'G.47.111 Retail sale in department stores and the like with food, beverages or tobacco predominating',
  [sniCodes._47112]: 'G.47.112 Retail sale in other non-specialised stores with food, beverages or tobacco predominating',
  [sniCodes._47191]: 'G.47.191 Other retail sale in department stores and the like',
  [sniCodes._47199]: 'G.47.199 Other retail sale in non-specialised stores n.e.c.',
  [sniCodes._47210]: 'G.47.210 Retail sale of fruit and vegetables in specialised stores',
  [sniCodes._47220]: 'G.47.220 Retail sale of meat and meat products in specialised stores',
  [sniCodes._47230]: 'G.47.230 Retail sale of fish, crustaceans and molluscs in specialised stores',
  [sniCodes._47241]: 'G.47.241 Retail sale of bread, cakes and flour confectionery in specialised stores',
  [sniCodes._47242]: 'G.47.242 Retail sale of sugar confectionery in specialised stores',
  [sniCodes._47250]: 'G.47.250 Retail sale of beverages in specialised stores',
  [sniCodes._47260]: 'G.47.260 Retail sale of tobacco products in specialised stores',
  [sniCodes._47291]: 'G.47.291 Retail sale of health foods in specialised stores',
  [sniCodes._47299]: 'G.47.299 Other retail sale of food in specialised stores n.e.c.',
  [sniCodes._47300]: 'G.47.300 Retail sale of automotive fuel in specialised stores',
  [sniCodes._47410]: 'G.47.410 Retail sale of computers, peripheral units and software in specialised stores',
  [sniCodes._47420]: 'G.47.420 Retail sale of telecommunications equipment in specialised stores',
  [sniCodes._47430]: 'G.47.430 Retail sale of audio and video equipment in specialised stores',
  [sniCodes._47510]: 'G.47.510 Retail sale of textiles in specialised stores',
  [sniCodes._47521]: 'G.47.521 Retail sale of wood and other building materials in specialised stores',
  [sniCodes._47522]: 'G.47.522 Retail sale of plumbing and heating equipment in specialised stores',
  [sniCodes._47523]: 'G.47.523 Retail sale of paints in specialised stores',
  [sniCodes._47531]: 'G.47.531 Retail sale of carpets, rugs, wall and floor coverings in specialised stores',
  [sniCodes._47532]: 'G.47.532 Retail sale of home furnishing textiles in specialised stores',
  [sniCodes._47540]: 'G.47.540 Retail sale of electrical household appliances in specialised stores',
  [sniCodes._47591]: 'G.47.591 Retail sale of home furniture in specialised stores',
  [sniCodes._47592]: 'G.47.592 Retail sale of office furniture in specialised stores',
  [sniCodes._47593]: 'G.47.593 Retail sale of glassware, china and kitchenware in specialised stores',
  [sniCodes._47594]: 'G.47.594 Retail sale of electrical fittings in specialised stores',
  [sniCodes._47595]: 'G.47.595 Retail sale of musical instruments and music scores in specialised stores',
  [sniCodes._47610]: 'G.47.610 Retail sale of books in specialised stores',
  [sniCodes._47621]: 'G.47.621 Retail sale of newspapers in specialised stores',
  [sniCodes._47622]: 'G.47.622 Retail sale of stationery in specialised stores',
  [sniCodes._47630]: 'G.47.630 Retail sale of music and video recordings in specialised stores',
  [sniCodes._47641]: 'G.47.641 Retail sale of sporting equipment except bicycles in specialised stores',
  [sniCodes._47642]: 'G.47.642 Retail sale of bicycles in specialised stores',
  [sniCodes._47643]: 'G.47.643 Retail sale of boats and boating accessories in specialised stores',
  [sniCodes._47650]: 'G.47.650 Retail sale of games and toys in specialised stores',
  [sniCodes._47711]: 'G.47.711 Retail sale of men, women and children clothing in specialised stores',
  [sniCodes._47712]: 'G.47.712 Retail sale of men clothing in specialised stores',
  [sniCodes._47713]: 'G.47.713 Retail sale of women clothing in specialised stores',
  [sniCodes._47714]: 'G.47.714 Retail sale of children clothing in specialised stores',
  [sniCodes._47715]: 'G.47.715 Retail sale of furs in specialised stores',
  [sniCodes._47721]: 'G.47.721 Retail sale of footwear in specialised stores',
  [sniCodes._47722]: 'G.47.722 Retail sale of leather goods in specialised stores',
  [sniCodes._47730]: 'G.47.730 Dispensing chemist',
  [sniCodes._47740]: 'G.47.740 Retail sale of medical and orthopaedic goods in specialised stores',
  [sniCodes._47750]: 'G.47.750 Retail sale of cosmetic and toilet articles in specialised stores',
  [sniCodes._47761]: 'G.47.761 Retail sale of flowers, plants, seedsand fertilisers in specialised stores',
  [sniCodes._47762]: 'G.47.762 Retail sale of  pet animals and pet food in specialised stores',
  [sniCodes._47771]: 'G.47.771 Retail sale of watches and clocks in specialised stores',
  [sniCodes._47772]: 'G.47.772 Retail sale of jewellery in specialised stores',
  [sniCodes._47781]: 'G.47.781 Retail sale of spectacles and other optical goods except photographic equipment in specialised stores',
  [sniCodes._47782]: 'G.47.782 Retail sale of photographic equipment in specialised stores',
  [sniCodes._47783]: 'G.47.783 Retail sale of art in specialised stores; art gallery activities',
  [sniCodes._47784]: 'G.47.784 Retail sale of coins and stamps in specialised stores',
  [sniCodes._47789]: 'G.47.789 Other retail sale in specialised stores n.e.c.',
  [sniCodes._47791]: 'G.47.791 Retail sale of antiques and second-hand books in stores',
  [sniCodes._47792]: 'G.47.792 Retail sale of other second-hand goods in stores',
  [sniCodes._47793]: 'G.47.793 Activities of auctioning houses',
  [sniCodes._47810]: 'G.47.810 Retail sale via stalls and markets of food, beverages and tobacco products',
  [sniCodes._47820]: 'G.47.820 Retail sale via stalls and markets of textiles, clothing and footwear',
  [sniCodes._47890]: 'G.47.890 Retail sale via stalls and markets of other goods',
  [sniCodes._47911]: 'G.47.911 Non-specialised retail sale via mail order houses or via internet',
  [sniCodes._47912]: 'G.47.912 Retail sale of clothing via mail order houses or via internet',
  [sniCodes._47913]: 'G.47.913 Retail sale of books and other media goods via mail order houses or via internet',
  [sniCodes._47914]: 'G.47.914 Retail sale of computers and other electronic equipment via mail order houses or via internet',
  [sniCodes._47915]: 'G.47.915 Retail sale of sports and leisure goods via mail order houses or via internet',
  [sniCodes._47916]: 'G.47.916 Retail sale of household goods via mail order houses or via internet',
  [sniCodes._47917]: 'G.47.917 Internet retail auctions',
  [sniCodes._47919]: 'G.47.919 Other retail sale via mail order houses or via internet',
  [sniCodes._47991]: 'G.47.991 Retail sale on commission',
  [sniCodes._47992]: 'G.47.992 Ambulatory and occasional retail sale of food',
  [sniCodes._47993]: 'G.47.993 Ambulatory and occasional retail sale of other goods',
  [sniCodes._47994]: 'G.47.994 Auctions not in stores or internet',
  [sniCodes._47999]: 'G.47.999 Retail sale not in stores, stalls or markets n.e.c.',
  [sniCodes._49100]: 'H.49.100 Passenger rail transport, interurban',
  [sniCodes._49200]: 'H.49.200 Freight rail transport',
  [sniCodes._49311]: 'H.49.311 Urban and suburban road passenger transport',
  [sniCodes._49319]: 'H.49.319 Other urban and suburban passenger land transport',
  [sniCodes._49320]: 'H.49.320 Taxi operation',
  [sniCodes._49390]: 'H.49.390 Other passenger land transport n.e.c.',
  [sniCodes._49410]: 'H.49.410 Freight transport by road',
  [sniCodes._49420]: 'H.49.420 Removal services',
  [sniCodes._49500]: 'H.49.500 Transport via pipeline',
  [sniCodes._50101]: 'H.50.101 Scheduled sea and coastal passenger water transport',
  [sniCodes._50102]: 'H.50.102 Non-scheduled sea and coastal passenger water transport',
  [sniCodes._50201]: 'H.50.201 Scheduled sea and coastal freight water transport',
  [sniCodes._50202]: 'H.50.202 Non-scheduled sea and coastal freight water transport',
  [sniCodes._50301]: 'H.50.301 Scheduled inland passenger water transport',
  [sniCodes._50302]: 'H.50.302 Non-scheduled inland passenger water transport',
  [sniCodes._50401]: 'H.50.401 Scheduled inland freight water transport',
  [sniCodes._50402]: 'H.50.402 Non-scheduled inland freight water transport',
  [sniCodes._51101]: 'H.51.101 Scheduled passenger air transport',
  [sniCodes._51102]: 'H.51.102 Non-scheduled passenger air transport',
  [sniCodes._51211]: 'H.51.211 Scheduled freight air transport',
  [sniCodes._51212]: 'H.51.212 Non-scheduled freight air transport',
  [sniCodes._51220]: 'H.51.220 Space transport',
  [sniCodes._52100]: 'H.52.100 Warehousing and storage',
  [sniCodes._52211]: 'H.52.211 Towing incidental to land transportation',
  [sniCodes._52219]: 'H.52.219 Other service activities incidental to land transportation',
  [sniCodes._52220]: 'H.52.220 Service activities incidental to water transportation',
  [sniCodes._52230]: 'H.52.230 Service activities incidental to air transportation',
  [sniCodes._52241]: 'H.52.241 Harbour cargo handling',
  [sniCodes._52249]: 'H.52.249 Other cargo handling',
  [sniCodes._52290]: 'H.52.290 Other transportation support activities',
  [sniCodes._53100]: 'H.53.100 Postal activities under universal service obligation',
  [sniCodes._53201]: 'H.53.201 Other postal activities',
  [sniCodes._53202]: 'H.53.202 Courier activities',
  [sniCodes._53203]: 'H.53.203 Newspaper distribution',
  [sniCodes._55101]: 'I.55.101 Hotels with restaurant except conference centres',
  [sniCodes._55102]: 'I.55.102 Lodging activities of conference centres',
  [sniCodes._55103]: 'I.55.103 Hotels without restaurant',
  [sniCodes._55201]: 'I.55.201 Youth hostels',
  [sniCodes._55202]: 'I.55.202 Other short-stay accommodation',
  [sniCodes._55300]: 'I.55.300 Camping grounds, recreational vehicle parks and trailer parks',
  [sniCodes._55900]: 'I.55.900 Other accommodation',
  [sniCodes._56100]: 'I.56.100 Restaurants and mobile food service activities',
  [sniCodes._56210]: 'I.56.210 Event catering activities',
  [sniCodes._56291]: 'I.56.291 Canteens',
  [sniCodes._56292]: 'I.56.292 Catering for hospitals',
  [sniCodes._56293]: 'I.56.293 Catering for schools, welfare and other institutions',
  [sniCodes._56294]: 'I.56.294 Catering for the transport sector',
  [sniCodes._56299]: 'I.56.299 Other catering',
  [sniCodes._56300]: 'I.56.300 Beverage serving activities',
  [sniCodes._58110]: 'J.58.110 Book publishing',
  [sniCodes._58120]: 'J.58.120 Publishing of directories and mailing lists',
  [sniCodes._58131]: 'J.58.131 Publishing of daily newspapers',
  [sniCodes._58132]: 'J.58.132 Publishing of advertising newspapers',
  [sniCodes._58140]: 'J.58.140 Publishing of journals and periodicals',
  [sniCodes._58190]: 'J.58.190 Other publishing activities',
  [sniCodes._58210]: 'J.58.210 Publishing of computer games',
  [sniCodes._58290]: 'J.58.290 Other software publishing',
  [sniCodes._59110]: 'J.59.110 Motion picture, video and television programme production activities',
  [sniCodes._59120]: 'J.59.120 Motion picture, video and television programme post-production activities',
  [sniCodes._59130]: 'J.59.130 Motion picture, video and television programme distribution activities',
  [sniCodes._59140]: 'J.59.140 Motion picture projection activities',
  [sniCodes._59200]: 'J.59.200 Sound recording and music publishing activities',
  [sniCodes._60100]: 'J.60.100 Radio broadcasting',
  [sniCodes._60200]: 'J.60.200 Television programming and broadcasting activities',
  [sniCodes._61100]: 'J.61.100 Wired telecommunications activities',
  [sniCodes._61200]: 'J.61.200 Wireless telecommunications activities',
  [sniCodes._61300]: 'J.61.300 Satellite telecommunications activities',
  [sniCodes._61900]: 'J.61.900 Other telecommunications activities',
  [sniCodes._62010]: 'J.62.010 Computer programming activities',
  [sniCodes._62020]: 'J.62.020 Computer consultancy activities',
  [sniCodes._62030]: 'J.62.030 Computer facilities management activities',
  [sniCodes._62090]: 'J.62.090 Other information technology and computer service activities',
  [sniCodes._63110]: 'J.63.110 Data processing, hosting and related activities',
  [sniCodes._63120]: 'J.63.120 Web portals',
  [sniCodes._63910]: 'J.63.910 News agency activities',
  [sniCodes._63990]: 'J.63.990 Other information service activities n.e.c.',
  [sniCodes._64110]: 'K.64.110 Central banking',
  [sniCodes._64190]: 'K.64.190 Other monetary intermediation',
  [sniCodes._64201]: 'K.64.201 Activities of financial holding companies',
  [sniCodes._64202]: 'K.64.202 Activities of non-financial holding companies',
  [sniCodes._64301]: 'K.64.301 Investment funds',
  [sniCodes._64309]: 'K.64.309 Other trusts, funds and similar financial entities',
  [sniCodes._64910]: 'K.64.910 Financial leasing',
  [sniCodes._64920]: 'K.64.920 Other credit granting',
  [sniCodes._64991]: 'K.64.991 Activities of investment companies and venture capital companies',
  [sniCodes._64992]: 'K.64.992 Trading in securities on own account',
  [sniCodes._64993]: 'K.64.993 Trading in securities for a limited and closed group of owners',
  [sniCodes._64999]: 'K.64.999 Various other financial service activities, except insurance and pension funding n.e.c.',
  [sniCodes._65111]: 'K.65.111 Unit link insurance',
  [sniCodes._65119]: 'K.65.119 Other life insurance',
  [sniCodes._65120]: 'K.65.120 Non-life insurance',
  [sniCodes._65200]: 'K.65.200 Reinsurance',
  [sniCodes._65300]: 'K.65.300 Pension funding',
  [sniCodes._66110]: 'K.66.110 Administration of financial markets',
  [sniCodes._66120]: 'K.66.120 Security and commodity contracts brokerage',
  [sniCodes._66190]: 'K.66.190 Other activities auxiliary to financial services, except insurance and pension funding',
  [sniCodes._66210]: 'K.66.210 Risk and damage evaluation',
  [sniCodes._66220]: 'K.66.220 Activities of insurance agents and brokers',
  [sniCodes._66290]: 'K.66.290 Other activities auxiliary to insurance and pension funding',
  [sniCodes._66301]: 'K.66.301 Investment fund management activities',
  [sniCodes._66309]: 'K.66.309 Other fund management activities',
  [sniCodes._68100]: 'L.68.100 Buying and selling of own real estate',
  [sniCodes._68201]: 'L.68.201 Renting and operating of own or leased dwelllings',
  [sniCodes._68202]: 'L.68.202 Renting and operating of own or leased industrial premises',
  [sniCodes._68203]: 'L.68.203 Renting and operating of own or leased other premises',
  [sniCodes._68204]: 'L.68.204 Property management of tenant-owners associations',
  [sniCodes._68209]: 'L.68.209 Other renting and operating of own or leased real estate',
  [sniCodes._68310]: 'L.68.310 Real estate agencies',
  [sniCodes._68320]: 'L.68.320 Management of real estate on a fee or contract basis',
  [sniCodes._69101]: 'M.69.101 Legal advisory and representation activities of solicitor firms',
  [sniCodes._69102]: 'M.69.102 Other legal advisory activities',
  [sniCodes._69103]: 'M.69.103 Advisory activities concerning patents and copyrights',
  [sniCodes._69201]: 'M.69.201 Accounting and bookkeeping activities',
  [sniCodes._69202]: 'M.69.202 Auditing activities',
  [sniCodes._69203]: 'M.69.203 Tax consultancy',
  [sniCodes._70100]: 'M.70.100 Activities of head offices',
  [sniCodes._70210]: 'M.70.210 Public relations and communication activities',
  [sniCodes._70220]: 'M.70.220 Business and other management consultancy activities',
  [sniCodes._71110]: 'M.71.110 Architectural activities',
  [sniCodes._71121]: 'M.71.121 Construction and civil engineering activities and related technical consultancy',
  [sniCodes._71122]: 'M.71.122 Industrial engineering activities and related technical consultancy',
  [sniCodes._71123]: 'M.71.123 Electric engineering activities and related technical consultancy',
  [sniCodes._71124]: 'M.71.124 Engineering activities and related technical consultancy in energy, environment, plumbing, heat and air-conditioning',
  [sniCodes._71129]: 'M.71.129 Other engineering activities and related technical consultancy',
  [sniCodes._71200]: 'M.71.200 Technical testing and analysis',
  [sniCodes._72110]: 'M.72.110 Research and experimental development on biotechnology',
  [sniCodes._72190]: 'M.72.190 Other research and experimental development on natural sciences and engineering',
  [sniCodes._72200]: 'M.72.200 Research and experimental development on social sciences and humanities',
  [sniCodes._73111]: 'M.73.111 Advertising agency activities',
  [sniCodes._73112]: 'M.73.112 Delivery of advertising material',
  [sniCodes._73119]: 'M.73.119 Other advertising activities',
  [sniCodes._73120]: 'M.73.120 Media representation',
  [sniCodes._73200]: 'M.73.200 Market research and public opinion polling',
  [sniCodes._74101]: 'M.74.101 Industrial and fashion design',
  [sniCodes._74102]: 'M.74.102 Graphic design',
  [sniCodes._74103]: 'M.74.103 Activities of interior decorators',
  [sniCodes._74201]: 'M.74.201 Portrait photography',
  [sniCodes._74202]: 'M.74.202 Advertising photography',
  [sniCodes._74203]: 'M.74.203 Press and other photography',
  [sniCodes._74204]: 'M.74.204 Photographic laboratory activities',
  [sniCodes._74300]: 'M.74.300 Translation and interpretation activities',
  [sniCodes._74900]: 'M.74.900 Other professional, scientific and technical activities n.e.c.',
  [sniCodes._75000]: 'M.75.000 Veterinary activities',
  [sniCodes._77110]: 'N.77.110 Renting and leasing of cars and light motor vehicles',
  [sniCodes._77120]: 'N.77.120 Renting and leasing of trucks',
  [sniCodes._77210]: 'N.77.210 Renting and leasing of recreational and sports goods',
  [sniCodes._77220]: 'N.77.220 Renting of video tapes and disks',
  [sniCodes._77290]: 'N.77.290 Renting and leasing of other personal and household goods',
  [sniCodes._77310]: 'N.77.310 Renting and leasing of agricultural machinery and equipment',
  [sniCodes._77320]: 'N.77.320 Renting and leasing of construction and civil engineering machinery and equipment',
  [sniCodes._77330]: 'N.77.330 Renting and leasing of office machinery and equipment (including computers)',
  [sniCodes._77340]: 'N.77.340 Renting and leasing of water transport equipment',
  [sniCodes._77350]: 'N.77.350 Renting and leasing of air transport equipment',
  [sniCodes._77390]: 'N.77.390 Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
  [sniCodes._77400]: 'N.77.400 Leasing of intellectual property and similar products, except copyrighted works',
  [sniCodes._78100]: 'N.78.100 Activities of employment placement agencies',
  [sniCodes._78200]: 'N.78.200 Temporary employment agency activities',
  [sniCodes._78300]: 'N.78.300 Other human resources provision',
  [sniCodes._79110]: 'N.79.110 Travel agency activities',
  [sniCodes._79120]: 'N.79.120 Tour operator activities',
  [sniCodes._79900]: 'N.79.900 Other reservation service and related activities',
  [sniCodes._80100]: 'N.80.100 Private security activities',
  [sniCodes._80200]: 'N.80.200 Security systems service activities',
  [sniCodes._80300]: 'N.80.300 Investigation activities',
  [sniCodes._81100]: 'N.81.100 Combined facilities support activities',
  [sniCodes._81210]: 'N.81.210 General cleaning of buildings',
  [sniCodes._81221]: 'N.81.221 Other building cleaning activities',
  [sniCodes._81222]: 'N.81.222 Chimney cleaning',
  [sniCodes._81290]: 'N.81.290 Other cleaning activities',
  [sniCodes._81300]: 'N.81.300 Landscape service activities',
  [sniCodes._82110]: 'N.82.110 Combined office administrative service activities',
  [sniCodes._82190]: 'N.82.190 Photocopying, document preparation and other specialised office support activities',
  [sniCodes._82200]: 'N.82.200 Activities of call centres',
  [sniCodes._82300]: 'N.82.300 Organisation of conventions and trade shows',
  [sniCodes._82910]: 'N.82.910 Activities of collection agencies and credit bureaus',
  [sniCodes._82920]: 'N.82.920 Packaging activities',
  [sniCodes._82990]: 'N.82.990 Other business support service activities n.e.c.',
  [sniCodes._84111]: 'O.84.111 Executive and legislative administration of central and local government',
  [sniCodes._84112]: 'O.84.112 Inspection, control, permit and licensing activities of central and local government',
  [sniCodes._84113]: 'O.84.113 Fiscal activities',
  [sniCodes._84114]: 'O.84.114 Public dissemination of information',
  [sniCodes._84115]: 'O.84.115 Supporting service activities for the government as a whole',
  [sniCodes._84121]: 'O.84.121 Administration of primary and secondary education',
  [sniCodes._84122]: 'O.84.122 Administration of higher education and research',
  [sniCodes._84123]: 'O.84.123 Administration of health care',
  [sniCodes._84124]: 'O.84.124 Administration of social welfare',
  [sniCodes._84125]: 'O.84.125 Administration of culture, environment, housing etc. programmes',
  [sniCodes._84131]: 'O.84.131 Administration of infrastructure programmes',
  [sniCodes._84132]: 'O.84.132 Administration of programmes relating to agriculture, forestry and fishing',
  [sniCodes._84133]: 'O.84.133 Administration of labour market programmes',
  [sniCodes._84139]: 'O.84.139 Administration of other business, industry and trade programmes',
  [sniCodes._84210]: 'O.84.210 Foreign affairs',
  [sniCodes._84221]: 'O.84.221 Military defence activities',
  [sniCodes._84222]: 'O.84.222 Defence support activities',
  [sniCodes._84223]: 'O.84.223 Civil defence activities',
  [sniCodes._84231]: 'O.84.231 Public prosecutor activities',
  [sniCodes._84232]: 'O.84.232 Law court activities',
  [sniCodes._84233]: 'O.84.233 Detention and rehabilitation of criminals',
  [sniCodes._84240]: 'O.84.240 Public order and safety activities',
  [sniCodes._84250]: 'O.84.250 Fire service activities',
  [sniCodes._84300]: 'O.84.300 Compulsory social security activities',
  [sniCodes._85100]: 'P.85.100 Pre-primary education',
  [sniCodes._85201]: 'P.85.201 Compulsory comprehensive school education and pre-school class',
  [sniCodes._85202]: 'P.85.202 Special school primary education',
  [sniCodes._85311]: 'P.85.311 General secondary education',
  [sniCodes._85312]: 'P.85.312 Municipal adult education',
  [sniCodes._85321]: 'P.85.321 Technical and vocational secondary education',
  [sniCodes._85322]: 'P.85.322 Special school secondary education',
  [sniCodes._85323]: 'P.85.323 Other secondary education',
  [sniCodes._85324]: 'P.85.324 School activities for occupational drivers',
  [sniCodes._85410]: 'P.85.410 Post-secondary non-tertiary education',
  [sniCodes._85420]: 'P.85.420 Tertiary education',
  [sniCodes._85510]: 'P.85.510 Sports and recreation education',
  [sniCodes._85521]: 'P.85.521 Activities of municipal culture schools',
  [sniCodes._85522]: 'P.85.522 Other cultural education',
  [sniCodes._85530]: 'P.85.530 Driving school activities',
  [sniCodes._85591]: 'P.85.591 Labour market training',
  [sniCodes._85592]: 'P.85.592 Folk high school education',
  [sniCodes._85593]: 'P.85.593 Activities of adult education associations',
  [sniCodes._85594]: 'P.85.594 Staff training',
  [sniCodes._85599]: 'P.85.599 Various other education n.e.c.',
  [sniCodes._85600]: 'P.85.600 Educational support activities',
  [sniCodes._86101]: 'Q.86.101 Hospital primary health activities',
  [sniCodes._86102]: 'Q.86.102 Specialised hospital somatic activities',
  [sniCodes._86103]: 'Q.86.103 Specialised hospital psychiatric activities',
  [sniCodes._86211]: 'Q.86.211 General primary medical practice activities',
  [sniCodes._86212]: 'Q.86.212 Other general medical practice activities',
  [sniCodes._86221]: 'Q.86.221 Specialist medical practice activities, at hospitals',
  [sniCodes._86222]: 'Q.86.222 Specialist medical practice activities, not at hospitals',
  [sniCodes._86230]: 'Q.86.230 Dental practice activities',
  [sniCodes._86901]: 'Q.86.901 Activities of medical laboratories etc.',
  [sniCodes._86902]: 'Q.86.902 Ambulance transports and ambulance health care activities',
  [sniCodes._86903]: 'Q.86.903 Primary health activities, not physicians',
  [sniCodes._86904]: 'Q.86.904 Activities of dental hygienists',
  [sniCodes._86905]: 'Q.86.905 Activities of physiotherapists etc.',
  [sniCodes._86909]: 'Q.86.909 Other human health activities n.e.c.',
  [sniCodes._87100]: 'Q.87.100 Residential nursing care activities',
  [sniCodes._87201]: 'Q.87.201 Care in special forms of accommodation for persons with mental retardation and mental disability',
  [sniCodes._87202]: 'Q.87.202 Care in special forms of accommodation for children and young people with substance abuse problems',
  [sniCodes._87203]: 'Q.87.203 Care in special forms of accommodation for adults with substance abuse problems',
  [sniCodes._87301]: 'Q.87.301 Care in special forms of accommodation for the elderly',
  [sniCodes._87302]: 'Q.87.302 Care in special forms of accommodation for disabled persons',
  [sniCodes._87901]: 'Q.87.901 Twenty-four hours care with accommodation for children and young people with social problems',
  [sniCodes._87902]: 'Q.87.902 Care with accommodation for adults n.e.c.',
  [sniCodes._88101]: 'Q.88.101 Social work activities without accommodation for the elderly',
  [sniCodes._88102]: 'Q.88.102 Social work activities without accommodation for disabled persons',
  [sniCodes._88910]: 'Q.88.910 Child day-care activities',
  [sniCodes._88991]: 'Q.88.991 Social work activities for children and young people with social problems',
  [sniCodes._88992]: 'Q.88.992 Day-care activities for adults with substance abuse problems',
  [sniCodes._88993]: 'Q.88.993 Social work activities without accommodation for adults n.e.c.',
  [sniCodes._88994]: 'Q.88.994 Humanitarian relief activities',
  [sniCodes._88995]: 'Q.88.995 Operation of refugee camps',
  [sniCodes._90010]: 'R.90.010 Performing arts',
  [sniCodes._90020]: 'R.90.020 Support activities to performing arts',
  [sniCodes._90030]: 'R.90.030 Artistic creation',
  [sniCodes._90040]: 'R.90.040 Operation of arts facilities',
  [sniCodes._91011]: 'R.91.011 Library activities',
  [sniCodes._91012]: 'R.91.012 Archives activities',
  [sniCodes._91020]: 'R.91.020 Museums activities',
  [sniCodes._91030]: 'R.91.030 Operation of historical sites and buildings and similar visitor attractions',
  [sniCodes._91040]: 'R.91.040 Botanical and zoological gardens and nature reserves activities',
  [sniCodes._92000]: 'R.92.000 Gambling and betting activities',
  [sniCodes._93111]: 'R.93.111 Operation of ski facilities',
  [sniCodes._93112]: 'R.93.112 Operation of golf courses',
  [sniCodes._93113]: 'R.93.113 Operation of motor racing tracks',
  [sniCodes._93114]: 'R.93.114 Operation of horse race tracks',
  [sniCodes._93119]: 'R.93.119 Operation of arenas, stadiums and other sports facilities',
  [sniCodes._93120]: 'R.93.120 Activities of sport clubs',
  [sniCodes._93130]: 'R.93.130 Fitness facilities',
  [sniCodes._93191]: 'R.93.191 Horse racing activities',
  [sniCodes._93199]: 'R.93.199 Other sports activities n.e.c.',
  [sniCodes._93210]: 'R.93.210 Activities of amusement parks and theme parks',
  [sniCodes._93290]: 'R.93.290 Other amusement and recreation activities',
  [sniCodes._94111]: 'S.94.111 Activities of business membership organisations',
  [sniCodes._94112]: 'S.94.112 Activities of employers membership organisations',
  [sniCodes._94120]: 'S.94.120 Activities of professional membership organisations',
  [sniCodes._94200]: 'S.94.200 Activities of trade unions',
  [sniCodes._94910]: 'S.94.910 Activities of religious organisations',
  [sniCodes._94920]: 'S.94.920 Activities of political organisations',
  [sniCodes._94990]: 'S.94.990 Activities of other membership organisations n.e.c.',
  [sniCodes._95110]: 'S.95.110 Repair of computers and peripheral equipment',
  [sniCodes._95120]: 'S.95.120 Repair of communication equipment',
  [sniCodes._95210]: 'S.95.210 Repair of consumer electronics',
  [sniCodes._95220]: 'S.95.220 Repair of household appliances and home and garden equipment',
  [sniCodes._95230]: 'S.95.230 Repair of footwear and leather goods',
  [sniCodes._95240]: 'S.95.240 Repair of furniture and home furnishings',
  [sniCodes._95250]: 'S.95.250 Repair of watches, clocks and jewellery',
  [sniCodes._95290]: 'S.95.290 Repair of other personal and household goods',
  [sniCodes._96011]: 'S.96.011 Washing and (dry-)cleaning for businesses and institutions',
  [sniCodes._96012]: 'S.96.012 Washing and (dry-)cleaning for households',
  [sniCodes._96021]: 'S.96.021 Hairdressing',
  [sniCodes._96022]: 'S.96.022 Beauty treatment',
  [sniCodes._96030]: 'S.96.030 Funeral and related activities',
  [sniCodes._96040]: 'S.96.040 Physical well-being activities',
  [sniCodes._96090]: 'S.96.090 Other personal service activities n.e.c.',
  [sniCodes._97000]: 'T.97.000 Activities of households as employers of domestic personnel',
  [sniCodes._98100]: 'T.98.100 Undifferentiated goods-producing activities of private households for own use',
  [sniCodes._98200]: 'T.98.200 Undifferentiated service-producing activities of private households for own use',
  [sniCodes._99000]: 'U.99.000 Activities of extraterritorial organisations and bodies',
};

export const sniCodesChoices = Object.entries(sniCodesDescriptions).map(([code, description]) => ({
  id: code,
  name: description,
}));

export type SniCodes = ValueOf<typeof sniCodes>;

export default sniCodes;
