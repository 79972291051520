import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const fundSources = {
  SALARY_PENSION: 'salaryPension',
  INVESTMENT_RETURNS: 'investmentReturns',
  INHERITANCE_GIFT: 'inheritanceGift',
  ONLINE_GAMBLING: 'onlineGambling',
  CRYPTO_TRADING: 'cryptoTrading',
  HOLDING: 'holding',
  OPERATION: 'operation',
  LOAN_PROCEEDS: 'loanProceeds',
  EQUITY_FINANCING: 'equityFinancing',
  GRANTS_OR_SUBSIDIES: 'grantsOrSubsidies',
  SALE_OF_ASSETS: 'saleOfAssets',
  INTERNAL_CASH_RESERVES: 'internalCashReserves',
  SAVINGS: 'savings',
  SECURITIES: 'securities',
  DIVIDENDS: 'dividends',
  SOCIAL_BENEFITS: 'socialBenefits',
  REAL_ESTATE_SOLD: 'realEstateSold',
  SELF_EMPLOYMENT: 'selfEmployment',
  SCHOLARSHIP: 'scholarship',
  INCOME_FROM_FAMILY_MEMBERS: 'incomeFromFamilyMembers',
  WINNINGS_LOTTERIES_BETS: 'winningsLotteriesBets',
  OTHER: 'other',
} as const;

const fundSourceEnLabels = {
  [fundSources.SALARY_PENSION]: 'Salary/Pension',
  [fundSources.INVESTMENT_RETURNS]: 'Returns from Investments',
  [fundSources.INHERITANCE_GIFT]: 'Inheritance or Gift',
  [fundSources.ONLINE_GAMBLING]: 'Online Gambling',
  [fundSources.CRYPTO_TRADING]: 'Crypto Trading',
  [fundSources.HOLDING]: 'Business is a holding company',
  [fundSources.OPERATION]: 'Revenue from business operation',
  [fundSources.LOAN_PROCEEDS]: 'Loan proceeds',
  [fundSources.EQUITY_FINANCING]: 'Equity financing',
  [fundSources.GRANTS_OR_SUBSIDIES]: 'Grant or subsidies',
  [fundSources.SALE_OF_ASSETS]: 'Sale of assets',
  [fundSources.INTERNAL_CASH_RESERVES]: 'Internal cash reserves',
  [fundSources.SAVINGS]: 'Savings',
  [fundSources.SECURITIES]: 'Securities',
  [fundSources.DIVIDENDS]: 'Dividends',
  [fundSources.SOCIAL_BENEFITS]: 'Social benefits',
  [fundSources.REAL_ESTATE_SOLD]: 'Sales of real estate',
  [fundSources.SELF_EMPLOYMENT]: 'Self employment',
  [fundSources.SCHOLARSHIP]: 'Scholarship',
  [fundSources.INCOME_FROM_FAMILY_MEMBERS]: 'Income from family members',
  [fundSources.WINNINGS_LOTTERIES_BETS]: 'Winnings from lotteries/bets',
  [fundSources.OTHER]: 'Other',
} as const;

const fundSourceSvLabels = {
  [fundSources.SALARY_PENSION]: 'Lön/pension',
  [fundSources.INVESTMENT_RETURNS]: 'Avkastning från investeringar',
  [fundSources.INHERITANCE_GIFT]: 'Arv eller gåva',
  [fundSources.ONLINE_GAMBLING]: 'Online spel',
  [fundSources.CRYPTO_TRADING]: 'Kryptohandel',
  [fundSources.HOLDING]: 'Företaget är ett holdingbolag',
  [fundSources.OPERATION]: 'Intäkter från affärsverksamhet',
  [fundSources.LOAN_PROCEEDS]: 'Lån',
  [fundSources.EQUITY_FINANCING]: 'Aktiefinansiering',
  [fundSources.GRANTS_OR_SUBSIDIES]: 'Bidrag eller subventioner',
  [fundSources.SALE_OF_ASSETS]: 'Försäljning av tillgångar',
  [fundSources.INTERNAL_CASH_RESERVES]: 'Interna kassareserver',
  [fundSources.SAVINGS]: 'Sparande',
  [fundSources.SECURITIES]: 'Värdepapper',
  [fundSources.DIVIDENDS]: 'Utdelningar',
  [fundSources.SOCIAL_BENEFITS]: 'Bidrag',
  [fundSources.REAL_ESTATE_SOLD]: 'Försäljning av fastighet',
  [fundSources.SELF_EMPLOYMENT]: 'Egenföretagande',
  [fundSources.SCHOLARSHIP]: 'Stipendium',
  [fundSources.INCOME_FROM_FAMILY_MEMBERS]: 'Inkomst från familjemedlemmar',
  [fundSources.WINNINGS_LOTTERIES_BETS]: 'Vinster från lotterier/spel',
  [fundSources.OTHER]: 'Annat',
} as const;

const fundSourceLabels = {
  [languages.EN]: fundSourceEnLabels,
  [languages.SV]: fundSourceSvLabels,
} as const;

export const fundSourceChoices = (
  language: Languages,
) => Object.values(fundSources).map((section) => ({
  id: section,
  name: fundSourceLabels[language][section],
}));

export type FundSources = ValueOf<typeof fundSources>;

export default fundSources;
