import React from 'react';

import {
  DateInput,
  Loading,
  RecordContextProvider,
  required,
  TextInput,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Grid,
} from '@mui/material';

import RootFields from './RootFields';

const Person = ({
  isMyClient,
  entityVariant,
  rootSource,
  direction,
}: {
  isMyClient : boolean
  entityVariant: string
  rootSource: string
  direction: string

}) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne('individuals', { id: record?.id }, { enabled: !!record?.id });

  if (isLoading && !data) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <RecordContextProvider value={{
      [`${entityVariant}.first_name`]: data?.firstName,
      [`${entityVariant}.last_name`]: data?.lastName,
      [`${entityVariant}.birthdate`]: data?.birthDate,
      [`${entityVariant}.ssn`]: data?.swedishSocialSecurityNumber,
      ...record,
    }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <RootFields rootSource={rootSource} direction={direction} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.first_name`} label="First name" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.last_name`} label="Last name" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.ssn`} label="Swedish social security number" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DateInput fullWidth source={`${entityVariant}.birthdate`} label="Birth date" validate={isMyClient ? required() : undefined} />
        </Grid>
      </Grid>
    </RecordContextProvider>

  );
};

export default Person;
