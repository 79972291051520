import { ValueOf } from '../utilities/types';

const languages = {
  SV: 'sv',
  EN: 'en',
} as const;

export type Languages = ValueOf<typeof languages>;

export default languages;
