import React, { useEffect } from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { isEmpty } from 'lodash';

import {
  TextInput,
  required,
} from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';

import ArrayInput from './base/ArrayInput';
import cleaner from '../../../utilities/dataProvider/cleaner';

const PurposeDialogBody = () => (
  <Grid container columnSpacing={6}>
    <Grid item xs={12} md={12}>
      <TextInput fullWidth source="purpose" label="Purpose" />
    </Grid>
  </Grid>

);

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <Typography
        variant="h6"
        color="primary.main"
      >
        <Typography color="primary.main" variant="h6">
          {value.purpose}
        </Typography>
      </Typography>
    </Box>
  );
};

const Purpose = () => {
  const value = useWatch({ name: '_value' });
  const { setValue } = useFormContext();
  useEffect(() => {
    setValue('value', (value ?? []).flatMap((v: {purpose: string }) => v.purpose), { shouldDirty: true });
  }, [setValue, value]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography
          color="#646464"
          variant="h6"
          marginBottom={4}
        >
          Purpose
        </Typography>
        <ArrayInput
          rootField="_value"
          title="Add purpose"
          itemNameSource="purpose"
          ItemLabel={ItemLabel}
          DialogBody={PurposeDialogBody}
          validate={required()}
          transformItemData={cleaner}
        />
      </Grid>
    </Grid>
  );
};

export default Purpose;
