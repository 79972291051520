import React from 'react';
import { InputAdornment } from '@mui/material';
import { TextInput } from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';

const SearchField = ({ source, label }: { source: string, label?: string, alwaysOn?: boolean }) => (
  <TextInput
    InputProps={{
      disableUnderline: true,
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    source={source}
    label={label}
    alwaysOn
  />
);

export default SearchField;
