import React from 'react';
import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  useRedirect,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  Pagination,
  usePermissions,
  SelectField,
  ChipField,
  ReferenceArrayField,
  SingleFieldList,
} from 'react-admin';

import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  CardContent,
  Divider,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useFormState } from 'react-hook-form';

import { isEmpty } from 'lodash';
import UpdateRecommendationField from '../../customFields/UpdateRecommendationField';
import StatusField from '../../customFields/StatusField';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReprocessRequest from '../../customFields/ReprocessRequest';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { requestEditMap } from '../../constants/requestFeatures';
import requestTypes from '../../constants/requestTypes';

import hasAccess from '../../utilities/hasAccess';

import RequestBreadcrumb from './RequestBreadcrumb';
import actions, { Actions } from '../../constants/actions';
import CommentList from '../comment/CommentList';
import { IRequest } from './types';
import useValidateId from '../../hooks/useValidateId';
import resourceTypes from '../../constants/resourceTypes';

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const RequestEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const redirect = useRedirect();
  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" redirect={false}>
      <RequestBreadcrumb />
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Information">
          <Grid container spacing={6}>
            <Grid item xs={12} md={12} />
            <Grid item xs={12} md={12} display="flex" justifyContent="center">
              <Typography color="primary" variant="largeTitle">Request Detailed View</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <IconButton onClick={() => redirect('list', '/requests')} size="small">
                  <ArrowBackIosIcon color="primary" fontSize="inherit" />
                </IconButton>
                <Box display="flex">
                  {hasAccess(permissions, 'RequestUpdateRecommendation') && <UpdateRecommendationField />}
                  {hasAccess(permissions, 'RequestReprocess') && <ReprocessRequest />}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card variant="outlined">
                <CardHeader
                  title="Request Details"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={6}>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Id</Typography>
                        <TextField source="id" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Custom Id</Typography>
                        <TextField source="customId" emptyText="-" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Recommendation</Typography>
                        <StatusField source="recommendation" />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Request Type</Typography>
                        <SelectField source="requestType" choices={Object.values(requestTypes)} />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Created at</Typography>
                        <DateField source="createdAt" showTime />
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="smallTitle">Process</Typography>
                        <ReferenceField reference="processes" source="process">
                          <TextField source="name" />
                        </ReferenceField>
                      </Box>
                    </Grid>
                    <FormDataConsumer>
                      {({ formData }) => !isEmpty(formData?.signals) && (
                        <Grid item xs={12} md={12}>
                          <Box display="flex" flexDirection="column">
                            <Typography variant="smallTitle">Signals</Typography>
                            <ReferenceArrayField source="signals" reference="signals" emptyText="-">
                              <SingleFieldList>
                                <ChipField emptyText="-" size="small" source="name" />
                              </SingleFieldList>
                            </ReferenceArrayField>
                          </Box>
                        </Grid>
                      )}
                    </FormDataConsumer>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CommentList source="comments" action={actions.REQUEST_ADD_COMMENT} />
            </Grid>
            <FormDataConsumer<IRequest>>
              {({ formData }) => requestEditMap[formData.requestType]()}
            </FormDataConsumer>
          </Grid>
        </FormTab>
        { hasAccess(permissions, 'AuditTrackList') && (
          <FormTab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    filter={{ 'resource.type': resourceTypes.REQUEST }}
                    sort={{ field: 'date', order: 'DESC' }}
                    pagination={<Pagination />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default RequestEdit;
