import paths, { Paths } from '../../../constants/paths';
import ruleNames, { RuleNames } from '../../../constants/ruleNames';

const ruleDescriptions: Record<Paths | RuleNames, string | undefined> = {
  [paths.BUSINESS]: 'Rules related to businesses',
  [paths.BUYER]: 'Rules related to buyers in car purchases',
  [paths.CAR_KYC]: 'Rules related to car KYC',
  [paths.CAR_PURCHASE]: 'Rules related to car purchases',
  [paths.CAR]: 'Rules related to cars',
  [paths.INDIVIDUAL]: 'Rules related to individuals',
  [paths.INITIATOR]: 'Rules related to payment initiators of transactions',
  [paths.KYC_BUSINESS]: 'Rules related to business KYC',
  [paths.KYC_INDIVIDUAL]: 'Rules related to individual KYC',
  [paths.KYC]: 'Rules related to KYC of individuals, businesses and cars',
  [paths.PROCESSOR]: 'Rules related to payment processors of transactions',
  [paths.PURCHASE]: 'Rules related to purchases of assets',
  [paths.RECIPIENT]: 'Rules related to payment recipients of transactions',
  [paths.SELLER]: 'Rules related to sellers in car purchases',
  [paths.TEST]: 'Rules for testing',
  [paths.TRANSACTION]: 'Rules related to transactions monitoring',
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: undefined,
  [ruleNames.AFFORDABILITY]: 'Check if a selected multiplier times the request amount is lower then the account balance at the end of a selected number of months. The rule is triggered if any monthly balance is under the threshold.',
  [ruleNames.AGE_THRESHOLD]: 'Check if the age of an individual is outside the age interval.',
  [ruleNames.AMOUNT_THRESHOLD]: 'Check if the amount of a request is above a threshold.  The rule is triggered if the amount is above the threshold.',
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: 'This rule is triggered if the average of incoming transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: 'This rule is triggered if the number of incoming transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: 'This rule is triggered if the incoming transaction amount deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: 'This rule is triggered if the sum of incoming transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: 'This rule is triggered if the average of outgoing transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: 'This rule is triggered if the number of outgoing transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: 'This rule is triggered if the outgoing transaction amount deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: 'This rule is triggered if the sum of outgoing transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: 'This rule is triggered if the average of processed transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: 'This rule is triggered if the number of processed transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: 'This rule is triggered if the processed amount deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: 'This rule is triggered if the sum of processed transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: 'This rule is triggered if the average of all transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: 'This rule is triggered if the total number of transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: 'This rule is triggered if the transaction amount deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: 'This rule is triggered if the sum of all transactions during the set period deviates more than the set threshold from historical average.',
  [ruleNames.ANOMALY_DETECTION]: 'Check if the request activity of the entity is unusual based on a machine learning model for anomaly detection. The rule is triggered if the derived anomaly score, which represents the degree of anomaly, is above the threshold. The anomaly score ranges from 0 to 100, where 50 is considered a neutral point. A score below 50 indicates an anomaly under the historical behavior. Likewise, a score above 50 indicates an anomaly above the historical behavior.',
  [ruleNames.BUSINESS_ACTIVE]: 'Check if the company status is not active. The rule is triggered if the company status is anything else than active.',
  [ruleNames.BUSINESS_AUDITOR]: 'Check the company auditor status. The rule is triggered if the auditor status is any of the selected statuses.',
  [ruleNames.BUSINESS_DATA_CHANGED]: 'The rule is designed to monitor changes in the basic business data, particularly for businesses who have already been onboarded. Its primary role is to detect alterations in the dataset to ensure the most up-to-date information is available.',
  [ruleNames.BUSINESS_EQUITY_SHARE]: 'Check if the company equity ratio (%) is below a threshold. The rule is triggered if the equity ratio is below the threshold.',
  [ruleNames.BUSINESS_EQUITY]: 'Check if the company total equity in kSEK is below a threshold. The rule is triggered if the total equity is below the threshold.',
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: 'Check if the company have a comment on the latest financial statement. The rule is triggered if a comment exists on the financial statement.',
  [ruleNames.BUSINESS_FTAX]: 'Check if the company is approved for preliminary tax (F-skatt Sweden). The rule is triggered if the company status is not approved.',
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: 'Check if the company sum of payment applications is above a threshold. The rule is triggered if the sum of applications is above the threshold.',
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: 'Check if the company have a number of payment applications above a threshold. The rule is triggered if the number of applications is above the threshold.',
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: 'Check if the company sum of payment remarks above a threshold. The rule is triggered if the sum of remarks is above the threshold.',
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: 'Check if the company have a number of payment remarks above a threshold. The rule is triggered if the number of remarks is above the threshold.',
  [ruleNames.BUSINESS_PROVIDER_WARNING]: 'Check if the company is on the provider\'s warning list. The rule is triggered if the company is on the list.',
  [ruleNames.BUSINESS_SANCTION_LIST]: 'Check if the business is on a sanction list using the configured sanction list provider. The rule is triggered if a match is found.',
  [ruleNames.BUSINESS_VAT]: 'Check if the company is registered for VAT (MOMS Sweden). The rule is triggered if the company is not registered.',
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: 'Check the list of historical owners of the car using the configured car information provider. The rule is triggered if there are more owners within the period than the allowed threshold.',
  [ruleNames.CAR_GUARANTOR_RELATED]: 'Check if the guarantor is related to the seller and/or buyer company using the provided guarantor information and configured company information provider.',
  [ruleNames.CAR_IN_CIRCULATION]: 'Check if the car is in circulation using the configured car information provider. The rule is triggered if the car is not in circulation.',
  [ruleNames.CAR_VALUATION]: 'Check if the car purchase amount is reasonable close to the valuation using the car valuation provided. The rule is triggered if the purchase amount is unreasonable.',
  [ruleNames.CASH_FLOW]: 'Check if the net cash flow (incoming - outgoing amounts) for the selected entity (entities) is above a specified threshold. The rule is triggered if the net cash flow exceeds this threshold, indicating a significant disparity between the cash inflows and outflows.',
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: 'Check if the absolute amount (incoming, outgoing, or both) for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the absolute amount is above the threshold.',
  [ruleNames.CO_ADDRESS]: undefined,
  [ruleNames.COLLECTOR]: 'Select fields to be collected. Rule is triggered if fields are collected',
  [ruleNames.CONNECTED_BUYER_SELLER]: undefined,
  [ruleNames.CONNECTED_ENTITY_PEP]: 'Check who the beneficial owner and/or the board members of the company is using the configured company information provider. Then check if the beneficial owner and/or board members is on a PEP list using the configured PEP list provider. The rule is triggered if a match is found on the beneficial owner and/or board members.',
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: 'Check who the beneficial owner and/or the board members of the company is using the configured company information provider. Then check if the beneficial owner and/or board members is on a sanction list using the configured sanction list provider. The rule is triggered if a match is found on the beneficial owner and/or board members.',
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: 'Check if the person is a citizen of country included in list of blocked countries',
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: 'Check if the residence country is included in list of blocked countries',
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: 'Check if the residence country is included in list of allowed countries',
  [ruleNames.EMPLOYMENT_TYPE]: 'Check if the employment status is included in the list of unacceptable employment status',
  [ruleNames.FOREIGN_ADDRESS]: undefined,
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: 'Check if the incoming frequency for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the frequency is above the threshold.',
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: 'Check if the incoming amount of volume for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the volume is above the threshold.',
  [ruleNames.INCOMING_FREQUENCY]: 'Check if the incoming frequency of a requests that the selected entity (entities) have been involved in is above a threshold.  The rule is triggered if the frequency is above the threshold.',
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: 'Sum the amounts of the incoming requests done by the recipient of the transaction during a rolling period. The rule is triggered if the sum is above the threshold.',
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: 'Check if the seller, processor and/or buyer have a criminal record using the configured individual information provider.',
  [ruleNames.INDIVIDUAL_PEP]: 'Check if the individual is on a PEP list using the configured PEP list provider. The rule is triggered if a match is found.',
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: 'Check if the individual is on a sanction list using the configured sanction list provider. The rule is triggered if a match is found.',
  [ruleNames.IP_COUNTRY_BLACKLIST]: 'Check if the IP address is in a blocked country',
  [ruleNames.KYC_VALID]: 'This rule evaluates the validity of the KYC (Know Your Customer) form by checking both the age of the form and whether all required fields are properly filled out. You can select between two types of validity checks: "Validity" and "Invalidity". Selecting "Validity" triggers the rule when the KYC form is current and fully completed. Conversely, selecting "Invalidity" triggers the rule when the form is either outdated or missing required information.',
  [ruleNames.LOW_INCOME]: 'Check if the income of an individual is below a threshold. Only supported using KYC form answers. The currency threshold must be the same as the currency of the KYC form answer.',
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: 'Check if the outgoing frequency for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the frequency is above the threshold.',
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: 'Check if the outgoing amount of volume (incoming, outgoing, or both) for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the volume is above the threshold.',
  [ruleNames.OUTGOING_FREQUENCY]: 'Check if the outgoing frequency of a requests that the selected entity (entities) have been involved in is above a threshold.  The rule is triggered if the frequency is above the threshold.',
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: 'Sum the amounts of the outgoing requests during a rolling period. The rule is triggered if the sum is above the threshold.',
  [ruleNames.PROCESSING_FREQUENCY]: 'Check if the processing frequency of a requests that the selected entity (entities) have been involved in is above a threshold.  The rule is triggered if the frequency is above the threshold.',
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: 'Sum the amounts of the processing requests during a rolling period. The rule is triggered if the sum is above the threshold.',
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: 'Check if the related individuals of the seller, processor and/or buyer company using the configured company information provider.',
  [ruleNames.SELLER_OWNS_CAR]: 'Check who is the owner of the car is using the configured car information provider. The rule is triggered if the is not owned by the seller.',
  [ruleNames.SNI_CODE_BLACKLIST]: 'Check if the Swedish Standard Industrial Classification (SNI) code is included in the list of blocked SNIs.',
  [ruleNames.SOURCE_OF_FUNDS]: 'Check if some source of funds are included in the list of unacceptable sources of funds',
  [ruleNames.SOURCE_OF_INCOME]: 'Check if some source of income are included in the list of unacceptable sources of income',
  [ruleNames.SPAR_CHANGED]: 'The rule is designed to monitor changes in the SPAR data, particularly for individuals who have already been onboarded. Its primary role is to detect alterations in the dataset to ensure the most up-to-date information is available.',
  [ruleNames.SUSPICIOUS_FUNDS]: undefined,
  [ruleNames.TEST_BLOCK]: 'Always return a block recommendation for every request containing the corresponding test object',
  [ruleNames.TEST_REVIEW]: 'Always return a review recommendation for every request containing the corresponding test object',
  [ruleNames.TIME_WINDOW]: 'Check if the date of the request is within the inputted time interval. The rule is trigged if the request time is contained in the interval',
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: 'Check if the total frequency for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the frequency is above the threshold.',
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: 'Check if the total volume for a specific category given the underlying PSD2 data is above a threshold over a period of time. The rule is triggered if the volume is above the threshold.',
  [ruleNames.TOTAL_FREQUENCY]: 'Check if the total frequency of a requests that the selected entity (entities) have been involved in is above a threshold.  The rule is triggered if the frequency is above the threshold.',
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: 'Sum the amounts of the total requests during a rolling period. The rule is triggered if the sum is above the threshold.',
  [ruleNames.ZIP_CODE_BLACKLIST]: 'Check if the zip code is included in the list of blocked zip codes',
};

export default ruleDescriptions;
