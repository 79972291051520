import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const SubNavigation = ({ children }: { children: React.ReactNode }) => (
  <Box display="flex" width="100%" justifyContent="center" marginTop={0}>
    {children}
  </Box>
);

export const SubSubNavigation = ({ children }: { children: React.ReactNode }) => (
  <Box display="flex" width="100%" justifyContent="center">
    {children}
  </Box>
);

export const SubListNavigation = ({ children }: { children: React.ReactNode }) => (
  <Box display="flex" justifyContent="center" margin={3}>
    {children}
  </Box>
);

SubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

SubSubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

SubListNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubNavigation;
