import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import {
  Typography,
  Box,
} from '@mui/material';
import { IFinancialData } from '../../components/business/editTabs/kyc/FinancialData';

const useGetGraphData = (rawData: IFinancialData) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ApexOptions>();
  const [series, setSeries] = useState<ApexAxisChartSeries>();

  const filterData = useMemo(() => rawData.records
    ?.filter((record) => record.fromDate), [rawData?.records]);

  useEffect(() => {
    if (!filterData) return;

    const newSeries = [{
      name: 'Net Income',
      type: 'bar',
      data: filterData.reverse().map(
        (record) => ({
          x: record?.fromDate?.substring(0, 4),
          y: record?.bsNetIncome ?? 0,
        }),
      ),
    }, {
      name: 'Sales',
      type: 'bar',
      data: filterData.map(
        (record) => ({
          x: record?.fromDate?.substring(0, 4),
          y: record?.plSales ?? 0 + 2 ?? 0,
        }),
      ),
    }];
    const newOptions = {
      colors: ['#DE2B2B', '#1F487E'],
      chart: {
        redrawOnParentResize: true,
        stacked: false,
        height: 400,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: 'solid',
        opacity: [1, 1],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        type: 'numeric',
        decimalsInFloat: 0,
      },
      tooltip: {
        shared: false,
        intersect: false,
        y: {
          formatter(y?: number) {
            if (typeof y !== 'undefined') {
              return `${y.toFixed(0)} SEK`;
            }
            return '';
          },
        },
      },
      xaxis: {
        type: 'category' as const,
        labels: {
          rotate: -90,
        },
      },
    };

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [filterData, rawData]);

  return {
    hasData,
    options,
    series,
  };
};

const FinancialSummary = ({ data }: { data: IFinancialData }) => {
  const { hasData, options, series } = useGetGraphData(data);

  if (!hasData) {
    return (
      <Box margin={10}>
        <Typography align="center"> No transactions available </Typography>
      </Box>
    );
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default FinancialSummary;
