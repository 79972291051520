import React, { useCallback } from 'react';
import {
  Typography,
  Popover,
  Box,
  IconButton,
  Card, CardHeader,
  CardContent,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';

type OtherEl = null | Element | (() => Element)

const InfoPopover = ({ content, otherEl, title }: {
  content: React.ReactElement | string,
  otherEl?: OtherEl,
  title: string
}) => {
  const [anchorEl, setAnchorEl] = React.useState<OtherEl>(null);

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (otherEl) {
      setAnchorEl(otherEl);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }, [otherEl]);

  const handleClose = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  }, []);

  const open = !!anchorEl;

  return (
    <Box>
      <IconButton
        size="small"
        color="primary"
        onClick={handleClick}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        style={{ marginLeft: 3 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{
          width: 280,
          margin: 0,
          border: '1px outset rgba(0, 0, 0, .3)',
        }}
        >
          <CardHeader
            title={title}
            sx={{
              color: 'black',
              fontWeight: 'bold',
            }}
            titleTypographyProps={{ variant: 'inherit' }}
            action={(
              <IconButton
                size="small"
                onClick={handleClose}
              >
                <CancelIcon />
              </IconButton>
            )}
          />
          <CardContent>
            <Typography display="block">{content}</Typography>
          </CardContent>
        </Card>

      </Popover>
    </Box>
  );
};

export default InfoPopover;
