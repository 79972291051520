import React, { useCallback, useState } from 'react';

import {
  useRecordContext,
  useNotify,
  useUpdate,
  useRefresh,
} from 'react-admin';

import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';
import { useParams } from 'react-router-dom';

const ReprocessRequest = () => {
  const refresh = useRefresh();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const notify = useNotify();

  const record = useRecordContext();

  const [reprocess] = useUpdate(
    'requests',
    { id: `${id}/reprocess`, data: { } },
    {
      onSuccess: () => {
        setLoading(false);
        setDone(true);
        notify('Request Reprocessed');
        refresh();
      },
      onError: () => {
        notify('Could not reprocess request', { type: 'error' });
      },
    },
  );

  const handleSubmit = useCallback(() => {
    setLoading(true);
    reprocess();
  }, [reprocess]);

  if (record?.recommendation !== 'review' && !record?.processStopped) return null;

  return (
    <Box display="flex">
      <Box display="flex" marginRight={2}>
        {!loading && !done && <Button variant="outlined" startIcon={<ReplayIcon color="primary" />} onClick={handleSubmit}>Reprocess</Button>}
        {loading && <Button variant="outlined" disabled startIcon={<CircularProgress size={16} />}>Reprocessing</Button>}
        {done && <Button variant="outlined" disabled startIcon={<CheckIcon />}>Reprocessed</Button>}
      </Box>
    </Box>
  );
};

ReprocessRequest.defaultProps = {
  addLabel: false,
};
export default ReprocessRequest;
