import { isValidSnn } from '../joiExtension/ssn';

type Values = {
  swedishSocialSecurityNumber?: string;
  customId?: string;
};

type Errors = {
  swedishSocialSecurityNumber?: string;
  customId?: string;
};

const validateSsn = (values: Values) => {
  const errors: Errors = {};
  if (values.swedishSocialSecurityNumber) {
    if (!isValidSnn(values.swedishSocialSecurityNumber)) {
      errors.swedishSocialSecurityNumber = 'Accepted format: yyyymmddxxxx';
    }
  }

  if (!values.customId) {
    // You can return translation keys
    errors.customId = 'ra.validation.required';
  }
  return errors;
};

export default validateSsn;
