import React from 'react';

import {
  CircularProgress,
  Button,
  Box,
  ButtonProps,
  styled,
} from '@mui/material';

const Root = styled(Box)({
  position: 'relative',
  padding: 0,
  width: 'fit-content',
});

const Progress = styled(CircularProgress)(() => ({
  color: 'primary.main',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '20%',
  left: '40%',
}));

type Input = {
  label: string,
  loading: boolean,
  onClick: ButtonProps['onClick'],
  variant?: ButtonProps['variant']
  startIcon?: ButtonProps['startIcon']
  color?: ButtonProps['color']
}

const ButtonLoading = ({
  label,
  loading,
  onClick,
  variant = 'contained',
  startIcon,
  color = 'secondary',
}: Input) => (
  <Root>
    <Button
      variant={variant}
      color={color}
      startIcon={startIcon}
      onClick={onClick}
      disabled={loading}
    >
      {label}
    </Button>
    {loading && <Progress size={24} />}
  </Root>
);

export default ButtonLoading;
