import React, { useCallback } from 'react';

import {
  Button, Box,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';

const Delete = () => {
  const { getValues, setValue } = useFormContext();
  const request = useRecordContext();
  const handleRemove = useCallback(() => {
    setValue(
      'report.activity.goods_services',
      getValues()?.report?.activity?.goods_services.filter((
        good: {id: string},
      ) => good.id !== request?.id),
    );

    setValue('ids', getValues().ids.filter((id: string) => id !== request?.id));
  }, [getValues, request?.id, setValue]);

  return (
    <Box>
      <Button startIcon={<DeleteIcon />} color="red" onClick={handleRemove}>Remove</Button>
    </Box>
  );
};

export default Delete;
