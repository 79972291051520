import React from 'react';

import { Grid, Typography } from '@mui/material';
import { SelectInput, TextInput } from 'react-admin';

import booleanChoices from '../../../constants/booleanChoices';

const ListedFinancialMarket = () => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Is the company owned by a state, region, municipality or equivalent?
      </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
      <SelectInput
        choices={booleanChoices.en}
        fullWidth
        source="value.option"
        label="Is the company listed on a regulated financial market?"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Is the company owned by a state, region, municipality or equivalent?
      </Typography>
    </Grid>
    <Grid item xs={12} md={8}>
      <TextInput
        rows={2}
        multiline
        fullWidth
        source="value.market"
        label="On which stock exchange is the company listed?"
      />
    </Grid>
    <Grid item xs={12} md={8}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>

);

export default ListedFinancialMarket;
