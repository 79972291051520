import React from 'react';

import { Grid } from '@mui/material';

import Entity from './Entity';
import Person from './Person';

const EntityForm = ({
  entityVariant,
}: {
  entityVariant: string,
}) => (
  <Grid>
    {entityVariant === 't_person' && <Person entityVariant={entityVariant} />}
    {entityVariant === 't_entity' && <Entity entityVariant={entityVariant} />}
  </Grid>

);
export default EntityForm;
