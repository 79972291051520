import React, { useMemo } from 'react';

import {
  useGetList,
  required,
  Loading,
  SelectInput,
} from 'react-admin';

import { Grid, Typography } from '@mui/material';

import { useWatch } from 'react-hook-form';

import ruleTypes, { RuleTypes } from '../../../constants/ruleTypes';
import { RequestRules, requestTypesSupported } from '../ruleFeatures';
import { RuleNames } from '../../../constants/ruleNames';
import { RequestTypes } from '../../../constants/requestTypes';
import { Rule } from '../../../utilities/schemas/rules';

type Form = {
  name: RuleNames;
  ruleType: RuleTypes;
  parentRule?: Rule;
}

const Process = () => {
  const rule = useWatch<Form>();
  const { data: allProcesses, isLoading } = useGetList<{
    id: string;
    requestType: RequestTypes;
  }>('processes', { pagination: { page: 1, perPage: 250 }, filter: { active: true } });

  const requestTypeSupported = useMemo(
    () => (rule.name && rule.ruleType === ruleTypes.REQUEST
      ? requestTypesSupported[rule.name as RequestRules]?.map(({ id }) => id)
      : null),
    [rule.name, rule.ruleType],
  );

  const choices = useMemo(
    () => (
      rule?.parentRule
        ? allProcesses?.filter(
          (process) => rule?.parentRule?.process === process.id,
        )
        : allProcesses?.filter(
          (process) => !requestTypeSupported || requestTypeSupported.includes(process.requestType),
        )),
    [allProcesses, requestTypeSupported, rule?.parentRule],
  );

  if (rule?.ruleType !== ruleTypes.REQUEST) return null;

  if (isLoading) return <Loading />;

  return (
    <Grid item xs={12} md={12}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Process</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            fullWidth
            source="process"
            label="Select process"
            choices={choices}
            validate={required()}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Process;
