import ruleNames, { RuleNames } from '../../../constants/ruleNames';
import paths, { Paths } from '../../../constants/paths';

const rulePath: Record<RuleNames, Paths[][]> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.AFFORDABILITY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.AGE_THRESHOLD]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.AMOUNT_THRESHOLD]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.RECIPIENT, paths.SELLER],
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.RECIPIENT, paths.SELLER],
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.RECIPIENT, paths.SELLER],
  ],
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.RECIPIENT, paths.SELLER],
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.INITIATOR, paths.BUYER],
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.INITIATOR, paths.BUYER],
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.INITIATOR, paths.BUYER],
  ],
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [paths.INITIATOR, paths.BUYER],
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [
      paths.RECIPIENT,
      paths.INITIATOR,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
    ],
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [
      paths.RECIPIENT,
      paths.INITIATOR,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
    ],
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [
      paths.RECIPIENT,
      paths.INITIATOR,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
    ],
  ],
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR_PURCHASE],
    [
      paths.RECIPIENT,
      paths.INITIATOR,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
    ],
  ],
  [ruleNames.ANOMALY_DETECTION]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.BUSINESS_ACTIVE]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_AUDITOR]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_DATA_CHANGED]: [
    [paths.KYC],
    [paths.BUSINESS],
  ],
  [ruleNames.BUSINESS_EQUITY_SHARE]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_EQUITY]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_FTAX]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_PROVIDER_WARNING]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.BUSINESS_SANCTION_LIST]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.BUSINESS,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.BUSINESS_VAT]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: [
    [paths.CAR],
    [paths.PURCHASE, paths.KYC],
  ],
  [ruleNames.CAR_GUARANTOR_RELATED]: [
    [paths.CAR],
    [paths.PURCHASE],
  ],
  [ruleNames.CAR_IN_CIRCULATION]: [
    [paths.CAR],
    [paths.PURCHASE, paths.KYC],
  ],
  [ruleNames.CAR_VALUATION]: [
    [paths.CAR],
    [paths.PURCHASE],
  ],
  [ruleNames.CASH_FLOW]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.CO_ADDRESS]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.BUSINESS,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.COLLECTOR]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.BUSINESS,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.CONNECTED_BUYER_SELLER]: [
    [paths.CAR],
    [paths.PURCHASE],
  ],
  [ruleNames.CONNECTED_ENTITY_PEP]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.EMPLOYMENT_TYPE]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.BUSINESS,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.FOREIGN_ADDRESS]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.INCOMING_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
    ],
  ],
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.INDIVIDUAL,
    ],
  ],
  [ruleNames.INDIVIDUAL_PEP]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.IP_COUNTRY_BLACKLIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.KYC_VALID]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.INDIVIDUAL,
    ],
  ],
  [ruleNames.LOW_INCOME]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.INDIVIDUAL,
    ],
  ],
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.OUTGOING_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
    ],
  ],
  [ruleNames.PROCESSING_FREQUENCY]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION],
    [paths.PROCESSOR],
  ],
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.BUSINESS,
    ],
  ],
  [ruleNames.SELLER_OWNS_CAR]: [
    [paths.CAR],
    [paths.PURCHASE],
  ],
  [ruleNames.SNI_CODE_BLACKLIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.SPAR_CHANGED]: [
    [paths.KYC],
    [paths.INDIVIDUAL],
  ],
  [ruleNames.SOURCE_OF_FUNDS]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.BUSINESS,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.SOURCE_OF_INCOME]: [
    [paths.KYC, paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.BUSINESS,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.SUSPICIOUS_FUNDS]: [
    [paths.TRANSACTION, paths.CAR, paths.KYC],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
      paths.INDIVIDUAL,
    ],
  ],
  [ruleNames.TEST_BLOCK]: [
    [paths.TEST],
  ],
  [ruleNames.TEST_REVIEW]: [
    [paths.TEST],
  ],
  [ruleNames.TIME_WINDOW]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INDIVIDUAL,
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.TOTAL_FREQUENCY]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: [
    [paths.TRANSACTION],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
    ],
  ],
  [ruleNames.ZIP_CODE_BLACKLIST]: [
    [paths.TRANSACTION, paths.CAR],
    [
      paths.INITIATOR,
      paths.RECIPIENT,
      paths.PROCESSOR,
      paths.BUYER,
      paths.SELLER,
      paths.PURCHASE,
    ],
  ],
};

export default rulePath;
