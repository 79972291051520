import React from 'react';

import {
  Loading,
  RecordContextProvider,
  required,
  SelectInput,
  TextInput,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Grid,
} from '@mui/material';

import { legalFormTypeChoices } from '../../../../../../../constants/legalForms';
import languages from '../../../../../../../constants/languages';

const Entity = ({
  entityVariant,
}: {
  entityVariant: string

}) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne('businesses', { id: record?.id }, { enabled: !!record?.id });

  if (isLoading) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <RecordContextProvider value={{
      [`${entityVariant}.name`]: data?.name,
      [`${entityVariant}.incorporation_legal_form`]: data?.incorporationLegalForm,
      [`${entityVariant}.incorporation_number`]: data?.swedishOrganizationNumber,
    }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.name`} label="Name" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.incorporation_number`} label=" Incorporation Number " />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            choices={legalFormTypeChoices(languages.EN)}
            source={`${entityVariant}.incorporation_legal_form`}
            label=" Incorporation Legal Form "
          />
        </Grid>
      </Grid>
    </RecordContextProvider>

  );
};

export default Entity;
