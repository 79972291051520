import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const identifierTypes = {
  SOCIAL_SECURITY_NUMBER: 'socialSecurityNumber',
  NATIONAL_INSURANCE_NUMBER: 'nationalInsuranceNumber',
  NATIONAL_ID: 'nationalID',
  PASSPORT_NUMBER: 'passportNumber',
  DRIVERS_LICENSE_NUMBER: 'driversLicenseNumber',
  OTHER: 'other',
} as const;

const identifierTypeEnLabels = {
  [identifierTypes.SOCIAL_SECURITY_NUMBER]: 'Social Security Number',
  [identifierTypes.NATIONAL_INSURANCE_NUMBER]: 'National Insurance Number',
  [identifierTypes.NATIONAL_ID]: 'National ID',
  [identifierTypes.PASSPORT_NUMBER]: 'Passport Number',
  [identifierTypes.DRIVERS_LICENSE_NUMBER]: 'Driver\'s License Number',
  [identifierTypes.OTHER]: 'Other',
} as const;

const identifierTypeSvLabels = {
  [identifierTypes.SOCIAL_SECURITY_NUMBER]: 'Personnumer',
  [identifierTypes.NATIONAL_INSURANCE_NUMBER]: 'Nationellt försäkringsnummer',
  [identifierTypes.NATIONAL_ID]: 'Nationellt ID',
  [identifierTypes.PASSPORT_NUMBER]: 'Passnummer',
  [identifierTypes.DRIVERS_LICENSE_NUMBER]: 'Körkortsnummer',
  [identifierTypes.OTHER]: 'Annat',
} as const;

const identifierTypeLabels = {
  [languages.EN]: identifierTypeEnLabels,
  [languages.SV]: identifierTypeSvLabels,
} as const;

export const identifierTypeChoices = (language: Languages) => [
  {
    id: identifierTypes.SOCIAL_SECURITY_NUMBER,
    name: identifierTypeLabels[language][identifierTypes.SOCIAL_SECURITY_NUMBER],
  },
  {
    id: identifierTypes.NATIONAL_INSURANCE_NUMBER,
    name: identifierTypeLabels[language][identifierTypes.NATIONAL_INSURANCE_NUMBER],
  },
  {
    id: identifierTypes.NATIONAL_ID,
    name: identifierTypeLabels[language][identifierTypes.NATIONAL_ID],
  },
  {
    id: identifierTypes.PASSPORT_NUMBER,
    name: identifierTypeLabels[language][identifierTypes.PASSPORT_NUMBER],
  },
  {
    id: identifierTypes.DRIVERS_LICENSE_NUMBER,
    name: identifierTypeLabels[language][identifierTypes.DRIVERS_LICENSE_NUMBER],
  },
  {
    id: identifierTypes.OTHER,
    name: identifierTypeLabels[language][identifierTypes.OTHER],
  },
];

export type IdentifierTypes = ValueOf<typeof identifierTypes>;

export default identifierTypes;
