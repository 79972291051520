import { RemoveCircle } from '@mui/icons-material';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useRecordContext, useRefresh, useUpdate } from 'react-admin';
import { useParams } from 'react-router-dom';
import useDialogStatus from '../../../hooks/useDialogStatus';

const DeleteEntities = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const { id } = useParams();
  const record = useRecordContext();
  const [update] = useUpdate();
  const refetch = useRefresh();

  const handleClick = useCallback(() => {
    update('cases', {
      id: `${id}/detach-entity`,
      data: {
        entityId: record?.id,
        entityType: record?.type,
      },
    }, {
      mutationMode: 'pessimistic',
      onSuccess: () => {
        closeDialog();
        refetch();
      },
    });
  }, [closeDialog, id, record?.id, record?.type, refetch, update]);

  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Remove entity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove the entity from the case?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleClick} variant="contained">Confirm</Button>
        </DialogActions>
      </Dialog>
      <Button color="error" startIcon={<RemoveCircle />} onClick={openDialog}>
        Remove
      </Button>
    </>
  );
};

export default DeleteEntities;
