import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import { uniqBy } from 'lodash';

import NumberInput from '../../../layout/inputFields/NumberInput';
import SelectArrayInput from '../../../layout/inputFields/SelectArrayInput';
import InfoPopover from '../../../layout/InfoPopover';

export const COLLECT_INDIVIDUAL_FIELDS = [
  { id: 'firstName', name: 'First name (Individual)' },
  { id: 'lastName', name: 'Last name (Individual)' },
  { id: 'address', name: 'Address' },
] as const;

export const COLLECT_BUSINESS_FIELDS = [
  { id: 'name', name: 'Name (Business)' },
  { id: 'preliminaryTaxRegistration', name: 'Preliminary tax registration (Business)' },
  { id: 'address', name: 'Address' },
  { id: 'legalForm', name: 'Legal form (Business)' },
  { id: 'signatory', name: 'Signatory (Business)' },
  { id: 'beneficialOwners', name: 'Beneficial owners (Business)' },
  { id: 'assignedBeneficialOwner', name: ' Assigned Beneficial owner (Business)' },
  { id: 'board', name: 'Board (Business)' },
  { id: 'industry', name: 'Industry code (Business)' },
] as const;

const Collector = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={6}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Data update frequency</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <NumberInput
            fullWidth
            source="ruleParameters.updateFreq"
            label="Days"
            validate={[required(), minValue(0)]}
            popups={[
              <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={12}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Missing entity fields</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectArrayInput
            fullWidth
            choices={
              uniqBy([...COLLECT_BUSINESS_FIELDS, ...COLLECT_INDIVIDUAL_FIELDS], 'id')
            }
            source="ruleParameters.collectFields"
            label="Collect fields"
            validate={[required(), minValue(0)]}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Collector;
