import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const industrySections = {
  AGRICULTURE_FORESTRY_FISHING: 'agricultureForestryFishing',
  MINING_QUARRYING: 'miningQuarrying',
  MANUFACTURING: 'manufacturing',
  ELECTRICITY_GAS_STEAM_AIR_CONDITIONING_SUPPLY: 'electricityGasSteamAirConditioningSupply',
  WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_REMEDIATION_ACTIVITIES: 'waterSupplySewerageWasteManagementRemediationActivities',
  CONSTRUCTION: 'construction',
  WHOLESALE_RETAIL_TRADE_REPAIR_MOTOR_VEHICLES_MOTORCYCLES: 'wholesaleRetailTradeRepairMotorVehiclesMotorcycles',
  TRANSPORTATION_STORAGE: 'transportationStorage',
  ACCOMMODATION_FOOD_SERVICE_ACTIVITIES: 'accommodationFoodServiceActivities',
  INFORMATION_COMMUNICATION: 'informationCommunication',
  FINANCIAL_INSURANCE_ACTIVITIES: 'financialInsuranceActivities',
  REAL_ESTATE_ACTIVITIES: 'realEstateActivities',
  PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES: 'professionalScientificTechnicalActivities',
  ADMINISTRATIVE_SUPPORT_SERVICE_ACTIVITIES: 'administrativeSupportServiceActivities',
  PUBLIC_ADMINISTRATION_DEFENCE_COMPULSORY_SOCIAL_SECURITY: 'publicAdministrationDefenceCompulsorySocialSecurity',
  EDUCATION: 'education',
  HUMAN_HEALTH_SOCIAL_WORK_ACTIVITIES: 'humanHealthSocialWorkActivities',
  ARTS_ENTERTAINMENT_RECREATION: 'artsEntertainmentRecreation',
  OTHER_SERVICE_ACTIVITIES: 'otherServiceActivities',
  ACTIVITIES_HOUSEHOLDS_EMPLOYERS_UNDIFFERENTIATED_GOODS_SERVICES_PRODUCING_ACTIVITIES_HOUSEHOLDS_OWN_USE: 'activitiesHouseholdsEmployersUndifferentiatedGoodsServicesProducingActivitiesHouseholdsOwnUse',
  ACTIVITIES_EXTRATERRITORIAL_ORGANISATIONS_BODIES: 'activitiesExtraterritorialOrganisationsBodies',
} as const;

const industrySectionEnLabels = {
  [industrySections.AGRICULTURE_FORESTRY_FISHING]: 'Agriculture, Forestry, and Fishing',
  [industrySections.MINING_QUARRYING]: 'Mining and Quarrying',
  [industrySections.MANUFACTURING]: 'Manufacturing',
  [industrySections.ELECTRICITY_GAS_STEAM_AIR_CONDITIONING_SUPPLY]: 'Electricity, Gas, Steam, and Air Conditioning Supply',
  [industrySections.WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_REMEDIATION_ACTIVITIES]: 'Water Supply, Sewerage, Waste Management, and Remediation Activities',
  [industrySections.CONSTRUCTION]: 'Construction',
  [industrySections.WHOLESALE_RETAIL_TRADE_REPAIR_MOTOR_VEHICLES_MOTORCYCLES]: 'Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles',
  [industrySections.TRANSPORTATION_STORAGE]: 'Transportation and Storage',
  [industrySections.ACCOMMODATION_FOOD_SERVICE_ACTIVITIES]: 'Accommodation and Food Service Activities',
  [industrySections.INFORMATION_COMMUNICATION]: 'Information and Communication',
  [industrySections.FINANCIAL_INSURANCE_ACTIVITIES]: 'Financial and Insurance Activities',
  [industrySections.REAL_ESTATE_ACTIVITIES]: 'Real Estate Activities',
  [industrySections.PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES]: 'Professional, Scientific, and Technical Activities',
  [industrySections.ADMINISTRATIVE_SUPPORT_SERVICE_ACTIVITIES]: 'Administrative and Support Service Activities',
  [industrySections.PUBLIC_ADMINISTRATION_DEFENCE_COMPULSORY_SOCIAL_SECURITY]: 'Public Administration and Defence; Compulsory Social Security',
  [industrySections.EDUCATION]: 'Education',
  [industrySections.HUMAN_HEALTH_SOCIAL_WORK_ACTIVITIES]: 'Human Health and Social Work Activities',
  [industrySections.ARTS_ENTERTAINMENT_RECREATION]: 'Arts, Entertainment, and Recreation',
  [industrySections.OTHER_SERVICE_ACTIVITIES]: 'Other Service Activities',
  [industrySections.ACTIVITIES_HOUSEHOLDS_EMPLOYERS_UNDIFFERENTIATED_GOODS_SERVICES_PRODUCING_ACTIVITIES_HOUSEHOLDS_OWN_USE]: 'Activities of Households as Employers; Undifferentiated Goods and Services Producing Activities of Households for Own Use',
  [industrySections.ACTIVITIES_EXTRATERRITORIAL_ORGANISATIONS_BODIES]: 'Activities of Extraterritorial Organisations and Bodies',
} as const;

const industrySectionSvLabels = {
  [industrySections.AGRICULTURE_FORESTRY_FISHING]: 'Jordbruk, Skogsbruk och Fiske',
  [industrySections.MINING_QUARRYING]: 'Gruvdrift och Brottning',
  [industrySections.MANUFACTURING]: 'Tillverkning',
  [industrySections.ELECTRICITY_GAS_STEAM_AIR_CONDITIONING_SUPPLY]: 'Elektricitet, Gas, Ånga och Luftkonditionering Leverans',
  [industrySections.WATER_SUPPLY_SEWERAGE_WASTE_MANAGEMENT_REMEDIATION_ACTIVITIES]: 'Vattentillförsel, Avlopp, Avfallshantering och Sanering Aktiviteter',
  [industrySections.CONSTRUCTION]: 'Byggnation',
  [industrySections.WHOLESALE_RETAIL_TRADE_REPAIR_MOTOR_VEHICLES_MOTORCYCLES]: 'Grossist och Detaljhandel; Reparation av Motorfordon och Motorcyklar',
  [industrySections.TRANSPORTATION_STORAGE]: 'Transport och Förvaring',
  [industrySections.ACCOMMODATION_FOOD_SERVICE_ACTIVITIES]: 'Logi och Måltidstjänster',
  [industrySections.INFORMATION_COMMUNICATION]: 'Information och Kommunikation',
  [industrySections.FINANCIAL_INSURANCE_ACTIVITIES]: 'Finansiella och Försäkringsaktiviteter',
  [industrySections.REAL_ESTATE_ACTIVITIES]: 'Fastighetsverksamhet',
  [industrySections.PROFESSIONAL_SCIENTIFIC_TECHNICAL_ACTIVITIES]: 'Professionella, Vetenskapliga och Tekniska Aktiviteter',
  [industrySections.ADMINISTRATIVE_SUPPORT_SERVICE_ACTIVITIES]: 'Administrativa och Supporttjänster',
  [industrySections.PUBLIC_ADMINISTRATION_DEFENCE_COMPULSORY_SOCIAL_SECURITY]: 'Offentlig Förvaltning och Försvar; Obligatorisk Social Trygghet',
  [industrySections.EDUCATION]: 'Utbildning',
  [industrySections.HUMAN_HEALTH_SOCIAL_WORK_ACTIVITIES]: 'Mänsklig Hälsa och Socialt Arbete',
  [industrySections.ARTS_ENTERTAINMENT_RECREATION]: 'Konst, Underhållning och Fritid',
  [industrySections.OTHER_SERVICE_ACTIVITIES]: 'Andra Serviceaktiviteter',
  [industrySections.ACTIVITIES_HOUSEHOLDS_EMPLOYERS_UNDIFFERENTIATED_GOODS_SERVICES_PRODUCING_ACTIVITIES_HOUSEHOLDS_OWN_USE]: 'Hushållsaktiviteter som Arbetsgivare; Odifferentierade Varor och Tjänster Producerande Aktiviteter av Hushåll för Eget Bruk',
  [industrySections.ACTIVITIES_EXTRATERRITORIAL_ORGANISATIONS_BODIES]: 'Aktiviteter av Extraterritoriella Organisationer och Organ',
} as const;

const industrySectionLabels = {
  [languages.EN]: industrySectionEnLabels,
  [languages.SV]: industrySectionSvLabels,
} as const;

export const industrySectionChoices = (
  language: Languages,
) => Object.values(industrySections).map((section) => ({
  id: section,
  name: industrySectionLabels[language][section],
}));

export type IndustrySections = ValueOf<typeof industrySections>;

export default industrySections;
