import React from 'react';
import { SelectInput, TextInput, required } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';

import Joi from '../../../utilities/joiExtension';
import { countryChoices, countryCodes } from '../../../constants/countries';
import languages from '../../../constants/languages';

export const organisationNumberValidation = joiResolver(Joi.object().keys({
  value: Joi.object().keys({
    country: Joi.string().valid(...countryCodes).required().label('Registration country'),
    registrationNumber: Joi.when('country', {
      is: Joi.string().valid('SWE').required(),
      then: Joi.orgNumber().required(),
      otherwise: Joi.string().required(),
    }).label('Registration number'),
  }).required(),
  comment: Joi.string().required().label('Comment'),
}), { abortEarly: false });

const OrganizationNumber = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Organization number
      </Typography>
    </Grid>
    <Grid item xs={12} md={7} container spacing={4}>
      <Grid item xs={12} md={6}>
        <SelectInput
          fullWidth
          label="Registration country"
          source="value.country"
          helperText={false}
          choices={countryChoices(languages.EN)}
          validate={[required()]}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          fullWidth
          label="Registration number"
          source="value.registrationNumber"
          helperText={false}
          validate={[required()]}
        />
      </Grid>
    </Grid>
    <Grid item xs={12} md={12}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
        validate={[required()]}
      />
    </Grid>
  </Grid>
);

export default OrganizationNumber;
