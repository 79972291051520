import { ValueOf } from '../utilities/types';

const timePeriods = {
  DAYS: 'days',
  MONTHS: 'months',
  YEARS: 'years',
} as const;

const timePeriodLabels = {
  [timePeriods.DAYS]: 'Days',
  [timePeriods.MONTHS]: 'Months',
  [timePeriods.YEARS]: 'Years',
} as const;

export type TimePeriods = ValueOf<typeof timePeriods>;

export const timePeriodChoices = [
  {
    id: timePeriods.DAYS,
    name: timePeriodLabels[timePeriods.DAYS],
  },
  {
    id: timePeriods.MONTHS,
    name: timePeriodLabels[timePeriods.MONTHS],
  },
  {
    id: timePeriods.YEARS,
    name: timePeriodLabels[timePeriods.YEARS],
  },
];

export default timePeriods;
