import React from 'react';

import {
  Card,
  CardHeader,
  Button,
  CardContent,
} from '@mui/material';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useRedirect } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';

const SummaryCard = ({
  children,
  title,
  edit = true,
  path,
}: {
  children: React.ReactChild
  title: string
  edit?: boolean
  path: string
}) => {
  const redirect = useRedirect();
  const { id } = useParams();
  const { state } = useLocation();
  const handleClick = () => {
    redirect(`/rules/${id ?? 'create'}/${path}`, undefined, undefined, undefined, state);
  };
  return (
    <Card sx={{ backgroundColor: 'background.default' }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ fontWeight: 'bold' }}
        sx={{ color: 'primary.main' }}
        action={<Button onClick={handleClick} sx={{ display: edit ? 'flex' : 'none' }} color="primary" endIcon={<EditOutlinedIcon />}>Edit</Button>}
      />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
