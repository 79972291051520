import React from 'react';

import { Grid, Typography } from '@mui/material';
import Select from './base/Select';
import booleanChoices from '../../../constants/booleanChoices';

const IsFinancialInstitution = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Is The Company a Financial Institution?
      </Typography>
    </Grid>
    <Select choices={booleanChoices.en} />
  </Grid>
);

export default IsFinancialInstitution;
