import BusinessKycList from '../components/request/RequestTypeForm/list/BusinessKyc';
import CarKycList from '../components/request/RequestTypeForm/list/CarKyc';
import CarPurchaseList from '../components/request/RequestTypeForm/list/CarPurchase';
import IndividualKycList from '../components/request/RequestTypeForm/list/IndividualKyc';
import TransactionList from '../components/request/RequestTypeForm/list/Transaction';

import TransactionEdit from '../components/request/RequestTypeForm/edit/Transaction';
import CarPurchaseEdit from '../components/request/RequestTypeForm/edit/CarPurchase';
import CarKycEdit from '../components/request/RequestTypeForm/edit/CarKyc';
import IndividualKycEdit from '../components/request/RequestTypeForm/edit/IndividualKyc';
import BusinessKycEdit from '../components/request/RequestTypeForm/edit/BusinessKyc';

import TransactionCreate from '../components/request/RequestTypeForm/create/Transaction';
import CarPurchaseCreate from '../components/request/RequestTypeForm/create/CarPurchase';
import CarKycCreate from '../components/request/RequestTypeForm/create/CarKyc';
import IndividualKycCreate from '../components/request/RequestTypeForm/create/IndividualKyc';
import BusinessKycCreate from '../components/request/RequestTypeForm/create/BusinessKyc';

import { requestTypes } from './requestTypes';

export const requestListMap = {
  [requestTypes.TRANSACTION]: TransactionList,
  [requestTypes.CAR_PURCHASE]: CarPurchaseList,
  [requestTypes.CAR_KYC]: CarKycList,
  [requestTypes.INDIVIDUAL_KYC]: IndividualKycList,
  [requestTypes.BUSINESS_KYC]: BusinessKycList,
};

export const requestEditMap = {
  [requestTypes.TRANSACTION]: TransactionEdit,
  [requestTypes.CAR_PURCHASE]: CarPurchaseEdit,
  [requestTypes.CAR_KYC]: CarKycEdit,
  [requestTypes.INDIVIDUAL_KYC]: IndividualKycEdit,
  [requestTypes.BUSINESS_KYC]: BusinessKycEdit,
};

export const requestCreateMap = {
  [requestTypes.TRANSACTION]: TransactionCreate,
  [requestTypes.CAR_PURCHASE]: CarPurchaseCreate,
  [requestTypes.CAR_KYC]: CarKycCreate,
  [requestTypes.INDIVIDUAL_KYC]: IndividualKycCreate,
  [requestTypes.BUSINESS_KYC]: BusinessKycCreate,
};
