import { get } from 'lodash';
import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Popover } from '@mui/material';

import mugshot1 from '../assets/exposure/mugshot1.jpg';
import mugshot2 from '../assets/exposure/mugshot2.jpeg';
import mugshot3 from '../assets/exposure/mugshot3.jpg';
import mugshot4 from '../assets/exposure/mugshot4.jpeg';

const ImageField = ({
  source,
  emptyImage,
}: {
  source: string;
  emptyImage: string;
  label?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const record = useRecordContext();

  const handlePopoverOpen = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!record) return null;

  const value = get(record, source);

  const image = (() => {
    if (value === 'mugshot1') return mugshot1;
    if (value === 'mugshot2') return mugshot2;
    if (value === 'mugshot3') return mugshot3;
    if (value === 'mugshot4') return mugshot4;
    return value ?? emptyImage;
  });

  return (
    <>
      <img
        src={image()}
        alt=""
        width={50}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <img
          src={image()}
          alt=""
          width={300}
        />
      </Popover>

    </>
  );
};

export default ImageField;
