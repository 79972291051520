import { ValueOf } from '../utilities/types';

const bankAccountTypes = {
  BUSINESS: 'business',
  CHECKING: 'checking',
  CREDIT_LINE: 'credit-line',
  EXTERNAL: 'external',
  INVESTMENT: 'investment',
  MORTGAGE: 'mortgage',
  LOAN: 'loan',
  OTHER: 'other',
  PENSION: 'pension',
  SAVINGS: 'savings',
  BANK_GIRO: 'bank-giro',
  BANK_ACCOUNT: 'bank-account',
  SECURITIES_ACCOUNT: 'securities-account',
  PREPAID_CARD: 'prepaid-card',
  COMPANY_ACCOUNT: 'company-account',
  INVESTMENT_SAVINGS_ACCOUNT: 'investment-savings-account',
  CAPITAL_INSURANCE: 'capital-insurance',
  CLIENT_ACCOUNT: 'client-account',
  CREDIT_CARD_ACCOUNT: 'credit-card-account',
  LIFE_INSURANCE: 'life-insurance',
  LOAN_ACCOUNT: 'loan-account',
  UNKNOWN: 'unknown',
  PLUS_GIRO_ACCOUNT: 'plus-giro-account',
  GAMING_ACCOUNT: 'gaming-account',
  SWISH_NUMBER: 'swish-number',
  OCCUPATIONAL_PENSIONS_INSURANCE: 'occupational-pensions-insurance',
  FOREIGN_CURRENCY_ACCOUNT: 'foreign-currency-account',
  SHARE_OR_EQUITY_FUNDS_ACCOUNT: 'share-or-equity-funds-account',
  CHECK: 'check',
  MONEY_ORDER: 'money-order',
  E_WALLET: 'e-wallet',
  CARD: 'card',
  TRANSACTION_REFERENCE: 'transaction-reference',
  GENERATED_TRANSACTION_REFERENCE: 'generated-transaction-reference',
  IBAN_NUMBER: 'iban-number',
  PSP_ACCOUNT: 'psp-account',
  SAFE_DEPOSIT_BOX: 'safe-deposit-box',
} as const;

export type BankAccountTypes = ValueOf<typeof bankAccountTypes>;

export const bankAccountTypesChoices = [
  { id: bankAccountTypes.BUSINESS, name: 'Business' },
  { id: bankAccountTypes.CHECKING, name: 'Checking' },
  { id: bankAccountTypes.CREDIT_LINE, name: 'Credit Line' },
  { id: bankAccountTypes.EXTERNAL, name: 'External' },
  { id: bankAccountTypes.INVESTMENT, name: 'Investment' },
  { id: bankAccountTypes.MORTGAGE, name: 'Mortgage' },
  { id: bankAccountTypes.LOAN, name: 'Loan' },
  { id: bankAccountTypes.OTHER, name: 'Other' },
  { id: bankAccountTypes.PENSION, name: 'Pension' },
  { id: bankAccountTypes.SAVINGS, name: 'Savings' },
  { id: bankAccountTypes.BANK_GIRO, name: 'Bank Giro' },
  { id: bankAccountTypes.BANK_ACCOUNT, name: 'Bank Account' },
  { id: bankAccountTypes.SECURITIES_ACCOUNT, name: 'Securities Account' },
  { id: bankAccountTypes.PREPAID_CARD, name: 'Prepaid Card' },
  { id: bankAccountTypes.COMPANY_ACCOUNT, name: 'Company Account' },
  { id: bankAccountTypes.INVESTMENT_SAVINGS_ACCOUNT, name: 'Investment Savings Account' },
  { id: bankAccountTypes.CAPITAL_INSURANCE, name: 'Capital Insurance' },
  { id: bankAccountTypes.CLIENT_ACCOUNT, name: 'Client Account' },
  { id: bankAccountTypes.CREDIT_CARD_ACCOUNT, name: 'Credit Card Account' },
  { id: bankAccountTypes.LIFE_INSURANCE, name: 'Life Insurance' },
  { id: bankAccountTypes.LOAN_ACCOUNT, name: 'Loan Account' },
  { id: bankAccountTypes.UNKNOWN, name: 'Unknown' },
  { id: bankAccountTypes.PLUS_GIRO_ACCOUNT, name: 'Plus Giro Account' },
  { id: bankAccountTypes.GAMING_ACCOUNT, name: 'Gaming Account' },
  { id: bankAccountTypes.SWISH_NUMBER, name: 'Swish Number' },
  { id: bankAccountTypes.OCCUPATIONAL_PENSIONS_INSURANCE, name: 'Occupational Pensions Insurance' },
  { id: bankAccountTypes.FOREIGN_CURRENCY_ACCOUNT, name: 'Foreign Currency Account' },
  { id: bankAccountTypes.SHARE_OR_EQUITY_FUNDS_ACCOUNT, name: 'Share Or Equity Funds Account' },
  { id: bankAccountTypes.CHECK, name: 'Check' },
  { id: bankAccountTypes.MONEY_ORDER, name: 'Money Order' },
  { id: bankAccountTypes.E_WALLET, name: 'E Wallet' },
  { id: bankAccountTypes.CARD, name: 'Card' },
  { id: bankAccountTypes.TRANSACTION_REFERENCE, name: 'Transaction Reference' },
  { id: bankAccountTypes.GENERATED_TRANSACTION_REFERENCE, name: 'Generated Transaction Reference' },
  { id: bankAccountTypes.IBAN_NUMBER, name: 'Iban Number' },
  { id: bankAccountTypes.PSP_ACCOUNT, name: 'PSP Account' },
  { id: bankAccountTypes.SAFE_DEPOSIT_BOX, name: 'Safe Deposit Box' },
];

export default bankAccountTypes;
