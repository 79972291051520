import React from 'react';

import {
  DateField,
  RecordContextProvider,
  ReferenceField,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Fade,
} from '@mui/material';
import { Alpha3Code } from 'i18n-iso-countries';

import useEntityData from '../../../../hooks/useEntityData';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import RegisteredField from '../../../../customFields/RegisteredField';

import actions from '../../../../constants/actions';
import { NationalIdentifierSingleField } from '../../../../customFields/NationalIdentifier';

type GeneralInfo = {
  id: string;
  createdAt?: string;
  activityDescription?: string;
  companyId?: string;
  lastChangeDate?: string;
  mainActivity: {
    industryCode?: string;
    industryText?: string;
    industryTextAdditional?: string
  };
  otherActivities: {
    industryCode?: string;
    industryText?: string;
  }[];
  secondaryNames: {
    activityDescription?: string;
    deRegistrationDate?: string;
    name?: string;
    registrationDate?: string;
  }[];
  status: {
    code?: number;
    text?: string;
  }
  country?: Alpha3Code;
  providerReference?: string;
  active?: boolean,
  lastAddressChange?: string,
  address?: string,
  auditorReservation?: boolean,
  cfarNumber?: string,
  changeDate?: string,
  coAddress?: string,
  commune?: string,
  communeCode?: string,
  companyDeregistrationDate?: string,
  companyHolder?: string,
  companyName?: string,
  companyRegistrationDate?: string,
  ceo?: {
    id?: string,
    ssn?: string,
    firstName?: string,
    lastName?: string,
  },
  nonLtdOwner?: {
    id?: string,
    name?: string,
  }
  groupMother?: {
    companyId?: string,
    name?: string,
    country?: string,
  },
  immediateGroupMother?: {
    companyId?: string,
    name?: string,
  },
  vehicles?: {
    used?: number,
    owned?: number,
    lessee?: number,
    lessor ?: number,
    disposedPrevMth?: number,
    acquiredPrevMth?: number
  },
  nameRegistration?: {
    companyId?: string,
    companyName?: string,
    orderNumber?: string,
    regDate?: string,
    town?: string,
    zipCode?: string
    address?: string,
  }[],
  oldName?: string,
  county?: string,
  countyCode?: string;
  email?: string,
  employerContributionReg?: boolean,
  employerContributionRegDate?: string,
  faxNumber?: string,
  industryCode?: string,
  industryTextAdditional?: string
  industryText?: string,
  legalGroupCode?: string,
  legalGroupText?: string,
  numberCompanyUnits?: number,
  numberEmployeesInterval?: string,
  numberEmployees?: number,
  phoneNumber?: string,
  preliminaryTaxReg?: boolean,
  preliminaryTaxRegDate?: string,
  // TODO: migrate to providerReference
  safeNumber?: string,
  severalCompanyName?: boolean,
  statusCode?: string,
  statusDateFrom?: string,
  statusTextDetailed?: string,
  statusTextHigh?: string,
  topDirectorFunction?: string,
  topDirectorName?: string,
  town?: string,
  state?: string,
  addressCountry?: Alpha3Code;
  vatReg?: boolean,
  vatNumber?: string,
  vatRegDate?: string,
  visitingAddresses?: {
    visitingAddresses?: string,
    co?: string;
    visitingZip?: string,
    visitingTown?: string,
    state?: string,
    country?: string,
  }[],
  mailingAddresses?: {
    mailingAddress?: string,
    co?: string,
    mailingZip?: string,
    mailingTown?: string,
    state?: string,
    country?: string,
  }[],
  reconstructionInformation?: {
    reconstructionText?: string,
    reconstructionDate?: string,
    reconstructionAdmName?: string,
    reconstructionAdmFirm?: string,
    reconstructionAdmVisitAddress?: string,
    reconstructionBoxAddress?: string,
    reconstructionPostCode?: string,
    reconstructionPostTown?: string,
  }[],
  webAddress?: string,
  zipCode?: string,
}

const GeneralCompanyInformation = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<GeneralInfo>({
    source: 'company-information',
    getAction: actions.BUSINESS_GET_GENERAL_INFO,
  });

  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <CardHeader
                title="Company Information"
                subheader={(
                  <Box display="flex" alignItems="baseline">
                    <Typography>Latest update:</Typography>
                    <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
                  </Box>
                )}
                action={(
                  <PurchaseButton
                    loading={isLoading}
                    label="Get Company Information"
                    onClick={handlePurchase}
                    action={actions.BUSINESS_CREATE_GENERAL_INFO}
                  />
                )}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Company Name:</Typography>
                      <TextField source="companyName" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Company ID:</Typography>
                      <TextField source="companyId" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Registration Date:</Typography>
                      <DateField source="companyRegistrationDate" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Legal Group Code:</Typography>
                      <TextField source="legalGroupCode" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Legal Group :</Typography>
                      <TextField source="legalGroupText" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Number of Employees:</Typography>
                      <TextField source="numberEmployeesInterval" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">VAT:</Typography>
                      <RegisteredField data={data.vatReg} />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">F-tax:</Typography>
                      <RegisteredField data={data.preliminaryTaxReg} />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Address:</Typography>
                      <TextField source="address" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Postal Code:</Typography>
                      <TextField source="zipCode" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">City:</Typography>
                      <TextField source="town" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">CEO:</Typography>
                      <ReferenceField source="ceo.id" reference="individuals" emptyText="-">
                        <NationalIdentifierSingleField />
                      </ReferenceField>
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Industry Code:</Typography>
                      <TextField source="industryCode" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Industry:</Typography>
                      <TextField source="industryText" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Box display="flex" gap={1} alignItems="center">
                      <Typography variant="smallTitle">Activity Description:</Typography>
                      <TextField source="activityDescription" emptyText="-" />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </RecordContextProvider>
    );
  }
  return (
    <Fade in={!data}>
      <Box margin={5} display="flex" alignItems="center" width="100%" flexDirection="column">
        <Typography variant="smallTitle">No Data Available</Typography>
        <PurchaseButton action={actions.BUSINESS_GET_GENERAL_INFO} loading={isLoading} label="Get General Company Information" empty onClick={handlePurchase} />
      </Box>
    </Fade>
  );
};

export default GeneralCompanyInformation;
