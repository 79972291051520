import React, { useEffect, useState } from 'react';

import {
  ReferenceArrayField,
  useListContext,
} from 'react-admin';

import {
  Card, CardHeader, Divider, Grid,
} from '@mui/material';

import { isEmpty } from 'lodash';

import Pagination from '../../layout/Pagination';

import { requestListMap } from '../../../constants/requestFeatures';
import { requestTypeLabels, RequestTypes, requestTypes } from '../../../constants/requestTypes';

const ListBody = ({
  requestType,
  setIsEmptyList,
}: {
  requestType: RequestTypes,
  setIsEmptyList: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { data } = useListContext();

  useEffect(() => {
    setIsEmptyList(isEmpty(data));
  }, [data, setIsEmptyList]);

  const RequestListComponent = requestListMap[requestType];

  return (
    <RequestListComponent />
  );
};

const CaseRequestItem = ({
  requestType,
}: {
  requestType: RequestTypes,
}) => {
  const [isEmptyList, setIsEmptyList] = useState(false);

  return (
    <Grid item xs={12} md={12} display={isEmptyList ? 'none' : 'block'}>
      <Card variant="outlined">
        <CardHeader title={`${requestTypeLabels[requestType]}s`} />
        <Divider />
        <ReferenceArrayField
          filter={{ requestType }}
          reference="requests"
          source="requests"
          perPage={10}
          pagination={(
            <Pagination disableEmptyText />
          )}
        >
          <ListBody requestType={requestType} setIsEmptyList={setIsEmptyList} />
        </ReferenceArrayField>
      </Card>
    </Grid>
  );
};

const CaseRequestList = () => Object.values(requestTypes)
  .map((requestType) => <CaseRequestItem requestType={requestType} key={requestType} />);

export default CaseRequestList;
