import { ValueOf } from '../utilities/types';
import fieldStatus from './fieldStatus';

const formAnswerConflictStatus = {
  ...fieldStatus,
  OTHER_VALUE_SELECTED: 'selection-different-kyc-value',
  NO_DATAPOINT: 'no-datapoint',
} as const;

export type FormAnswerConflictStatus = ValueOf<typeof formAnswerConflictStatus>;

export default formAnswerConflictStatus;
