import React from 'react';

import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import Button from '@mui/material/Button';

import { BulkUpdate } from '../../ping/components/UpdatePingStatus';
import useDialogStatus from '../../../hooks/useDialogStatus';

const BulkUpdatePings = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  return (
    <>
      <Button
        startIcon={<LibraryAddCheckIcon />}
        onClick={openDialog}
      >
        Bulk Handle
      </Button>
      <BulkUpdate
        open={open}
        closeDialog={closeDialog}
      />
    </>
  );
};

export default BulkUpdatePings;
