import React, { useEffect } from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { isEmpty } from 'lodash';

import {
  TextField,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';

import ArrayInput from './base/ArrayInput';
import cleaner from '../../../utilities/dataProvider/cleaner';
import { SourceOfWealthFormAnswer } from '../../kycForm/view/answers/SourceOfWealth';

type SourceOfWealthValue = string[];

const SourceOfWealthDialogBody = () => (
  <Grid container columnSpacing={6}>
    <Grid item xs={12} md={12}>
      <TextInput fullWidth source="source" label="Source" />
    </Grid>
  </Grid>

);

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <Typography
        variant="h6"
        color="primary.main"
      >
        <Typography color="primary.main" variant="h6">
          {value.source}
        </Typography>
      </Typography>
    </Box>
  );
};

export const SourceOfWealthAnswer = () => {
  const record = useRecordContext<{
    value: SourceOfWealthValue;
  }>();

  const value = (record?.value ?? []).join(', ');
  return <TextField record={{ value }} source="value" emptyText="-" fontWeight="bold" />;
};

export const sourceOfWealthFormatter = (
  datapointValue: SourceOfWealthValue,
): SourceOfWealthFormAnswer => ({
  options: datapointValue,
});

const SourceOfWealth = () => {
  const value = useWatch({ name: '_value' });
  const { setValue } = useFormContext();
  useEffect(() => {
    const formattedValue = (value ?? []).flatMap((v: {source: string }) => v.source);
    setValue('value', formattedValue, { shouldDirty: true });
  }, [setValue, value]);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Typography
          color="#646464"
          variant="h6"
          marginBottom={4}
        >
          Source of funds
        </Typography>
        <ArrayInput
          rootField="_value"
          title="Add source"
          itemNameSource="source"
          ItemLabel={ItemLabel}
          DialogBody={SourceOfWealthDialogBody}
          validate={required()}
          transformItemData={cleaner}
        />
      </Grid>
    </Grid>
  );
};

export default SourceOfWealth;
