import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const CaseBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record?.id.toString() ?? '',
    path: `/cases/${record?.id}`,
  });

  return null;
};

export default CaseBreadcrumb;
