import React from 'react';

import {
  DateField,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
  Fade,
} from '@mui/material';

import useEntityData from '../../../../../hooks/useEntityData';

import PurchaseButton from '../../../../layout/button/PurchaseButton';

import VehicleIdentity from './VehicleIdentity';
import OwnerInfo from './OwnerInfo';
import Status from './Status';
import Technical from './Technical';

import actions from '../../../../../constants/actions';

export type CarBasicData = {
  id: string;
  createdAt: string;
  entityId: string;
  regnr?: string,
  personligtRegnr?: string,
  fordonsslag?: string,
  fabrikat?: string,
  arsmodell?: string,
  farg?: string,
  chassinummer?: string,
  motornummer?: string,
  modellnr?: string,
  gruppkod?: string,
  datumForRegistrering?: string,
  fordonsstatus?: string,
  datumForStatus?: string,
  statusorsak?: string,
  datumITrafikForstaGangen?: string,
  regnrTidigareAnvant?: string,
  euRegbevisdatum?: string,
  brukareFrom?: string,
  antalBrukare?: string,
  agareFrom?: string,
  agarRoll?: string,
  signalOmFleraAgare?: string,
  slutdatum?: string,
  personnummer?: string,
  sekel?: string,
  namn?: string,
  tilltalsnamn?: string,
  lanKommunForsamling?: string,
  reklamsparr?: string,
  sekretess?: string,
  ursprungsorsak?: string,
  ursprungskod?: string,
  ursprungligtRegisteringsdatum?: string,
  producentansvarig?: string,
  utredn?: string,
  utrorsak?: string,
  lk?: string,
  direktimport?: string,
  leasing?: string,
  uppl?: string,
  kredgiv?: string,
  gruppnr?: string,
  krednamn?: string,
  kredAdress?: string,
  kredPostnr?: string,
  kredOrt?: string,
  adress?: string,
  postnr?: string,
  postAnst?: string,
  kategori?: string,
  personkategori?: string,
  personstatus?: string,
  skyddad?: string,
  datumForv?: string,
  fbolag?: string,
  fbolagkod?: string,
  datumFors?: string,
  ftyp?: string,
  eeGgodkannandenummer?: string,
  eeGgodkannandedatum?: string,
  tillverkningsmanad?: string,
  karosserikod?: string,
  kaross?: string,
  antalPassagerare?: string,
  yrkestrafikAnvandningsatt1?: string,
  gallerFranDatum1?: string,
  yrkestrafikAnvandningsatt2?: string,
  gallerFranDatum2?: string,
  regstatus?: string,
  statusDatum?: string,
  datumGodkandBesiktning?: string,
  datumGodkandTillOchMed?: string,
  forelaggande?: string,
  fbesutfall?: string,
  kforbud?: string,
  kforbdatum?: string,
  kforborsak?: string,
  inrapport?: string,
  myndighet?: string,
  brukforbud?: string,
  saldo?: string,
  avskyltas?: string,
  registreringsbesiktning?: string,
  registreringstyp?: string,
  skfri?: string,
  skfrupph?: string,
  skfrorsak?: string,
  skfrunder?: string,
  fskatt?: string,
  vegavgift?: string,
  fdeb?: string,
  fdebavgift?: string,
  fskattdeb?: string,
  fskattater?: string,
  fobet?: string,
  skrotpr?: string,
  aterInfortISverigeOchTagetITrafik?: string,
  fordonsAr?: string,
  qCount?: string,
  pastallningsAvgift?: string,
  bussklass?: string,
  fordklass?: string,
  fordKategori?: string,
  handelsBet?: string,
  langd?: string,
  bredd?: string,
  passagerare?: string,
  sittande?: string,
  baltade?: string,
  iRullstol?: string,
  staende?: string,
  krockkuddePassFram?: string,
  lyftkapacitet?: string,
  agarDisp?: string,
  agarDispUpphor?: string,
  vagVanligFjadring?: string,
  regOmbesiktning?: string,
  skyltFram?: string,
  skyltBak?: string,
  fordonsVariant?: string,
  fordonsVersion?: string,
  kaross21?: string,
  dispensText?: string,
  textkod?: string,
  fritext?: string,
  tillfreg?: string,
  betmanad?: string,
  vagavgifttom?: string,
  periodskatt?: string,
  periodSkatt1Uppbordsmanad?: string,
  periodskatt1FranOchMed?: string,
  periodskatt1TillOchMed?: string,
  periodskatt2Uppbordsmanad?: string,
  periodskatt2FranOchMed?: string,
  periodskatt2TillOchMed?: string,
  periodskatt3Uppbordsmanad?: string,
  periodskatt3FranOchMed?: string,
  periodskatt3TillOchMed?: string,
  externModell?: string,
  externMarke?: string,
  externArsModell?: string,
  skattbettom?: string,
  skattetabell?: string,
  kredVidAvregistreringRegisterhallningsavgiftKr?: string,
  efterlystLista?: {
    efterlysningsanledning?: string,
    efterlysningensGallande?: string,
    efterlysningsdatum?: string,
    efterlysandePMyndNamn?: string,
    efterlysandePMyndKod?: string,
    avlyssningsdatum?: string,
    avlyssningsPMyndkod?: string
  }[],
  iAnsprak?: string,
  datiansprak?: string,
  statusDatuml?: string,
  typBeckteckning?: string,
  tillverkningsdatum?: string,
  fsFordonsskattkuldSaldo?: string,
  fsFordonsskattBrukandeforbud?: string,
  tnTrangselskattSkuldSaldo?: string,
  tnTrangselskattBrukandeforbud?: string,
  anInfrastrukturSkuldSaldo?: string,
  anInfrastrukturBrukandeforbud?: string,
  paFelparkeringSkuldSaldo?: string,
  paFelparkeringBrukforbud?: string,
  vaVagavgiftSkuldSaldo?: string,
  vaVagavgiftBrukforbud?: string,
  err?: string
};

const Index = () => {
  const { data, handlePurchase, isLoading } = useEntityData<CarBasicData>({ source: 'basic', getAction: actions.CAR_GET_BASIC_DATA });

  if (data) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <PurchaseButton action={actions.CAR_CREATE_BASIC_DATA} loading={isLoading} label="Get General Data" onClick={handlePurchase} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField
              record={{ createdAt: data.createdAt }}
              sx={{ marginLeft: 1, fontWeight: 'bold' }}
              source="createdAt"
              emptyText="-"
              showTime
            />
          </Box>
        </Grid>
        <VehicleIdentity data={data} />
        <OwnerInfo data={data} />
        <Status data={data} />
        <Technical data={data} />
      </Grid>
    );
  }
  return (
    <Fade in={!isLoading}>
      <Box margin={5} display="flex" alignItems="center" width="100%" flexDirection="column">
        <Typography variant="smallTitle">No Data Available</Typography>
        <PurchaseButton action={actions.CAR_CREATE_BASIC_DATA} loading={isLoading} label="Get General Data" empty onClick={handlePurchase} />
      </Box>
    </Fade>
  );
};

export default Index;
