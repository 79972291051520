import React from 'react';

import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import Label from '../../../../customFields/Label';
import labels from '../../../../constants/labels';
import timePeriods from '../../../../constants/timePeriods';

import NumberInput from '../../../layout/inputFields/NumberInput';
import SelectInput from '../../../layout/inputFields/SelectInput';
import InfoPopover from '../../../layout/InfoPopover';

import ThresholdInput from './ThresholdInput';

const CategorizationAmountThreshold = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <ThresholdInput source="default" />
      </Grid>
      <Grid item xs={12} md={12}>
        <Label
          variant="label"
          title="Period parameters"
          info="The period that the amount threshold will be checked against."
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          source="ruleParameters.period.amount"
          fullWidth
          label="Length"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <SelectInput
          source="ruleParameters.period.unit"
          choices={Object.values(timePeriods).map((period) => ({ id: period, name: period }))}
          fullWidth
          label="Period"
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Category</Typography>
        <SelectInput
          source="ruleParameters.category"
          choices={labels}
          fullWidth
          label="Category"
          validate={required()}
          popups={[<InfoPopover key="category" title="Category" content="The transactions with the selected category that is to be checked against the amount threshold." />]}
        />
      </Grid>
    </Grid>

  </Grid>

);

export default CategorizationAmountThreshold;
