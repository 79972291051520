import React, { useCallback } from 'react';

import {
  Datagrid,
  TextField,
  ArrayField,
  useRecordContext,
  NumberField,
  DateField,
  useRedirect,
  SelectField,
} from 'react-admin';

import {
  Card,
  Grid,
  Typography,
  Box,
  CardContent,
  CardHeader,
  Divider,
  Button,
} from '@mui/material';

import { LaunchOutlined } from '@mui/icons-material';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import ReferenceScoreField from '../../../../customFields/ReferenceScoreField';
import ReferenceLastKycRequest from '../../../../customFields/ReferenceLastKycRequest';
import { transmodeTypesChoices } from '../../../../constants/transmodeTypes';

const CarPurchase = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const extraInformation = record?.extraInformation;
  const reformedEntities = {
    entities: [
      {
        requestRole: 'Guarantor',
        id: extraInformation?.guarantor?.id,
        type: extraInformation?.guarantor?.type,
      },
      {
        requestRole: 'Buyer',
        id: extraInformation?.buyer?.id,
        type: extraInformation?.buyer?.type,
      },
      {
        requestRole: 'Seller',
        id: extraInformation?.seller?.id,
        type: extraInformation?.seller?.type,
      },
    ].filter((item) => item?.id),
  };

  const handleClick = useCallback(
    () => redirect('edit', 'cars', extraInformation?.car?.id),
    [extraInformation?.car?.id, redirect],
  );

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader
              title="Car Purchase Details"
              action={(
                <Button startIcon={<LaunchOutlined />} onClick={handleClick}>
                  Car Profile
                </Button>
              )}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Amount</Typography>
                    <NumberField record={extraInformation} source="amount" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Currency</Typography>
                    <TextField record={extraInformation} source="currency" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Date</Typography>
                    <DateField showTime record={extraInformation} source="date" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Valuation</Typography>
                    <NumberField record={extraInformation} source="valuation" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Text message</Typography>
                    <TextField record={extraInformation} source="textMessage" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">Reference</Typography>
                    <TextField record={extraInformation} source="reference" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">
                      Transaction channel
                    </Typography>
                    <SelectField record={extraInformation} choices={transmodeTypesChoices} source="transactionChannel" emptyText="-" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="smallTitle">
                      Transaction channel provide
                    </Typography>
                    <TextField record={extraInformation} source="transactionChannelProvider" emptyText="-" />
                  </Box>
                </Grid>
              </Grid>
              <Card variant="outlined">
                <CardHeader title="Involved Entities" />
                <Divider />
                <ArrayField record={reformedEntities} source="entities">
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField emptyText="-" source="requestRole" label="Role" />
                    <ReferenceEntityField source="" label="Name" />
                    <TextField emptyText="-" source="type" label="Type" />
                    <ReferenceLastKycRequest source="" label="Latest KYC request" />
                    <ReferenceScoreField source="" label="Risk score" />
                  </Datagrid>
                </ArrayField>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CarPurchase;
