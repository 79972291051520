import { useState } from 'react';
import { RaRecord } from 'react-admin';
import { useLocation } from 'react-router-dom';
import ruleTypes, { RuleTypes } from '../constants/ruleTypes';
import ruleViews, { RuleViews } from '../constants/ruleView';

type RuleState = {
  state?: {
    record?: {
      view?: RuleViews
      ruleType?: RuleTypes
      parentRule?: RaRecord
    }
  }
  search: string
}

const useRule = () => {
  const {
    state,
    search,
  } = useLocation() as RuleState;

  const defaultType = search.includes('ongoing') ? ruleTypes.ONGOING : ruleTypes.REQUEST;

  const [ruleType, changeRuleType] = useState<RuleTypes>(
    state?.record?.ruleType ?? defaultType,
  );

  const [view, changeView] = useState<RuleViews >(
    state?.record?.view ?? ruleViews.TREE,
  );

  return {
    ruleType,
    changeRuleType,
    view,
    changeView,
    parentRule: state?.record?.parentRule,
  };
};

export default useRule;
