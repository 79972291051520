import { requestTypes } from './requestTypes';
import { requestRoles } from './requestRoles';
import { ValueOf } from '../utilities/types';

const paths = {
  BUSINESS: 'business',
  BUYER: requestRoles.BUYER,
  CAR_KYC: requestTypes.CAR_KYC,
  CAR_PURCHASE: requestTypes.CAR_PURCHASE,
  CAR: requestRoles.CAR,
  INDIVIDUAL: 'individual',
  INITIATOR: requestRoles.INITIATOR,
  KYC_BUSINESS: requestTypes.BUSINESS_KYC,
  KYC_INDIVIDUAL: requestTypes.INDIVIDUAL_KYC,
  KYC: 'kyc',
  PROCESSOR: requestRoles.PROCESSOR,
  PURCHASE: 'purchase',
  RECIPIENT: requestRoles.RECIPIENT,
  SELLER: requestRoles.SELLER,
  TEST: 'test',
  TRANSACTION: requestTypes.TRANSACTION,
} as const;

export type Paths = ValueOf<typeof paths>;

export default paths;
