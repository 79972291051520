import React from 'react';

import {
  required,
  TextInput,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

import CreateDialog from '../layout/dialog/CreateDialog';
import InfoPopover from '../layout/InfoPopover';

const TransactionTypeCreate = () => (
  <CreateDialog title="Transaction type">
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
      <Box width="80%">
        <Box display="flex" alignItems="center">
          <Typography variant="mediumTitle">Transaction Type Name</Typography>
          <InfoPopover title="Transaction Type Name" content="Unique name for the transaction type." />
        </Box>
        <TextInput fullWidth validate={required()} source="name" />
      </Box>
    </Box>
  </CreateDialog>

);

export default TransactionTypeCreate;
