import React from 'react';

import { useRecordContext, TextField } from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Efternamn',
  [languages.EN]: 'Last name',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const LastName = ({ source }: { source?: string }) => {
  const recordObj = useRecordContext();

  const language = recordObj?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source={source ?? 'lastName'}
      datapoint={conflictFields.LAST_NAME}
    >
      <ValueField />
    </Answer>
  );
};

LastName.ValueField = ValueField;

LastName.Nested = ({ source }: { source?: string}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{CONTENT[language]}</Typography>
        <TextField
          source={source ?? 'lastName'}
          emptyText="-"
          sx={sx}
        />
      </Box>
    </Grid>
  );
};

export default LastName;
