import React, { useCallback } from 'react';
import { useDelete, useNotify, useRecordContext } from 'react-admin';
import { IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const RemoveCustomConnectionButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const [deleteOne] = useDelete(
    'connections',
    { id: record?.id },
    {
      onSuccess: () => {
        notify('Connection removed successfully');
      },
      onError: () => {
        notify('Failed to remove connection', { type: 'error' });
      },
    },
  );

  const handleRemove = useCallback(() => {
    deleteOne();
  }, [deleteOne]);

  return (
    <IconButton onClick={handleRemove}>
      <RemoveCircleOutlineIcon color="error" />
    </IconButton>
  );
};

export default RemoveCustomConnectionButton;
