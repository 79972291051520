import React from 'react';

import { Grid, Typography } from '@mui/material';
import Select from './base/Select';
import { legalFormTypeChoices } from '../../../constants/legalForms';

const LegalForm = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Legal form of business
      </Typography>
    </Grid>
    <Select choices={legalFormTypeChoices('en')} />
  </Grid>
);

export default LegalForm;
