import React from 'react';
import {
  minValue, required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import { useWatch } from 'react-hook-form';

import InfoPopover from '../../../layout/InfoPopover';
import SelectInput from '../../../layout/inputFields/SelectInput';
import NumberInput from '../../../layout/inputFields/NumberInput';

import { thresholdPriorities, thresholdPrioritiesForm } from '../../../../constants/thresholdPriorities';

const PriorityParameters = () => {
  const priority = useWatch({ name: 'ruleParameters.parameterPriority' });

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Threshold priority</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            source="ruleParameters.parameterPriority"
            label="Threshold Priority"
            fullWidth
            choices={Object.values(thresholdPriorities)}
            popups={[<InfoPopover key="threshold priority" title="Threshold priority" content={'Given no override parameters, set prioritization of parameters. \n If no KYC form found, parameters will adapt the default values.'} />]}
            validate={required()}
          />
        </Grid>

        {thresholdPrioritiesForm.includes(priority) && (
          <Grid item xs={12} md={12}>
            <NumberInput
              validate={minValue(0)}
              fullWidth
              source="ruleParameters.kycFormDataMaxAge"
              label="Max KYC form age (days)"
              popups={[<InfoPopover
                key="max days"
                title="Max KYC form age"
                content={
                  'Set a maximum number of days since the creation of the KYC form. If maximum days is reached \n and no override entity parameters was found, the parameters will adapt the default values.'
                }
              />]}

            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PriorityParameters;
