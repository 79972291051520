import React from 'react';
import {
  Card,
  Grid,
  CardContent,
  CardHeader,
  Divider,
  Box,
  Typography,
} from '@mui/material';

import {
  Datagrid,
  TextField,
  ArrayField,
  useRecordContext,
  DateField,
} from 'react-admin';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import ReferenceScoreField from '../../../../customFields/ReferenceScoreField';

const IndividualKyc = () => {
  const record = useRecordContext();
  if (!record) return null;

  const { extraInformation } = record;

  const reformedEntities = {
    entities: [
      {
        id: extraInformation?.entity?.id,
        type: extraInformation?.entity?.type,
      },
    ].filter((item) => item?.id),
  };

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Individual KYC Request Details" />
            <Divider />
            <CardContent>
              <Grid container>
                <Grid container spacing={6}>
                  <Grid item xs={3} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Bank ID issue date</Typography>
                      <DateField showTime record={extraInformation} source="bankIdIssueDate" emptyText="-" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Card variant="outlined">
                <CardHeader title="Involved Entities" />
                <Divider />
                <ArrayField record={reformedEntities} source="entities">
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <ReferenceEntityField source="" label="Name" />
                    <TextField emptyText="-" source="type" label="Type" />
                    <ReferenceScoreField source="" label="Risk score" />
                  </Datagrid>
                </ArrayField>
              </Card>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndividualKyc;
