import React from 'react';

import {
  Loading,
  maxLength,
  NumberInput,
  RecordContextProvider,
  required,
  SelectInput,
  TextInput,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import { Box, Grid } from '@mui/material';

const Car = ({ path }: { path: string }) => {
  const record = useRecordContext();
  const { data, isLoading } = useGetOne('cars', { id: record?.id }, { enabled: !!record?.id });

  if (isLoading) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }
  return (
    <RecordContextProvider value={{
      [`${path}.registration_number`]: data?.registrationNumber,
      ...record,
    }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput choices={[{ id: 'FORDB', name: 'Car' }]} disabled fullWidth source={`${path}.item_type`} label="Type" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${path}.registration_number`} label="Registration number" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <NumberInput fullWidth source={`${path}.estimated_value`} label="Valuation" />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput fullWidth multiline minRows={3} label="Description of the item" source={`${path}.description`} validate={[required(), maxLength(4000)]} />
        </Grid>
      </Grid>

    </RecordContextProvider>

  );
};

export default Car;
