import { ValueOf } from '../utilities/types';

const pingStatuses = {
  PENDING: 'pending',
  RESOLVED: 'resolved',
  CONFIRMED: 'confirmed',
  IGNORED: 'ignored',
} as const;

export type PingStatuses = ValueOf<typeof pingStatuses>;

export default pingStatuses;
