import React from 'react';

import {
  Box,
  TextField,
} from '@mui/material';

export interface FilterType {
  registrationNumber: string;
}

const Filter = ({
  filter,
  setFilter,
}: {
  filter?: FilterType,
  setFilter: (x: (prev?: FilterType) => FilterType) => void
}) => {
  const handleChange = (prop: keyof FilterType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => ({ ...prev!, [prop]: event.target.value }));
  };
  return (
    <Box>
      <TextField
        value={filter?.registrationNumber}
        onChange={handleChange('registrationNumber')}
        size="small"
        label="Registration number"
      />
    </Box>
  );
};

export default Filter;
