import React, { useMemo } from 'react';

import { Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { LoadingPage, RecordContextProvider, useRedirect } from 'react-admin';

import { useParams } from 'react-router-dom';

import SanctionMatchInformation from './SanctionMatchInformation';

import useEntityData from '../../../../../../hooks/useEntityData';

import { SanctionListMatches } from '../../../../../../utilities/schemas/sanctionListMatches';

import actions from '../../../../../../constants/actions';

import SanctionAbout from './SanctionAbout';
import SanctionNationalIdentifiers from './SanctionNationalIdentifiers';
import SanctionImages from './SanctionImages';
import SanctionSources from './SanctionSources';
import SanctionRelatedEntities from './SanctionRelatedEntities';
import SanctionSanctions from './SanctionSanctions';

const GoBackButton = () => {
  const redirect = useRedirect();
  const path = useParams();

  return (
    <IconButton onClick={() => { redirect(`/individuals/${path.id}/kyc-data/sanction-list`); }} size="small">
      <ArrowBackIosIcon color="inherit" fontSize="medium" />
    </IconButton>
  );
};

const SanctionDetails = () => {
  const {
    data,
    isLoading,
  } = useEntityData<SanctionListMatches>({
    source: 'sanction-list',
    getAction: actions.INDIVIDUAL_GET_SANCTION_LIST,
  });

  const { sanctionId } = useParams();

  const currentSanction = useMemo(
    () => data?.potentialMatches.find((match) => match._id === sanctionId),
    [data, sanctionId],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <RecordContextProvider value={{ ...currentSanction, providerName: data?.providerName }}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={12}>
          <GoBackButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={4}
            bgcolor="background.paper"
            paddingX={4}
          >
            <Grid xs={12} md={12} item>
              <SanctionMatchInformation />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <SanctionAbout />
                </Grid>
                <Grid xs={12} md={12} item>
                  <SanctionNationalIdentifiers />
                </Grid>
              </Grid>
              <Grid xs={12} md={12} item>
                <SanctionImages />
              </Grid>
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <SanctionSanctions />
                </Grid>
                <Grid xs={12} md={12} item>
                  <SanctionRelatedEntities />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <SanctionSources />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

export default SanctionDetails;
