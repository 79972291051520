import React, { useCallback } from 'react';

import { StyledTab, StyledTabs } from '../../../../layout/Tab';
import { RequestTypes } from '../../../../../constants/requestTypes';

const Filter = ({
  filter,
  setFilter,
}: {
  filter: { requestType: RequestTypes }
  setFilter: (x: (prev: typeof filter) => typeof filter) => void
}) => {
  const handleChange = useCallback((event: React.SyntheticEvent, newValue: RequestTypes) => {
    setFilter((prev) => ({
      ...prev,
      requestType: newValue,
    }));
  }, [setFilter]);

  return (
    <StyledTabs value={filter?.requestType} onChange={handleChange}>
      <StyledTab value="transaction" label="Transactions" />
      <StyledTab value="carPurchase" label="Car purchases" />
    </StyledTabs>
  );
};

export default Filter;
