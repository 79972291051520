import React from 'react';

import { Grid, Typography } from '@mui/material';
import { NumberInput, SelectInput, TextInput } from 'react-admin';
import currencies from '../../../constants/currencies';

const Salary = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Monthly income
      </Typography>
    </Grid>
    <Grid item xs={12} md={7}>
      <Grid container spacing={10}>
        <Grid item xs={12} md={6}>
          <NumberInput fullWidth source="value.value" label="New value" />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectInput
            choices={currencies.map((curr) => ({
              name: curr,
              id: curr,
            }))}
            fullWidth
            source="value.currency"
            label="New currency"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextInput
            fullWidth
            source="comment"
            multiline
            rows={5}
            helperText={false}
            placeholder="Please explain your decision."
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default Salary;
