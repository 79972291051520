import { useState, useCallback, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import ruleTypes from '../constants/ruleTypes';
import { Paths } from '../constants/paths';
import { RuleNames } from '../constants/ruleNames';

type PathOrName = Paths | RuleNames | undefined;

const useSelectState = () => {
  const record = useRecordContext();
  const [path, setPath] = useState<PathOrName[]>([undefined, undefined, undefined]);
  const [selectedIndex, setIndex] = useState([0, 0, 0]);

  useEffect(() => {
    if (record?.ruleType === ruleTypes.ONGOING) setPath((prev) => ['kyc', ...prev.slice(1)]);
  }, [record?.ruleType]);

  const changePath = useCallback((newPath: PathOrName, position: number) => (
    setPath((currentPaths) => currentPaths.map((oldPaths, index) => {
      if (index > position) {
        return undefined;
      }
      if (position === index) {
        return newPath;
      }
      return oldPaths;
    }))
  ), []);

  const changeIndex = useCallback((newIndex: number, position: number) => (
    setIndex((currentIndexes) => currentIndexes.map((oldIndex, index) => {
      if (index > position) {
        return 0;
      }
      if (position === index) {
        return newIndex;
      }
      return oldIndex;
    }))
  ), []);

  return {
    path,
    changePath,
    selectedIndex,
    changeIndex,
  };
};

export default useSelectState;
