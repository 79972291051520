import React from 'react';
import {
  ReferenceField,
  useRecordContext,
} from 'react-admin';

import get from 'lodash/get';

import DateFieldCheck from './DateFieldCheck';

const ReferenceLastKycRequest = ({
  source = 'entity',
}: {
  source?: string;
  label: string;
}) => {
  const record = useRecordContext();
  const value = source ? get(record, source, {}) : record;
  const idSource = source ? `${source}.id` : 'id';
  if (value.type === 'Individual') {
    return (
      <ReferenceField label="Individual" source={idSource} reference="individuals" link={false}>
        <DateFieldCheck source="kycLatestRequest" />
      </ReferenceField>
    );
  }
  if (value.type === 'Business') {
    return (
      <ReferenceField label="Business" source={idSource} reference="businesses" link={false}>
        <DateFieldCheck source="kycLatestRequest" />
      </ReferenceField>
    );
  }
  return null;
};

export default ReferenceLastKycRequest;
