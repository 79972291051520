import React from 'react';

import { useRecordContext, TextField } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const LABEL = {
  [languages.SV]: 'Skatteregistreringsnummer (TIN)',
  [languages.EN]: 'Tax Registration Number (TIN)',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Tin = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={LABEL[language]}
      source="tin"
      datapoint={conflictFields.TIN}
    >
      <ValueField />
    </Answer>
  );
};

Tin.ValueField = ValueField;

export default Tin;
