import React from 'react';

import {
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';
import countries from '../../../../constants/countries';

const SOURCE = 'ruleParameters.countries';

const CountryListNoPurchase = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Countries</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <ArrayInput source={SOURCE} label={false}>
          <SimpleFormIterator
            fullWidth
            sx={{
              '& .RaSimpleFormIterator-line': {
                borderBottom: 'none',
                paddingY: 2,
                '& .RaSimpleFormIterator-inline': {
                  rowGap: '1em',
                },
              },
            }}
          >
            <SelectInput
              choices={countries}
              fullWidth
              label="Country"
              source=""
              helperText={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  </Grid>
);

export default CountryListNoPurchase;
