import React from 'react';

import {
  TextField,
  useRecordContext,
  Datagrid,
  ArrayField,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Grid,
  Divider,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';
import actions from '../../../../constants/actions';
import { NationalIdentifierSingleField } from '../../../../customFields/NationalIdentifier';
import { PopulationRegister } from '../../../../utilities/schemas/populationRegister';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

const EntityData = () => {
  const record = useRecordContext();
  const { data } = useEntityData<PopulationRegister>({
    resource: 'individuals',
    source: 'population-register',
    getAction: actions.INDIVIDUAL_GET_SPAR,
  });

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Entity Data" />
        <Divider />
        <ArrayField source="record" record={{ record: [record] }}>
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <TextField source="firstName" emptyText="-" />
            <TextField record={data?.record?.name?.at(0)} source="middleName" emptyText="-" />
            <TextField record={record} source="lastName" emptyText="-" />
            <NationalIdentifierSingleField emptyText="-" underline={false} label="National identifiers" />
            <TextField emptyText="-" record={data?.record?.details?.at(0)} source="gender" />
          </Datagrid>
        </ArrayField>
      </Card>
    </Grid>
  );
};

export default EntityData;
