import React from 'react';

import {
  RecordContextProvider,
  useGetOne,
} from 'react-admin';

import { useParams } from 'react-router-dom';

import List from './List';

const Transactions = () => {
  const { id } = useParams();
  const { data } = useGetOne('cases', { id });
  return (
    <RecordContextProvider value={{ ...data }}>
      <List />
    </RecordContextProvider>
  );
};

export default Transactions;
