import React, { useCallback, useState } from 'react';
import {
  Identifier,
  InputHelperText,
  InputProps,
  RecordContextProvider,
  useInput,
} from 'react-admin';
import {
  Box,
  FormControl,
  IconButton,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ReferenceEntityField from './ReferenceEntityField';
import { EntityTypes } from '../constants/entityTypes';
import EntitySelectionDialog from '../components/entities/EntitySelectionDialog';

const ReferenceEntityInputV2 = (props: InputProps) => {
  const {
    label,
    helperText,
  } = props;
  const {
    id: fieldId,
    field: {
      onChange,
      value,
    },
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    isRequired,
  } = useInput(props);

  const renderHelperText = (helperText !== false && helperText !== undefined)
    || ((isTouched || isSubmitted) && invalid);

  const [open, setOpen] = useState(false);
  const handleStartEdit = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleSelect = useCallback((data: { id: Identifier, type: EntityTypes }) => {
    onChange(data);
    setOpen(false);
  }, [onChange]);
  const handleClear = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  return (
    <>
      <FormControl
        fullWidth
        required={isRequired}
        error={(isTouched || isSubmitted) && invalid}
        variant="standard"
      >
        <FormLabel htmlFor={fieldId}>{label}</FormLabel>
        <Box id={fieldId} display="flex" flexDirection="row" gap={2} alignItems="center">
          <RecordContextProvider value={value}>
            <ReferenceEntityField source="" linkType={false} emptyText="" />
          </RecordContextProvider>
          <Box>
            <IconButton size="small" onClick={handleStartEdit} color="primary">
              {value ? <EditIcon /> : <AddCircleOutlineIcon />}
            </IconButton>
            {!!value && (
              <IconButton size="small" onClick={handleClear} color="warning">
                <HighlightOffIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <FormHelperText>
          {renderHelperText && (
            <InputHelperText
              touched={isTouched || isSubmitted}
              error={error?.message}
              helperText={helperText}
            />
          )}
        </FormHelperText>
      </FormControl>
      <EntitySelectionDialog
        currentValue={value?.id}
        open={open}
        handleClose={handleClose}
        handleSelect={handleSelect}
        label={label}
      />
    </>
  );
};

export default ReferenceEntityInputV2;
