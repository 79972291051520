import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import {
  NumberField, useRecordContext, TextField,
} from 'react-admin';

const RuleScore = () => {
  const record = useRecordContext();
  const period = record?.riskTimeline?.period;
  const amount = record?.riskTimeline?.amount;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Rule score</Typography>
          <NumberField source="score" />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Risk decreasing timeline</Typography>
          <TextField record={{ timeline: `${amount} ${period}` }} source="timeline" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default RuleScore;
