import { ValueOf } from '../utilities/types';

const fundsTypes = {
  CASINO_CHIP: 'casino-chip',
  BANK_MONEY_ORDER: 'bank-money-order',
  CHEQUE: 'cheque',
  CARD: 'card',
  SWISH: 'swish',
  BANK_GIRO: 'bank-giro',
  CARD_TERMINAL: 'card-terminal',
  SERVICE_BOX: 'service-box',
  AGENT: 'agent',
  OTHER: 'other',
  UNKNOWN: 'unknown',
  NOT_RELEVANT: 'not-relevant',
} as const;

export const fundsTypesChoices = [
  { id: fundsTypes.CASINO_CHIP, name: 'Chips' },
  { id: fundsTypes.BANK_MONEY_ORDER, name: 'Bank money order' },
  { id: fundsTypes.CHEQUE, name: 'Cheque' },
  { id: fundsTypes.CARD, name: 'Card' },
  { id: fundsTypes.SWISH, name: 'Swish' },
  { id: fundsTypes.BANK_GIRO, name: 'BankGiro' },
  { id: fundsTypes.CARD_TERMINAL, name: 'Card terminal' },
  { id: fundsTypes.SERVICE_BOX, name: 'Service box ' },
  { id: fundsTypes.AGENT, name: 'Agent' },
  { id: fundsTypes.OTHER, name: 'Other' },
  { id: fundsTypes.UNKNOWN, name: 'Unknown' },
  { id: fundsTypes.NOT_RELEVANT, name: 'Not relevant' },
];

export type FundsTypes = ValueOf<typeof fundsTypes>;

export default fundsTypes;
