import React from 'react';

import { Grid, Typography } from '@mui/material';
import { SelectArrayInput, TextInput } from 'react-admin';
import { countryChoices } from '../../../constants/countries';

const Residence = () => (

  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Country
      </Typography>
    </Grid>
    <Grid item xs={12} md={7}>
      <Grid container />
      <Grid item xs={12} md={6}>
        <SelectArrayInput fullWidth choices={countryChoices('en')} source="value" />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextInput
          fullWidth
          source="comment"
          multiline
          rows={5}
          helperText={false}
          placeholder="Please explain your decision."
        />
      </Grid>
    </Grid>
  </Grid>

);

export default Residence;
