import Joi from 'joi';
import Luhn from '../Luhn';

export const isValidSnn = (value: string) => {
  if (!value.match(/^\d{12}$/)) return false;
  return Luhn(value.slice(2));
};

export interface IJoiSsnExtension extends Joi.Root {
  ssn(): Joi.AnySchema;
}

const JoiSsnExtension = (joi: Joi.Root) => joi.extend({
  type: 'ssn',
  messages: {
    invalid: '"{{#label}}" must be a valid Swedish social security number. Accepted format: yyyymmddxxxx',
  },
  validate(value, { error }) {
    if (!isValidSnn(value)) {
      return { value, errors: error('invalid') };
    }
    return { value };
  },
}) as IJoiSsnExtension;

export default JoiSsnExtension;
