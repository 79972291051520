import React from 'react';

import {
  TextField,
  ArrayField,
  Datagrid,
  DateField,
  RecordContextProvider,
  BooleanField,
  SelectField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Fade,
} from '@mui/material';

import useEntityData from '../../../../hooks/useEntityData';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import actions from '../../../../constants/actions';
import { deRegistrationDescriptions } from '../../../../constants/deRegistrationCodes';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import NoResults from '../../../layout/NoResults';
import { PopulationRegister as PopulationRegisterType } from '../../../../utilities/schemas/populationRegister';

const PopulationRegister = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<PopulationRegisterType>({
    resource: 'individuals',
    source: 'population-register',
    getAction: actions.INDIVIDUAL_GET_SPAR,
  });

  if (!data) {
    return (
      <Fade in>
        <Box margin={5} display="flex" width="100%" alignItems="center" flexDirection="column">
          <Typography variant="smallTitle">No Data Available</Typography>
          <PurchaseButton action={actions.INDIVIDUAL_CREATE_SPAR} loading={isLoading} label="Get Population register" empty onClick={handlePurchase} />
        </Box>
      </Fade>
    );
  }

  return (
    <RecordContextProvider
      value={{
        ...(data?.record ?? {}),
        createdAt: data?.createdAt,
      }}
    >
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Population register"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Population register"
                onClick={handlePurchase}
                action={actions.INDIVIDUAL_CREATE_SPAR}
              />

            )}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={6}>

              <Grid item xs={2} md={2}>
                <Box display="flex" flexDirection="column" gap={1} alignItems="start">
                  <Typography variant="smallTitle">
                    Social Security Number:
                  </Typography>
                  <TextField source="personId" emptyText="-" />
                </Box>
              </Grid>
              <Grid item xs={2} md={2}>
                <Box display="flex" flexDirection="column" gap={1} alignItems="start">
                  <Typography variant="smallTitle">Income year</Typography>
                  <DateField emptyText="-" source="incomeYear" />
                </Box>
              </Grid>

              <Grid item xs={2} md={2}>
                <Box display="flex" flexDirection="column" gap={1} alignItems="start">
                  <Typography variant="smallTitle">Aggregated income</Typography>
                  <TextField emptyText="-" source="aggregatedIncome" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Registered names" />
                  <Divider />
                  <ArrayField source="name">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" label="First name" source="firstName" />
                      <TextField emptyText="-" label="Middle name" source="middleName" />
                      <TextField emptyText="-" label="Last name" source="surName" />
                      <TextField emptyText="-" label="Shortened name" source="shortenedDisplayName" />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Personal Details" />
                  <Divider />
                  <ArrayField source="details">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <BooleanField emptyText="-" label="Secrecy" source="secrecy.secrecy" />
                      <BooleanField emptyText="-" label="Protected registration" source="protectedRegistration" />
                      <TextField emptyText="-" label="Gender" source="gender" />
                      <DateField emptyText="-" label="Birth date" source="birth.birthDate" />
                      <DateField emptyText="-" label="Deregistration date" source="deRegistrationDate" />
                      <SelectField emptyText="-" label="Deregistration reason" choices={Object.entries(deRegistrationDescriptions).map(([key, value]) => ({ id: key, name: value }))} />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Population registration" />
                  <Divider />
                  <ArrayField source="populationRegistration">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" source="countyCode" />
                      <TextField label="County name" emptyText="-" source="countyName" />
                      <TextField emptyText="-" source="districtCode" />
                      <TextField label="District name" emptyText="-" source="districtName" />
                      <TextField emptyText="-" source="municipalityCode" />
                      <TextField label="Municipality name" emptyText="-" source="municipalityName" />
                      <DateField emptyText="-" source="populationRegistrationDate" />
                      <SelectField
                        emptyText="-"
                        label="Status"
                        source="residenceStatusCode"
                        choices={[
                          { id: 'MUNICIPAL', name: 'The person does not have a residence, but can be reached via the address' },
                          { id: 'RESIDENTIAL', name: 'The residential address is where the person lives' },
                          { id: 'UNKNOWN', name: 'The person does not have a known residence' },
                        ]}
                      />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Population registration address" />
                  <Divider />
                  <ArrayField source="populationRegistrationAddress">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" label="Delivery address 1" source="domesticAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="Delivery address 2" source="domesticAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="Care of address" source="domesticAddress.coAddress" />
                      <TextField emptyText="-" label="Zip code" source="domesticAddress.zipCode" />
                      <TextField emptyText="-" label="City" source="domesticAddress.city" />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Contact address" />
                  <Divider />
                  <ArrayField source="contactAddress">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" label="International country" source="internationalAddress.country" />
                      <TextField emptyText="-" label="International delivery address 1" source="internationalAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="International delivery address 2" source="internationalAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="International delivery address 3" source="internationalAddress.deliveryAddress3" />
                      <TextField emptyText="-" label="Delivery address 1" source="domesticAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="Delivery address 2" source="domesticAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="Care of address" source="domesticAddress.coAddress" />
                      <TextField emptyText="-" label="Zip code" source="domesticAddress.zipCode" />
                      <TextField emptyText="-" label="City" source="domesticAddress.city" />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Foreign address" />
                  <Divider />
                  <ArrayField source="foreignAddress">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" label="Country" source="internationalAddress.country" />
                      <TextField emptyText="-" label="Delivery address 1" source="internationalAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="Delivery address 2" source="internationalAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="Delivery address 3" source="internationalAddress.deliveryAddress3" />

                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Special postal address" />
                  <Divider />
                  <ArrayField source="specialPostalAddress">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField emptyText="-" label="International country" source="internationalAddress.country" />
                      <TextField emptyText="-" label="International delivery address 1" source="internationalAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="International delivery address 2" source="internationalAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="International delivery address 3" source="internationalAddress.deliveryAddress3" />
                      <TextField emptyText="-" label="Delivery address 1" source="domesticAddress.deliveryAddress1" />
                      <TextField emptyText="-" label="Delivery address 2" source="domesticAddress.deliveryAddress2" />
                      <TextField emptyText="-" label="Care of address" source="domesticAddress.coAddress" />
                      <TextField emptyText="-" label="Zip code" source="domesticAddress.zipCode" />
                      <TextField emptyText="-" label="City" source="domesticAddress.city" />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Relation" />
                  <Divider />
                  <ArrayField source="relation">
                    <Datagrid
                      sx={boldDataGridStyle}
                      empty={<NoResults />}
                      bulkActionButtons={false}
                    >
                      <TextField label="First name" emptyText="-" source="firstName" />
                      <TextField label="Middle name" emptyText="-" source="middleName" />
                      <TextField label="Last name" emptyText="-" source="surName" />
                      <TextField label="Social security number" emptyText="-" source="personId" />
                      <DateField label="Birth date" emptyText="-" source="birthDate" />
                      <DateField emptyText="-" label="Deregistration date" source="deRegistrationDate" />
                      <SelectField emptyText="-" label="Deregistration reason" choices={Object.entries(deRegistrationDescriptions).map(([key, value]) => ({ id: key, name: value }))} />
                      <SelectField
                        emptyText="-"
                        label="Status"
                        source="relationType"
                        choices={[
                          { id: 'GUARDIAN', name: 'Guardian' },
                          { id: 'SPOUSE_OR_PARTNER', name: 'Spouse or partner' },
                        ]}
                      />
                      <DateField emptyText="-" source="dateFrom" />
                      <DateField emptyText="-" source="dateTo" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </RecordContextProvider>
  );
};

export default PopulationRegister;
