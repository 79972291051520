import React from 'react';

import { SelectInput } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { ruleCasePriorityChoices } from '../constants/casePriority';

const RuleCasePriorityInput = ({ edit }: { edit: boolean }) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={12}>
      <Typography variant="label">Case priority</Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <SelectInput disabled={!edit} label="Case priority" source="casePriority" choices={ruleCasePriorityChoices} fullWidth />
    </Grid>
  </Grid>
);

export default RuleCasePriorityInput;
