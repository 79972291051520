import React from 'react';

import { Grid, Typography } from '@mui/material';
import { DateInput, TextInput } from 'react-admin';

const DateOfBirth = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Date of birth
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <DateInput source="value" fullWidth />
    </Grid>
    <Grid item xs={12} md={12}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default DateOfBirth;
