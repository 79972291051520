import React, { useMemo } from 'react';

import {
  useRecordContext,
  ChipField,
  SingleFieldList,
  ArrayField,
} from 'react-admin';

import { capitalize, get, isEmpty } from 'lodash';

import { Typography } from '@mui/material';

const LabelsField = ({
  source,
  sortable = false,
} : {
  source: string,
  label: string,
  sortable?: boolean
}) => {
  const record = useRecordContext();

  const labels = useMemo(() => ({
    [source]: get(record, source)?.map((item: string) => ({ item: capitalize(item) })),
  }), [record, source]);

  if (!isEmpty(labels)) {
    return (
      <ArrayField record={labels} sortable={sortable} source={source}>
        <SingleFieldList linkType={false}>
          <ChipField source="item" />
        </SingleFieldList>
      </ArrayField>
    );
  }
  return <Typography>-</Typography>;
};

export default LabelsField;
