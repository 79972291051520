import { useEffect, useMemo } from 'react';

import { useGetList, useRecordContext } from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';

const useRuleParent = () => {
  const record = useRecordContext();
  const { setValue } = useFormContext();

  const triggeredBy = useWatch({ name: 'triggeredBy', defaultValue: record?.parentRule?.id });

  const { data } = useGetList(
    'rules',
    {
      filter: {
        ruleType: record?.ruleType,
      },
    },
    {
      notifyOnChangeProps: ['data'],
    },
  );

  const parentRule = useMemo(() => data
    ?.find((rule) => rule.id === triggeredBy), [data, triggeredBy]);

  useEffect(() => {
    setValue('parentRule', parentRule);
  }, [parentRule, setValue]);

  const triggeredByChoices = data ? data
    .map((rule) => ({ id: rule.id, name: rule.name })) : undefined;

  return {
    triggeredByChoices,
    parentRule,
    triggeredBy,
  };
};

export default useRuleParent;
