import React from 'react';

import { useRecordContext, SelectField } from 'react-admin';

import { get } from 'lodash';

import { Grid, Box, Typography } from '@mui/material';

import Answer from '../Answer';

import booleanChoices from '../../../../constants/booleanChoices';
import languages, { Languages } from '../../../../constants/languages';

const TITLE = {
  [languages.SV]: 'Äger personen företaget/organisationen tillsammans med en eller flera familjemedlemmar?',
  [languages.EN]: 'Does the person control or own the company/organization together with one or more close relatives?',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return <Typography>-</Typography>;

  return (
    <SelectField
      choices={booleanChoices[language]}
      record={{ value }}
      sx={sx}
      source="value"
      emptyText="-"
      fontWeight="bold"
    />
  );
};

const FamilyControl = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={TITLE[language]}
      source={source ?? 'familyControl'}
      datapoint={null}
    >
      <ValueField language={language} />
    </Answer>

  );
};

FamilyControl.ValueField = ValueField;

FamilyControl.Nested = ({ source }: { source?: string}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{TITLE[language]}</Typography>
        <SelectField
          choices={booleanChoices[language]}
          source={source ?? 'familyControl'}
          emptyText="-"
          sx={sx}
        />
      </Box>
    </Grid>
  );
};

export default FamilyControl;
