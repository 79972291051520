import React from 'react';

import {
  RaRecord,
  SimpleForm,
  useRecordContext,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import Body from './Body';

import { EntityTypes } from '../../../../../../constants/entityTypes';

const DetailedView = ({
  handleOpenChange,
  isEditView = false,
  type,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void,
  isEditView?: boolean
  type: EntityTypes
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void
}) => {
  const { setValue, getValues } = useFormContext();
  const record = useRecordContext();
  return (
    <SimpleForm record={record} mode="all" toolbar={false}>
      <Body
        type={type}
        setRecord={setRecord}
        isEditView={isEditView}
        getValues={getValues}
        setValue={setValue}
        handleOpenChange={handleOpenChange}
        handleCloseDialog={handleCloseDialog}
      />
    </SimpleForm>

  );
};

export default DetailedView;
