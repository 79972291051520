import React, { useCallback, useMemo, useState } from 'react';
import {
  TextField, InputAdornment, Tooltip, Button,
} from '@mui/material';

import Copy from '@mui/icons-material/ContentCopy';

const CopyToClipBoard = ({ value }: {value?: string}) => {
  const [copied, setCopied] = useState(false);

  const copyText = useCallback(() => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value as string).then(
        () => {
          setCopied(true);
          setTimeout(() => {
            setCopied(false);
          }, 2500);
        },
      );
    }
  }, [value]);

  const isDisabled = useMemo(() => copied || !value || value === '', [copied, value]);

  return (
    <Tooltip open={copied} arrow sx={{ m: 0 }} title="Copied!">
      <Button fullWidth sx={{ m: 0, p: 0 }} disabled={isDisabled} onClick={copyText}>
        <TextField
          value={value}
          variant="outlined"
          fullWidth
          sx={{
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            ...(copied ? { backgroundColor: '#d2f8d2' } : {}),
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end" sx={{ margin: '1 auto' }}>
                <Copy />
              </InputAdornment>
            ),
          }}
        />
      </Button>
    </Tooltip>
  );
};

export default CopyToClipBoard;
