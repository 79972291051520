import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';
import { ruleNameChoices } from './ruleFeatures';

const RuleBreadcrumb = () => {
  const record = useRecordContext();
  const ruleDescription = ruleNameChoices.find((item) => item.id === record?.name)?.name;

  useRegisterBreadcrumb({
    title: ruleDescription ?? record?.name,
    path: `/rules/${record?.id}`,
  });

  return null;
};

export default RuleBreadcrumb;
