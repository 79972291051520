import React from 'react';

import {
  required,
  minValue,
  maxValue,
  NumberInput,
} from 'react-admin';

import { Grid } from '@mui/material';

import SelectInput from '../../../layout/inputFields/SelectInput';

import Label from '../../../../customFields/Label';

const RuleScore = () => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={12}>
      <Label
        variant="label"
        title="Rule Score"
        info="The rule score is reflecting how the risk score of an entity should be affected when the suspicious
           pattern the rule is looking for is detected. It is interpreted as the probability of financial crime given
           that the financial pattern has been detected. It is expressed as a percentage (between 0 and 100%)"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <NumberInput
        source="score"
        fullWidth
        validate={[required(), maxValue(100), minValue(0)]}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Label
        variant="label"
        title="Rule decreasing timeline"
        info="The risk decreasing timeline is adjusting the risk of the entity based on a algorithm over the inputted time"
      />
    </Grid>
    <Grid item xs={6} md={6}>
      <NumberInput
        source="riskTimeline.amount"
        fullWidth
        label="Number"
        validate={[required(), minValue(1)]}
      />
    </Grid>
    <Grid item xs={6} md={6}>
      <SelectInput
        source="riskTimeline.period"
        choices={[{ id: 'months', name: 'months' },
          { id: 'days', name: 'days' },
          { id: 'years', name: 'years' }]}
        fullWidth
        label="Unit"
        validate={required()}
      />
    </Grid>
  </Grid>
);

export default RuleScore;
