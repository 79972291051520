import React from 'react';

import {
  ReferenceField,
  useRecordContext,
} from 'react-admin';

import get from 'lodash/get';

import ColoredScoreField from './ColoredScoreField';

const ReferenceScoreField = ({
  source,
  sortable,
}: {
  source?: string,
  label?: string,
  sortable?: boolean
}) => {
  const record = useRecordContext();
  const value = source ? get(record, source, {}) : record;
  const idSource = source ? `${source}.id` : 'id';
  if (value.type === 'Individual') {
    return (
      <ReferenceField sortable={sortable} label="Individual" source={idSource} reference="individuals" link={false}>
        <ColoredScoreField source="riskScore" />
      </ReferenceField>
    );
  }
  if (value.type === 'Business') {
    return (
      <ReferenceField sortable={sortable} label="Business" source={idSource} reference="businesses" link={false}>
        <ColoredScoreField source="riskScore" />
      </ReferenceField>
    );
  }
  if (value.type === 'Car') {
    return (
      <ReferenceField sortable={sortable} label="Car" source={idSource} reference="cars" link={false}>
        <ColoredScoreField source="riskScore" />
      </ReferenceField>
    );
  }
  return null;
};

export default ReferenceScoreField;
