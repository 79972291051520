import React from 'react';

import { Grid } from '@mui/material';

import SelectRequestParty from '../../../../customFields/SelectRequestParty';

import requestEntityFields from '../../../../constants/requestEntityFields';
import requestTypes from '../../../../constants/requestTypes';

const IndividualKyc = () => (
  <Grid container spacing={6}>
    {requestEntityFields[requestTypes.INDIVIDUAL_KYC.id].map((role) => (
      <Grid item xs={12} md={12} display="flex">
        <SelectRequestParty key={role} role={role} />
      </Grid>
    ))}
  </Grid>
);

export default IndividualKyc;
