import { createTheme, Theme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import customVariables from './customVariables';

const theme: Theme = createTheme({
  spacing: 4,
  breakpoints,
  components,
  typography,
  shadows,
  palette,
  ...customVariables,
});

export default theme;
