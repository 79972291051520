import React from 'react';

import {
  SaveButton,
  Toolbar,
  NumberInput,
  required,
  minValue,
  maxValue,
  useRecordContext,

  TextInput,
  DeleteButton,
  RecordContextProvider,
} from 'react-admin';

import {
  Grid,
  Typography,

} from '@mui/material';

import { useFormContext } from 'react-hook-form';

import EditDialog from '../layout/dialog/EditDialog';
import actions from '../../constants/actions';
import resourceTypes from '../../constants/resourceTypes';

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();

  return (
    <Toolbar sx={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between' }}>
      <DeleteButton />
      <SaveButton
        disabled={!isValid}
        icon={null as any}
      />
    </Toolbar>
  );
};

const RiskClassEdit = () => {
  const record = useRecordContext();

  return (
    <EditDialog
      customToolbar={<CustomToolbar />}
      title="risk class"
      action={actions.RISK_CLASS_UPDATE}
      resourceType={resourceTypes.RISK_CLASS}
    >
      <RecordContextProvider value={record}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <Typography variant="mediumTitle">
              Risk class name
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextInput sx={{ width: '70%' }} validate={required()} source="name" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="mediumTitle">
              Interval score
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <NumberInput validate={[required(), maxValue(99), minValue(0)]} source="min" label="Minimum (%)" />
          </Grid>
          <Grid item xs={6} md={6}>
            <NumberInput validate={[required(), maxValue(100), minValue(1)]} source="max" label="Maximum (%)" />
          </Grid>
        </Grid>
      </RecordContextProvider>
    </EditDialog>
  );
};

export default RiskClassEdit;
