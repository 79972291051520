import React from 'react';

import {
  ArrayField,
  Datagrid,
  RaRecord,
  RecordContextProvider,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';

import NoResults from '../../../../layout/NoResults';

const CarGeneralInformation = ({ data }: { data: RaRecord }) => {
  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={6} md={6}>
          <Card variant="outlined">
            <CardHeader
              title="Vehicle Identity"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Personalized number plate:
                    </Typography>
                    <TextField emptyText="-" source="personligtRegnr" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Chassi number:</Typography>
                    <TextField emptyText="-" source="chassinummer" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Motor number:</Typography>
                    <TextField emptyText="-" source="motornummer" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Model number:</Typography>
                    <TextField emptyText="-" source="modellnr" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Group code:</Typography>
                    <TextField emptyText="-" source="gruppkod" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Group number:</Typography>
                    <TextField emptyText="-" source="gruppnr" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Multiple users:</Typography>
                    <SelectField
                      choices={[
                        { id: 'Y', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="signalOmFleraAgare"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Direct import:</Typography>
                    <SelectField
                      choices={[
                        { id: 'Y', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="direktimport"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Leasing:</Typography>
                    <TextField emptyText="-" source="leasing" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Registration inspection:
                    </Typography>
                    <TextField emptyText="-" source="registreringsbesiktning" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Registration type:</Typography>
                    <TextField emptyText="-" source="registreringstyp" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Registration Date:</Typography>
                    <TextField emptyText="-" source="datumForRegistrering" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Reintroduced in Sweden and taken into trafic:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="aterInfortISverigeOchTagetITrafik"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Registration fee:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="pastallningsAvgift"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Driving ban:
                    </Typography>
                    <SelectField
                      choices={[
                        { id: 'J', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="kforbud"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Driving ban date:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="kforbdatum"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Driving ban reason:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="kforborsak"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Use ban:
                    </Typography>
                    <SelectField
                      choices={[
                        { id: 'J', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="brukforbud"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Number of users:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="antalBrukare"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader
                    sx={{ color: 'black', fontWeight: 'bold' }}
                    titleTypographyProps={{ variant: 'inherit' }}
                    title="Wanted list"
                  />
                  <Divider />
                  <ArrayField label="Role(s)" source="efterlystLista">
                    <Datagrid
                      sx={boldDataGridStyle}
                      bulkActionButtons={false}
                      empty={<NoResults />}
                    >
                      <TextField source="efterlysningsanledning" emptyText="-" label="Reason" />
                      <TextField source="efterlysningensGallande" emptyText="-" label="Active" />
                      <TextField source="efterlysningsdatum" emptyText="-" label="Date" />
                      <TextField source="efterlysandePMyndNamn" emptyText="-" label="Police authority name" />
                      <TextField source="efterlysandePMyndKod" emptyText="-" label="Police authority code" />
                      <TextField source="avlyssningsdatum" emptyText="-" label="Cancel Date" />
                      <TextField source="avlyssningsPMyndkod" emptyText="-" label="Canceled by Police authority code" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default CarGeneralInformation;
