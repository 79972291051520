import React from 'react';

import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  useRecordContext,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

const SourceField = ({ label }: {label?: string}) => {
  const record = useRecordContext();

  if (record?.createdBy) {
    return <TextField label={label} source="createdBy" emptyText="-" />;
  }
  return <TextField label={label} source="provider" emptyText="-" />;
};

const ConflictResolutionSources = () => (
  <Card variant="outlined">
    <CardHeader
      title="Sources"
      sx={{ paddingY: 2 }}
    />
    <Divider />
    <ReferenceManyField reference="data-point-sources" target="datapointId">
      <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
        <DateField source="createdAt" showTime label="Timestamp" emptyText="-" />
        <TextField source="comment" emptyText="-" />
        <TextField source="type" emptyText="-" />
        <SourceField label="Source" />
      </Datagrid>
    </ReferenceManyField>
  </Card>
);

export default ConflictResolutionSources;
