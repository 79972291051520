import React, { useCallback } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import {
  useDataProvider, useNotify, useRecordContext,
} from 'react-admin';
import ErrorIcon from '@mui/icons-material/Error';

const ReportStatus = ({ handleDownloadClick }: {handleDownloadClick: () => void }) => {
  const record = useRecordContext();
  switch (record?.status) {
    case 'loading':
      return <CircularProgress size="1rem" />;
    case 'ready':
      return (
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            onClick={handleDownloadClick}
            color="secondary"
            startIcon={<CloudDownloadIcon />}
          >
            Download
          </Button>
        </Box>
      );
    default:
      return <ErrorIcon />;
  }
};

const DownloadReport = ({ label }: {label?: string, sortable?: boolean}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();

  const handleDownloadClick = useCallback(() => {
    dataProvider.getOne('reports/download', { id: record?.id })
      .then((response) => {
        window.open(response.data.downloadUrl, '_blank');
      })
      .catch(() => {
        notify('Error: Could not download report', { type: 'warning' });
      });
  }, [dataProvider, notify, record?.id]);

  return (
    <ReportStatus key={label} handleDownloadClick={handleDownloadClick} />
  );
};
export default DownloadReport;
