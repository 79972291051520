import paths from '../../constants/paths';
import requestRoles from '../../constants/requestRoles';

const ruleSubCategories = {
  INITIATOR: { ...requestRoles.initiator, type: 'subcategory' },
  RECIPIENT: { ...requestRoles.recipient, type: 'subcategory' },
  PROCESSOR: { ...requestRoles.processor, type: 'subcategory' },
  KYC_INDIVIDUAL: { id: paths.INDIVIDUAL, name: 'Individual', type: 'subcategory' },
  KYC_BUSINESS: { id: paths.BUSINESS, name: 'Business', type: 'subcategory' },
  CAR_KYC: { id: paths.KYC, name: 'KYC', type: 'subcategory' },
  CAR_PURCHASE: { id: paths.PURCHASE, name: 'Purchase', type: 'subcategory' },
  TEST: { id: paths.TEST, name: 'Test', type: 'subcategory' },
} as const;

export default ruleSubCategories;
