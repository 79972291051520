import React, { useCallback, useMemo, useState } from 'react';
import {
  useRecordContext,
  Form,
  useCreate,
  TextInput,
  required,
  useResourceContext,
  useNotify,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { FieldValues } from 'react-hook-form';
import LinkIcon from '@mui/icons-material/Link';
import getResourceName from '../../utilities/getResourceName';
import ReferenceEntityInputV2 from '../../customFields/ReferenceEntityInputV2';

const CreateCustomConnectionButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [create] = useCreate();
  const resource = useResourceContext();
  const notify = useNotify();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleSubmit = useCallback(async (data: FieldValues) => {
    await create('connections', { data }, {
      onSuccess: () => {
        setOpen(false);
        notify('Connection added successfully');
      },
      onError: () => {
        notify('Failed to create connection', { type: 'error' });
      },
    });
  }, [create, notify]);

  const prefilledValues = useMemo(() => ({
    entity1: {
      id: record?.id,
      type: getResourceName(resource),
    },
  }), [record, resource]);

  return (
    <>
      <Button
        startIcon={<LinkIcon color="secondary" />}
        onClick={handleOpen}
      >
        Add custom connection
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <Form record={prefilledValues} onSubmit={handleSubmit}>
          <DialogTitle>Add custom connection</DialogTitle>
          <DialogContent>
            <Stack spacing={4}>
              <ReferenceEntityInputV2 source="entity2" label="Connected entity" validate={required()} />
              <TextInput source="description" validate={required()} fullWidth />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default CreateCustomConnectionButton;
