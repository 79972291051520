import Joi from 'joi';
import Luhn from '../Luhn';

export const isValidOrgNumber = (value: string) => {
  if (!value.match(/^\d{10}$/)) return false;
  return Luhn(value);
};

export interface IJoiOrgNumberExtension extends Joi.Root {
  orgNumber(): Joi.AnySchema;
}

const JoiOrgNumberExtension = (joi: Joi.Root) => joi.extend({
  type: 'orgNumber',
  messages: {
    invalid: '"{{#label}}" must be a valid Swedish organisation number. Accepted format: xxxxxxxxxx',
  },
  validate(value, { error }) {
    if (!isValidOrgNumber(value)) {
      return { value, errors: error('invalid') };
    }
    return { value };
  },
}) as IJoiOrgNumberExtension;

export default JoiOrgNumberExtension;
