import React from 'react';

import { Box, Typography } from '@mui/material';

import isoCountries from 'i18n-iso-countries';

export const alpha2ToFlagEmoji = (alpha3: string) => {
  if (!alpha3) return '';

  const alpha2 = isoCountries.alpha3ToAlpha2(alpha3);

  const codePoints = alpha2.split('').map((char: string) => 127397 + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

const CountyFlag = ({ value } : {value: Record<string, string>}) => (
  <Box display="flex" alignItems="center" gap={2}>
    <Typography variant="h6">{alpha2ToFlagEmoji(value.country)}</Typography>
    <Typography color="moank.main" variant="h6">{isoCountries.getName(value.country, 'en')}</Typography>
  </Box>
);

export default CountyFlag;
