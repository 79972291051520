import React, { useState, useEffect } from 'react';

import {
  getTreeFromFlatData,
} from '@nosferatu500/react-sortable-tree';

import {
  useDelete,
  useNotify,
  useRefresh,
  useUpdate,
} from 'react-admin';

import Title from '../components/rules/components/treeView/Title';

import SubTitle from '../components/rules/components/treeView/SubTitle';
import { Rule } from '../utilities/schemas/rules';

const ruleDataToTreeData = (data?: Rule[]) => {
  const flatData = (data ?? []).map((rule) => ({
    ...rule,
    expanded: true,
    title: <Title rule={rule} />,
    subtitle: <SubTitle rule={rule} />,
    triggeredBy: rule.triggeredBy || null,
  }));

  return getTreeFromFlatData({
    flatData,
    getKey: (rule: Rule) => rule.id,
    getParentKey: (rule: Rule) => rule.triggeredBy ?? 'root',
    rootKey: 'root',
  });
};

const useRuleTreeState = ({ data }: { data?: Rule[] }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const [deleteOne] = useDelete(
    undefined,
    undefined,
    {
      onError: () => notify('Could not perform rule deletion', { type: 'error' }),
      onSuccess: () => refresh(),
    },
  );
  const [updateOne] = useUpdate(
    undefined,
    undefined,
    {
      onError: () => notify('Could not perform rule update', { type: 'error' }),
      onSuccess: () => refresh(),
    },
  );

  const [treeData, setTreeData] = useState({
    treeData: ruleDataToTreeData(data),
  });
  useEffect(() => {
    setTreeData({
      treeData: ruleDataToTreeData(data),
    });
  }, [data]);

  const updateMove = async (rule: Rule) => {
    await updateOne('rules', { id: rule.id, data: rule });
  };

  const removeNode = async (rule: Rule) => {
    await deleteOne('rules', { id: rule.id, previousData: rule });
  };

  return {
    treeData,
    updateMove,
    setTreeData,
    removeNode,
  };
};

export default useRuleTreeState;
