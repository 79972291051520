import React from 'react';

import { Grid } from '@mui/material';

import SelectRequestParty from '../../../../customFields/SelectRequestParty';

import requestEntityFields from '../../../../constants/requestEntityFields';
import requestTypes from '../../../../constants/requestTypes';
import requestRoles from '../../../../constants/requestRoles';

const CarKyc = () => (
  <Grid container spacing={6}>
    {requestEntityFields[requestTypes.CAR_KYC.id].map((role) => (
      <Grid item xs={12} md={12} display="flex">
        <SelectRequestParty
          key={role}
          role={role}
          defaultValue={role === requestRoles.entity.id ? 'Car' : undefined}
          disabled={role === requestRoles.entity.id}
        />
      </Grid>
    ))}
  </Grid>
);

export default CarKyc;
