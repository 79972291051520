import { ValueOf } from '../utilities/types';

// Enumeration for the codes
export const deRegistrationCodes = {
  AS: 'AS',
  AV: 'AV',
  FI: 'FI',
  GN: 'GN',
  GS: 'GS',
  OB: 'OB',
  TA: 'TA',
  UV: 'UV',
  A: 'A',
  G: 'G',
  O: 'O',
};

export type StatusCodes = ValueOf<typeof deRegistrationCodes>;

// Mapping of codes to their English explanations
export const deRegistrationDescriptions: Record<StatusCodes, string> = {
  [deRegistrationCodes.AS]: 'Annulled Coordination Number',
  [deRegistrationCodes.AV]: 'Deceased',
  [deRegistrationCodes.FI]: 'False identity',
  [deRegistrationCodes.GN]: 'Changed social security number',
  [deRegistrationCodes.GS]: 'Changed coordination number',
  [deRegistrationCodes.OB]: 'Disappeared',
  [deRegistrationCodes.TA]: 'Technical deregistration',
  [deRegistrationCodes.UV]: 'Emigrated',
  [deRegistrationCodes.A]: 'Deceased (Old code, corresponds to the new code AV)',
  [deRegistrationCodes.G]: 'Changed social security number (Old code, corresponds to the new codes GN/GS)',
  [deRegistrationCodes.O]: 'Other cause than A or G, for example transferred to the register of non-existence or emigrated (Old code, could correspond to any of the two-letter codes except AV, GN and GS)',
};

export default deRegistrationCodes;
