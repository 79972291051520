import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const MemberBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record ? `${record.firstName} ${record.lastName}` : '',
    path: `/members/${record?.id}`,
  });

  return null;
};

export default MemberBreadcrumb;
