import mergeWith from 'lodash/mergeWith';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import uploadDocument from '../uploadDocument';
import config from '../../config';

type FieldMap = {
  update: string[];
  create: string[];
};
const fileFields: Record<string, FieldMap> = {
  cases: {
    update: ['files'],
    create: ['files'],
  },
  'form-answers': {
    update: ['files'],
    create: ['files'],
  },
};

const concatArrayCustomizer = <T extends unknown>(a: T[], b: T[]) => {
  if (Array.isArray(a) && Array.isArray(b)) return a.concat(b);
  return undefined;
};

type SafeHandler<T extends 'update' | 'create'> = (
  resource: string,
  params: UpdateParams | CreateParams,
) => Promise<T extends 'update' ? UpdateResult : CreateResult>

const getActionHandler = <T extends 'update' | 'create'>(
  dataProvider: DataProvider,
  action: T,
) => async (resource: string, params: T extends 'update' ? UpdateParams : CreateParams) => {
  const originalActionHandler = dataProvider[action] as SafeHandler<T>;
  if (!Object.keys(fileFields).includes(resource)) return originalActionHandler(resource, params);

  const fields = fileFields[resource][action];

  const fieldsExists = fields.filter((field) => params.data[field] !== undefined);
  if (fieldsExists.length === 0) return originalActionHandler(resource, params);

  const existingFiles = fieldsExists.reduce((data, field) => ({
    ...data,
    [field]: (params.data[field] as any[])
      .filter((fileData) => !(fileData.rawFile instanceof window.File)),
  }), {});

  const filesToUpload = fieldsExists
    .flatMap((field) => (params.data[field] as any[]).map((item) => ({
      file: item.rawFile,
      fileName: field,
    })))
    .filter((fileData) => fileData.file instanceof window.File);

  const uploadedFiles = filesToUpload.length > 0 ? await uploadDocument(`${config.apiUrl}/upload`, filesToUpload) : {};

  const filesData = mergeWith(existingFiles, uploadedFiles, concatArrayCustomizer);
  return originalActionHandler(resource, {
    ...params,
    data: {
      ...params.data,
      ...filesData,
    },
  });
};

const addUploadFeature = (dataProvider: DataProvider) => ({
  ...dataProvider,
  update: getActionHandler(dataProvider, 'update'),
  create: getActionHandler(dataProvider, 'create'),
});

export default addUploadFeature;
