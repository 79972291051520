import React, { useCallback } from 'react';

import { Box } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIosNew';
import { Save } from '@mui/icons-material';

import { Button, useSaveContext } from 'react-admin';
import { useFormContext, useFormState } from 'react-hook-form';

const FormWizardActions = ({
  next,
  isLastPage,
  previous,
  isFirstPage,
  edit,
}: {
  next: () => void,
  isLastPage: boolean,
  previous: () => void,
  isFirstPage: boolean,
  edit: boolean,
}) => {
  const { save } = useSaveContext();
  const { getValues } = useFormContext();
  const { isValid } = useFormState();

  const onSubmit = useCallback(() => {
    if (!save) return;

    const data = getValues();
    save(data);
  }, [save, getValues]);

  if (!save) return null;

  if (!isLastPage) {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button startIcon={<ArrowBackIcon />} disabled={isFirstPage} onClick={previous} label="Previous" />
        <Button variant="contained" disabled={!isValid} onClick={next} label="Next" />
      </Box>
    );
  }
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      {edit && <Button startIcon={<ArrowBackIcon />} disabled={isFirstPage} onClick={previous} label="Previous" />}
      {edit && <Button startIcon={<Save />} variant="contained" disabled={!isValid} label="Save" onClick={onSubmit} />}
    </Box>
  );
};

export default FormWizardActions;
