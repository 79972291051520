import React from 'react';
import { DateField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'När började ditt företag att verka?',
  [languages.EN]: 'When did your business start operating?',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <DateField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const OperationStartDate = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="operationStartDate"
      datapoint={conflictFields.OPERATION_START_DATE}
    >
      <ValueField />
    </Answer>
  );
};

OperationStartDate.ValueField = ValueField;

export default OperationStartDate;
