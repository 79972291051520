import { HttpError } from 'react-admin';
import errorMessages from '../constants/errorMessages';

const errorNotification = (error: unknown) => {
  if (error instanceof HttpError) {
    return errorMessages[error.message] ?? error.message ?? 'Internal error';
  }
  return 'Internal error';
};

export default errorNotification;
