import { isArray, isNil, isObject } from 'lodash';

const cleanItem = (value: any) => {
  if (isNil(value) || value === '') {
    return undefined;
  }

  if (isObject(value)) {
    if (Object.keys(value).length === 0 || Object.values(value).every((item) => isNil(item) || item === '')) {
      return undefined;
    }
  }
  return value;
};

const cleaner = (data: any) => JSON.parse(JSON.stringify(data, (key, value) => {
  if (isNil(value) || value === '') return undefined;

  if (isObject(value)) {
    if (Object.keys(value).length === 0 || Object.values(value).every((item) => isNil(item) || item === '')) {
      return undefined;
    }
  }

  if (isArray(value)) {
    const cleanedArray = value
      .map((item) => cleanItem(item))
      .filter((item) => !!item);
    if (cleanedArray.length === 0) return undefined;
    return cleanedArray;
  }

  return value;
}));

export const entityCleaner = (data: any) => JSON.parse(JSON.stringify(data, (key, value) => {
  if (isNil(value) || value === '') return undefined;

  if (key === 'customId') return undefined;

  if (isObject(value)) {
    if (Object.keys(value).length === 0 || Object.values(value).every((item) => isNil(item) || item === '')) {
      return undefined;
    }
  }

  if (isArray(value)) {
    const cleanedArray = value
      .map((item) => cleanItem(item))
      .filter((item) => !!item);
    if (cleanedArray.length === 0) return undefined;
    return cleanedArray;
  }

  return value;
}));

export default cleaner;
