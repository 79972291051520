import React from 'react';

import {
  Datagrid,
  DateField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import NoResults from '../../../layout/NoResults';
import StatusField from '../../../../customFields/StatusField';
import LinkField from '../../../layout/LinkField';

const IndividualKyc = (
  {
    children,
  }: {
    children?: React.ReactNode,
  },
) => (
  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <DateField source="createdAt" showTime />
    <ReferenceEntityField sortable={false} source="extraInformation.entity" label="Individual" />
    <StatusField sortable={false} source="recommendation" />
    <ReferenceField sortable={false} reference="processes" source="process">
      <ChipField source="name" />
    </ReferenceField>
    {children}
  </Datagrid>
);

export default IndividualKyc;
