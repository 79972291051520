import React, { useState } from 'react';

import {
  ReferenceManyField,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
} from '@mui/material';

import { PingListBody } from '../components/ping/PingList';
import RuleVersion from '../components/ping/components/actions/RuleVersion';
import { PingStatuses } from '../constants/pingStatuses';
import SelectInputButton from '../components/layout/inputFields/SelectInputButton';
import Pagination from '../components/layout/Pagination';

const LatestRelatedPings = ({
  defaultType = 'all',
}: {
  defaultType?: PingStatuses | 'all'
}) => {
  const [status, setStatus] = useState<PingStatuses | 'all'>(defaultType);
  const [versions, setVersions] = useState<string[]>([]);
  const [isTest, setIsTest] = useState<boolean>(false);

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader
          title="Related Pings"
          action={(
            <Box display="flex" gap={1}>
              <SelectInputButton
                choices={[
                  { id: 'all', name: 'All' },
                  { id: 'pending', name: 'Pending' },
                  { id: 'confirmed', name: 'Confirmed' },
                  { id: 'ignored', name: 'Ignored' },
                  { id: 'resolved', name: 'Resolved' },
                ]}
                value={status}
                setValue={setStatus}
              />
              <RuleVersion
                versions={versions}
                setVersions={setVersions}
                isTest={isTest}
                setIsTest={setIsTest}
              />
            </Box>
          )}
        />
        <Divider />
        <ReferenceManyField
          filter={{ ...(status !== 'all' ? { status } : {}), isTestPing: isTest, ruleVersion: versions }}
          perPage={5}
          pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
          reference="pings"
          target="entities.id"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <PingListBody />
        </ReferenceManyField>
      </Card>
    </Grid>
  );
};

export default LatestRelatedPings;
