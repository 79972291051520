import React, { useCallback, useState } from 'react';

import {
  DateInput,
  useGetList,
  useRecordContext,
  ArrayField,
  SelectArrayInput,
} from 'react-admin';

import {
  Grid,
  Box,
  Button,
  Dialog,
  Divider,
  CardHeader,
  Card,
  DialogActions,
  IconButton,
} from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LayersClearIcon from '@mui/icons-material/LayersClear';

import { useFormContext } from 'react-hook-form';
import { capitalize, size } from 'lodash';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import RuleDatagrid from '../../../rules/components/RuleDatagrid';
import SelectInputButton from '../../../layout/inputFields/SelectInputButton';
import Pagination from '../../../layout/Pagination';
import { RuleVersion } from '../../../dashboard/components/RuleTable';

import { Rule } from '../../../../utilities/schemas/rules';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import ruleTypes from '../../../../constants/ruleTypes';
import pingStatuses from '../../../../constants/pingStatuses';

const CustomButton = ({
  setSelectedIds,
  selectedIds,
}: {
  setSelectedIds: (x: any) => void
  selectedIds: Set<string>
}) => {
  const rule = useRecordContext<Rule>();
  if (!rule) return null;

  if (selectedIds.has(rule.id)) {
    return (
      <IconButton
        onClick={
          () => setSelectedIds(
            (prev: Set<string>) => new Set([...prev].filter((id) => id !== rule?.id)),
          )
        }
        color="error"
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    );
  }
  return (
    <IconButton
      onClick={() => setSelectedIds((prev: Set<string>) => new Set(prev.add(rule.id)))}
      color="secondary"
    >
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

const SelectRule = ({ source } : { source: string}) => {
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  const [ruleType, setRuleType] = useState(ruleTypes.REQUEST);
  const [version, setVersion] = useState('');

  const { data } = useGetList('rules', {
    filter: { ruleType, version },
    pagination: { page: 1, perPage: 500 },
  });

  const { open, openDialog, closeDialog } = useDialogStatus();
  const { setValue } = useFormContext();

  const isDirty = selectedIds.size > 0;

  const handleSave = useCallback(() => {
    setValue(source, Array.from(selectedIds));
    closeDialog();
  }, [closeDialog, selectedIds, setValue, source]);

  const handleClose = () => {
    setSelectedIds(new Set());
    closeDialog();
  };

  return (
    <Box
      marginBottom="1%"
      justifyContent="center"
      sx={{
        width: {
          sm: '100%', xs: '100%', md: '20%', lg: '12%',
        },
      }}
    >
      <Button sx={{ width: '100%' }} startIcon={<PlaylistAddIcon />} variant="contained" onClick={openDialog}>{`Select rules (${selectedIds.size})`}</Button>
      <Dialog scroll="body" transitionDuration={500} open={open} fullWidth maxWidth="xl">
        <Card style={{ margin: 0 }}>
          <CardHeader
            title="Select Rules"
            action={(
              <Box display="flex" gap={2}>
                {selectedIds.size !== size(data) && (
                  <Button
                    startIcon={<LibraryAddIcon />}
                    onClick={() => setSelectedIds(new Set(data?.map((i) => i.id)))}
                  >
                    Select all
                  </Button>
                )}
                {selectedIds.size === size(data) && (
                  <Button startIcon={<LayersClearIcon />} onClick={() => setSelectedIds(new Set())}>
                    Unselect all
                  </Button>
                )}
                <SelectInputButton
                  choices={[
                    { id: 'request', name: 'Request rule' },
                    { id: 'ongoing', name: 'Ongoing rule' },
                  ]}
                  value={ruleType}
                  setValue={setRuleType}
                />
                <RuleVersion
                  value={version}
                  setValue={setVersion}
                  ruleType={ruleType}
                />
              </Box>
            )}

          />
          <Divider />
          {data && (
            <ArrayField source="data" record={{ data }} perPage={15}>
              <RuleDatagrid sx={{ ...boldDataGridStyle }} ruleType={ruleType}>
                <CustomButton selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
              </RuleDatagrid>
              <Pagination disableEmptyText />
            </ArrayField>
          )}
        </Card>
        <DialogActions>
          <Button onClick={handleClose} startIcon={<CloseIcon />} color="error" variant="outlined">Close</Button>
          <Button onClick={handleSave} disabled={!isDirty} startIcon={<SaveIcon />} variant="outlined">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const PingOverview = () => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <Box display="flex" height="fit-content" gap="2%" alignItems="center" flexWrap="wrap">
        <SelectRule source="report.ruleIds" />
      </Box>
    </Grid>
    <Grid item xs={12} md={12}>
      <SelectArrayInput
        sx={{ width: { sx: '100%', md: '17%' } }}
        choices={Object.values(pingStatuses).map(
          (status) => ({ id: status, name: capitalize(status) }),
        )}
        fullWidth
        label="Ping statuses"
        source="report.pingStatuses"
      />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <DateInput fullWidth source="report.from" />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={1} display="flex" justifyContent="center" alignItems="center">
      <ArrowForwardIcon sx={{ marginBottom: 2 }} fontSize="large" />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <DateInput fullWidth source="report.to" />
    </Grid>
  </Grid>
);

export default PingOverview;
