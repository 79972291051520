import React from 'react';

type ChildProps = {
  children: React.ReactElement
  path?: string,
  label?: string,
}

const Child = ({
  children,
}: ChildProps) => children;

export default Child;
