import { useRecordContext } from 'react-admin';

import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';
import { IBusiness } from '../../utilities/schemas/business';

const BusinessBreadcrumb = () => {
  const record = useRecordContext<IBusiness>();
  useRegisterBreadcrumb({
    title: record?.name ?? record?.registrationNumber ?? record?.customIds?.at(0) ?? record?.id,
    path: `/businesses/${record?.id}`,
  });

  return null;
};

export default BusinessBreadcrumb;
