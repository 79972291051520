import { useCallback, useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { omit } from 'lodash';

const useListFilter = ({ source } :{ source: string}) => {
  const [filter, setFilter] = useState<Record<string, any>>();
  const { setFilters, filterValues, displayedFilters } = useListContext();

  const handelChangeFilter = useCallback((filterValue: Record<string, any>) => {
    setFilter(filterValue);
  }, []);

  useEffect(() => {
    if (displayedFilters[source]) {
      setFilters(
        {
          ...filterValues,
          ...filter,
        },
        displayedFilters,
      );
    }
    if (!displayedFilters[source]) {
      setFilters(omit(filterValues, source), displayedFilters);
    }

  // Do not include all list ctx deps due to changing page will trigger updated list ctx
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, setFilter, source]);

  return {
    handelChangeFilter,
    filterValues,
    displayedFilters,
  };
};

export default useListFilter;
