import { ValueOf } from '../utilities/types';

const unitsOfTime = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
} as const;

export type UnitsOfTime = ValueOf<typeof unitsOfTime>;

export const unitsOfTimeChoices = [
  {
    id: unitsOfTime.DAY,
    name: 'days',
  },
  {
    id: unitsOfTime.MONTH,
    name: 'months',
  },
  {
    id: unitsOfTime.YEAR,
    name: 'years',
  },
];

export default unitsOfTime;
