import * as React from 'react';

import {
  Box,
  Grid,
  List,
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
  styled,
} from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0.25)} ${theme.spacing(4)}`,
  background: theme.footer.background,
  position: 'relative',
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'inline-block',
  width: 'auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '&, &:hover, &:active': {
    color: '#ff0000',
  },
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  '& span': {
    color: theme.footer.color,
  },
}));

const Footer = () => (
  <Wrapper>
    <Grid container spacing={0} justifyContent="center">
      <List>
        <ListItem>
          <ListItemText
            primary={`© ${new Date().getFullYear()} - Pingwire`}
          />
        </ListItem>
      </List>
    </Grid>
  </Wrapper>
);

export default Footer;
