import React from 'react';
import { FunctionField, TextField, useRecordContext } from 'react-admin';
import { get } from 'lodash';
import { Alpha3Code } from 'i18n-iso-countries';
import { Box } from '@mui/material';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { alpha2ToFlagEmoji } from '../../../../customFields/CountryFlag';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Organisationsnummer',
  [languages.EN]: 'Organization Number',
};

const ValueField = () => {
  const record = useRecordContext();
  return (
    <Box display="flex" gap={2}>
      <FunctionField
        render={({ value }: { value?: { country: Alpha3Code } }) => (
          !!value?.country && alpha2ToFlagEmoji(value.country)
        )}
      />
      <TextField sx={get(record, 'sx')} source="value.registrationNumber" emptyText="-" fontWeight="bold" />
    </Box>
  );
};

const OrgNumber = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="organizationNumber"
      datapoint={conflictFields.REGISTRATION_NUMBER}
    >
      <ValueField />
    </Answer>
  );
};

OrgNumber.ValueField = ValueField;

export default OrgNumber;
