import React, { useCallback } from 'react';

import {
  useRecordContext,
  SelectArrayInput,
  useGetList,
  useResourceContext,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';

import { useWatch } from 'react-hook-form';
import useRequests from '../hooks/useRequest';
import useDialogStatus from '../hooks/useDialogStatus';

import InfoPopover from '../components/layout/InfoPopover';

import requestTypes from '../constants/requestTypes';
import entityTypes from '../constants/entityTypes';

const PerformKyc = () => {
  const resource = useResourceContext();
  const { open, openDialog, closeDialog } = useDialogStatus();
  const processes = useWatch({ name: 'request.processes', defaultValue: [] });
  const record = useRecordContext();

  const types = (() => {
    switch (resource) {
      case 'individuals':
        return { requestType: requestTypes.INDIVIDUAL_KYC.id, type: entityTypes.INDIVIDUAL };
      case 'businesses':
        return { requestType: requestTypes.BUSINESS_KYC.id, type: entityTypes.BUSINESS };
      case 'cars':
        return { requestType: requestTypes.CAR_KYC.id, type: entityTypes.CAR };
      default:
        return null;
    }
  })();

  const {
    createRequest,
    loading,
  } = useRequests({ id: record?.id, ...types! });

  const { data } = useGetList('processes', { filter: { active: true }, pagination: { page: 1, perPage: 100 } });

  const handleClick = useCallback(() => {
    processes.map((process: string) => createRequest(process));
    closeDialog();
  }, [closeDialog, createRequest, processes]);

  return (
    <Box display="flex" alignItems="center">
      {!loading && <Button variant="contained" startIcon={<DoubleArrowIcon />} onClick={openDialog}>Perform KYC</Button>}
      {loading && (
        <Button
          disabled
          startIcon={<CircularProgress size={16} />}
        >
          Performing KYC
        </Button>
      )}
      <InfoPopover
        title="Perform KYC"
        content={'Create a KYC request for the entity that will be run against all viable pre-defined rules. \n  If at least one rule is broken, the risk will be updated and a new case will appear below.'}
      />
      <Dialog open={open}>
        <DialogTitle color="primary">Perform KYC</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Typography variant="label">Select the processes that you want to perform the KYC against</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <SelectArrayInput
                label="Select"
                source="request.processes"
                fullWidth
                choices={data?.map((item) => ({ id: item.id, name: item.name }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} startIcon={<CloseIcon />} color="error">Cancel</Button>
          <Button onClick={handleClick} startIcon={<DoubleArrowIcon />}>Perform</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PerformKyc;
