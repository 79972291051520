import React, {
  useCallback, useState,
} from 'react';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Theme,
  styled,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';

import pingwireLogo from '../../assets/logos/pingwire-green.svg';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledCard = styled(Card)(({ theme }: {theme: Theme}) => ({
  maxWidth: 700,
  width: `calc(100% - 2 * ${theme?.spacing(3) ?? 0})`,
  padding: theme?.spacing(3),
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - 2 * ${theme.spacing(5)})`,
    padding: theme.spacing(5),
  },
  minHeight: 400,
}));

const BrandLogo = styled('img')(() => ({
  marginBottom: '2rem',
  height: '3.5rem',
}));

const StartPage = () => {
  const [type, setType] = useState('');

  const { id } = useParams();

  const redirect = useRedirect();

  const handleChange = useCallback((event: SelectChangeEvent) => {
    setType(event.target.value as string);
  }, []);

  const handleClick = useCallback(() => {
    redirect(`${type}/general`);
  }, [redirect, type]);

  const exit = useCallback(() => {
    redirect(`/cases/${id}`);
  }, [id, redirect]);

  return (
    <Root>
      <BrandLogo src={pingwireLogo} alt="Pingwire" />
      <StyledCard>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography variant="h3">Select a report type to continue</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormControl fullWidth>
                <InputLabel>Report type</InputLabel>
                <Select
                  value={type}
                  label="Report type"
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="str">STR - Suspicious Transaction Report</MenuItem>
                  <MenuItem value="sar">SAR - Suspicious Activity Report</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2} container justifyContent="center">
              <Button onClick={handleClick} disabled={!type} variant="contained">
                Continue
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">General information</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    The majority of the included fields are optional.
                    However,  all required fields are marked with *
                    and will not let you create a report if
                    not correctly filled. Once the required fields are
                    filled in you can create the report.
                    Information about the fields can be seen
                    by clicking on the information icon.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Correct format</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Remember to state references in
                    a coherent string without hyphens, blanks, commas or similar.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="red"
                endIcon={<ExitToAppIcon />}
                onClick={exit}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    </Root>
  );
};

export default StartPage;
