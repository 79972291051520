import React from 'react';

import {
  ReferenceManyField,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
} from '@mui/material';

import Pagination from '../components/layout/Pagination';

import { BusinessListBody } from '../components/business/BusinessList';

const RelatedConductions = () => (
  <Grid item xs={12} md={12}>
    <Card variant="outlined">
      <CardHeader
        title="Related Conductions"
      />
      <Divider />
      <ReferenceManyField
        perPage={5}
        pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
        reference="businesses"
        source="id"
        target="conductors.id"
      >
        <BusinessListBody />
      </ReferenceManyField>
    </Card>
  </Grid>
);

export default RelatedConductions;
