import React from 'react';

import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
} from 'react-admin';

import {
  Grid,
  Card,
} from '@mui/material';
import { boldDataGridStyle } from '../constants/style/datagridStyles';
import LinkField from '../components/layout/LinkField';

const CarOwnership = () => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <ReferenceManyField
          fullWidth
          perPage={5}
          pagination={<Pagination rowsPerPageOptions={[5, 10, 20]} />}
          reference="cars"
          target="owner.id"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <LinkField sortable={false} source="registrationNumber" label="Registration number" />
            <TextField sortable={false} source="registrationCountry" emptyText="-" />
            <TextField sortable={false} source="carModel" emptyText="-" />
            <DateField sortable source="createdAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Card>
    </Grid>
  </Grid>
);

export default CarOwnership;
