import React, { useCallback, useState } from 'react';

import {
  ReferenceManyField,
  Button,
  useCreate,
  useRedirect,
  Identifier,
  useResourceContext,
} from 'react-admin';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  Box,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useParams } from 'react-router-dom';

import { CaseListBody } from '../components/case/CaseList';
import Pagination from '../components/layout/Pagination';

import { entityTypesFromResource } from '../constants/entityTypes';
import caseStatuses from '../constants/caseStatuses';
import SelectInputButton from '../components/layout/inputFields/SelectInputButton';

const CreateButton = () => {
  const { id } = useParams();

  const [create] = useCreate();
  const redirect = useRedirect();
  const resource = useResourceContext();

  const entityType = entityTypesFromResource[resource];

  const handleClick = useCallback(() => {
    create(
      'cases',
      { data: { entityId: id, entityType } },
      { onSuccess: ((resp: { id: Identifier }) => redirect('list', `cases/${resp.id}`)) },
    );
  }, [create, id, redirect, entityType]);

  return (
    <Button label="Create" startIcon={<AddIcon />} onClick={handleClick} />
  );
};

const LatestRelatedCases = () => {
  const [status, setStatus] = useState('all');

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader
          title="Related Cases"
          action={(
            <Box display="flex" gap={1}>
              <SelectInputButton
                choices={[
                  { id: 'active', name: 'Active cases only' },
                  { id: 'all', name: 'All cases' },
                ]}
                value={status}
                setValue={setStatus}
              />
              <CreateButton />
            </Box>
          )}
        />
        <Divider />
        <ReferenceManyField
          perPage={5}
          pagination={<Pagination disableEmptyText rowsPerPageOptions={[5, 10, 20]} />}
          filter={status === 'all'
            ? {}
            : { status: [caseStatuses.IN_PROGRESS, caseStatuses.OPEN, caseStatuses.PENDING] }}
          reference="cases"
          target="entities"
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <CaseListBody />
        </ReferenceManyField>
      </Card>
    </Grid>
  );
};

export default LatestRelatedCases;
