import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import { Controller, useFormContext } from 'react-hook-form';
import {
  NumberInput,
  maxValue,
  minValue,
  required as requiredValidator,
  useRecordContext,
} from 'react-admin';
import { get } from 'lodash';

const InputSlider = ({
  source,
  max = 100,
  min = 0,
  required,
}: {
  source: string,
  max?: number,
  min?: number,
  required?: boolean,
}) => {
  const record = useRecordContext();
  const { control } = useFormContext();
  const validation = [minValue(min), maxValue(max)];
  if (required) validation.push(requiredValidator());

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container spacing={3} alignItems="center">

        <Grid item xs>
          <Controller
            name={source}
            defaultValue={get(record, source)}
            control={control}
            render={({ field }) => (
              <Slider
                value={field.value}
                onChange={(_, value) => field.onChange(value)}
                aria-labelledby="input-slider"
              />
            )}
          />
        </Grid>
        <Grid item>
          <NumberInput
            source={source}
            label={false}
            sx={{ maxWidth: 100 }}
            size="small"
            validate={validation}
            inputProps={{
              step: 1,
              min,
              max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InputSlider;
