import React, { useCallback } from 'react';

import {
  RaRecord,
  RecordContextProvider,
  SelectField,
  TextField,
  useRecordContext,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Button,
} from '@mui/material';

import LaunchOutlined from '@mui/icons-material/LaunchOutlined';

const OwnerInfo = ({ data }: { data: RaRecord }) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    if (record?.owner?.type === 'Business') redirect('edit', 'businesses', record?.owner?.id);
    if (record?.owner?.type === 'Individual') redirect('edit', 'individuals', record?.owner?.id);
  }, [record?.owner.id, record?.owner.type, redirect]);

  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={6} md={6}>
          <Card variant="outlined">
            <CardHeader
              title="Owner Information"
              action={(
                <Button startIcon={<LaunchOutlined />} onClick={handleClick}>
                  Owner Profile
                </Button>
              )}
            />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Protected:
                    </Typography>
                    <SelectField
                      choices={[
                        { id: 'J', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="skyddad"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Person status:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="personstatus"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      National identification number:
                    </Typography>
                    <TextField emptyText="-" source="personnummer" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Name:</Typography>
                    <TextField emptyText="-" source="namn" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Address:</Typography>
                    <TextField
                      emptyText="-"
                      source="adress"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Postal number:</Typography>
                    <TextField
                      emptyText="-"
                      source="postnr"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Post office:</Typography>
                    <TextField
                      emptyText="-"
                      source="postAnst"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Secrecy:</Typography>
                    <TextField emptyText="-" source="sekretess" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Number plate used before:
                    </Typography>
                    <SelectField
                      choices={[
                        { id: 'J', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="regnrTidigareAnvant"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Acquisition date:</Typography>
                    <TextField
                      emptyText="-"
                      source="datumForv"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Insurance company:</Typography>
                    <TextField
                      emptyText="-"
                      source="fbolag"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Insurance company code:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fbolagkod"
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">Insurance type:</Typography>
                    <TextField emptyText="-" source="ftyp" />
                  </Box>
                </Grid>
                <Grid item xs={3} md={3}>
                  <Box>
                    <Typography variant="smallTitle">
                      Advertising block:
                    </Typography>
                    <SelectField
                      choices={[
                        { id: 'J', name: 'Yes' },
                        { id: 'N', name: 'No' },
                      ]}
                      emptyText="-"
                      source="reklamsparr"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Card variant="outlined">
                    <CardHeader
                      sx={{ color: 'black', fontWeight: 'bold' }}
                      titleTypographyProps={{ variant: 'inherit' }}
                      title="Loan"
                    />
                    <Divider />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={3} md={3}>
                          <Box>
                            <Typography variant="smallTitle">Lender:</Typography>
                            <TextField emptyText="-" source="kredgiv" />
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Box>
                            <Typography variant="smallTitle">Lender name:</Typography>
                            <TextField emptyText="-" source="krednamn" />
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Box>
                            <Typography variant="smallTitle">
                              Lender address:
                            </Typography>
                            <TextField emptyText="-" source="kredAdress" />
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Box>
                            <Typography variant="smallTitle">
                              Lender postal number:
                            </Typography>
                            <TextField emptyText="-" source="kredPostnr" />
                          </Box>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Box>
                            <Typography variant="smallTitle">
                              Lender town:

                            </Typography>
                            <TextField emptyText="-" source="kredOrt" />
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default OwnerInfo;
