import React from 'react';

import { Grid, Typography } from '@mui/material';
import Select from './base/Select';
import booleanChoices from '../../../constants/booleanChoices';

const BeneficialOwner = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Do you have a beneficial owner?
      </Typography>
    </Grid>
    <Select choices={booleanChoices.en} />
  </Grid>
);

export default BeneficialOwner;
