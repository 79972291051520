import React from 'react';

import {
  ArrayField,
  Datagrid,
  TextField,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useWatch } from 'react-hook-form';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import Create from './dialog/Create';
import Edit from './dialog/Edit';
import Delete from './Delete';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

const List = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const goods = useWatch({ name: 'report.activity.goods_services' });

  return (
    <Grid container>
      {goods?.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <ArrayField record={{ goods }} source="goods">
                <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                  <TextField sortable={false} source="id" label="ID" />
                  <TextField sortable={false} source="item.item_type" label="Goods type" />
                  <TextField sortable={false} source="registrationNumber" label="Registration number" />
                  <Edit />
                  <Delete />
                </Datagrid>
              </ArrayField>
            </Card>
          </Grid>
        </Grid>
      )}
      {(!goods || goods?.length === 0) && (
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Box padding="10%" alignItems="center" display="flex" flexDirection="column" gap={5}>
            <Typography variant="h2" color="primary">No Goods Listed</Typography>
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Box>
        </Grid>
      )}
      <Create open={open} closeDialog={closeDialog} />
    </Grid>

  );
};

export default List;
