import React from 'react';

import { SelectField, useRecordContext } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import booleanChoices from '../../../../constants/booleanChoices';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Ägs företaget av en stat, region, kommun eller motsvarande?',
  [languages.EN]: 'Is the company owned by a state, region, municipality or equivalent?',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return <SelectField choices={booleanChoices[language]} record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const StateOwned = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="stateOwned"
      datapoint={conflictFields.STATE_OWNED}
    >
      <ValueField language={language} />
    </Answer>
  );
};

StateOwned.ValueField = ValueField;

export default StateOwned;
