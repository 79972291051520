import reportTypes from './reportTypes';

import EntitiesOverview from '../components/reports/reportTypesForm/create/EntitiesOverview';
import PingOverview from '../components/reports/reportTypesForm/create/PingOverview';
import CaseOverview from '../components/reports/reportTypesForm/create/CaseOverview';
import RiskDistribution from '../components/reports/reportTypesForm/create/RiskDistribution';
import SfsaReport from '../components/reports/reportTypesForm/create/SfsaReport';
import PingTestOverview from '../components/reports/reportTypesForm/create/PingTestOverview';

const reportCreateMap = {
  [reportTypes.PING_TEST_OVERVIEW.id]: PingTestOverview,
  [reportTypes.FIPO.id]: () => {},
  [reportTypes.PING_OVERVIEW.id]: PingOverview,
  [reportTypes.ENTITIES_OVERVIEW.id]: EntitiesOverview,
  [reportTypes.CASE_OVERVIEW.id]: CaseOverview,
  [reportTypes.RISK_DISTRIBUTION.id]: RiskDistribution,
  [reportTypes.SFSA.id]: SfsaReport,
};

export default reportCreateMap;
