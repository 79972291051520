import React from 'react';
import {
  AutocompleteArrayInput,
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';
import { useWatch } from 'react-hook-form';
import { businessKycParams, individualKycParams } from '../../../../constants/kycParams';

import InfoPopover from '../../../layout/InfoPopover';
import NumberInput from '../../../layout/inputFields/NumberInput';
import entityTypes from '../../../../constants/entityTypes';
import SelectInput from '../../../layout/inputFields/SelectInput';

const KycValid = () => {
  const entityTypesValue = useWatch({ name: 'ruleParameters.entityTypes' });

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="label">KYC Form parameters</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            source="ruleParameters.typeOfCheck"
            fullWidth
            validate={required()}
            choices={[
              { id: 'validity', name: 'Validity' },
              { id: 'invalidity', name: 'Invalidity' },
            ]}
            label="Type of validity check"
            popups={[
              <InfoPopover
                key="typeOfCheck"
                title="Type of validity check"
                content="This selection determines the rule logic based on the validity of the KYC form. Choose 'Validity' to trigger the rule when the KYC form is not too old and contains all the required fields. Choose 'Invalidity' to trigger the rule when the KYC form is either outdated or missing required fields."
              />,
            ]}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <AutocompleteArrayInput
            source="ruleParameters.entityTypes"
            fullWidth
            validate={required()}
            choices={[
              { id: entityTypes.INDIVIDUAL, name: entityTypes.INDIVIDUAL },
              { id: entityTypes.BUSINESS, name: entityTypes.BUSINESS },
            ]}
            label="Select entity type(s)"
          />
        </Grid>

        {entityTypesValue?.includes(entityTypes.INDIVIDUAL) && (
          <Grid item xs={12} md={12}>
            <AutocompleteArrayInput
              source="ruleParameters.individualFormFields"
              fullWidth
              choices={Object.values(individualKycParams)}
              label="Select individual field(s)"
            />
          </Grid>
        )}
        {entityTypesValue?.includes(entityTypes.BUSINESS) && (
          <Grid item xs={12} md={12}>
            <AutocompleteArrayInput
              source="ruleParameters.businessFormFields"
              fullWidth
              choices={Object.values(businessKycParams)}
              label="Select business field(s)"
            />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <Typography variant="label">KYC form max age</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <NumberInput
            validate={minValue(0)}
            fullWidth
            source="ruleParameters.kycFormDataMaxAge"
            label="days"
            popups={[<InfoPopover
              key="max age"
              title="Max KYC form age"
              content={
                'Optionally, set a maximum number of days since the creation of the KYC form. If maximum days is reached \n and no override entity parameters was found, the parameters will adapt the default values.'
              }
            />]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KycValid;
