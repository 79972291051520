import React from 'react';
import { useGetOne, useRecordContext } from 'react-admin';
import get from 'lodash/get';

import { EntityTypes } from '../../constants/entityTypes';
import { TransactionMonitoringThresholds } from '../../utilities/schemas/datapoints/transactionMonitoringThresholds';
import conflictFields from '../../constants/conflictFields';
import ConflictStatusIcon from '../conflict/ConflictStatusIcon';

const DatapointStatusField = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: EntityTypes;
  label?: string;
}) => {
  const record = useRecordContext();

  const transactionTypeConfiguration = get(record, 'typeConfiguration');
  const identificationInfo = transactionTypeConfiguration ? {
    transactionTypes: transactionTypeConfiguration,
  } : undefined;

  const { data: expectedTMThresholdDatapoint } = useGetOne<{
    id: string;
    status: 'resolved' | 'conflict' | 'empty';
    selectedValue: TransactionMonitoringThresholds | undefined;
  }>('data-points/status', {
    id: `${conflictFields.TRANSACTION_MONITORING_THRESHOLD}/${entityId}/${entityType}${identificationInfo ? `/${JSON.stringify(identificationInfo)}` : ''}`,
  });

  if (!expectedTMThresholdDatapoint) return null;

  return (
    <ConflictStatusIcon status={expectedTMThresholdDatapoint.status} />
  );
};

export default DatapointStatusField;
