import { ValueOf } from '../utilities/types';

const recommendations = {
  PROCEED: 'proceed',
  REVIEW: 'review',
  BLOCK: 'block',
} as const;

export type Recommendations = ValueOf<typeof recommendations>;

export const recommendationsChoices = [
  { id: recommendations.PROCEED, name: 'Proceed' },
  { id: recommendations.REVIEW, name: 'Review' },
  { id: recommendations.BLOCK, name: 'Block' },
];

export default recommendations;
