import React from 'react';
import { SelectField, useRecordContext } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import booleanChoices from '../../../../constants/booleanChoices';
import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Har företaget en verklig huvudman?',
  [languages.EN]: 'Do you have a beneficial owner?',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return <SelectField record={{ value }} sx={sx} choices={booleanChoices[language]} source="value" emptyText="-" fontWeight="bold" />;
};

const BeneficialOwner = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      datapoint={conflictFields.BENEFICIAL_OWNER}
      source="beneficialOwner"
    >
      <ValueField language={language} />
    </Answer>
  );
};

BeneficialOwner.ValueField = ValueField;

export default BeneficialOwner;
