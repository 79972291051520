import React, { useCallback, useMemo, useState } from 'react';

import {
  required,
  usePermissions,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material';

// import ReorderIcon from '@mui/icons-material/Reorder';
import DoneIcon from '@mui/icons-material//Done';

import { useFormContext } from 'react-hook-form';
import useSelectState from '../../../hooks/useSelectState';

import RulePathList from './RulePathList';
import AutoCompleteInput from '../inputFields/AutoCompleteInput';

import hasAccess from '../../../utilities/hasAccess';

import {
  RequestRules,
  ongoingRules,
  requestRules,
  requestTypesSupported,
  rulePath,
  rulePermissions,
  rulesShortDescription,
} from '../../rules/ruleFeatures';
import subCategories from '../../rules/ruleSubCategories';
import ruleTypes from '../../../constants/ruleTypes';
import { Actions } from '../../../constants/actions';

const categories = [
  { id: 'transaction', name: 'Transaction', type: 'category' },
  { id: 'kyc', name: 'KYC', type: 'category' },
  { id: 'car', name: 'Car', type: 'category' },
  { id: 'test', name: 'Test', type: 'category' },
] as const;

const RuleSelect = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions<Actions[]>();
  const { setValue } = useFormContext();

  const {
    path,
    changePath,
    selectedIndex,
    changeIndex,
  } = useSelectState();

  const [open, setOpen] = useState(false);

  const handleChangeOpenStatus = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setOpen(!open);
  }, [open]);

  const handleClickSelect = useCallback(() => {
    setValue('name', path[2]);
    setOpen(false);
  }, [path, setValue]);

  const ruleList = record?.ruleType === ruleTypes.REQUEST ? requestRules : ongoingRules;

  const permittedRules = useMemo(() => Object.values(ruleList)
    .filter((rule) => (rulePermissions[rule])
      ?.every((action) => hasAccess(permissions, action))), [permissions, ruleList]);

  const filteredRules = useMemo(() => (record?.parentRule?.requestTypes
    ? permittedRules
      .filter((rule) => requestTypesSupported[rule as RequestRules]
        ?.some((requestType) => record?.parentRule?.requestTypes
          ?.includes(requestType.id)))
    : permittedRules), [permittedRules, record?.parentRule?.requestTypes]);

  const permittedCategories = useMemo(
    () => categories.filter((choice) => filteredRules?.some(
      (rule) => rulePath[rule][0].includes(choice.id),
    )),
    [filteredRules],
  );

  const filteredRulesChoices = useMemo(() => filteredRules.map((ruleName) => ({
    id: ruleName,
    name: rulesShortDescription[ruleName],
    type: 'rule' as const,
    path: rulePath[ruleName][1],
  })), [filteredRules]);

  return (
    <>
      <Box>
        <AutoCompleteInput
          source="name"
          fullWidth
          label="Search for rule name... 🔍"
          choices={filteredRulesChoices}
          open={open || undefined}
          validate={required()}
          popups={[
          // <ReorderIcon key="rule list" onClick={handleChangeOpenStatus} />
          ]}
        />
      </Box>
      <Dialog
        open={open}
        onClose={handleChangeOpenStatus}
        maxWidth="lg"
      >
        <DialogContent>
          <Box display="flex">
            <Box marginLeft={1} marginRight={0.5} height={500} width={375} display={record?.ruleType === ruleTypes.ONGOING ? 'none' : 'block'}>
              <RulePathList
                choices={permittedCategories}
                path={path}
                changePath={changePath}
                selectedIndex={selectedIndex}
                changeIndex={changeIndex}
                type="category"
              />
            </Box>
            <Box marginLeft={0.5} marginRight={1} height={500} width={375}>
              <RulePathList
                choices={Object.values(subCategories)}
                path={['kyc', undefined, undefined]}
                changePath={changePath}
                selectedIndex={selectedIndex}
                changeIndex={changeIndex}
                type="subcategory"
              />
            </Box>
            <Box marginLeft={0.5} marginRight={1} height={500} width={375}>
              <RulePathList
                choices={filteredRulesChoices}
                path={path}
                changePath={changePath}
                selectedIndex={selectedIndex}
                changeIndex={changeIndex}
                type="rule"
              />
            </Box>

          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeOpenStatus}>Close</Button>
          <Button
            disabled={!path[2]}
            onClick={handleClickSelect}
            variant="contained"
            color="primary"
            startIcon={<DoneIcon />}
          >
            Select
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default RuleSelect;
