import React, { useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import {
  useDataProvider,
  useRedirect,
  useNotify,
  useSafeSetState,
  Form,
  TextInput,
  required,
  Link,
} from 'react-admin';
import { useParams } from 'react-router-dom';

import AuthLayout from '../AuthLayout';
import InputGroup from '../InputGroup';
import ButtonGroup from '../ButtonGroup';

type ForgetPasswordFormFields = {
  username?: string;
}

const ForgetPasswordForm = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const { alias } = useParams();

  const submit = useCallback(async (data: ForgetPasswordFormFields) => {
    setLoading(true);

    try {
      await dataProvider.create(
        'auth/initiate-forget-password',
        {
          data: {
            email: data.username,
            alias,
          },
        },
      );
      redirect('/login');
      notify('Reset password email has been sent');
    } catch {
      notify('Could not send email', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, [setLoading, dataProvider, alias, redirect, notify]);

  const onClick = useCallback(() => {
    redirect('/login');
  }, [redirect]);

  return (
    <AuthLayout>
      <Form onSubmit={submit}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Box marginTop={6} marginBottom={6}>
          <Typography variant="h6" color="primary" marginTop={6}>
            Account alias:
            {' '}
            {alias}
          </Typography>
          <Link
            to="/login"
            underline="always"
          >
            Back
          </Link>
        </Box>
        <Typography component="h2" variant="body1" align="center">
          Enter your email to receive a reset password link
        </Typography>
        <InputGroup>
          <TextInput
            source="username"
            label="Username"
            disabled={loading}
            validate={[required()]}
            fullWidth
          />
        </InputGroup>
        <ButtonGroup>
          <Button
            variant="contained"
            type="submit"
            color="primary"
          >
            Send email
          </Button>
          <Button
            onClick={onClick}
            color="primary"
          >
            Sign in
          </Button>
        </ButtonGroup>
      </Form>
    </AuthLayout>
  );
};

export default ForgetPasswordForm;
