import React from 'react';

import { Grid } from '@mui/material';

import ParentRule from '../ParentRule';
import Process from '../Process';
import EntitiesToCheck from '../EntitiesToCheck';

const RuleTriggers = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12}>
      <ParentRule />
    </Grid>
    <Process />
    <EntitiesToCheck />
  </Grid>
);
export default RuleTriggers;
