import React from 'react';

import {
  List,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import useRuleType from '../../hooks/useRule';
import useRuleVersion from '../../hooks/useRuleVersion';

import RulesActions from './components/actions';
import RuleDatagrid from './components/RuleDatagrid';
import RuleExpand, { GetList, OpenIconButton } from './RuleExpand';
import TreeView from './components/treeView';

import ruleTypes, { RuleTypes } from '../../constants/ruleTypes';
import ruleViews, { RuleViews } from '../../constants/ruleView';
import { Rule } from '../../utilities/schemas/rules';

import Pagination from '../layout/Pagination';

const RuleListBody = ({
  ruleType,
  view,
  isDraft,
  version,
}: {
  ruleType: RuleTypes
  view: RuleViews
  isDraft: boolean
  version: string
}) => {
  switch (view) {
    case ruleViews.LIST:
      switch (ruleType) {
        case ruleTypes.REQUEST:
          return (
            <RuleDatagrid
              expand={<RuleExpand ruleType={ruleType} version={version} isDraft={isDraft} />}
              isRowExpandable={(row: Rule) => GetList(row.id, row.ruleType, row?.version)}
              ruleType={ruleType}
            >
              <OpenIconButton isDraft={isDraft} ruleType={ruleType} version={version} />
            </RuleDatagrid>
          );
        case ruleTypes.ONGOING:
          return (
            <RuleDatagrid
              expand={<RuleExpand ruleType={ruleType} version={version} isDraft={isDraft} />}
              isRowExpandable={(row: Rule) => GetList(row.id, row.ruleType, row?.version)}
              ruleType={ruleType}
            >
              <OpenIconButton isDraft={isDraft} ruleType={ruleType} version={version} />
            </RuleDatagrid>
          );
        default:
          return null;
      }
    case ruleViews.TREE:
      return (
        <TreeView
          ruleType={ruleType}
          isDraft={isDraft}
          version={version}
        />
      );
    default:
      return null;
  }
};

const RuleList = () => {
  const {
    ruleType,
    changeRuleType,
    view,
    changeView,
  } = useRuleType();

  const { version, changeVersion, isDraft } = useRuleVersion();

  return (
    <List
      perPage={1000}
      pagination={(
        <Pagination
          disableEmptyText
          hidden={view === ruleViews.TREE}
        />
      )}
      filter={{
        ruleType,
        version: isDraft ? null : version,
        ...(view === ruleViews.LIST ? { triggeredBy: 'none' } : {}),
      }}
      empty={false}
      actions={(
        <RulesActions
          view={view}
          ruleType={ruleType}
          changeRuleType={changeRuleType}
          changeView={changeView}
          version={version}
          changeVersion={changeVersion}
          isDraft={isDraft}
        />
      )}
      exporter={false}
      sx={view === ruleViews.TREE ? {
        height: '100%',
        '& .RaList-content': {
          height: '100%',
          marginBottom: 0,
        },
      } : undefined}
    >
      <Card sx={{ margin: 0, height: '100%' }} variant="outlined">
        <RuleListBody ruleType={ruleType} view={view} isDraft={isDraft} version={version} />
      </Card>
    </List>

  );
};

export default RuleList;
