import React from 'react';

import {
  DateInput,
  Loading,
  RaRecord,
  RecordContextProvider,
  required,
  SelectInput,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Grid,
} from '@mui/material';

import { UseFormGetValues, FieldValues, UseFormSetValue } from 'react-hook-form';

import { EntityPartiesAccordion, GoodsAccordion } from '../../accordion';
import Actions from './Actions';

import TextInput from '../../../../../layout/inputFields/TextField';

import { requestTypes } from '../../../../../../constants/requestTypes';
import { transmodeTypesChoices } from '../../../../../../constants/transmodeTypes';
import { requestEntityFromFields, requestEntityToFields } from '../../../../../../constants/requestEntityFields';
import InfoPopover from '../../../../../layout/InfoPopover';
import { ICarPurchase, ITransaction } from '../../../../../request/types';

const getFrom = (request: ITransaction | ICarPurchase | undefined) => {
  if (!request) return undefined;
  if (request.requestType === requestTypes.TRANSACTION) {
    return request.extraInformation.initiator;
  }
  return request.extraInformation.buyer;
};

const getTo = (request: ITransaction | ICarPurchase | undefined) => {
  if (!request) return undefined;
  if (request.requestType === requestTypes.TRANSACTION) {
    return request.extraInformation.recipient;
  }
  return request.extraInformation.seller;
};

const Body = ({
  handleOpenChange,
  setValue,
  getValues,
  isCustom,
  isEditView,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  isCustom: boolean
  isEditView: boolean
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void,
}) => {
  const record = useRecordContext<ITransaction | ICarPurchase>();
  const [fromVariant, setFromVariant] = React.useState<string>('');
  const [toVariant, setToVariant] = React.useState<string>('');
  const extraInfo = record?.extraInformation;
  const from = getFrom(record);
  const to = getTo(record);

  if (!extraInfo && !isCustom && !isEditView) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Actions
          isEditView={isEditView}
          setFromVariant={setFromVariant}
          setToVariant={setToVariant}
          getValues={getValues}
          setValue={setValue}
          handleOpenChange={handleOpenChange}
          isCustom={isCustom}
          fromVariant={fromVariant}
          toVariant={toVariant}
          setRecord={setRecord}
          handleCloseDialog={handleCloseDialog}
        />
      </Grid>
      <RecordContextProvider value={{
        fromVariant,
        toVariant,
        transactionnumber: !isCustom ? record?.id : undefined,
        amount_local: extraInfo?.amount,
        date_transaction: extraInfo?.date,
        teller: from?.ip,
        transaction_location: from?.electronicId,
        comment: extraInfo?.textMessage,
        internal_ref_number: extraInfo?.reference,
        transmode_code: extraInfo?.transactionChannel,
        transmode_comment: extraInfo?.transactionChannelProvider,
      }}
      >
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="transactionnumber"
            label="Transaction Number"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="amount_local"
            label="Amount"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DateInput
            source="date_transaction"
            label="Date"
            validate={required()}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="internal_ref_number"
            label="Reference"
            fullWidth
            popups={[<InfoPopover
              title="Reference"
              content="Refers to the regulation (EU) 2015/847 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL
                of 20 May 2015 on information accompanying transfers of funds and repealing Regulation (EC) No 1781/2006.
                If the transaction is not included in the regulation then state another reference which purpose is to
                trace the transaction, for example a reference for swish or an OCR message."
            />]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput source="teller" label="IP" fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="transaction_location"
            label="Electronic ID"
            fullWidth
            popups={[<InfoPopover
              title="Electronic ID"
              content="For transactions authorized trough BankId, the serial number of the certificate could be used."
            />]}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            choices={transmodeTypesChoices}
            source="transmode_code"
            label="Transaction channel"
            fullWidth
            validate={required()}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="transmode_comment"
            label="Transaction channel provider"
            fullWidth
            popups={[<InfoPopover
              title="Transaction channel provider"
              content="Provider of transaction channel for specific cases if the information is known.
                For `Money transfer` state the name of the organization that provided the money transfer.
                For `Gambling transactions` state the name of the organization that provided the game.
                For `Securities transactions` state the name of the organization that provided the transaction."
            />]}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput
            source="comment"
            label="Text message"
            fullWidth
            popups={[<InfoPopover
              title="Text message"
              content="The text that the sender may have written regarding the transaction."
            />]}
          />
        </Grid>
      </RecordContextProvider>
      <Grid item xs={12} md={12}>
        <EntityPartiesAccordion
          from={from}
          to={to}
          fromLabel={record ? requestEntityFromFields[record.requestType] : undefined}
          toLabel={record ? requestEntityToFields[record.requestType] : undefined}
          setFromVariant={setFromVariant}
          setToVariant={setToVariant}
          fromVariant={fromVariant}
          toVariant={toVariant}
          currency={record?.extraInformation.currency}
        />
      </Grid>
      {(record?.requestType === requestTypes.CAR_PURCHASE || isCustom) && (
        <Grid item xs={12} md={12}>
          <GoodsAccordion />
        </Grid>
      )}
    </Grid>
  );
};

export default Body;
