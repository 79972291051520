import React, { useCallback } from 'react';
import {
  Form,
  RecordContextProvider,
  required,
  TextInput,
  useRefresh,
  useUpdate,
} from 'react-admin';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';
import conflictFieldsAnswerFields from './conflictFieldsAnswerField';
import { DatapointValue, DEFAULT_SX } from './ConflictResolution';
import languages from '../../constants/languages';

const ConflictMarkAsVerified = ({
  datapointValue,
  handleClose,
}: {
  datapointValue: DatapointValue;
  handleClose: () => void;
}) => {
  const refresh = useRefresh();
  const [update] = useUpdate();

  const handleSubmit = useCallback((data: { comment?: string }) => {
    const { comment } = data;
    update(
      'data-points/select-one',
      { id: datapointValue.id, data: { comment } },
      {
        onSuccess: () => {
          handleClose();
          refresh();
        },
      },
    );
  }, [handleClose, datapointValue.id, refresh, update]);

  return (
    <Grid container gap={1}>
      <Grid item xs={12} md={12} mb={3}>
        <Box display="flex" alignItems="center">
          <IconButton size="medium" onClick={handleClose}>
            <ArrowBackIosFilled fontSize="medium" color="primary" />
          </IconButton>
          <Typography color="primary" variant="h5">Back</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
            <Card
              sx={{ m: 0 }}
              variant="outlined"
            >
              <CardContent>
                <Form values={{ comment: '' }} onSubmit={handleSubmit}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Typography color="primary" variant="h5">Mark as verified</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <RecordContextProvider value={{ ...datapointValue, sx: DEFAULT_SX }}>
                        {React.createElement(
                          conflictFieldsAnswerFields[datapointValue.name],
                          { language: languages.EN },
                        )}
                      </RecordContextProvider>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextInput
                        source="comment"
                        validate={required()}
                        fullWidth
                        minRows={3}
                        multiline
                        placeholder="Please explain your decision."
                      />
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" justifyContent="center">
                      <Box display="flex" gap={8}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">Save</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConflictMarkAsVerified;
