import React, { useCallback, useEffect, useState } from 'react';

import { RaRecord, useRecordContext } from 'react-admin';

import {
  Box,
  Button,
} from '@mui/material';

import {
  useFormContext,
  UseFormGetValues,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import { EntityTypes } from '../../../../../../constants/entityTypes';

const Actions = ({
  handleOpenChange,
  setValue,
  getValues,
  isEditView,
  type,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  isEditView: boolean
  type: EntityTypes
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void
}) => {
  const [loading, setLoading] = useState(false);
  const {
    reset, getValues: localGetValues, setValue: localSetValues, formState: { isValid, isDirty },
  } = useFormContext();

  const { report, ids } = getValues()!;
  const request = useRecordContext();

  const handleBack = useCallback(() => {
    reset();
    setRecord!(undefined);
    handleOpenChange();
  }, [handleOpenChange, reset, setRecord]);

  useEffect(() => {
    if (loading) { setTimeout(() => setLoading(() => false), 1000); }
  }, [loading]);

  const handleSave = useCallback(() => {
    localSetValues('type', type);
    if (isEditView) {
      setLoading(() => true);
      setValue('report.activity.goods_services', [localGetValues(),
        ...(report?.activity.goods_services ?? [])
          .filter((
            good: { id: string},
          ) => good.id !== localGetValues().id)]);
    } else {
      setValue('ids', [request?.id, ...(ids ?? [])]);
      setValue('report.activity.goods_services', [localGetValues(), ...(report?.activity?.goods_services ?? [])]);
    }
    handleBack();
    handleCloseDialog();
  }, [
    handleBack,
    type,
    ids,
    isEditView,
    localGetValues,
    request?.id,
    setValue,
    localSetValues,
    report?.activity?.goods_services,
    handleCloseDialog,
  ]);

  const handleRemove = useCallback(() => {
    setValue(
      'report.activity.goods',
      getValues()?.report?.activity?.goods.filter((
        good: {id: string},
      ) => good.id !== request?.id),
    );

    setValue('ids', getValues().ids.filter((id: string) => id !== request?.id));
  }, [getValues, request?.id, setValue]);

  return (
    <Box width="100%" justifyContent={!isEditView ? 'space-between' : 'flex-end'} display="flex" gap={2}>
      {(!isEditView) && (
        <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleBack}>
          Back
        </Button>
      )}
      {(!isEditView) && <Button disabled={!isValid} startIcon={<AddIcon />} onClick={handleSave} variant="contained">Add</Button>}
      {(loading && isEditView) && <Button disabled><CheckIcon color="primary" /></Button>}
      {(isEditView && !loading) && <Button startIcon={<DeleteIcon />} onClick={handleRemove} color="error" variant="outlined">Delete</Button>}
      {(isEditView && !loading) && <Button disabled={!isValid && isDirty} startIcon={<SaveIcon />} onClick={handleSave} variant="outlined">Save</Button>}
    </Box>
  );
};

export default Actions;
