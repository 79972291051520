import React, { useCallback, useState } from 'react';
import { RecordContextProvider, useRecordContext } from 'react-admin';

import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Requests from './Requests';
import DetailedView from './detailedView';

import { StyledTabs, StyledTabWhite } from '../../../../layout/Tab';
import { ICase } from '../../../../../utilities/schemas/case';

const Create = ({ open, closeDialog }: { open: boolean, closeDialog: () => void}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('case');
  const record = useRecordContext<ICase>();

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setEditOpen(false);
  }, []);

  const handleCloseDialog = useCallback(() => {
    closeDialog();
    setEditOpen(false);
  }, [closeDialog]);

  const handleOpenChange = useCallback(() => {
    setEditOpen((prev) => !prev);
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <StyledTabs value={value} onChange={handleChangeTab}>
        <StyledTabWhite value="case" label="Case Requests" />
        <StyledTabWhite value="all" label="All Requests" />
        <StyledTabWhite value="custom" label="Custom" sx={{ borderTopRightRadius: 4 }} />
      </StyledTabs>
      <DialogContent sx={{
        backgroundColor: 'white', borderTopRightRadius: 4, overflowX: 'hidden', minHeight: 725,
      }}
      >
        {value === 'case' && <Requests handleCloseDialog={closeDialog} target="id" editOpen={editOpen} handleOpenChange={handleOpenChange} />}
        {value === 'all' && <Requests handleCloseDialog={closeDialog} editOpen={editOpen} handleOpenChange={handleOpenChange} entities={record?.entities.map(({ id }) => id)} />}
        {value === 'custom' && (
          <RecordContextProvider value={undefined}>
            <DetailedView
              handleCloseDialog={closeDialog}
              isCustom
              handleOpenChange={handleOpenChange}
            />
          </RecordContextProvider>
        )}
      </DialogContent>
      <DialogActions sx={{
        backgroundColor: 'white',
      }}
      >
        <Button startIcon={<CloseIcon />} color="red" onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Create;
