import React, { useCallback } from 'react';

import {
  LinearProgress,
  ListBase,
  RecordContextProvider,
  TextField,
  useGetOne,
  useRedirect,
} from 'react-admin';

import {
  Edge,
  Node,
  useStoreApi,
} from 'reactflow';
import { DateTime } from 'luxon';

import {
  Button,
  Box,
  Drawer,
  Typography,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import LaunchIcon from '@mui/icons-material/Launch';

import Pagination from '../../../layout/Pagination';

import { NationalIdentifierSingleField } from '../../../../customFields/NationalIdentifier';

import { requestListMap } from '../../../../constants/requestFeatures';
import entityTypes from '../../../../constants/entityTypes';
import { requestTypes } from '../../../../constants/requestTypes';
import { EdgeData, NodeData } from './utils';
import Params from '../../Params';

const TextBox = ({ source, label }: {source: string, label: string}) => (
  <Grid item xs={12} md={3}>
    <Box display="flex" flexDirection="column">
      <Typography variant="label">{label}</Typography>
      <TextField source={source} emptyText="-" />
    </Box>
  </Grid>
);

const IndividualBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('individuals', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="customId" label="Custom ID" />
        <TextBox source="firstName" label="First name" />
        <TextBox source="lastName" label="Last name" />
        <Grid item xs={12} md={12}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">National identifiers</Typography>
            <NationalIdentifierSingleField limit={5} underline={false} />
          </Box>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

const AccountBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('account-entities', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="accountName" label="Account name" />
        <TextBox source="bic" label="BIC" />
        <TextBox source="provider" label="Provider" />
        <TextBox source="iban" label="IBAN" />
        <TextBox source="bban" label="BBAN" />
      </Grid>
    </RecordContextProvider>
  );
};

const BusinessBody = ({ id }: {id: string}) => {
  const { data, isLoading } = useGetOne('businesses', { id });

  if (isLoading) return <LinearProgress sx={{ width: '100%' }} />;

  if (!data) return null;

  return (
    <RecordContextProvider value={data}>
      <Grid container spacing={6}>
        <TextBox source="id" label="ID" />
        <TextBox source="customId" label="Custom ID" />
        <TextBox source="name" label="Name" />
        <TextBox source="registrationNumber" label="Registration number" />
        <TextBox source="residence" label="Country of registration" />
      </Grid>
    </RecordContextProvider>
  );
};

const EntityBody = ({ node }: {node: Node}) => {
  if (node.type === entityTypes.INDIVIDUAL) {
    return <IndividualBody id={node.id} />;
  }

  if (node.type === entityTypes.BUSINESS) {
    return <BusinessBody id={node.id} />;
  }

  if (node.type === entityTypes.ACCOUNT) {
    return <AccountBody id={node.id} />;
  }

  return null;
};

const Stat = ({
  value,
  label,
  unit,
}: {
  value?: number;
  label: string;
  unit?: string
}) => {
  if (!value) return null;
  return (
    <Box display="flex" gap={1}>
      <Typography fontWeight="bold">
        {label}
        :
      </Typography>
      <Typography>{value}</Typography>
      {unit && <Typography>{unit}</Typography>}
    </Box>
  );
};

const TransactionListComponent = requestListMap[requestTypes.TRANSACTION];

const InfoPane = ({
  params,
  selectedEdge,
  selectedNode,
}: {
  params: Params,
  selectedEdge?: Edge<EdgeData>
  selectedNode?: Node<NodeData>
}) => {
  const redirect = useRedirect();

  const storeApi = useStoreApi();

  const removeSelection = useCallback(() => {
    const { resetSelectedElements } = storeApi.getState();
    resetSelectedElements();
  }, [storeApi]);

  const handleRedirect = useCallback(() => {
    if (!selectedNode) return;

    if (selectedNode.type === entityTypes.INDIVIDUAL) {
      redirect('edit', 'individuals', selectedNode.id);
    }

    if (selectedNode.type === entityTypes.BUSINESS) {
      redirect('edit', 'businesses', selectedNode.id);
    }
  }, [selectedNode, redirect]);

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={!!selectedEdge || !!selectedNode}
        onClose={removeSelection}
        variant="persistent"
        sx={{
          '& .MuiPaper-root': {
            position: 'absolute',
            height: selectedEdge ? 'calc(40%)' : 'calc(30%)',
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box px={4} py={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={8}>
              {selectedEdge && (
                <Stat
                  label="Volume"
                  value={selectedEdge.data?.volume}
                  unit={params.currency}
                />
              )}
              {selectedNode && (
                <Button
                  onClick={handleRedirect}
                  startIcon={<LaunchIcon />}
                >
                  See Profile
                </Button>
              )}
            </Box>
            <IconButton onClick={removeSelection}><CancelIcon /></IconButton>
          </Box>
          <Divider />
          {selectedEdge && (
            <ListBase
              resource="requests"
              filter={{
                // TODO: Replace this with recipient and initiator queries
                id: selectedEdge.data?.grouped,
                requestType: requestTypes.TRANSACTION,
                'extraInformation.currency': params.currency,
                'custom.from': DateTime.fromISO(params.from).startOf('day').toISO(),
                'custom.to': DateTime.fromISO(params.to).endOf('day').toISO(),
                'extraInformation.transactionTypes': params.transactionTypeConfiguration,
              }}
              perPage={5}
              sort={{ field: 'extraInformation.date', order: 'DESC' }}
            >
              <TransactionListComponent />
              <Pagination disableEmptyText rowsPerPageOptions={[5, 10, 25]} />
            </ListBase>
          )}
          {selectedNode && (
            <Box padding="1%">
              <EntityBody node={selectedNode} />
            </Box>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};

export default InfoPane;
