import ruleNames, { RuleNames } from '../../../constants/ruleNames';

const rulesShortDescription: Record<RuleNames, string> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: 'Additional delivery address',
  [ruleNames.AFFORDABILITY]: 'Affordability',
  [ruleNames.AGE_THRESHOLD]: 'Age threshold',
  [ruleNames.AMOUNT_THRESHOLD]: 'Amount threshold',
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: 'Anomaly detection for average incoming amount during period',
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: 'Anomaly detection for incoming frequency during period',
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: 'Anomaly detection for incoming single amount',
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: 'Anomaly detection for incoming volume sum during period',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: 'Anomaly detection for average outgoing amount during period',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: 'Anomaly detection for outgoing frequency during period',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: 'Anomaly detection for outgoing single amount',
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: 'Anomaly detection for outgoing volume sum during period',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: 'Anomaly detection for average processed amount during period',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: 'Anomaly detection for processing frequency during period',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: 'Anomaly detection for processed single amount',
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: 'Anomaly detection for processed volume sum during period',
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: 'Anomaly detection for average total amount during period',
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: 'Anomaly detection for total frequency during period',
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: 'Anomaly detection for total single amount',
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: 'Anomaly detection for total volume sum during period',
  [ruleNames.ANOMALY_DETECTION]: 'Anomaly detection',
  [ruleNames.BUSINESS_ACTIVE]: 'Business is active',
  [ruleNames.BUSINESS_AUDITOR]: 'Business auditor status',
  [ruleNames.BUSINESS_DATA_CHANGED]: 'Check for modifications in the business data',
  [ruleNames.BUSINESS_EQUITY_SHARE]: 'Business equity ratio',
  [ruleNames.BUSINESS_EQUITY]: 'Business equity',
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: 'Business financial statement comment',
  [ruleNames.BUSINESS_FTAX]: 'Business preliminary tax (F-skatt Sweden)',
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: 'Business payments applications sum',
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: 'Business payments applications',
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: 'Business payments remarks sum',
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: 'Business payments remarks',
  [ruleNames.BUSINESS_PROVIDER_WARNING]: 'Business provider warning list',
  [ruleNames.BUSINESS_SANCTION_LIST]: 'Business on sanction list',
  [ruleNames.BUSINESS_VAT]: 'Business VAT (MOMS Sweden)',
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: 'Car historical owners frequency',
  [ruleNames.CAR_GUARANTOR_RELATED]: 'Guarantor relation',
  [ruleNames.CAR_IN_CIRCULATION]: 'Car in circulation',
  [ruleNames.CAR_VALUATION]: 'Reasonable car valuation',
  [ruleNames.CASH_FLOW]: 'Cash flow',
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: 'Categorization amount threshold',
  [ruleNames.CO_ADDRESS]: 'C/o delivery address',
  [ruleNames.COLLECTOR]: 'Collect fields',
  [ruleNames.CONNECTED_BUYER_SELLER]: 'Buyer and seller are connected',
  [ruleNames.CONNECTED_ENTITY_PEP]: 'Connected entities is PEP',
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: 'Connected entities on sanction list',
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: 'Blocked citizenship countries',
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: 'Blocked residence countries',
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: 'Allowed residence countries',
  [ruleNames.EMPLOYMENT_TYPE]: 'Employment Status',
  [ruleNames.FOREIGN_ADDRESS]: 'Foreign address',
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: 'Incoming categorization frequency',
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: 'Incoming categorization rolling sum',
  [ruleNames.INCOMING_FREQUENCY]: 'Incoming frequency',
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: 'Incoming volume rolling sum',
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: 'Individual have criminal record',
  [ruleNames.INDIVIDUAL_PEP]: 'Individual is PEP',
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: 'Individual on sanction list',
  [ruleNames.IP_COUNTRY_BLACKLIST]: 'IP address country blocklist',
  [ruleNames.KYC_VALID]: 'KYC form validation',
  [ruleNames.LOW_INCOME]: 'Low income',
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: 'Outgoing categorization frequency',
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: 'Outgoing categorization rolling sum',
  [ruleNames.OUTGOING_FREQUENCY]: 'Outgoing frequency',
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: 'Outgoing volume rolling sum',
  [ruleNames.PROCESSING_FREQUENCY]: 'Processing frequency',
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: 'Processing volume rolling sum',
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: 'Related individuals criminal record',
  [ruleNames.SELLER_OWNS_CAR]: 'Seller owns car',
  [ruleNames.SNI_CODE_BLACKLIST]: 'Blocked SNI codes',
  [ruleNames.SOURCE_OF_FUNDS]: 'Source of funds',
  [ruleNames.SOURCE_OF_INCOME]: 'Source of income',
  [ruleNames.SPAR_CHANGED]: 'Check for modifications in the SPAR data',
  [ruleNames.SUSPICIOUS_FUNDS]: 'Suspicious funds',
  [ruleNames.TEST_BLOCK]: 'Test Block',
  [ruleNames.TEST_REVIEW]: 'Test Review',
  [ruleNames.TIME_WINDOW]: 'Time window',
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: 'Total categorization frequency',
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: 'Total categorization rolling sum',
  [ruleNames.TOTAL_FREQUENCY]: 'Total frequency',
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: 'Total volume rolling sum',
  [ruleNames.ZIP_CODE_BLACKLIST]: 'Blocked zip codes',
};

export default rulesShortDescription;
