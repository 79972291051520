import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const roles = {
  CEO: 'ceo',
  BOARD_MEMBER: 'boardMember',
  OTHER: 'other',

} as const;

const roleEnLabels = {
  [roles.CEO]: 'CEO',
  [roles.BOARD_MEMBER]: 'Board Member',
  [roles.OTHER]: 'Other',
} as const;

const roleSvLabels = {
  [roles.CEO]: 'VD',
  [roles.BOARD_MEMBER]: 'Styrelseledamot',
  [roles.OTHER]: 'Annat',
} as const;

const roleLabels = {
  [languages.EN]: roleEnLabels,
  [languages.SV]: roleSvLabels,
} as const;

export const roleChoices = (
  language: Languages,
) => Object.values(roles).map((section) => ({
  id: section,
  name: roleLabels[language][section],
}));

export type Roles = ValueOf<typeof roles>;

export default roles;
