import { createContext } from 'react';
import { AccountDetails } from '../../../utilities/accountDetailsStore';
import { LoginMethods } from '../../../constants/loginMethods';

type LoginContextType = {
  accountDetails: AccountDetails | null,
  loginMethods?: {
    method: LoginMethods,
    parameters?: any
  }[];
  loginMethodsLoading: boolean;
  loginMethodsError: unknown;
  resetAlias: () => void,
  switchLoginMethod: (newLoginMethod: LoginMethods) => void,
  saveAccountAlias: (alias: string, rememberAlias?: boolean) => void,
};
const LoginContext = createContext<LoginContextType | null>(null);

export default LoginContext;
