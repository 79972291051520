import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useRecordContext,
  usePermissions,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import ApiKeyField from './Fields/ApiKeyField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import hasAccess from '../../utilities/hasAccess';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import LinkField from '../layout/LinkField';
import { IAPIKey } from '../../utilities/schemas/apiKey';
import { Actions } from '../../constants/actions';

const Roles = ({ label }: {label: string}) => {
  const record = useRecordContext<IAPIKey>();
  return (
    <ArrayField
      label={label}
      record={{
        permissionRoles: record?.permissionRoles.map((item: string) => ({ name: item, id: item })),
      }}
      source="permissionRoles"
    >
      <SingleFieldList linkType={false}>
        <ChipField source="name" />
      </SingleFieldList>
    </ArrayField>

  );
};

const ApiKeyList = () => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
          {!hasAccess(permissions, 'ApiKeyGet') && <TextField sortable={false} source="id" />}
          {hasAccess(permissions, 'ApiKeyGet') && <LinkField sortable={false} source="id" />}
          <DateField sortable source="createdAt" showTime />
          <TextField sortable={false} source="clientId" label="Client ID" />
          {hasAccess(permissions, 'ApiKeyGetSecret') && (<ApiKeyField label="Client Secret" />)}
          <Roles label="Roles" />
        </Datagrid>
      </Card>
    </List>

  );
};

export default ApiKeyList;
