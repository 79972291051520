import React from 'react';

import {
  useRecordContext,
  TextField,
  useGetOne,
  ReferenceOneField,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import ruleTypes from '../../../../../constants/ruleTypes';
import EntitiesToCheck from './EntitiesToCheck';

const NestedUnder = () => {
  const record = useRecordContext();

  const { data } = useGetOne('rules', { id: record?.triggeredBy }, { enabled: !record?.parentRule && !!record?.triggeredBy });
  const nestedUnder = record?.parentRule ? record?.parentRule?.name : data?.name;
  return <TextField record={{ nestedUnder }} source="nestedUnder" emptyText="None" />;
};

const RuleTriggers = () => {
  const record = useRecordContext();
  const isRequestRule = record?.ruleType === ruleTypes.REQUEST;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label" color="black" fontWeight="bold">Nested under</Typography>
          <NestedUnder />
        </Box>
      </Grid>
      <Grid item xs={12} md={12} container spacing={2}>
        {isRequestRule && (
          <>
            <Grid item xs={12} md={12}>
              <Typography variant="label" fontWeight="bold">Supported request format</Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography fontWeight="bold" color="black">Process</Typography>
              <ReferenceOneField target="_id" link="list" reference="processes" source="process">
                <TextField source="name" />
              </ReferenceOneField>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12}>
          <Typography fontWeight="bold" color="black">Entities to check</Typography>
          <EntitiesToCheck />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RuleTriggers;
