import React, { useCallback, useState } from 'react';
import { SelectInput as RaSelectInput, SelectInputProps } from 'react-admin';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { InputAdornment } from '@mui/material';

const SelectInput = ({ ...props }: SelectInputProps & { popups?: React.ReactElement[]}) => {
  const [open, setOpen] = useState(false);
  const popups = [<ArrowDropDownIcon
    key="dropdown"
    sx={{ transform: !open ? 'rotate(180deg)' : 'none' }}
  />, ...(props.popups! ?? [])];

  const handelFocusChange = useCallback(() => setOpen(!open), [setOpen, open]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const IconComponent = () => (
    <InputAdornment position="end">
      {popups}
    </InputAdornment>
  );

  return (
    <RaSelectInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onFocus={handelFocusChange}
      SelectProps={{
        IconComponent,
      }}
    />
  );
};

export default SelectInput;
