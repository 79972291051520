import React, { useMemo } from 'react';

import {
  TextField,
  ArrayField,
  Datagrid,
  useRedirect,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Divider,
  Button,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import { boldDataGridStyle } from '../constants/style/datagridStyles';

import useRelatedEntities from '../hooks/useRelatedEntities';
import NoResults from '../components/layout/NoResults';
import entityTypes from '../constants/entityTypes';

const RelatedIndividualsList = ({
  setKycTab,
}: {
  setKycTab: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { entities } = useRelatedEntities();

  const individuals = useMemo(
    () => entities
      .filter((individual) => individual.entityType === entityTypes.INDIVIDUAL),
    [entities],
  );

  const redirect = useRedirect();
  const { id } = useParams();

  const handleKycTabChange = () => {
    setKycTab('related-individuals');
    redirect('1', 'businesses', id);
  };

  if (individuals?.length > 0) {
    return (
      <Card variant="outlined">
        <CardHeader
          sx={{
            color: 'black',
            fontWeight: 'bold',
          }}
          titleTypographyProps={{ variant: 'inherit' }}
          title={`Related Individuals: ${individuals.length}`}
          action={
            <Button onClick={handleKycTabChange} color="secondary">See more</Button>
          }
        />
        <Divider />
        <ArrayField record={{ individuals }} source="individuals">
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <TextField source="name" />
            <TextField source="relation" />
          </Datagrid>
        </ArrayField>
      </Card>
    );
  }
  return <NoResults />;
};

export default RelatedIndividualsList;
