import decodeJwt from 'jwt-decode';
import { fetchUtils, addRefreshAuthToAuthProvider } from 'react-admin';
import config from '../config';
import { authTokenStore, lastAuthenticatedActivityStore, refreshTokenStore } from './authTokenStore';
import { Actions } from '../constants/actions';
import errorLogger from './errorLogger';

const httpClient = fetchUtils.fetchJson;

type AuthToken = {
  id?: string;
  username?: string;
  displayName?: string;
  accountName?: string;
  accountId?: string;
  actions?: Actions[];
  iat: number;
  exp: number;
}

type RefreshToken = {
  id?: string;
  accountId?: string;
  iat: number;
  exp: number;
}

type LoginParams = {
  username: string;
  password: string;
  alias: string
}

export const refreshAuth = (() => {
  let ongoingRefresh: Promise<void> | null = null;

  return async () => {
    const authToken = authTokenStore.getToken();
    const refreshToken = refreshTokenStore.getToken();

    if (authToken && refreshToken) {
      const decodedAuthToken = decodeJwt<AuthToken>(authToken);
      const decodedRefreshToken = decodeJwt<RefreshToken>(refreshToken);
      const isAuthExpired = (decodedAuthToken.exp * 1000 - Date.now()) <= 5000;
      const isRefreshExpired = (decodedRefreshToken.exp * 1000 - Date.now()) <= 5000;

      if (isAuthExpired && !isRefreshExpired) {
        if (ongoingRefresh) return ongoingRefresh;

        ongoingRefresh = (async () => {
          try {
            const response = await httpClient(`${config.apiUrl}/auth/refresh-token`, {
              method: 'POST',
              body: JSON.stringify({ refreshToken }),
            });
            const newAuthToken = response.json.token;
            if (newAuthToken) authTokenStore.setToken(newAuthToken);
          } catch (e) {
            // Continue even if the refresh failed
            errorLogger(e);
          } finally {
            ongoingRefresh = null;
          }
        })();
        return ongoingRefresh;
      }
    }
    return undefined;
  };
})();

const baseAuthProvider = {
  login: async (login: LoginParams) => {
    const response = await httpClient(`${config.apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify(login),
    });
    const auth = response.json;
    if (!auth) throw new Error('Network error');
    authTokenStore.setToken(auth.token);
    if (auth.refreshToken) refreshTokenStore.setToken(auth.refreshToken);
  },
  checkError: async (error: { message: string, status: number, body: unknown }) => {
    const { status } = error;
    const checkToken = authTokenStore.getToken();
    if (status === 401 && checkToken) {
      authTokenStore.removeToken();
      refreshTokenStore.removeToken();
      lastAuthenticatedActivityStore.removeToken();
      throw new Error('Authentication error');
    }
  },
  checkAuth: async () => {
    const checkToken = authTokenStore.getToken();
    // eslint-disable-next-line prefer-promise-reject-errors
    if (!checkToken) return Promise.reject({ message: false });
    return Promise.resolve();
  },
  logout: async () => {
    authTokenStore.removeToken();
    refreshTokenStore.removeToken();
    lastAuthenticatedActivityStore.removeToken();
  },
  getIdentity: async () => {
    const token = authTokenStore.getToken();
    if (!token) throw new Error('Authentication error');
    const {
      id,
      username,
      displayName,
      accountName,
      accountId,
    } = decodeJwt<AuthToken>(token);
    if (!id) throw new Error('Authentication error');
    return {
      id,
      displayName,
      username,
      avatar: undefined,
      accountName,
      accountId,
    };
  },
  getPermissions: async () => {
    const token = authTokenStore.getToken();
    if (!token) return [];
    const { actions } = decodeJwt<AuthToken>(token);
    return actions ?? [];
  },
  handleCallback: async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    if (error) return { redirectTo: `/login?error=${error}` };

    const token = urlParams.get('token');
    if (!token || !token.length) return { redirectTo: '/login?error=Invalid token' };

    authTokenStore.setToken(token);
    const refreshToken = urlParams.get('refreshToken');
    if (refreshToken && refreshToken.length) refreshTokenStore.setToken(refreshToken);

    return undefined;
  },
};

const authProvider = addRefreshAuthToAuthProvider(baseAuthProvider, refreshAuth);

export default authProvider;
