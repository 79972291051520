import React, { useCallback, useState } from 'react';
import {
  Identifier,
  List,
  usePermissions,
  useRecordContext,
} from 'react-admin';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import SelectInputButton from '../layout/inputFields/SelectInputButton';
import FilterActions from '../filters/FilterActions';
import Pagination from '../layout/Pagination';
import entityTypes, { EntityTypes, resourceFromEntityTypes } from '../../constants/entityTypes';
import { IndividualFilters, IndividualListBody } from '../individual/IndividualList';
import { BusinessFilters, BusinessListBody } from '../business/BusinessList';
import { CarFilters, CarListBody } from '../car/CarList';
import { AccountEntityFilters, AccountEntityListBody } from '../accountEntity/AccountEntityList';
import actions, { Actions } from '../../constants/actions';
import hasAccess from '../../utilities/hasAccess';

const LIST_BODY = {
  [entityTypes.INDIVIDUAL]: IndividualListBody,
  [entityTypes.BUSINESS]: BusinessListBody,
  [entityTypes.CAR]: CarListBody,
  [entityTypes.ACCOUNT]: AccountEntityListBody,
} as const;

const LIST_FILTERS = {
  [entityTypes.INDIVIDUAL]: IndividualFilters,
  [entityTypes.BUSINESS]: BusinessFilters,
  [entityTypes.CAR]: CarFilters,
  [entityTypes.ACCOUNT]: AccountEntityFilters,
} as const;

const ALLOWED_LIST_PERMISSION = {
  [entityTypes.ACCOUNT]: actions.ACCOUNT_ENTITY_LIST,
  [entityTypes.BUSINESS]: actions.BUSINESS_LIST,
  [entityTypes.CAR]: actions.CAR_LIST,
  [entityTypes.INDIVIDUAL]: actions.INDIVIDUAL_LIST,
} as const;

const SelectButtonMultiple = ({
  currentValue,
  onSelect,
  entityType,
}: {
  currentValue: Identifier[],
  onSelect: (selectedData: { id: Identifier, type: EntityTypes }, wasSelected: boolean) => void,
  entityType: EntityTypes,
}) => {
  const record = useRecordContext();
  const { id } = (record ?? {});
  const contains = !!id && currentValue.includes(id);

  const handleClick = useCallback(() => {
    if (!id) return;
    onSelect({ id, type: entityType }, contains);
  }, [contains, entityType, id, onSelect]);

  if (contains) {
    return (
      <IconButton
        color="error"
        onClick={handleClick}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    );
  }

  return (
    <IconButton
      color="primary"
      onClick={handleClick}
    >
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

const SelectButtonSingle = ({
  currentValue,
  entityType,
  onSelect,
}: {
  currentValue: Identifier,
  entityType: EntityTypes,
  onSelect: (selectedData: { id: Identifier, type: EntityTypes }) => void,
}) => {
  const record = useRecordContext();
  const { id } = (record ?? {});

  const handleClick = useCallback(() => {
    if (!id) return;
    onSelect({ id, type: entityType });
  }, [entityType, id, onSelect]);

  return (
    <Button
      onClick={handleClick}
      color="primary"
      disabled={currentValue === id}
      variant="contained"
      size="small"
    >
      Select
    </Button>
  );
};

type EntityDetails = { id: Identifier, type: EntityTypes };

const EntitySelectionDialog = ({
  label,
  open,
  multiple,
  handleClose,
  handleSelect,
  currentValue,
}: {
  label: React.ReactNode;
  open: boolean;
  multiple?: false;
  handleClose: () => void;
  handleSelect: (selectedData: EntityDetails) => void;
  currentValue: Identifier
} | {
  label: React.ReactNode;
  open: boolean;
  multiple: true;
  handleClose: () => void;
  handleSelect: (selectedData: EntityDetails, wasSelected: boolean) => void;
  currentValue: Identifier[]
}) => {
  const [entityType, setEntityType] = useState<EntityTypes>(entityTypes.INDIVIDUAL);
  const { permissions } = usePermissions<Actions[]>();
  const ListBody = LIST_BODY[entityType];
  const resource = resourceFromEntityTypes[entityType];
  const filters = LIST_FILTERS[entityType];

  return (
    <Dialog scroll="body" open={open} maxWidth="xl" onClose={handleClose} fullWidth>
      <Card style={{ margin: 0 }}>
        <CardHeader
          title={label}
          action={(
            <Box display="flex" gap={2}>
              <SelectInputButton
                label="Entity Type"
                value={entityType}
                setValue={setEntityType}
                choices={
                  Object.values(entityTypes)
                    .filter((type) => hasAccess(permissions, ALLOWED_LIST_PERMISSION[type]))
                    .map((type) => ({ id: type, name: type }))
                }
              />
            </Box>
          )}
        />
        <CardContent>
          <List
            storeKey={false}
            disableSyncWithLocation
            actions={<FilterActions />}
            resource={resource}
            filters={filters}
            empty={false}
            pagination={<Pagination disableEmptyText />}
          >
            <Card sx={{ margin: 0 }} variant="outlined">
              <ListBody>
                {multiple ? (
                  <SelectButtonMultiple
                    onSelect={handleSelect}
                    entityType={entityType}
                    currentValue={currentValue}
                  />
                ) : (
                  <SelectButtonSingle
                    onSelect={handleSelect}
                    entityType={entityType}
                    currentValue={currentValue}
                  />
                )}
              </ListBody>
            </Card>
          </List>
        </CardContent>
      </Card>
      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={handleClose} variant="text">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntitySelectionDialog;
