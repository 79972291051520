import { ValueOf } from '../utilities/types';

const questions = {
  Q_ACTIVITIES: 'q-activities',
  Q_ADDRESS: 'q-address',
  Q_ASSIGNED_OWNERS: 'q-assignedOwners',
  Q_BENEFICIAL_OWNER: 'q-beneficialOwner',
  Q_BENEFICIAL_OWNERS: 'q-beneficialOwners',
  Q_CITIZENSHIP: 'q-citizenship',
  Q_CITY: 'q-city',
  Q_COMPANY_NAME: 'q-companyName',
  Q_CONTROL_TYPE: 'q-controlType',
  Q_CUSTOM: 'q-custom',
  Q_DATE_OF_BIRTH: 'q-dateOfBirth',
  Q_EMAIL: 'q-email',
  Q_EMPLOYMENT_STATUS: 'q-employmentStatus',
  Q_FAMILY_CONTROL: 'q-familyControl',
  Q_FILE_UPLOAD: 'q-file-upload',
  Q_FIRST_NAME: 'q-firstName',
  Q_FORECASTED_TURNOVER: 'q-forecastedTurnover',
  Q_INDUSTRY: 'q-industry',
  Q_IS_FINANCIAL_INSTITUTION: 'q-isFinancialInstitution',
  Q_LAST_NAME: 'q-lastName',
  Q_LEGAL_FORM: 'q-legalForm',
  Q_LISTED_FINANCIAL_MARKET: 'q-listedFinancialMarket',
  Q_OPERATION_START_DATE: 'q-operationStartDate',
  Q_ORG_NUMBER: 'q-orgNumber', // to be changed
  Q_OWNER_STRUCTURE: 'q-ownerStructure',
  Q_PEP: 'q-pep',
  Q_PERSONAL_NUMBER: 'q-personalNumber',
  Q_PHONE_NUMBER: 'q-phoneNumber',
  Q_POSTAL_CODE: 'q-postalCode',
  Q_PREVIOUS_TURNOVER: 'q-previousTurnover',
  Q_PRODUCTS: 'q-products',
  Q_PURPOSE: 'q-purpose',
  Q_RESIDENCE: 'q-residence',
  Q_ROLE: 'q-role',
  Q_SALARY: 'q-salary',
  Q_SIGNATORIES: 'q-signatories',
  Q_SIGNER: 'q-signer',
  Q_EXPECTED_TRANSACTION_BEHAVIOUR: 'q-expectedTransactionBehaviour',
  Q_SOURCE_OF_FUNDS: 'q-sourceOfFunds',
  Q_SOURCE_OF_WEALTH_AMOUNT: 'q-sourceOfWealthAmount',
  Q_SOURCE_OF_WEALTH: 'q-sourceOfWealth',
  Q_STATE_OWNED: 'q-stateOwned',
  Q_TAX_RESIDENCY: 'q-taxResidency',
  Q_TIN: 'q-tin',
  Q_WEBSITE: 'q-website',
} as const;

export type Questions = ValueOf<typeof questions>;

export default questions;
