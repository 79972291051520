import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import Answer from '../Answer';

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Custom = ({ extraParameters }: {
  extraParameters?: {
    label?: string,
    id?: string,
  }
}) => {
  if (!extraParameters?.label) return null;
  if (!extraParameters.id) return null;

  return (
    <Answer
      label={extraParameters.label}
      source={`custom.${extraParameters.id}`}
      datapoint={null}
    >
      <ValueField />
    </Answer>
  );
};

Custom.ValueField = ValueField;

export default Custom;
