import React from 'react';

import { Theme, useTheme } from '@mui/material';

import { ChipField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

const ColoredChipField = ({
  label,
  small,
  source,
  colorSource,
} : {
  label?: string,
  small?: boolean,
  sortable?: boolean,
  source?: string;
  colorSource?: string;
}) => {
  const record = useRecordContext();
  const { palette } = useTheme<Theme>();

  if (!record) return null;

  const color = get(record, colorSource ?? 'color');

  return (
    <ChipField
      sx={{
        ...(small ? { fontSize: 9.5, height: 15 } : {}),
        backgroundColor: color,
        '& .MuiChip-label': {
          color: palette.getContrastText(color),
          ...(small ? { paddingLeft: 2, paddingRight: 2 } : {}),
        },
      }}
      label={label}
      source={source ?? 'name'}
    />
  );
};

export default ColoredChipField;
