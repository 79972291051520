const unitToDenominator = {
  day: 1 / 30,
  month: 1,
  year: 12,
} as const;

const getCoefficientFromPeriod = (unit?: 'day' | 'month' | 'year', period?: number) => {
  if (!unit && !period) return 1;
  return (unitToDenominator[unit ?? 'month']) / (period ?? 1);
};

export default getCoefficientFromPeriod;
