import React, { useCallback, useRef } from 'react';

import {
  Edit,
  TextField,
  DateField,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  ReferenceArrayField,
  ArrayField,
  FileInput,
  useRedirect,
  Toolbar,
  SaveButton,
  SingleFieldList,
  usePermissions,
  useUnselectAll,
  SelectField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import {
  Box,
  Card,
  Grid,
  Typography,
  IconButton,
  CardHeader,
  CardContent,
  Divider,
  Button,
  styled,
  SxProps,
} from '@mui/material';

import { blue } from '@mui/material/colors';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { useFormState } from 'react-hook-form';
import { Route, Routes } from 'react-router-dom';

import { capitalize } from 'lodash';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import EnhancedFileField from '../../customFields/EnhancedFileField';
import UpdateCaseStatusField from '../../customFields/UpdateCaseStatusField';
import DownloadReport from '../../customFields/DownloadReport';

import hasAccess from '../../utilities/hasAccess';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import StatusField from '../../customFields/StatusField';
import ReferenceScoreField from '../../customFields/ReferenceScoreField';
import ReferenceRiskClassField from '../../customFields/ReferenceRiskClassField';

import CaseRequestList from './components/CaseRequestList';
import CaseBreadcrumb from './CaseBreadcrumb';
import CasePingList from './components/CasePingList';
import AddEntities from './components/AddEntities';
import DeleteEntities from './components/DeleteEntities';

import Pagination from '../layout/Pagination';
import useCustomEditView from '../../hooks/useCustomEditView';
import AddPing from './components/AddPing';
import CommentList from '../comment/CommentList';
import actions, { Actions } from '../../constants/actions';
import useValidateId from '../../hooks/useValidateId';
import CaseAssign from './components/CaseAssign';
import resourceTypes from '../../constants/resourceTypes';
import casePriority from '../../constants/casePriority';
import UpdateCasePriorityField from '../../customFields/UpdateCasePriorityField';
import CaseEscalate from './components/CaseEscalate';
import ReferenceManyEditField from '../../customFields/ReferenceManyEditField';

const customEditSx = (isCustomView: boolean): SxProps => (
  isCustomView
    ? { '& .MuiPaper-root': { backgroundColor: 'transparent', boxShadow: 'none' } }
    : {}
);
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blue[500]),
  backgroundColor: blue[500],
  '&:hover': {
    backgroundColor: blue[700],
  },
  '&:disabled': {
    backgroundColor: blue[300],
    color: theme.palette.getContrastText(blue[300]),
  },
  marginLeft: 4,
}));

const CustomToolbar = () => {
  const { isDirty } = useFormState();
  if (!isDirty) {
    return null;
  }
  return (
    <Toolbar>
      <SaveButton />
    </Toolbar>
  );
};

const CaseEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const unselectAll = useUnselectAll('cases');
  const redirect = useRedirect();
  const fipoListRef = useRef(null);
  const { isCustomView } = useCustomEditView();

  const validatedId = useValidateId();
  const handleGoAmlClick = useCallback(() => {
    if (validatedId.isValid) {
      redirect(`/fipo/${validatedId.id}`);
    }
  }, [validatedId, redirect]);
  if (!validatedId.isValid) return null;

  return (
    <Edit mutationMode="pessimistic" redirect={false} sx={customEditSx(isCustomView)}>
      <CaseBreadcrumb />

      {/* Custom nested routes */}
      <Routes>
        <Route
          path="/add-ping"
          element={
            <AddPing id={validatedId.id} />
          }
        />
      </Routes>

      <TabbedForm toolbar={<CustomToolbar />} sx={isCustomView ? { display: 'none' } : {}}>
        <FormTab label="Information">
          <Grid container>
            <Grid container spacing={6}>
              <Grid item xs={12} md={12} display="flex" justifyContent="center">
                <Typography variant="largeTitle" color="primary">Case Detailed View</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box display="flex" justifyContent="space-between">
                  <IconButton onClick={() => { redirect('list', '/cases'); unselectAll(); }} size="small">
                    <ArrowBackIosIcon color="primary" fontSize="inherit" />
                  </IconButton>
                  <Box display="flex">
                    {hasAccess(permissions, actions.CASE_UPDATE_STATUS)
                     && <UpdateCaseStatusField />}
                    {hasAccess(permissions, actions.CASE_UPDATE)
                    && <UpdateCasePriorityField />}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Card variant="outlined">
                  <CardHeader title="Case Details" />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">ID</Typography>
                          <TextField source="id" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Status</Typography>
                          <StatusField source="status" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Created at</Typography>
                          <DateField source="createdAt" showTime />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Closed at</Typography>
                          <DateField source="closedAt" showTime emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="smallTitle">Assignee</Typography>
                        <CaseAssign label="Assignee" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="smallTitle">Escalated To</Typography>
                        <CaseEscalate label="EscalatedTo" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ReferenceManyEditField
                          withColor
                          showEdit={hasAccess(permissions, actions.CASE_UPDATE)}
                          source="tags"
                          reference="tags"
                          label="Tags"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Priority</Typography>
                          <SelectField
                            source="priority"
                            choices={
                              Object.values(casePriority)
                                .map((priority) => ({
                                  name: capitalize(priority),
                                  id: priority,
                                }))
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Merged Into</Typography>
                          <ReferenceField source="mergedInto" reference="cases" emptyText="-">
                            <TextField source="id" />
                          </ReferenceField>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Merged from:</Typography>
                          <ReferenceManyField reference="cases" target="mergedInto">
                            <SingleFieldList empty={'-' as any}>
                              <ChipField source="id" />
                            </SingleFieldList>
                          </ReferenceManyField>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={6}>
                <CommentList source="comments" action={actions.CASE_ADD_COMMENT} />
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader title="Entities" action={<AddEntities />} />
                  <Divider />
                  <ArrayField source="entities" perPage={10}>
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField source="type" />
                      <ReferenceEntityField source="" label="Display name" />
                      <ReferenceScoreField label="Risk score" />
                      <ReferenceRiskClassField label="Risk class" />
                      <DeleteEntities />
                    </Datagrid>
                    <Pagination disableEmptyText />
                  </ArrayField>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <CasePingList />
              </Grid>
              <CaseRequestList />
              {hasAccess(permissions, 'ReportDownload') && (
                <Grid item xs={12} md={12} ref={fipoListRef}>
                  <Card variant="outlined">
                    <CardHeader
                      action={hasAccess(permissions, 'ReportCreate') && <ColorButton startIcon={<PostAddIcon />} variant="contained" onClick={handleGoAmlClick}>Report to Police (goAML)</ColorButton>}
                      title="FIPO reports"
                    />
                    <Divider />
                    <ReferenceArrayField sort={{ field: 'createdAt', order: 'DESC' }} reference="reports" source="fipoReports">
                      <Datagrid
                        sx={boldDataGridStyle}
                        bulkActionButtons={false}
                        empty={(
                          <Box padding={2}>
                            <Typography align="center">No FIPO reports available</Typography>
                          </Box>
                        )}
                      >
                        <TextField sortable={false} source="id" />
                        <DateField sortable={false} source="createdAt" showTime />
                        <TextField sortable={false} source="fileName" />
                        <DownloadReport />
                      </Datagrid>
                    </ReferenceArrayField>
                  </Card>
                </Grid>
              )}
              {hasAccess(permissions, 'CaseDownload') && (
                <Grid item xs={12} md={12}>
                  <Card variant="outlined">
                    <CardHeader title="Related files" />
                    <Divider />
                    <CardContent>
                      <FileInput source="files" label="Related files" accept="application/pdf" multiple>
                        <EnhancedFileField source="id" title="name" target="_blank" parentId={validatedId.id} resource="cases" />
                      </FileInput>
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </FormTab>
        {hasAccess(permissions, 'AuditTrackList') && (
          <FormTab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    filter={{ 'resource.type': resourceTypes.CASE }}
                    sort={{ field: 'date', order: 'DESC' }}
                    perPage={10}
                    pagination={<Pagination disableEmptyText />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default CaseEdit;
