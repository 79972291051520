import React, { useEffect, useState } from 'react';

import {
  Loading,
  RaRecord,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Grid,
} from '@mui/material';

import { UseFormGetValues, FieldValues, UseFormSetValue } from 'react-hook-form';

import Actions from './Actions';
import EntityForm from './forms';

import entityTypes, { EntityTypes } from '../../../../../../constants/entityTypes';

const sourceMap = {
  [entityTypes.INDIVIDUAL]: 't_person',
  [entityTypes.BUSINESS]: 't_entity',
  [entityTypes.CAR]: '',
  [entityTypes.ACCOUNT]: '',
};

const Body = ({
  handleOpenChange,
  setValue,
  getValues,
  isEditView,
  type,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  isEditView: boolean
  type: EntityTypes
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void
}) => {
  const [, setRec] = useState(false);
  const record = useRecordContext();

  useEffect(() => {
    if (record?.id) {
      setRec(() => true);
    }
  }, [record?.id]);

  if (!record && !isEditView) {
    return (
      <Box width="100%" height="100%" sx={{ transform: 'translate(0%, 100%)' }}>
        <Loading />
      </Box>
    );
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Actions
          type={type}
          isEditView={isEditView}
          getValues={getValues}
          setValue={setValue}
          handleOpenChange={handleOpenChange}
          setRecord={setRecord}
          handleCloseDialog={handleCloseDialog}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <EntityForm entityVariant={sourceMap[type]} />
      </Grid>
    </Grid>
  );
};

export default Body;
