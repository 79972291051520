import React, { useCallback, useState } from 'react';

import {
  useCreate,
  useGetOne,
  useListContext,
  useNotify,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

import CloudQueueIcon from '@mui/icons-material/CloudQueue';

import useDialogStatus from '../../../../hooks/useDialogStatus';

const Release = ({
  disabled,
  version,
}: {
  disabled: boolean,
  version: string
}) => {
  const { refetch } = useListContext();
  const [description, setDescription] = useState('');
  const { open, openDialog, closeDialog } = useDialogStatus();

  const { data: ruleVersion, isLoading } = useGetOne('rule-version', { id: version }, { enabled: !disabled });

  const notify = useNotify();
  const [release] = useCreate();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(() => event.target.value);
  };

  const handleRelease = useCallback(() => {
    release(
      'rules/release',
      { data: { description, ruleVersion: version } },
      {
        onSuccess: () => {
          notify('Rule tree was released', { type: 'success' });
          closeDialog();
          refetch();
        },
        onError: () => {
          notify('Rule tree could not be released', { type: 'error' });
        },
      },
    );
  }, [release, description, version, notify, closeDialog, refetch]);

  if (isLoading) return null;

  if (!ruleVersion?.isTestVersion) return null;

  if (ruleVersion?.isActive) return null;

  return (
    <>
      <Button
        color="primary"
        startIcon={<CloudQueueIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Release
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Go live with test rule tree </DialogTitle>
        <DialogContent>
          <Typography>
            The current test version will be released as a live version.
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <TextField
              multiline
              maxRows={4}
              value={description}
              fullWidth
              sx={{ marginTop: '1%' }}
              label="Description of release"
              onChange={handleChange}
            />

            <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
              <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
              <Button onClick={handleRelease} variant="contained">Release</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Release;
