import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const countryConnections = {
  NO_CONNECTION: 'noConnection',
  STAYING_MORE_THAN_180_DAYS: 'stayingMoreThan180Days',
  HAVE_INVESTMENTS_OR_BUSINESS: 'haveInvestmentsOrBusiness',
  NATIONALLY_REGISTERED: 'nationallyRegistered',
} as const;

export const countryConnectionEnLabels = {
  [countryConnections.NO_CONNECTION]: 'I have no connection to the country',
  [countryConnections.STAYING_MORE_THAN_180_DAYS]: 'I stay in the country more than 180 days a year',
  [countryConnections.HAVE_INVESTMENTS_OR_BUSINESS]: 'I have investments and/or business operations in the country',
  [countryConnections.NATIONALLY_REGISTERED]: 'Registered in the country',
} as const;

const countryConnectionSvLabels = {
  [countryConnections.NO_CONNECTION]: 'Jag har ingen anknytning till landet',
  [countryConnections.STAYING_MORE_THAN_180_DAYS]: 'Jag vistas i landet mer än 180 dagar per år',
  [countryConnections.HAVE_INVESTMENTS_OR_BUSINESS]: 'Jag har investeringar och/eller affärsverksamhet i landet',
  [countryConnections.NATIONALLY_REGISTERED]: 'Folkbokförd i landet',
} as const;

const countryConnectionLabels = {
  [languages.EN]: countryConnectionEnLabels,
  [languages.SV]: countryConnectionSvLabels,
} as const;

export const countryConnectionChoices = (
  language: Languages,
) => Object.values(countryConnections).map((section) => ({
  id: section,
  name: countryConnectionLabels[language][section],
}));

export type CountryConnections = ValueOf<typeof countryConnections>;

export default countryConnections;
