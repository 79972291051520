import React from 'react';

import {
  TextField,
  ArrayField,
  Datagrid,
  useRecordContext,
  EditButton,
  ReferenceField,
  RaRecord,
  DateField,
  RecordContextProvider,
} from 'react-admin';

import {
  Card,
  CardHeader,
  Grid,
  Divider,
  Typography,
  Box,
} from '@mui/material';

import LaunchIcon from '@mui/icons-material/Launch';

import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';
import NoResults from '../../../../layout/NoResults';

import ColoredScoreField from '../../../../../customFields/ColoredScoreField';
import DateFieldCheck from '../../../../../customFields/DateFieldCheck';
import PurchaseButton from '../../../../layout/button/PurchaseButton';
import actions from '../../../../../constants/actions';

const Expand = () => {
  const record = useRecordContext();
  const resource = record?.entity?.type === 'Business' ? 'businesses' : 'individuals';
  return (
    <Grid container spacing={3}>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">Address:</Typography>
          <TextField emptyText="-" source="adress" />
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">Postal number:</Typography>
          <TextField emptyText="-" source="postnr" />
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">Post office:</Typography>
          <TextField emptyText="-" source="postAnst" />
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">Risk score:</Typography>
          <ReferenceField reference={resource} source="entity.id" link={false}>
            <ColoredScoreField source="riskScore" />
          </ReferenceField>
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">KYC Onboarding:</Typography>
          <ReferenceField reference={resource} source="entity.id" link={false}>
            <DateFieldCheck source="kycOnboarding" />
          </ReferenceField>
        </Box>
      </Grid>
      <Grid item xs={2} md={2}>
        <Box>
          <Typography variant="smallTitle">KYC Latests Request</Typography>
          <ReferenceField reference={resource} source="entity.id" link={false}>
            <DateFieldCheck source="kycLatestRequest" />
          </ReferenceField>
        </Box>
      </Grid>
    </Grid>
  );
};

const SeeProfileButton = () => {
  const record = useRecordContext();
  const resource = record?.entity?.type === 'Business' ? 'businesses' : 'individuals';

  return <EditButton resource={resource} record={record?.entity} icon={<LaunchIcon />} label="See profile" />;
};

const PreviousOwnerList = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: RaRecord,
  handlePurchase: () => void,
  isLoading: boolean,
}) => {
  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader
              title="Previous owners"
              subheader={(
                <Box display="flex" alignItems="baseline">
                  <Typography>Latest update:</Typography>
                  <DateField
                    sx={{ marginLeft: 1, fontWeight: 'bold' }}
                    source="createdAt"
                    emptyText="-"
                    showTime
                  />
                </Box>
              )}
              action={(
                <PurchaseButton
                  loading={isLoading}
                  label="Get Previous Owners"
                  onClick={handlePurchase}
                  action={actions.CAR_CREATE_PREVIOUS_OWNERS_DATA}
                />
              )}
            />
            <Divider />
            <ArrayField source="tidigareAgareLista">
              <Datagrid
                sx={boldDataGridStyle}
                bulkActionButtons={false}
                empty={<NoResults variant="h6" />}
                expand={<Expand />}
                expandSingle
              >
                <TextField source="namn" emptyText="-" label="Name" />
                <TextField source="fbnr" emptyText="-" label="National identification number" />
                <TextField source="datforv" emptyText="-" label="Acquisition date" />
                <SeeProfileButton />
              </Datagrid>
            </ArrayField>
          </Card>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default PreviousOwnerList;
