import React from 'react';

import {
  Box,
  Badge,
  Grid,
  Avatar,
  Typography,
  styled,
} from '@mui/material';
import { useGetIdentity } from 'react-admin';

const Footer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.sidebar.footer.background,
  padding: `${theme.spacing(2.75)} ${theme.spacing(4)}`,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.sidebar.footer.color,
}));

const FooterSubText = styled(Typography)(({ theme }) => ({
  color: theme.sidebar.footer.color,
  fontSize: '0.7rem',
  display: 'block',
  padding: '1px',
}));

const FooterBadge = styled(Badge)(({ theme }) => ({
  marginRight: theme.spacing(1),
  '& span': {
    backgroundColor: theme.sidebar.footer.online.background,
    border: `1.5px solid ${theme.palette.common.white}`,
    height: '12px',
    width: '12px',
    borderRadius: '50%',
  },
}));

const SidebarFooter = () => {
  const { identity } = useGetIdentity();

  const isLoaded = identity;

  return (
    <Footer>
      <Grid container spacing={2}>
        <Grid item>
          <FooterBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            {isLoaded && <Avatar alt={identity.displayName} src={identity.avatar} />}
          </FooterBadge>
        </Grid>
        <Grid item>
          {isLoaded && (
            <>
              <FooterText variant="body2">{identity.displayName}</FooterText>
              <FooterSubText variant="caption">{identity.accountName}</FooterSubText>
            </>
          )}
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
