import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';

export default ({ steps }: {steps: string[]}) => {
  const { path } = useParams();

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={steps.findIndex((step) => step === path)} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel sx={{ fontWeight: 'bold' }}>{capitalize(label.split('-').join(' '))}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
