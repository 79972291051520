import React, { useCallback, useMemo, useState } from 'react';

import {
  useResourceContext,
} from 'react-admin';

import { DateTime } from 'luxon';

import { useParams } from 'react-router-dom';

import SubNavigation from '../layout/SubNavigation';
import { StyledTabs, StyledTab } from '../layout/Tab';

import { ValueOf } from '../../utilities/types';

import Params from './Params';

import DailySum from './tabs/DailySum';
import DetailedView from './tabs/DetailedView';
import { entityTypesFromResource } from '../../constants/entityTypes';

const tabs = {
  DAILY_SUM: 'dailySum',
  DETAILED_VIEW: 'detailedView',
} as const;

type TabValues = ValueOf<typeof tabs>;

const Transaction = () => {
  const resource = useResourceContext();
  const { id } = useParams();
  const [tab, setTab] = useState<TabValues>(tabs.DAILY_SUM);

  const currentDate = DateTime.now();

  const [params, setParams] = useState<Params>({
    from: currentDate.minus({ years: 1 }).toFormat('yyyy-MM-dd'),
    to: currentDate.toFormat('yyyy-MM-dd'),
    currency: 'SEK',
  });

  const filter = useMemo(() => ({
    entityId: id,
    entityType: entityTypesFromResource[resource],
    from: params.from,
    currency: params.currency,
    to: params.to,
    labels: params.labels,
  }), [id, params, resource]);

  const handleTabChange = useCallback((
    event: React.SyntheticEvent,
    newValue: ValueOf<typeof tabs>,
  ) => {
    setTab(newValue);
  }, []);

  return (
    <>
      <SubNavigation>
        <StyledTabs value={tab} onChange={handleTabChange}>
          <StyledTab label="Daily Sum" value={tabs.DAILY_SUM} />
          <StyledTab label="Detailed view" value={tabs.DETAILED_VIEW} />
        </StyledTabs>
      </SubNavigation>
      {tab === tabs.DAILY_SUM
      && <DailySum filter={filter} setParams={setParams} params={params} />}
      {tab === tabs.DETAILED_VIEW
      && (
        <DetailedView
          setParams={setParams}
          params={params}
          filter={filter}
        />
      )}
    </>
  );
};

export default Transaction;
