import React from 'react';

import {
  useGetOne,
  TextField,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

import { useWatch, useFormState, useFormContext } from 'react-hook-form';
import { isNumber } from 'lodash';

const RiskClassInput = ({ source }: { source: string }) => {
  const { getFieldState } = useFormContext();
  const score = useWatch({ name: source });
  const { isValidating } = useFormState();
  const { error } = getFieldState(source);

  const isScoreValid = !isValidating && !error && isNumber(score);
  const { data } = useGetOne('risk-classes/from-score', { id: score }, { enabled: isScoreValid });

  if (!data) return <Typography>None</Typography>;

  return (
    <Box>
      <TextField record={data} source="name" />
    </Box>
  );
};

export default RiskClassInput;
