import React from 'react';

import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import { Typography } from '@mui/material';

import businessResolver from '../../utilities/validators/orgNumber';

import cleaner from '../../utilities/dataProvider/cleaner';
import countries from '../../constants/countries';

const ErrorMessage = () => {
  const { formState: { errors } } = useFormContext();
  const globalError = errors[''];
  return globalError && typeof globalError.message === 'string'
    ? <Typography color="error" marginBottom={4}>{globalError.message}</Typography> : null;
};

const BusinessCreate = () => (
  <Create transform={cleaner}>
    <SimpleForm resolver={businessResolver}>
      <ErrorMessage />
      <TextInput source="customId" label="Custom id" />
      <TextInput source="name" label="Registered name" />
      <TextInput source="registrationNumber" />
      <SelectInput source="residence" choices={countries} label="Registration Country" />
      <ReferenceArrayInput label="Groups" source="groups" reference="groups">
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="email" />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Create>
);

export default BusinessCreate;
