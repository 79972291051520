import React, { useState, useCallback } from 'react';
import {
  Form,
  TextInput,
  required,
  useNotify,
  useRefresh,
  useResourceContext,
  useUpdate,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import AddCommentIcon from '@mui/icons-material/AddComment';
import { useParams } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

const CreateCommentField = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const resource = useResourceContext();
  const { id } = useParams();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [commentCreate] = useUpdate();

  const handleComment = useCallback(({ comment }: FieldValues) => {
    setOpen(false);
    commentCreate(
      resource,
      { id: `${id}/comment`, data: { comment } },
      {
        onSuccess: () => {
          setOpen(false);
          refresh();
        },
        onError: () => {
          notify('Could not create comment', { type: 'error' });
        },
      },
    );
  }, [commentCreate, id, notify, refresh, resource]);

  return (
    <Box display="flex">
      <Box display="flex">
        <Button
          startIcon={<AddCommentIcon color="secondary" />}
          onClick={handleOpen}
        >
          Add Comment
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <Form values={{ comment: '' }} onSubmit={handleComment}>
          <DialogTitle>Create comment</DialogTitle>
          <DialogContent>
            <TextInput
              source="comment"
              multiline
              margin="dense"
              validate={required()}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained">
              Comment
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </Box>
  );
};

export default CreateCommentField;
