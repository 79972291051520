import React from 'react';

import { SelectField, TextField, useRecordContext } from 'react-admin';

import { get } from 'lodash';
import { getName } from 'i18n-iso-countries';
import { Grid, Box, Typography } from '@mui/material';
import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { countryChoices } from '../../../../constants/countries';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Land',
  [languages.EN]: 'Country',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;
  const text = value?.map((v: string) => getName(v, language)).join(', ');

  return <TextField record={{ value: text }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Residence = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="residence"
      datapoint={conflictFields.RESIDENCE}
    >
      <ValueField language={language} />
    </Answer>
  );
};

Residence.ValueField = ValueField;

const Nested = ({ source }: { source?: string}) => {
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{CONTENT[language]}</Typography>
        <SelectField
          choices={countryChoices(language)}
          source={source ? `${source}.0` : 'residence.0'}
          emptyText="-"
          sx={sx}
        />
      </Box>
    </Grid>
  );
};

Residence.Nested = Nested;

export default Residence;
