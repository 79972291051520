import React, { useCallback, useState } from 'react';

import {
  useCreate,
  useListContext,
  useNotify,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

import PublishIcon from '@mui/icons-material/Publish';

import useDialogStatus from '../../../../hooks/useDialogStatus';

import { RuleTypes } from '../../../../constants/ruleTypes';

const Publish = ({ disabled, ruleType } : { disabled: boolean, ruleType: RuleTypes }) => {
  const { refetch } = useListContext();
  const notify = useNotify();
  const [isTestVersion, setIsTestVersion] = useState(false);
  const [description, setDescription] = useState('');
  const { open, openDialog, closeDialog } = useDialogStatus();
  const [publish] = useCreate();

  const handleChangeIsTestVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTestVersion(event.target.value as unknown as boolean);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(() => event.target.value);
  };

  const handleClick = useCallback(() => {
    publish(
      'rules/publish',
      { data: { description, ruleType, isTestVersion } },
      {
        onSuccess: () => {
          notify('Rule draft was published', { type: 'success' });
          closeDialog();
          refetch();
        },
        onError: () => {
          notify('Rule draft could not be published', { type: 'error' });
        },
      },
    );
  }, [publish, description, ruleType, isTestVersion, notify, closeDialog, refetch]);

  return (
    <>
      <Button
        color="primary"
        startIcon={<PublishIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Publish
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Publish Rule Tree Draft</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={4}>
            <Box>
              <Typography>Should the rule tree be published as a test version?</Typography>
              <FormControl>
                <RadioGroup
                  value={isTestVersion}
                  onChange={handleChangeIsTestVersion}
                  row
                >
                  <FormControlLabel value control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
            <TextField
              multiline
              maxRows={4}
              value={description}
              fullWidth
              sx={{ marginTop: '1%' }}
              label="Description of version"
              onChange={handleChange}
            />
            <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
              <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
              <Button onClick={handleClick} variant="contained">Confirm</Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Publish;
