import React from 'react';

import {
  Box,
  Typography,
} from '@mui/material';

import { DateField, useRecordContext } from 'react-admin';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

const DateFieldCheck = ({
  source,
}: {
  source: string;
}) => {
  const record = useRecordContext();
  if (!record) return null;

  if (record[source]) {
    return (
      <Box display="flex" alignItems="center">
        <CheckCircleIcon fontSize="small" color="secondary" />
        <DateField source={source} />
      </Box>
    );
  }
  return (
    <Box display="flex" alignItems="center">
      <NotInterestedIcon color="error" />
      <Typography>None</Typography>
    </Box>
  );
};

export default DateFieldCheck;
