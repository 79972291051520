import React, { useState } from 'react';
import {
  useRecordContext,
  useDataProvider,
  useNotify,
  TextField,
} from 'react-admin';
import { Button } from '@mui/material';
import { IAPIKey } from '../../../utilities/schemas/apiKey';

const ApiKeyField = ({ label }: { label?: string }) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({});
  const record = useRecordContext<IAPIKey>();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onClick = () => {
    dataProvider.getOne(`api-keys/${record?.id}`, { id: 'client-secret' })
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        notify('Could not fetch client secret key', { type: 'error' });
      });

    setVisible(true);
  };
  if (visible) return <TextField label={label} record={data} source="clientSecret" />;
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
    >
      See Client Secret Key
    </Button>
  );
};

export default ApiKeyField;
