import React, { ReactNode } from 'react';
import loginMethods, { LoginMethods } from '../../constants/loginMethods';
import CredentialsPage from './login/credentials/CredentialsPage';
import SSOPage from './login/sso/SSOPage';

export const mapLoginForm: Record<LoginMethods, ReactNode> = {
  [loginMethods.credentials]: <CredentialsPage />,
  [loginMethods.sso]: <SSOPage />,
};

export const mapSwitchLabel: Record<LoginMethods, string> = {
  [loginMethods.credentials]: 'Switch to password login',
  [loginMethods.sso]: 'Switch to SSO login',
};
