import React, { useCallback, useState } from 'react';

import {
  RaRecord,
  useRecordContext,
} from 'react-admin';

import {
  IconButton,
} from '@mui/material';

import { useFormContext } from 'react-hook-form';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveIcon from '@mui/icons-material/Remove';
import { IRequest } from '../../../../request/types';

const CustomButton = ({
  handleOpenChange,
  setRecord,
}: {
  handleOpenChange: () => void,
  setRecord: React.Dispatch<React.SetStateAction<RaRecord | undefined>>,
}) => {
  const [hover, setHover] = useState<string | undefined>();
  const { getValues, setValue } = useFormContext<{
    ids?: string[];
    report?: {
      transactions?: {
        transactionnumber?: string;
      }[]
    }
  }>();
  const request = useRecordContext<IRequest>();

  const handleRemove = useCallback(() => {
    setValue(
      'report.transactions',
      getValues()?.report?.transactions
        ?.filter((transaction) => transaction.transactionnumber !== request?.id),
    );

    setValue('ids', (getValues().ids ?? []).filter((id: string) => id !== request?.id));
  }, [getValues, request?.id, setValue]);

  const handleAdd = useCallback(() => {
    if (!request) return;
    handleOpenChange();
    setRecord(request);
  }, [handleOpenChange, request, setRecord]);

  if (!request) return null;

  if ((getValues()?.ids ?? []).includes(request?.id)) {
    return (
      <IconButton
        onMouseOver={() => setHover(request?.id as string)}
        onMouseLeave={() => setHover(undefined)}
        onClick={hover ? handleRemove : undefined}
      >
        {hover !== request?.id && <CheckCircleOutlineIcon color="secondary" />}
        {hover === request?.id && <RemoveIcon color="error" />}
      </IconButton>
    );
  }
  return (
    <IconButton onClick={handleAdd}>
      <AddCircleOutlineIcon />
    </IconButton>
  );
};

export default CustomButton;
