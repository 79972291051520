import React from 'react';

import {
  Box,
  Grid,
  Typography,
  Fade,
} from '@mui/material';

import PurchaseButton from '../../../../layout/button/PurchaseButton';
import VehicleInspection from './VehicleInspection';
import actions from '../../../../../constants/actions';
import useEntityData from '../../../../../hooks/useEntityData';

type CarVehicleInspection = {
  id: string;
  createdAt: string;
  entityId: string;
  regnr?: string;
  skyltnr?: string;
  fabrikat?: string;
  fordonsslag?: string;
  farg?: string;
  chassi?: string;
  armodell?: string;
  motornr?: string;
  modellnr?: string;
  gruppkod?: string;
  senastgodk0?: string;
  senastgodk?: string;
  kontrollbesGallerTom?: string;
  forelagg?: string;
  senastutfall?: string;
  korforbud?: string;
  anledning?: string;
  inrapportor?: string;
  inrapportorext?: string;
  lampbes1?: string;
  lampbes2?: string;
  senastbskt?: string;
  bsktgrupp?: string;
  stationnr?: string;
  station?: string;
  vagmatare?: string;
  hctomgang?: string;
  cokvave?: string;
  co2500?: string;
  lambda?: string;
  retardation?: string;
  bromsvf?: string;
  bromshf?: string;
  bromsvb?: string;
  bromshb?: string;
  buller?: string;
  respit?: string;
  orsak?: string;
  brister?: string[];
  fordonsar?: string;
  kforbdatum?: string;
  fortsattning?: boolean;
  sidref?: string;
  err?: string
}

const VehicleInspectionWrapper = () => {
  const { data, handlePurchase, isLoading } = useEntityData<CarVehicleInspection>({ source: 'vehicle-inspection', getAction: actions.CAR_CREATE_VEHICLE_INSPECTION_DATA });

  if (data) {
    return (
      <Grid container spacing={6}>
        <VehicleInspection data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
      </Grid>
    );
  }
  return (
    <Fade in={!isLoading}>
      <Box margin={5} display="flex" alignItems="center" width="100%" flexDirection="column">
        <Typography variant="smallTitle">No Data Available</Typography>
        <PurchaseButton action={actions.CAR_CREATE_VEHICLE_INSPECTION_DATA} loading={isLoading} label="Get Vehicle Inspection Data" empty onClick={handlePurchase} />
      </Box>
    </Fade>
  );
};

export default VehicleInspectionWrapper;
