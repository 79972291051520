import React, { useCallback, useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
  SelectField,
} from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';

import { capitalize } from 'lodash';
import useDialogStatus from '../hooks/useDialogStatus';

import casePriority, { CasePriority } from '../constants/casePriority';

type CaseDoc = {
  id: string;
  priority: CasePriority;
};

const UpdateCasePriorityField = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<CaseDoc>();
  const { id, priority } = record || {};
  const [newPriority, setNewPriority] = useState<CaseDoc['priority']>(record?.priority || casePriority.NORMAL);

  const { open, openDialog, closeDialog } = useDialogStatus();

  const [closed] = useUpdate();

  const onPriorityChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setNewPriority(e.currentTarget.value as CaseDoc['priority']);
  }, []);

  const handleSubmit = useCallback(() => {
    closed(
      'cases',
      { id: `${id}/priority`, data: { priority: newPriority } },
      {
        onSuccess: () => {
          notify('Case priority have been updated');
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Could not update case priority', { type: 'error' });
        },
      },
    );
  }, [closeDialog, closed, id, newPriority, notify, refresh]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle fontWeight="bold">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="bold">Update Priority</Typography>
            <IconButton onClick={closeDialog}>
              <Cancel />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{
          display: 'flex',
          justifyContent: 'start',
          paddingX: '18%',
        }}
        >
          <Box display="flex" gap={5} flexDirection="column">
            <Box display="flex" flexDirection="column">
              <FormLabel focused={false}>Current priority</FormLabel>
              <SelectField
                source="priority"
                choices={
                  Object.values(casePriority)
                    .map((item) => ({
                      name: capitalize(item),
                      id: item,
                    }))
                }
              />
            </Box>
            <FormControl>
              <FormLabel focused={false}>Change to:</FormLabel>
              <RadioGroup
                name="position"
                onChange={onPriorityChange}
                value={newPriority}

              >
                <FormControlLabel
                  value={casePriority.LOW}
                  control={(
                    <Radio />
                  )}
                  label="Low"
                  sx={{
                    display: priority === casePriority.LOW ? 'none' : 'flex',

                  }}
                />
                <FormControlLabel
                  value={casePriority.NORMAL}
                  control={(
                    <Radio />
                  )}
                  label="Normal"
                  sx={{
                    display: priority === casePriority.NORMAL ? 'none' : 'flex',
                  }}
                />
                <FormControlLabel
                  value={casePriority.HIGH}
                  control={(
                    <Radio />
                  )}
                  label="High"
                  sx={{
                    display: priority === casePriority.HIGH ? 'none' : 'flex',

                  }}
                />
                <FormControlLabel
                  value={casePriority.URGENT}
                  control={(
                    <Radio />
                  )}
                  label="Urgent"
                  sx={{
                    display: priority === casePriority.URGENT ? 'none' : 'flex',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Box display="flex">
        <Box display="flex" marginRight={2}>
          <Button
            variant="contained"
            onClick={openDialog}
          >
            Update Priority
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UpdateCasePriorityField;
