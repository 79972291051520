import React from 'react';
import { Box, Typography } from '@mui/material';

import { FieldStatus } from '../../constants/fieldStatus';
import ConflictStatusIcon from './ConflictStatusIcon';

const ConflictResolutionTitle = ({
  status,
  label,
  children,
}: {
  status: FieldStatus;
  label: string;
  children?: React.ReactNode | false;
}) => (
  <Box display="flex" width="100%" gap={4} alignItems="center">
    <ConflictStatusIcon status={status} />
    <Box display="flex" flexDirection="column">
      <Typography variant="label">{label}</Typography>
      {children}
    </Box>
  </Box>
);

export default ConflictResolutionTitle;
