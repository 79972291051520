import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const TagBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record ? record.name : '',
    path: `/tags/${record?.id}`,
  });

  return null;
};

export default TagBreadcrumb;
