import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  RecordContextProvider,
  useRecordContext,
  useStore,
} from 'react-admin';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { isEqual } from 'lodash';
import FilterListIcon from '@mui/icons-material/FilterList';

import useDialogStatus from '../../hooks/useDialogStatus';
import { TransactionTypeConfiguration } from './AddTransactionTypeDialogButton';
import TransactionTypeConfigurationField from '../../customFields/TransactionTypeConfigurationField';
import DatapointStatusField from './DatapointStatusField';
import { EntityTypes } from '../../constants/entityTypes';
import { ConflictResolutionBody } from '../conflict/ConflictResolution';
import conflictFields from '../../constants/conflictFields';
import { TransactionMonitoringThresholds } from '../../utilities/schemas/datapoints/transactionMonitoringThresholds';

const OverrideExpectedBehaviourButton = ({
  typeConfiguration,
  overriddenExpectedBehaviours,
  setOverriddenExpectedBehaviours,
}: {
  typeConfiguration: TransactionTypeConfiguration | undefined;
  overriddenExpectedBehaviours: Map<string | undefined, TransactionMonitoringThresholds | undefined>
  setOverriddenExpectedBehaviours: React.Dispatch<
  React.SetStateAction<Map<string | undefined, TransactionMonitoringThresholds | undefined>>>,
}) => {
  const record = useRecordContext();

  const typeConfigurationKey = typeConfiguration
    ? JSON.stringify(typeConfiguration) : undefined;

  const currentOverridenValue = overriddenExpectedBehaviours.get(typeConfigurationKey);
  const isCurrentlyCompared = isEqual(currentOverridenValue, record?.value);

  const startComparing = useCallback(() => {
    if (!record?.value) return;

    setOverriddenExpectedBehaviours((currentOverride) => {
      const newOverride = new Map(currentOverride);
      newOverride.set(typeConfigurationKey, record.value);
      return newOverride;
    });
  }, [record?.value, setOverriddenExpectedBehaviours, typeConfigurationKey]);

  const stopComparing = useCallback(() => {
    setOverriddenExpectedBehaviours((currentOverride) => {
      const newOverride = new Map(currentOverride);
      newOverride.set(typeConfigurationKey, undefined);
      return newOverride;
    });
  }, [setOverriddenExpectedBehaviours, typeConfigurationKey]);

  return (
    <Button
      variant={isCurrentlyCompared ? 'contained' : 'outlined'}
      label="Compare in table"
      onClick={isCurrentlyCompared ? stopComparing : startComparing}
      size="medium"
    />
  );
};

const ExpectedBehaviourFilter = ({
  entityId,
  entityType,
}: {
  entityId: string;
  entityType: EntityTypes;
}) => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = useCallback((_event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  }, []);

  const [
    typeConfigurations,
  ] = useStore<(TransactionTypeConfiguration | undefined)[]>('transactionTable', [undefined]);

  const [
    overriddenExpectedBehaviours,
    setOverriddenExpectedBehaviours,
  ] = useStore(`transactionOverriddenExpectedBehaviours-${entityId}`, new Map<string | undefined, TransactionMonitoringThresholds | undefined>());

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      setOverriddenExpectedBehaviours(new Map());
    };
  }, [setOverriddenExpectedBehaviours]);

  const currentTypeConfiguration = typeConfigurations[tabIndex];

  return (
    <>
      <Button label="Filter expected behavior" onClick={openDialog} startIcon={<FilterListIcon />} />
      <Dialog open={open} onClose={closeDialog} maxWidth="xl" fullWidth>
        <DialogTitle color="primary">
          Filter expected behavior
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {typeConfigurations.map((typeConfiguration) => (
              <Tab
                key={JSON.stringify(typeConfiguration) ?? 'undefined'}
                label={(
                  <RecordContextProvider value={{ typeConfiguration }}>
                    <Box display="flex" gap={2} alignItems="center">
                      <TransactionTypeConfigurationField source="typeConfiguration" />
                      <DatapointStatusField entityId={entityId} entityType={entityType} />
                    </Box>
                  </RecordContextProvider>
                )}
              />
            ))}
          </Tabs>
          <Divider />
          <Box paddingTop={8}>
            <ConflictResolutionBody
              name={conflictFields.TRANSACTION_MONITORING_THRESHOLD}
              entityId={entityId}
              entityType={entityType}
              info={currentTypeConfiguration ? {
                transactionTypes: currentTypeConfiguration,
              } : undefined}
              customValueActions={(
                <OverrideExpectedBehaviourButton
                  overriddenExpectedBehaviours={overriddenExpectedBehaviours}
                  setOverriddenExpectedBehaviours={setOverriddenExpectedBehaviours}
                  typeConfiguration={currentTypeConfiguration}
                />
              )}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ExpectedBehaviourFilter;
