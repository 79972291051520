import { ValueOf } from '../utilities/types';

const sanctionTypes = {
  COUNTER_NARCOTICS: 'counter-narcotics',
  HOSTILE_ACTIVITIES: 'hostile-activities',
  HUMAN_RIGHTS: 'human-rights',
  NON_PROLIFERATION: 'non-proliferation',
  TERRITORIAL_VIOLATION: 'territorial-violation',
  TERRORISM: 'terrorism',
  UNKNOWN: 'unknown',
} as const;

export const sanctionTypesLabels = {
  [sanctionTypes.COUNTER_NARCOTICS]: 'Counter narcotics',
  [sanctionTypes.HOSTILE_ACTIVITIES]: 'Hostile ctivities',
  [sanctionTypes.HUMAN_RIGHTS]: 'Human rights',
  [sanctionTypes.NON_PROLIFERATION]: 'Non-proliferation',
  [sanctionTypes.TERRITORIAL_VIOLATION]: 'Territorial violation',
  [sanctionTypes.TERRORISM]: 'Terrorism',
  [sanctionTypes.UNKNOWN]: 'Unknown',
} as const;

export type SanctionTypes = ValueOf<typeof sanctionTypes>;

export default sanctionTypes;
