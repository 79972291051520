import React from 'react';
import {
  Create, SimpleForm, SelectArrayInput, required,
} from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { permissionRolesChoices } from '../../constants/permissionRoles';

const ApiKeyCreate = () => (
  <Create>
    <SimpleForm>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography>
            Permission Roles
          </Typography>
        </Grid>
        <Grid item xs={4} md={4}>
          <SelectArrayInput
            source="permissionRoles"
            fullWidth
            validate={required()}
            choices={permissionRolesChoices}
            label="Permission Roles"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default ApiKeyCreate;
