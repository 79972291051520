import React from 'react';

import {
  Button,
  Card,
  CardHeader,
  Dialog,
} from '@mui/material';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useRecordContext,
} from 'react-admin';

import HistoryIcon from '@mui/icons-material/History';

import useDialogStatus from '../../hooks/useDialogStatus';

import { IComment } from './IComments';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

const CommentHistory = ({
  label,
}: {
  label?: string
}) => {
  const { open, closeDialog, openDialog } = useDialogStatus();
  const record = useRecordContext<IComment>();

  if (!record?.editions?.length) return null;

  const history = [
    record,
    ...record.editions,
  ];

  return (

    <>
      <Button
        aria-label={label}
        onClick={openDialog}
        startIcon={<HistoryIcon fontSize="inherit" />}
        size="small"
        sx={{
          color: 'grey',
          padding: 0,
          fontSize: 'inherit',
          '.MuiButton-startIcon': {
            marginRight: '2px',
          },
        }}
      >
        History
      </Button>
      <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
      >
        <Card
          sx={{
            p: 0,
            m: 0,
            border: 'none',
          }}
          variant="outlined"
        >
          <CardHeader
            title="Previous versions of comment"
          />
          <RecordContextProvider value={{ history }}>
            <ArrayField source="history">
              <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                <TextField emptyText="-" source="message" label="Comment" />
                <DateField showTime source="date" />
                <ReferenceField label="Member" source="member" reference="members" link="show">
                  <FunctionField render={(member: { firstName: string, lastName: string }) => `${member.firstName} ${member.lastName}`} />
                </ReferenceField>
              </Datagrid>
            </ArrayField>
          </RecordContextProvider>
        </Card>
      </Dialog>
    </>

  );
};

export default CommentHistory;
