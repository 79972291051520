import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  SimpleFormIterator,
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

import { sniCodesChoices } from '../../../../constants/sniCodes';

const SOURCE = 'ruleParameters.sniCodes';

const SniCodeBlocklist = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Blocked SNI codes</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <ArrayInput source={SOURCE} label={false}>
          <SimpleFormIterator
            fullWidth
            sx={{
              '& .RaSimpleFormIterator-line': {
                borderBottom: 'none',
                paddingY: 2,
                '& .RaSimpleFormIterator-inline': {
                  rowGap: '1em',
                },
              },
            }}
          >
            <AutocompleteInput
              choices={sniCodesChoices}
              fullWidth
              label="SNI code"
              source=""
              helperText={false}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Data update frequency</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          fullWidth
          source="ruleParameters.updateFreq"
          label="Days"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default SniCodeBlocklist;
