import React, { useCallback } from 'react';
import {
  useRecordContext,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import errorLogger from '../utilities/errorLogger';

const EnhancedFileField = ({
  source,
  title,
  target,
  parentId,
  resource,
}: {
  source: string;
  title: string;
  target?: string;
  parentId: string;
  resource: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);
  const label = get(record, title);
  const dataProvider = useDataProvider();

  const notify = useNotify();

  const onClick = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dataProvider.getOne(`${resource}/${parentId}/file`, { id: value })
      .then(({ data }) => {
        window.open(data.downloadUrl, target);
      })
      .catch((error) => {
        errorLogger(error);
        notify('Could not download the file', { type: 'error' });
      });
  }, [value, parentId, dataProvider, resource, target, notify]);

  if (record?.rawFile) {
    return <Link href={value} target={target}>{label}</Link>;
  }

  return (
    <Link href={`/${resource}/${parentId}/file/${value}`} target={target} onClick={onClick}>
      {label}
    </Link>
  );
};

EnhancedFileField.propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  target: PropTypes.string,
};

EnhancedFileField.defaultProps = {
  target: undefined,
};

export default EnhancedFileField;
