import React from 'react';

import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const AgeThreshold = () => (
  <Grid item xs={12} md={12}>
    <Grid container gap={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Upper Age Limit</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          fullWidth
          defaultValue={100}
          source="ruleParameters.upperAgeLimit"
          label="Years"
          validate={[required(), minValue(1)]}
          popups={[
            <InfoPopover key="upperLimit" title="Upper Age threshold" content="The upper threshold of an entity. Note that if the age of the entity is older than the threshold, the rule will be triggered." />,
          ]}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="label">Lower Age Limit</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          fullWidth
          defaultValue={0}
          source="ruleParameters.lowerAgeLimit"
          label="Years"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="loweLimit" title="Lower Age threshold" content="The lower threshold of an entity. Note that if the age of the entity is younger than the threshold, the rule will be triggered." />,
          ]}
        />
      </Grid>

    </Grid>
  </Grid>
);

export default AgeThreshold;
