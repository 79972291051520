import React from 'react';

import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';

import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  RecordContextProvider,
  ReferenceField,
  TextField,
  useGetOne,
  useRecordContext,
} from 'react-admin';

import { isArray, isPlainObject } from 'lodash';

import { ConflictFields } from '../../constants/conflictFields';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { ViewJSONDialog } from '../../customFields/ViewJSON';
import { EntityTypes } from '../../constants/entityTypes';

const ValueField = ({ label }: {label: string}) => {
  const record = useRecordContext();

  const valueIsComplex = (isArray(record?.value) && record?.value?.every(isPlainObject))
  || isPlainObject(record?.value);

  if (!valueIsComplex) return <Typography>{record?.value ?? ''}</Typography>;

  return <ViewJSONDialog title={label} source="value" />;
};

const ConflictResolutionHistory = ({
  entityId,
  entityType,
  handleClose,
  name,
  identificationInfo,
}: {
  entityId: string,
  entityType: EntityTypes,
  handleClose: () => void,
  name: ConflictFields,
  identificationInfo?: {},
}) => {
  const urlParamsId = identificationInfo ? `${name}/${entityType}/${entityId}/${identificationInfo}` : `${name}/${entityType}/${entityId}`;
  const { data } = useGetOne('resolution-history', { id: urlParamsId });

  return (
    <Grid container gap={1}>
      <Grid item xs={12} md={12} mb={3}>
        <Box display="flex" alignItems="center">
          <IconButton size="medium" onClick={handleClose}>
            <ArrowBackIosFilled fontSize="medium" color="primary" />
          </IconButton>
          <Typography color="primary" variant="h5">Back</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container>
          <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
            <Card
              variant="outlined"
              sx={{ m: 0 }}
            >
              <CardContent>
                <Typography variant="h5" color="primary">Resolution History</Typography>
                <RecordContextProvider value={data}>
                  <Card variant="outlined">
                    <ArrayField source="history">
                      <Datagrid
                        sx={boldDataGridStyle}
                        bulkActionButtons={false}
                      >
                        <DateField source="createdAt" showTime label="Timestamp" emptyText="-" />
                        <ValueField label="Value" />
                        <TextField source="comment" emptyText="-" />
                        <ReferenceField label="User" source="createdBy" reference="members" emptyText="System">
                          <FunctionField render={(member: {firstName: string, lastName: string}) => `${member.firstName} ${member.lastName}`} />
                        </ReferenceField>
                      </Datagrid>
                    </ArrayField>
                  </Card>
                </RecordContextProvider>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConflictResolutionHistory;
