import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const UpdateFreqOnly = () => (
  <Grid item xs={12} md={12}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Data update frequency</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          source="ruleParameters.updateFreq"
          label="Days"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>

    </Grid>
  </Grid>
);

export default UpdateFreqOnly;
