import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  SelectField,
} from 'react-admin';

import { Card } from '@mui/material';

import reportTypes from '../../constants/reportTypes';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import DownloadReport from '../../customFields/DownloadReport';

import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';

const ReportList = () => (
  <List
    queryOptions={{ refetchInterval: 5000 }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <Datagrid
        sx={boldDataGridStyle}
        bulkActionButtons={false}
        empty={<NoResults variant="h6" />}
      >
        <DateField sortable showTime source="createdAt" />
        <TextField sortable={false} source="fileName" />
        <SelectField sortable={false} choices={Object.values(reportTypes)} source="type" />
        <DownloadReport sortable={false} label="Status" />
      </Datagrid>
    </Card>
  </List>
);

export default ReportList;
