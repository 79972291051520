import React, { useMemo } from 'react';
import { SelectField, useRecordContext } from 'react-admin';

import { get, isEmpty } from 'lodash';
import { Box, Grid, Typography } from '@mui/material';
import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { countryChoices } from '../../../../constants/countries';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Skatterättslig hemvist',
  [languages.EN]: 'Tax residency ',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value') as string[] | undefined;
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" gap={0}>
        {value?.map((item, i) => (
          <React.Fragment key={item}>
            <SelectField
              sx={sx}
              record={{ item }}
              source="item"
              choices={countryChoices(language)}
              fontWeight="bold"
            />
            {i + 1 !== value.length && <Typography fontWeight="bold" mr={1}>,</Typography>}
          </React.Fragment>
        ))}
        {isEmpty(value) && <Typography>-</Typography>}
      </Box>
    </Box>
  );
};

const TaxResidency = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source={source ?? 'taxResidency'}
      datapoint={conflictFields.TAX_RESIDENCY}
    >
      <ValueField language={language} />
    </Answer>
  );
};

TaxResidency.ValueField = ValueField;

const Nested = ({ source }: { source?: string}) => {
  const record = useRecordContext();
  const sx = get(record, 'sx', {});

  const language = record?.language as Languages;

  const taxResidency = useMemo(
    () => get(record, source ?? 'taxResidency', []),
    [source, record],
  ) as string[] | undefined;

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{CONTENT[language]}</Typography>
        <Box display="flex" gap={0}>
          {taxResidency?.map((item, i) => (
            <React.Fragment key={item}>
              <SelectField
                record={{ item }}
                source="item"
                choices={countryChoices(language)}
                sx={sx}
              />
              {i + 1 !== taxResidency.length && <Typography mr={1}>,</Typography>}
            </React.Fragment>
          ))}
          {isEmpty(taxResidency) && <Typography>-</Typography>}
        </Box>
      </Box>
    </Grid>
  );
};

TaxResidency.Nested = Nested;

export default TaxResidency;
