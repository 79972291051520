import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';

import {
  Datagrid,
  ArrayField,
  NumberField,
  useRecordContext,
  useGetOne,
  useInfiniteGetList,
  DateField,
  RaRecord,
  SelectField,
  ReferenceField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Typography,
} from '@mui/material';

import { uniqBy } from 'lodash';

import SelectInputButton from '../../layout/inputFields/SelectInputButton';
import NoResults from '../../layout/NoResults';

import { noRowBorderDataGridStyle } from '../../../constants/style/datagridStyles';
import ruleTypes, { RuleTypes } from '../../../constants/ruleTypes';
import { ruleNameChoices } from '../../rules/ruleFeatures';

const CustomVersionField = ({ vers } : {vers: RaRecord}) => (
  <Box display="flex">
    <Typography>
      {`Version ${vers?.number}, \xa0`}
    </Typography>
    <DateField record={vers} source="date" showTime />
    <Typography>
      {`,\xa0 ${vers?.description}`}
    </Typography>
  </Box>
);

const ProgressBar = memo(({
  source,
  color,
  sortable = false,
}: {
  source: string,
  color: string,
  label?: string,
  sortable?: boolean
}) => {
  const record = useRecordContext();
  const value = record ? record[source] : undefined;
  const valueInPercent = 100 * value;

  if (!value) {
    return (
      <Typography>
        -
      </Typography>
    );
  }

  return (
    <Box display="flex">
      <Box
        sx={{
          backgroundColor: '#F5F5F5',
          width: 175,
          height: 26,
          marginRight: 2,
        }}
      >
        <Box
          sx={{
            width: 175,
            height: 26,
            maxWidth: `${valueInPercent}%`,
            backgroundColor: (() => {
              if (color === 'yellow') return '#F3B61F';
              if (color === 'blue') return '#1F487E';
              if (color === 'green') return '#238061';
              return '';
            })(),
          }}
        />
      </Box>
      <NumberField sortable={sortable} source="value" record={{ value }} label="Trend" options={{ style: 'percent' }} />
    </Box>
  );
});

export const RuleVersion = ({
  value,
  setValue,
  ruleType,
} : {
  value: string,
  setValue: (x: string) => void
  ruleType: RuleTypes
}) => {
  const [choices, setChoices] = useState<{
    id: string;
    name: string;
  }[]>([]);

  const {
    data, hasNextPage, fetchNextPage,
  } = useInfiniteGetList<{id: string, number: number}>('rule-version', { filter: { ruleType } });

  const pages = useMemo(() => data?.pages.map((item) => item.data).flat(), [data?.pages]);

  useEffect(() => {
    if (pages) {
      const latest = pages.sort((a, b) => b.number - a.number)[0];
      setValue(latest?.id);

      setChoices((prev) => uniqBy([
        ...prev,
        ...(pages?.map((vers) => ({ id: vers.id, name: `Version ${vers.number}`, custom: <CustomVersionField vers={vers} /> })) ?? []),
      ], 'id'));
    }
  }, [pages, setValue]);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  if (!pages) {
    return (
      <Box margin={10}>
        <Typography align="center">No Data available</Typography>
      </Box>
    );
  }

  return (
    <SelectInputButton
      choices={choices}
      value={value}
      setValue={setValue}
      handleLoadMore={handleLoadMore}
      hasMore={hasNextPage}
      label="Rule version"
    />
  );
};

const RuleTable = () => {
  const [period, setPeriod] = useState('30');
  const [ruleType, setRuleType] = useState(ruleTypes.REQUEST);
  const [version, setVersion] = useState('');
  const { data, isLoading } = useGetOne('dashboard', { id: `rule-trends/${period}/${version}` }, { enabled: !!version });

  return (
    <Card style={{ margin: 2 }}>
      <CardHeader
        title="Model Risk Management Validation"
        sx={{
          gap: 6,
        }}
        action={(
          <Box display="flex" gap={2}>
            <SelectInputButton
              choices={[
                { id: 'request', name: 'Request rule' },
                { id: 'ongoing', name: 'Ongoing rule' },
              ]}
              value={ruleType}
              setValue={setRuleType}
            />
            <RuleVersion
              value={version}
              setValue={setVersion}
              ruleType={ruleType}
            />
            <SelectInputButton
              choices={[
                { id: '1', name: 'Last day' },
                { id: '7', name: 'Last week' },
                { id: '30', name: 'Last month' },
                { id: '90', name: 'Last quarter' },
                { id: '365', name: 'Last year' },
                { id: '99999', name: 'All time' },
              ]}
              value={period}
              setValue={setPeriod}
            />
          </Box>
        )}
      />
      <ArrayField source="ruleStats" record={data!}>
        <Datagrid isLoading={isLoading} sx={noRowBorderDataGridStyle} empty={<NoResults variant="h6" />} bulkActionButtons={false}>
          <ReferenceField
            sortable={false}
            source="ruleId"
            reference="rules"
            link={(record: RaRecord) => `/rules/${record.id}/summary`}
          >
            <SelectField sx={{ textDecoration: 'underline' }} source="name" choices={ruleNameChoices} />
          </ReferenceField>
          <ProgressBar sortable={false} label="Trigger percentage" source="hitRate" color="blue" />
          <ProgressBar sortable={false} source="acc" color="green" label="Accuracy" />
          <ProgressBar sortable={false} source="trend" color="yellow" label="Trend" />
        </Datagrid>
      </ArrayField>
    </Card>

  );
};

export default RuleTable;
