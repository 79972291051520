const Luhn = (input: string) => {
  const arr = input
    .split('') // convert string to array
    .reverse() // reverse order for Luhn
    .map((n) => Number.parseInt(n, 10));

  if (arr.length < 2) return false;
  if (arr.some((n) => Number.isNaN(n))) return false;

  const sum = arr.reduce((previous, current, index) => {
    let value = current;
    if (index % 2) value *= 2;
    if (value > 9) value -= 9;
    return previous + value;
  }, 0);
  return sum % 10 === 0;
};

export default Luhn;
