import React, { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  DateField,
} from 'react-admin';

import { Card, Divider } from '@mui/material';

import hasAccess from '../../utilities/hasAccess';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';

import SignalCreate from './SignalCreate';
import SignalEdit from './SignalEdit';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

const SignalActions = () => (
  <SignalCreate />
);

const SignalList = () => {
  const [activationTab, setActivationTab] = useState(0);
  const [active, setActive] = useState(true);
  const { permissions } = usePermissions<Actions[]>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivationTab(() => newValue);
    setActive(() => newValue === 0);
  };
  return (
    <List
      empty={false}
      filter={{ active }}
      actions={<SignalActions />}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={activationTab} onChange={handleChange}>
              <StyledTab label="Active " />
              <StyledTab label="Inactive" />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
          {hasAccess(permissions, actions.SIGNAL_GET) && <SignalEdit />}
          <DateField sortable={false} source="createdAt" label="Created at" />
          <TextField sortable={false} source="name" />
        </Datagrid>
      </Card>
    </List>
  );
};

export default SignalList;
