import React from 'react';

import {
  maxValue,
  minValue,
  NumberInput,
  required,
  TextInput,
} from 'react-admin';

import {
  Typography,
  Grid,
} from '@mui/material';

import CreateDialog from '../layout/dialog/CreateDialog';

const RiskClassCreate = () => (
  <CreateDialog title="risk class">
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <TextInput sx={{ width: '70%' }} validate={required()} source="name" />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="mediumTitle">Set interval score</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput validate={[required(), maxValue(99), minValue(0)]} source="min" label="Minimum (%)" />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput validate={[required(), maxValue(100), minValue(1)]} source="max" label="Maximum (%)" />
      </Grid>
    </Grid>
  </CreateDialog>
);

export default RiskClassCreate;
