import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';
import conflictFields from '../../../../constants/conflictFields';

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const Email = ({ source }: {source?: string }) => (
  <Answer
    label="Email"
    source={source ?? 'email'}
    datapoint={conflictFields.EMAIL}
  >
    <ValueField />
  </Answer>
);

Email.ValueField = ValueField;

export default Email;
