import React from 'react';

import {
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ChipField,
} from 'react-admin';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import StatusField from '../../../../customFields/StatusField';
import NoResults from '../../../layout/NoResults';
import LinkField from '../../../layout/LinkField';
import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

const Transaction = (
  {
    children,
  }: {
    children?: React.ReactNode;
  },
) => (
  <Datagrid sx={{ ...boldDataGridStyle }} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <DateField sortable source="extraInformation.date" showTime label="Date" />
    <TextField sortable source="extraInformation.amount" label="Amount" />
    <TextField sortable={false} source="extraInformation.currency" label="Currency" />
    <StatusField sortable={false} source="extraInformation.status" label="Status" resource="transactions" />
    <ReferenceEntityField sortable={false} source="extraInformation.processor" label="Processor" />
    <ReferenceEntityField sortable={false} source="extraInformation.subProcessor" label="Sub-processor" />
    <ReferenceEntityField sortable={false} source="extraInformation.recipient" label="Recipient" />
    <ReferenceEntityField sortable={false} source="extraInformation.initiator" label="Initiator" />
    <StatusField sortable={false} source="recommendation" />
    <ReferenceField sortable={false} reference="processes" source="process">
      <ChipField source="name" />
    </ReferenceField>
    {children}
  </Datagrid>
);

export default Transaction;
