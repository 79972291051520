import React, { useCallback } from 'react';

import {
  useCreate,
  useGetOne,
  useNotify,
  useRefresh,
} from 'react-admin';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import CloudOffIcon from '@mui/icons-material/CloudOff';

import useDialogStatus from '../../../../hooks/useDialogStatus';

const Deactivate = ({
  disabled,
  version,
} : {
  disabled: boolean,
  version: string
}) => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  const { data: ruleVersion, isLoading } = useGetOne('rule-version', { id: version }, { enabled: !disabled });

  const notify = useNotify();
  const refresh = useRefresh();
  const [deactivate] = useCreate();

  const handleDeactivate = useCallback(() => {
    deactivate(
      'rules/deactivate',
      { data: { ruleVersion: version } },
      {
        onSuccess: () => {
          notify('Rule tree was deactivated', { type: 'success' });
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Rule tree could not be deactivated', { type: 'error' });
        },
      },
    );
  }, [deactivate, version, notify, closeDialog, refresh]);

  if (isLoading) return null;

  if (!ruleVersion?.isActive) return null;

  return (
    <>
      <Button
        color="primary"
        startIcon={<CloudOffIcon />}
        onClick={openDialog}
        disabled={disabled}
      >
        Deactivate
      </Button>
      <Dialog onClose={closeDialog} open={open} fullWidth maxWidth="sm">
        <DialogTitle>Deactivate Test Rule Tree</DialogTitle>
        <DialogContent>
          <Typography>
            The current live test version will be deactivated.
          </Typography>
          <Box paddingTop="5%" gap={2} display="flex" justifyContent="flex-end">
            <Button onClick={closeDialog} color="error" variant="contained">Close</Button>
            <Button onClick={handleDeactivate} variant="contained">Deactivate</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Deactivate;
