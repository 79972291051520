import { useCallback, useState } from 'react';
import {
  Identifier,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { EntityTypes } from '../constants/entityTypes';
import { RequestTypes } from '../constants/requestTypes';
import errorLogger from '../utilities/errorLogger';

type UseRequests = {
  id?: Identifier
  type: EntityTypes
  requestType: RequestTypes
}

const useRequests = ({ id, type, requestType }: UseRequests) => {
  const dataProvider = useDataProvider();
  const [requestData, setData] = useState();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const createRequest = useCallback((process: string) => {
    setLoading(true);
    dataProvider.create('requests', {
      data: {
        extraInformation: {
          entity: { id, type },
        },
        requestType,
        process,
      },
    })
      .then((response) => {
        setData(response.data);
        setLoading(false);
        notify('KYC Request Completed');
        refresh();
      })
      .catch((error) => {
        notify('Could not create KYC request', { type: 'error' });
        errorLogger(error);
      });
  }, [dataProvider, id, notify, refresh, requestType, type]);

  return {
    requestData, createRequest, loading,
  };
};

export default useRequests;
