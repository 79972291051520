import React from 'react';
import { Card, styled } from '@mui/material';

import pingwireLogo from '../../assets/logos/pingwire-green.svg';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(247, 249, 252)',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 364,
  width: `calc(100% - 2 * ${theme.spacing(3)})`,
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - 2 * ${theme.spacing(5)})`,
    padding: theme.spacing(5),
  },
}));

const BrandLogo = styled('img')(() => ({
  marginBottom: '2rem',
  height: '2.5rem',
}));

const AuthLayout = ({ children }: { children: React.ReactNode}) => (
  <Root>
    <BrandLogo src={pingwireLogo} alt="Pingwire" />
    <StyledCard>
      {children}
    </StyledCard>
  </Root>
);

export default AuthLayout;
