import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const PingBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record?.id?.toString() ?? '',
    path: `/pings/${record?.id}`,
  });

  return null;
};

export default PingBreadcrumb;
