import React from 'react';

import {
  required,
  minValue,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';
import ThresholdInput from './ThresholdInput';

const ThresholdWithKycData = ({ overrideCurrencies }: { overrideCurrencies?: string[]}) => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Data update frequency</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          source="ruleParameters.updateFreq"
          label="Days"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Threshold parameters</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            <ThresholdInput source="default" overrideCurrencies={overrideCurrencies} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>

);

export default ThresholdWithKycData;
