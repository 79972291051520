import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';
import { IAPIKey } from '../../utilities/schemas/apiKey';

const ApiKeyBreadcrumb = () => {
  const record = useRecordContext<IAPIKey>();
  useRegisterBreadcrumb({
    title: record?.clientId,
    path: `/api-keys/${record?.id}`,
  });

  return null;
};

export default ApiKeyBreadcrumb;
