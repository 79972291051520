import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  styled,
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import BreadcrumbsManager from './BreadcrumbsManager';

// import NavbarNotificationsDropdown from './NavbarNotificationsDropdown';
// import NavbarLanguagesDropdown from './NavbarLanguagesDropdown';
import NavbarUserDropdown from './NavbarUserDropdown';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: theme.header.background,
  color: theme.header.color,
}));

const IconButton = styled(MuiIconButton)({
  '& svg': {
    width: 22,
    height: 22,
  },
});

const Navbar = ({ onDrawerToggle }: { onDrawerToggle?: () => void }) => (
  <AppBar position="sticky" elevation={0}>
    <Toolbar>
      <Grid container alignItems="center" spacing={5}>
        <Grid item>
          {onDrawerToggle && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="medium"
            >
              <MenuOpenIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs>
          <BreadcrumbsManager />
        </Grid>
        <Grid item>
          {/* <NavbarNotificationsDropdown />
            <NavbarLanguagesDropdown /> */}
          <NavbarUserDropdown />
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>
);

Navbar.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default Navbar;
