import React from 'react';

import {
  required,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

import CreateDialog from '../layout/dialog/CreateDialog';
import InfoPopover from '../layout/InfoPopover';

import useRequestTypesChoices from '../../hooks/useRequestTypesChoices';

const ProcessCreate = () => {
  const { choices } = useRequestTypesChoices();

  return (
    <CreateDialog title="processes">
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
        <Box width="80%">
          <Box display="flex" alignItems="center">
            <Typography variant="mediumTitle">Process Name</Typography>
            <InfoPopover title="Process Name" content="Unique name for the process used for sending request and effects applied rules and risk score." />
          </Box>
          <TextInput fullWidth validate={required()} source="name" />
        </Box>
        <Box width="80%">
          <Box display="flex" alignItems="center">
            <Typography variant="mediumTitle">Request type</Typography>
            <InfoPopover title="Request type" content="Type of request allowed within the process." />
          </Box>
          <SelectInput
            fullWidth
            source="requestType"
            label="Request Type"
            choices={choices}
            validate={required()}
          />
        </Box>
      </Box>
    </CreateDialog>

  );
};

export default ProcessCreate;
