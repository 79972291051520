import React from 'react';
import {
  Create,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
  required,
  useGetList,
  Toolbar,
  SaveButton,
  TextInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import SendIcon from '@mui/icons-material/Send';
import { requestCreateMap } from '../../constants/requestFeatures';
import useRequestTypesChoices from '../../hooks/useRequestTypesChoices';
import cleaner from '../../utilities/dataProvider/cleaner';
import { RequestTypes } from '../../constants/requestTypes';

type RequestFormCreate = {
  requestType?: RequestTypes;
  process?: string;
  customId?: string;
}

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();

  return (
    <Toolbar>
      <SaveButton
        label="Send"
        icon={<SendIcon />}
        disabled={!isValid}
      />
    </Toolbar>
  );
};

const RequestCreate = () => {
  const { data } = useGetList('processes');
  const { choices } = useRequestTypesChoices();
  return (
    <Create transform={cleaner}>
      <SimpleForm mode="all" toolbar={<CustomToolbar />}>
        <SelectInput
          source="requestType"
          label="Request Type"
          choices={choices}
          validate={required()}
        />
        <FormDataConsumer<RequestFormCreate>>
          {({ formData }) => formData?.requestType && requestCreateMap[formData.requestType]()}
        </FormDataConsumer>
        <SelectInput
          optionValue="name"
          source="process"
          choices={data ?? []}
          optionText="name"
          validate={required()}
        />
        <TextInput sx={{ minWidth: 200 }} source="customId" label="Custom Id" />

      </SimpleForm>
    </Create>
  );
};

export default RequestCreate;
