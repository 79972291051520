import React from 'react';
import { useRecordContext } from 'react-admin';

import {
  Box,
  Typography,
  Chip,
} from '@mui/material';

import { get } from 'lodash';

const SelectArrayField = ({
  source,
  emptyText,
  choices,
}: {
  source: string,
  choices: { id: string, name:string }[];
  emptyText?: string;
}) => {
  const record = useRecordContext();
  const value = get(record, source);

  if (!value || !Array.isArray(value)) return <Typography>{emptyText ?? ''}</Typography>;

  return (
    <Box display="flex" flexDirection="row" gap={1} flexWrap="wrap">
      {value.map((item) => {
        const choice = choices.find((c) => c.id === item);
        return (
          <Chip key={item} label={choice?.name} />
        );
      })}
    </Box>
  );
};

export default SelectArrayField;
