import React from 'react';

import { Grid, Typography } from '@mui/material';
import Select from './base/Select';
import { employmentStatusChoices } from '../../../constants/employmentStatuses';

const EmploymentStatus = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Employment
      </Typography>
    </Grid>
    <Select choices={employmentStatusChoices('en')} />
  </Grid>
);

export default EmploymentStatus;
