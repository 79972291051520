import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get, isEmpty } from 'lodash';

import { Typography } from '@mui/material';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const MARKET_LABEL = {
  [languages.SV]: 'På vilken börs är företaget noterat?',
  [languages.EN]: 'On which stock exchange is the company listed?',
};

const ValueField = () => {
  const record = useRecordContext();

  const valueObj = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (isEmpty(valueObj)) return <Typography>-</Typography>;

  if (valueObj?.option === 'no') {
    return (
      <Typography fontWeight="bold">
        None
      </Typography>
    );
  }

  return <TextField record={{ value: valueObj?.market }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const ListedFinancialMarket = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (

    <Answer
      label={MARKET_LABEL[language]}
      source="listedFinancialMarket"
      datapoint={conflictFields.LISTED_FINANCIAL_MARKET}
    >
      <ValueField />
    </Answer>

  );
};

ListedFinancialMarket.ValueField = ValueField;

export default ListedFinancialMarket;
