import React from 'react';
import {
  Filter, ReferenceInput, SelectInput, useListContext,
} from 'react-admin';
import { IAuditTrack } from '../../utilities/schemas/auditTrack';

const fetchUser = (search: string) => ({ authenticator: search });

const AuditFilter = ({ context }: { context?: 'form' | 'button' }) => {
  const { filterValues } = useListContext<IAuditTrack>();
  return (
    <Filter context={context}>
      <SelectInput
        source="resource.type"
        label="Resource Type"
        choices={[
          { id: 'Individual', name: 'Individuals' },
          { id: 'Request', name: 'Request' },
          { id: 'Member', name: 'Members' },
          { id: 'Business', name: 'Businesses' },
          { id: 'Ping', name: 'Pings' },
          { id: 'Rule', name: 'Rule' },
          { id: 'Car', name: 'Car' },
        ]}
        alwaysOn
        SelectProps={{
          disableUnderline: true,
        }}
      />
      <SelectInput
        source="authenticator.type"
        label="User Type"
        choices={[
          { id: 'System', name: 'System' },
          { id: 'Member', name: 'Members' },
          { id: 'ApiKey', name: 'Api key' },
        ]}
        alwaysOn
        SelectProps={{
          disableUnderline: true,
        }}
      />
      {filterValues?.authenticator?.type === 'Member' && (
        <ReferenceInput
          label="Member"
          source="authenticator.id"
          reference="members"
          filterToQuery={fetchUser}
          alwaysOn
        >
          <SelectInput
            SelectProps={{
              disableUnderline: true,
            }}
            label="Member"
            optionText={(member) => `${member.firstName} ${member.lastName}`}
          />
        </ReferenceInput>
      )}
      {filterValues?.authenticator?.type === 'ApiKey' && (
        <ReferenceInput
          label="Client ID"
          source="authenticator.id"
          reference="api-keys"
          filterToQuery={fetchUser}
          alwaysOn
        >
          <SelectInput
            SelectProps={{
              disableUnderline: true,
            }}
            label="Client ID"
            optionText={(user) => `${user.clientId}`}
          />
        </ReferenceInput>
      )}
    </Filter>
  );
};

export default AuditFilter;
