import React from 'react';

import {
  FunctionField,
  useRecordContext,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import {
  get,
  isArray,
  isEmpty,
  isString,
  upperFirst,
} from 'lodash';

import { getName } from 'i18n-iso-countries';
import languages from '../../../../../../constants/languages';
import NoResults from '../../../../../layout/NoResults';
import { SanctionListMatches } from '../../../../../../utilities/schemas/sanctionListMatches';
import { sanctionTypesLabels } from '../../../../../../constants/sanctionTypes';

type Sanction = SanctionListMatches['potentialMatches'][number]['sanctions'][number]

const getSanction = ({
  issuingBody,
  regime,
  measures,
  sanctionType,
  country,
  fromDate,
  toDate,
}: Sanction) => {
  const countryName = isString(country) && !isEmpty(country) ? getName(country, languages.EN) : '-';
  const sanctionTypeName = isString(sanctionType) && !isEmpty(sanctionType) ? get(sanctionTypesLabels, sanctionType, '-') : '-';
  const regimeName = isString(regime) && !isEmpty(regime) ? upperFirst(regime) : '-';
  const issuingBodyName = isString(issuingBody) && !isEmpty(issuingBody) ? upperFirst(issuingBody) : '-';
  const fromDateText = isString(fromDate) && !isEmpty(fromDate) ? new Date(fromDate).toLocaleDateString() : '-';
  const toDateText = isString(toDate) && !isEmpty(toDate) ? new Date(toDate).toLocaleDateString() : '-';
  const measuresText = !isEmpty(measures) && isArray(measures) ? upperFirst(measures!.join(', ')) : '-';

  return [
    `Issuing body: ${issuingBodyName}`,
    `Country: ${countryName}`,
    `Regime: ${regimeName}`,
    `Sanction type: ${sanctionTypeName}`,
    `Measures: ${measuresText}`,
    `From: ${fromDateText}`,
    `To: ${toDateText}`,
  ].join('\n');
};

const SanctionSanctions = () => {
  const record = useRecordContext<SanctionListMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Sanctions" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            {isEmpty(record.sanctions) && <NoResults text="No data to display." icon={null} />}
            {!isEmpty(record.sanctions) && (
              <Grid item xs={12} md={12}>
                <Box>
                  <FunctionField
                    sx={{ whiteSpace: 'pre' }}
                    source="sanctions"
                    render={(item: SanctionListMatches['potentialMatches'][number]) => {
                      if (isEmpty(item.sanctions)) return '-';
                      return (
                        <ul>
                          {record.sanctions
                            .map((sanction) => (
                              <li style={{ marginTop: 6 }}>
                                {getSanction(sanction)}
                              </li>
                            ))}
                        </ul>
                      );
                    }}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SanctionSanctions;
