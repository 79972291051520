import Joi from 'joi';

export const isValidHexColorString = (
  value: string,
) => {
  const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return regex.test(value);
};

export interface IJoiColorExtension extends Joi.Root {
  color(): Joi.AnySchema;
}

const JoiColorExtension = (joi: Joi.Root) => joi.extend({
  type: 'color',
  messages: {
    invalid: '"{{#label}}" must be a valid hex color',
  },
  validate(value, { error, schema }) {
    // eslint-disable-next-line no-underscore-dangle
    const valids = (schema as unknown as {_valids: {_values: Set<string>}})?._valids?._values;
    if (valids?.has(value)) return { value };
    if (!isValidHexColorString(value)) {
      return { value, errors: error('invalid') };
    }
    return { value };
  },
}) as IJoiColorExtension;

export default JoiColorExtension;
