import React from 'react';
import { useRecordContext, useGetManyAggregate } from 'react-admin';
import { Tooltip, Typography } from '@mui/material';
import { get } from 'lodash';

type TransactionTypeConfiguration = {
  or: {
    and: {
      inclusion: 'included' | 'excluded',
      transactionTypeId: string;
    }[];
  }[]
};

const toStringTransactionTypeConfiguration = (
  typeConfiguration: TransactionTypeConfiguration,
  nameHashMap: Map<string, string>,
) => typeConfiguration.or
  .map(({ and }) => and
    .map(({ inclusion, transactionTypeId }) => `${inclusion === 'included' ? '' : 'not '}${nameHashMap.get(transactionTypeId) ?? 'unknown'}`)
    .join(' and '))
  .join(' or ');

const useTransactionTypeConfigurationString = (
  typeConfiguration?: TransactionTypeConfiguration,
) => {
  const ids = (typeConfiguration?.or ?? [])
    .flatMap(({ and }) => and.map(({ transactionTypeId }) => transactionTypeId));
  const {
    data,
    isLoading,
    error,
  } = useGetManyAggregate('transaction-types', {
    ids,
  }, { enabled: !!typeConfiguration });

  if (!typeConfiguration) return { data: 'All', isLoading: false, error: null };
  if (!data) return { data: null, isLoading, error };

  const nameHashMap = new Map(data.map((transactionType) => [
    transactionType.id,
    transactionType.name,
  ]));

  return {
    data: toStringTransactionTypeConfiguration(typeConfiguration, nameHashMap),
    isLoading,
    error,
  };
};

const TransactionTypeConfigurationField = ({
  source,
}: {
  source: string;
  label?: string | false
}) => {
  const record = useRecordContext<{}>();
  const value = get(record, source) as TransactionTypeConfiguration | undefined;

  const {
    data,
    isLoading,
    error,
  } = useTransactionTypeConfigurationString(value);

  if (!record || isLoading || error) return null;

  return (
    <Tooltip title={data}>
      <Typography maxWidth={200} noWrap>
        {data}
      </Typography>
    </Tooltip>
  );
};

export default TransactionTypeConfigurationField;
