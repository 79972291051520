import React from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  SelectInput,
  SimpleFormIterator,
  minLength,
  minValue,
  required,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';

import currencies from '../../../../constants/currencies';

const LowIncome = () => (
  <Grid item xs={12} md={12}>
    <Box marginBottom={2}>
      <Typography variant="label">Lowest acceptable income</Typography>
    </Box>
    <Box display="flex" flexWrap="wrap" gap={2}>
      <ArrayInput
        source="ruleParameters.threshold"
        label=""
        validate={[required(), minLength(1)]}
      >
        <SimpleFormIterator
          inline
          sx={{
            '& .RaSimpleFormIterator-line': {
              borderBottom: 'none',
              paddingY: 2,
              '& .RaSimpleFormIterator-inline': {
                rowGap: '1em',
              },
            },
          }}
        >
          <NumberInput
            source="amount"
            label="Amount"
            validate={[required(), minValue(0)]}
            helperText={false}
          />
          <AutocompleteInput
            source="currency"
            label="Currency"
            choices={currencies.map((curr) => ({ id: curr, name: curr }))}
            validate={[required()]}
            helperText={false}
          />
          <SelectInput
            source="unitOfTime"
            label="Time period"
            choices={[
              { id: 'year', name: 'per year' },
              { id: 'month', name: 'per month' },
            ]}
            validate={[required()]}
            helperText={false}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  </Grid>
);

export default LowIncome;
