import React from 'react';

import {
  Box, Typography, Fade, TypographyVariant,
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

const NoResults = ({
  variant, timeout, text = 'No results found', icon = <SearchOffIcon />,
}: {
  variant?: TypographyVariant,
  timeout?: number
  text?: string
  icon?: React.ReactNode
}) => (
  <Fade in timeout={timeout}>
    <Box sx={{
      padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    }}
    >
      {icon}
      <Typography variant={variant}>
        {text}
      </Typography>
    </Box>
  </Fade>

);

export default NoResults;
