import React from 'react';

import {
  Card,
  Grid, Typography,
} from '@mui/material';

import { get } from 'lodash';

import {
  useRecordContext,
  TextField,
  TextInput,
  SelectInput,
  NumberInput,
  minValue,
  required,
  Datagrid,
} from 'react-admin';

import currencies from '../../../constants/currencies';
import { UnitsOfTime, unitsOfTimeChoices } from '../../../constants/unitsOfTime';
import { boldDataGridStyle } from '../../../constants/style/datagridStyles';

interface TransactionDetails {
  monthlyVolume?: number;
  monthlyFrequency?: number;
  median?: number;
  max?: number;
  expectedTransactionTimeline?: {
    amount: number;
    unit: UnitsOfTime;
  }
}

export type TransactionMonitoringThresholdsValue = {
  currency: string;
  incoming?: TransactionDetails;
  outgoing?: TransactionDetails;
  process?: TransactionDetails;
  total?: TransactionDetails;
};

const NestedField = ({
  source,
  unit,
  unitSource,
}: {
  label: string;
  source: string;
  unit?: string;
  unitSource?: string;
}) => {
  const record = useRecordContext();

  const value = get(record, source);
  const finalUnit = unit ?? (unitSource ? get(record, unitSource) : undefined);

  const string = value ? `${value} ${finalUnit ?? ''}`.trimEnd() : '-';

  return (
    <TextField record={{ string }} source="string" />
  );
};

const TransactionMonitoringThresholdsInput = ({ source }: { source: string }) => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={currencies.map((curr) => ({ name: curr, id: curr }))}
        label="Currency"
        fullWidth
        source={`${source}.currency`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Incoming transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.incoming.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.incoming.expectedTransactionTimeline.unit`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Outgoing transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.outgoing.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.outgoing.expectedTransactionTimeline.unit`}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Processed transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.process.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.process.expectedTransactionTimeline.unit`}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Typography color="primary" variant="h6">Total transactions</Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.monthlyVolume`}
        label="Monthly volume"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.max`}
        label="Maximum amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.median`}
        label="Typical amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.monthlyFrequency`}
        label="Number of transactions"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Typography fontWeight={500} fontSize={14}>
        Behavior duration
      </Typography>
    </Grid>
    <Grid item xs={12} md={6}>
      <NumberInput
        fullWidth
        source={`${source}.total.expectedTransactionTimeline.amount`}
        label="Amount"
        validate={minValue(0)}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SelectInput
        choices={unitsOfTimeChoices}
        label="Unit"
        fullWidth
        source={`${source}.total.expectedTransactionTimeline.unit`}
      />
    </Grid>
  </Grid>
);

export const TransactionMonitoringThresholdsAnswer = () => {
  const record = useRecordContext<{
    value: TransactionMonitoringThresholdsValue
  }>();

  const currency = record?.value.currency;
  const reformedValue = [
    {
      description: 'Incoming transactions',
      ...record?.value.incoming,
    },
    {
      description: 'Outgoing transactions',
      ...record?.value.outgoing,
    },
    {
      description: 'Processed transactions',
      ...record?.value.process,
    },
    {
      description: 'Total transactions',
      ...record?.value.total,
    },
  ];

  return (
    <Card variant="outlined">
      <Datagrid
        resource="transaction-monitoring-thresholds"
        data={reformedValue}
        total={reformedValue.length}
        isLoading={!record}
        bulkActionButtons={false}
        sort={{ field: '_id', order: 'DESC' }}
        sx={boldDataGridStyle}
      >
        <TextField source="description" label={false} />
        <NestedField
          source="monthlyVolume"
          label="Monthly volume"
          unit={currency}
        />
        <NestedField
          source="max"
          label="Max amount"
          unit={currency}
        />
        <NestedField
          source="median"
          label="Typical amount"
          unit={currency}
        />
        <NestedField
          source="monthlyFrequency"
          label="Number of transactions"
        />
        <NestedField
          source="expectedTransactionTimeline.amount"
          label="Behavior duration"
          unitSource="expectedTransactionTimeline.unit"
        />
      </Datagrid>
    </Card>
  );
};

const TransactionMonitoringThresholdsForm = () => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
        marginBottom={4}
      >
        Transaction Monitoring Thresholds
      </Typography>
      <TransactionMonitoringThresholdsInput source="value" />
    </Grid>
    <Grid item xs={12} md={12} justifyContent="center" display="flex">
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={4}
        helperText={false}
        placeholder="Please explain your decision."
        validate={required()}
      />
    </Grid>
  </Grid>
);

export default TransactionMonitoringThresholdsForm;
