import React from 'react';

import { useRecordContext } from 'react-admin';

import {
  Box,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Button,
} from '@mui/material';

import { Download } from '@mui/icons-material';

import { isEmpty } from 'lodash';

import person from '../../../../../../assets/exposure/person.png';

import { SanctionListMatches } from '../../../../../../utilities/schemas/sanctionListMatches';
import ImageField from '../../../../../../customFields/ImageField';
import NoResults from '../../../../../layout/NoResults';

const SanctionImages = () => {
  const record = useRecordContext<SanctionListMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Images" />
        <Divider />
        <CardContent>
          {isEmpty(record.profileImages) && <NoResults icon={null} text="No data to display." />}
          {!isEmpty(record.profileImages) && (
            <Box display="flex" flexDirection="row" alignItems="baseline">
              {record.profileImages!.map((img, index) => (
                <Box key={`img-${img}`} gap={1} display="flex" flexDirection="column" alignItems="center">
                  <ImageField source={`profileImages[${index}]`} emptyImage={person} />
                  <Button
                    sx={{
                      textDecoration: 'underline',
                      ':hover': { textDecoration: 'underline', backgroundColor: 'transparent' },
                      alignItems: 'center',
                    }}
                    size="small"
                    startIcon={(
                      <Download
                        sx={{
                          marginRight: -2,
                        }}
                        fontSize="small"
                      />
                    )}
                    onClick={() => window.open(img)}
                  >
                    Download
                  </Button>
                </Box>
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SanctionImages;
