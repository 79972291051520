import React, { useCallback, useMemo, useState } from 'react';
import {
  ListItemText,
  List,
  ListItem,
  ListSubheader,
  InputAdornment,
  TextField,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import InfoPopover from '../InfoPopover';
import { ruleDescriptions } from '../../rules/ruleFeatures';
import { Paths } from '../../../constants/paths';
import { RuleNames } from '../../../constants/ruleNames';

const containsText = (text: string, searchText: string) => (
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
);

const RulePathList = ({
  choices,
  path,
  changePath,
  type,
  selectedIndex,
  changeIndex,
}: {
  choices: {
    id: Paths | RuleNames;
    name: string;
    type: 'category' | 'subcategory' | 'rule';
    path?: Paths[]
  }[]
  path: (Paths | RuleNames | undefined)[],
  type: 'category' | 'subcategory' | 'rule';
  changePath: (newPath: Paths | RuleNames | undefined, position: number) => void;
  changeIndex: (newIndex: number, position: number) => void;
  selectedIndex: number[];
}) => {
  const handleListItemClick = useCallback((index: number, id: Paths | RuleNames | undefined) => {
    if (type === 'category') {
      changePath(id, 0);
      changeIndex(index, 0);
    }
    if (type === 'subcategory') {
      changePath(id, 1);
      changeIndex(index, 1);
    }
    if (type === 'rule') {
      changePath(id, 2);
      changeIndex(index, 2);
    }
  }, [changeIndex, changePath, type]);

  const [searchText, setSearchText] = useState('');
  const filterChoices = useMemo(() => choices.sort((a, b) => (b.name > a.name ? -1 : 1)).filter(
    (option) => {
      if (type === 'category') {
        return containsText(option.name, searchText);
      }
      if (type === 'subcategory') {
        if (!path[0]) return false;
        return containsText(option.name, searchText) && path[0] === option.id;
      }
      return containsText(option.name, searchText) && option.path?.every(
        (item, index) => path[index] === undefined || item.includes(path[index]!),
      );
    },
  ), [choices, path, searchText, type]);

  const isSelected = useCallback((index: number) => {
    if (type === 'category') {
      return (selectedIndex[0] === index + 1) && !!path[0];
    } if (type === 'subcategory') {
      return (selectedIndex[1] === index + 1) && !!path[1];
    }
    return (selectedIndex[2] === index + 1) && !!path[2];
  }, [path, selectedIndex, type]);

  return (
    <List
      color="secondary"
      style={{ maxHeight: '100%', overflow: 'auto' }}
    >
      <ListSubheader>
        <TextField
          placeholder="Type to search..."
          style={{ backgroundColor: 'white' }}
          fullWidth
          margin="dense"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </ListSubheader>
      {(type === 'category' || type === 'subcategory') && (
        <ListItem
          button
          key="all"
          selected={type === 'category' ? selectedIndex[0] === 0 : selectedIndex[1] === 0}
          onClick={() => handleListItemClick(0, undefined)}
        >
          <ListItemText primary="All" />
        </ListItem>
      )}
      {filterChoices.map((choice, index) => (
        <ListItem
          button
          key={choice.id}
          selected={isSelected(index)}
          onClick={() => handleListItemClick(index + 1, choice.id)}
        >
          <ListItemText primary={choice.name} />
          {ruleDescriptions[choice.id] && (
            <InfoPopover title={choice.name} content={ruleDescriptions[choice.id]!} />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export default RulePathList;
