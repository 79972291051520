export const businessKycParams = {
  RESIDENTIAL_ADDRESS: { id: 'address', name: 'Residential address' },
  CITY: { id: 'city', name: 'City' },
  ZIP_CODE: { id: 'postalCode', name: 'Zip code' },
  COUNTRY: { id: 'country', name: 'Country' },
  EMAIL: { id: 'email', name: 'Email' },
  PHONE_NUMBER: { id: 'phoneNumber', name: 'Phone number' },
  WEBSITE: { id: 'website', name: 'Website' },
  COMPANY_NAME: { id: 'companyName', name: 'Company name' },
  TAX_ID_NUMBER: { id: 'tin', name: 'Tax ID number' },
  COMPANY_REGISTRATION_NUMBER: { id: 'organizationNumber', name: 'Company registration number' },
} as const;

export const individualKycParams = {
  CONTACT_INFO_RESIDENTIAL_ADDRESS: { id: 'address', name: 'Residential address' },
  CONTACT_INFO_CITY: { id: 'city', name: 'City' },
  CONTACT_INFO_ZIP_CODE: { id: 'postalCode', name: 'Zip Code' },
  CONTACT_INFO_COUNTRY: { id: 'country', name: 'Country' },
  CONTACT_INFO_EMAIL: { id: 'email', name: 'Email' },
  CONTACT_INFO_PHONE_NUMBER: { id: 'phoneNumber', name: 'Phone Number' },
  CONTACT_INFO_WEBSITE: { id: 'website', name: 'Website' },
  IDENTIFICATION_FIRST_NAME: { id: 'firstName', name: 'First Name' },
  IDENTIFICATION_LAST_NAME: { id: 'lastName', name: 'Last Name' },
  IDENTIFICATION_MIDDLE_NAME: { id: 'middleName', name: 'Middle Name' },
  IDENTIFICATION_DATE_OF_BIRTH: { id: 'dateOfBirth', name: 'Date of Birth' },
  IDENTIFICATION_GENDER: { id: 'gender', name: 'Gender' },
} as const;
