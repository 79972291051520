import React, { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  SelectField,
} from 'react-admin';

import { Card, Divider } from '@mui/material';

import useRequestTypesChoices from '../../hooks/useRequestTypesChoices';

import hasAccess from '../../utilities/hasAccess';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';

import ProcessCreate from './ProcessCreate';
import ProcessEdit from './ProcessEdit';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

const ProcessActions = () => (
  <ProcessCreate />
);

const ProcessList = () => {
  const [activationTab, setActivationTab] = useState(0);
  const [active, setActive] = useState(true);
  const { permissions } = usePermissions<Actions[]>();

  const { choices } = useRequestTypesChoices();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivationTab(() => newValue);
    setActive(() => newValue === 0);
  };
  return (
    <List
      empty={false}
      filter={{ active }}
      actions={<ProcessActions />}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={activationTab} onChange={handleChange}>
              <StyledTab label="Active " />
              <StyledTab label="Inactive" />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
          {hasAccess(permissions, actions.PROCESS_GET) && <ProcessEdit />}
          <TextField sortable={false} source="name" />
          <SelectField
            sortable={false}
            source="requestType"
            label="Request Type"
            choices={choices}
          />
        </Datagrid>
      </Card>
    </List>
  );
};

export default ProcessList;
