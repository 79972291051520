import { ValueOf } from '../utilities/types';

const relatedEntityRelations = {
  ASSOCIATE: 'associate',
  SIBLING: 'sibling',
  SPOUSE: 'spouse',
  PARENT: 'parent',
  CHILD: 'child',
  OTHER_FAMILY: 'otherFamily',
  OTHER: 'other',
} as const;

export type RelatedEntityRelations = ValueOf<typeof relatedEntityRelations>;

export const relatedEntityRelationsText: Record<RelatedEntityRelations, string> = {
  [relatedEntityRelations.ASSOCIATE]: 'Known associate',
  [relatedEntityRelations.SIBLING]: 'Sibling',
  [relatedEntityRelations.SPOUSE]: 'Spouse',
  [relatedEntityRelations.PARENT]: 'Parent',
  [relatedEntityRelations.CHILD]: 'Child',
  [relatedEntityRelations.OTHER_FAMILY]: 'Other family',
  [relatedEntityRelations.OTHER]: 'Other',
};

export const relatedEntityRelationsChoices = Object.entries(relatedEntityRelationsText)
  .map(([key, value]) => ({
    id: key,
    name: value,
  }));

export default relatedEntityRelations;
