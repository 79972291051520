import { Card } from '@mui/material';

import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
} from 'react-admin';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import LinkField from '../layout/LinkField';
import SearchField from '../layout/SearchField';

import ColoredScoreField from '../../customFields/ColoredScoreField';
import { NationalIdentifierSingleField } from '../../customFields/NationalIdentifier';
import ChipStringField from '../../customFields/ChipStringField';

export const IndividualListBody = ({ children }: {children?: React.ReactElement}) => (
  <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <ArrayField sortable={false} source="customIds" label="Custom Ids">
      <SingleFieldList linkType={false}>
        <ChipStringField />
      </SingleFieldList>
    </ArrayField>
    <NationalIdentifierSingleField label="National identifier" underline={false} />
    <TextField sortable={false} source="firstName" />
    <TextField sortable={false} source="lastName" />
    <ColoredScoreField source="riskScore" />
    {children}
  </Datagrid>
);

export const IndividualFilters = [
  <SearchField source="firstName" />,
  <SearchField source="lastName" />,
  <SearchField source="nationalIdentifier.identifier" label="National identifier" />,
];

const IndividualList = () => (
  <List
    resource="individuals"
    filters={IndividualFilters}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <IndividualListBody />
    </Card>
  </List>
);

export default IndividualList;
