import React, { useCallback, useEffect, useState } from 'react';

import { isFunction } from 'lodash';

import { RaRecord, useRecordContext } from 'react-admin';

import {
  useFormContext,
  UseFormGetValues,
  FieldValues,
  UseFormSetValue,
} from 'react-hook-form';

import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

const Actions = ({
  handleOpenChange,
  setValue,
  getValues,
  isCustom,
  setFromVariant,
  setToVariant,
  isEditView,
  toVariant,
  fromVariant,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void
  setValue: UseFormSetValue<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  isCustom: boolean
  setFromVariant: (x: string) => void
  setToVariant: (x: string) => void
  isEditView: boolean
  toVariant: string
  fromVariant: string
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void,
}) => {
  const [customIsAdded, setCustomIsAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    reset, getValues: localGetValues, setValue: localSetValues, formState: { isValid, isDirty },
  } = useFormContext();

  const { report, ids } = getValues()!;
  const request = useRecordContext();

  const isValidForm = isValid && fromVariant !== '' && toVariant !== '';

  const handleBack = useCallback(() => {
    reset();
    if (isFunction(setRecord)) {
      setRecord!(undefined);
    }
    setFromVariant('');
    setToVariant('');
    handleOpenChange();
  }, [handleOpenChange, reset, setFromVariant, setRecord, setToVariant]);

  useEffect(() => {
    if (loading) { setTimeout(() => setLoading(() => false), 1000); }
  }, [loading]);

  const handleSave = useCallback(() => {
    localSetValues('toVariant', toVariant);
    localSetValues('fromVariant', fromVariant);
    if (isEditView) {
      setLoading(() => true);
      setValue('report.transactions', [localGetValues(),
        ...(report?.transactions ?? [])
          .filter((
            transaction: { transactionnumber: string},
          ) => transaction.transactionnumber !== localGetValues().transactionnumber)]);
    } else {
      setValue('report.transactions', [localGetValues(), ...(report?.transactions ?? [])]);
      if (isCustom) {
        setCustomIsAdded(() => true);
        setLoading(() => true);
      }
      if (!isCustom) {
        setValue('ids', [request?.id, ...(ids ?? [])]);
      }
    }
    handleBack();
    handleCloseDialog();
  }, [
    handleBack,
    ids,
    isCustom,
    isEditView,
    localGetValues,
    report?.transactions,
    request?.id,
    setValue,
    toVariant,
    fromVariant,
    localSetValues,
    handleCloseDialog,
  ]);

  return (
    <Box width="100%" justifyContent={(!isCustom && !isEditView) ? 'space-between' : 'flex-end'} display="flex">
      {(!isCustom && !isEditView) && (
        <Button startIcon={<ArrowBackIosNewIcon />} onClick={handleBack}>
          Back
        </Button>
      )}
      {(!customIsAdded && !isEditView) && <Button startIcon={<AddIcon />} disabled={!isValidForm} onClick={handleSave} variant="contained">Add</Button>}
      {(loading && isCustom) && <Button disabled><CircularProgress size={24} /></Button>}
      {(loading && isEditView) && <Button disabled><CheckIcon color="primary" /></Button>}
      {(customIsAdded && !loading) && <Button disabled={!isValidForm} startIcon={<EditIcon />} onClick={handleSave} variant="outlined">Edit</Button>}
      {(isEditView && !loading) && <Button disabled={!isValidForm || !isDirty} startIcon={<SaveIcon />} onClick={handleSave} variant="outlined">Save</Button>}
    </Box>
  );
};

export default Actions;
