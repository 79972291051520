import { ValueOf } from '../utilities/types';

export const requestTypes = {
  TRANSACTION: 'transaction',
  CAR_KYC: 'carKyc',
  CAR_PURCHASE: 'carPurchase',
  INDIVIDUAL_KYC: 'individualKyc',
  BUSINESS_KYC: 'businessKyc',
} as const;

export const requestTypeLabels = {
  [requestTypes.TRANSACTION]: 'Transaction',
  [requestTypes.CAR_KYC]: 'Car KYC',
  [requestTypes.CAR_PURCHASE]: 'Car Purchase',
  [requestTypes.INDIVIDUAL_KYC]: 'Individual KYC',
  [requestTypes.BUSINESS_KYC]: 'Business KYC',
};

const getRequestTypeChoice = (requestType: RequestTypes) => ({
  id: requestType,
  name: requestTypeLabels[requestType],
});

const requestTypesChoices = {
  TRANSACTION: getRequestTypeChoice(requestTypes.TRANSACTION),
  CAR_KYC: getRequestTypeChoice(requestTypes.CAR_KYC),
  CAR_PURCHASE: getRequestTypeChoice(requestTypes.CAR_PURCHASE),
  INDIVIDUAL_KYC: getRequestTypeChoice(requestTypes.INDIVIDUAL_KYC),
  BUSINESS_KYC: getRequestTypeChoice(requestTypes.BUSINESS_KYC),
};

export type RequestTypes = ValueOf<typeof requestTypes>;

export default requestTypesChoices;
