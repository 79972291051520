import React from 'react';

import { useRecordContext, useRedirect } from 'react-admin';

import { Button } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';

import entityTypes from '../constants/entityTypes';

const ShowButton = ({ resourceLink, linkType = 'edit' }: { resourceLink?: string, linkType?: 'edit' | 'show'}) => {
  const record = useRecordContext();
  const redirect = useRedirect();

  const resource = (() => {
    if (!resourceLink) {
      switch (record?.type) {
        case entityTypes.INDIVIDUAL:
          return 'individuals';
        case entityTypes.BUSINESS:
          return 'businesses';
        case entityTypes.CAR:
          return 'cars';
        default:
          return null;
      }
    }
    return resourceLink;
  })();

  if (!resource) return null;

  return (
    <Button startIcon={<VisibilityIcon />} onClick={() => redirect(`/${resource}/${record?.id}${linkType === 'show' ? '/show' : ''}`)}>
      Show
    </Button>
  );
};

export default ShowButton;
