import React, { useState } from 'react';

import {
  RecordContextProvider,
  useRecordContext,
} from 'react-admin';

import Car from './Car';
import ToggleButton from './ToggleButton';

import Accordion from '../../../../../layout/Accordion';

const PATH = 'goods_services.0.item';

const GoodsAccordion = () => {
  const [value, setValue] = useState<boolean>();
  const record = useRecordContext();
  const extraInfo = record?.extraInformation;
  const car = extraInfo?.car;

  return (
    <Accordion label="Car" actions={<ToggleButton value={value} setValue={setValue} />}>
      <RecordContextProvider value={{
        [`${PATH}.estimated_value`]: extraInfo?.valuation,
        [`${PATH}.item_type`]: 'FORDB',
        [`${PATH}.description`]: undefined,
        ...car,
      }}
      >
        {value && <Car path={PATH} />}
      </RecordContextProvider>
    </Accordion>

  );
};

export default GoodsAccordion;
