import React from 'react';

import {
  DateField,
  FunctionField,
  SelectField,
  TextField,
  useRecordContext,

} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import { isEmpty, isString } from 'lodash';

import { Alpha3Code, getName } from 'i18n-iso-countries';

import { AdverseMediaMatches } from '../../../../../../utilities/schemas/adverseMediaMatches';

import AdverseMediaTitle from './AdverseMediaTitle';

import languages from '../../../../../../constants/languages';

const getPersonName = ({ firstName, lastName, fullName }: {
  firstName?: string;
  lastName?: string;
  fullName?: string;
}) => {
  if (fullName && !isEmpty(fullName)) {
    return fullName;
  }

  return `${firstName} ${lastName}`;
};

const getAddress = ({
  streetAddress,
  additionalAddress,
  city,
  country,
  description,
  postcode,
  state,
}: {
  streetAddress?: string;
  additionalAddress?: string;
  postcode?: string;
  city?: string;
  state?: string;
  country?: Alpha3Code;
  description?: string;
}) => {
  if (description && !isEmpty(description)) {
    return description;
  }

  const address = [
    streetAddress,
    additionalAddress,
    postcode,
    city,
    state,
    country ? getName(country, languages.EN) : null,
  ].filter((item) => item && isString(item) && !isEmpty(item));

  return address.join(', ');
};

const AdverseMediaAbout = () => {
  const record = useRecordContext<AdverseMediaMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="About" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Name</AdverseMediaTitle>
                <TextField source="name" record={{ name: getPersonName(record) }} emptyText="-" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Birth date</AdverseMediaTitle>
                <DateField source="birthDate" emptyText="-" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Other names</AdverseMediaTitle>
                <FunctionField
                  sx={{ whiteSpace: 'pre' }}
                  source="otherNames"
                  render={(item: AdverseMediaMatches['potentialMatches'][number]) => {
                    if (isEmpty(item.otherNames)) return '-';
                    return record.otherNames
                      .map((otherName) => getPersonName(otherName))
                      .join('\n');
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Gender</AdverseMediaTitle>
                <TextField source="gender" emptyText="-" />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Address</AdverseMediaTitle>
                <FunctionField
                  sx={{ whiteSpace: 'pre' }}
                  source="address"
                  render={(item: AdverseMediaMatches['potentialMatches'][number]) => {
                    if (!item.address) return '-';
                    return getAddress(item.address);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Other addresses</AdverseMediaTitle>
                <FunctionField
                  sx={{ whiteSpace: 'pre' }}
                  source="otherAddresses"
                  render={(item: AdverseMediaMatches['potentialMatches'][number]) => {
                    if (isEmpty(item.otherAddresses)) return '-';
                    return record.otherAddresses
                      .map((otherAddress) => getAddress(otherAddress))
                      .join('\n');
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Birth place</AdverseMediaTitle>
                <FunctionField
                  sx={{ whiteSpace: 'pre' }}
                  source="birthPlace"
                  render={(item: AdverseMediaMatches['potentialMatches'][number]) => {
                    if (!item.birthPlace) return '-';
                    return getAddress(item.birthPlace);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box>
                <AdverseMediaTitle>Is deceased</AdverseMediaTitle>
                <SelectField
                  source="isDecased"
                  choices={[{
                    id: true,
                    name: 'Yes',
                  }, {
                    id: false,
                    name: 'No',
                  }]}
                  emptyText="-"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AdverseMediaAbout;
