import React, { useCallback } from 'react';

import {
  Datagrid,
  DateField,
  Edit,
  Pagination,
  ReferenceManyField,
  SimpleForm,
  TextField,
  usePermissions,
  useRedirect,
  TabbedShowLayout,
  Labeled,
} from 'react-admin';

import {
  Box,
  Card,
  Grid,
  SxProps,
} from '@mui/material';

import { Routes, Route, useParams } from 'react-router-dom';
import { isString } from 'lodash';
import useCustomEditView from '../../hooks/useCustomEditView';

import { StyledTab, StyledTabs } from '../layout/Tab';
import SubNavigation from '../layout/SubNavigation';

import { AccountEntityListBody } from '../accountEntity/AccountEntityList';

import CarOwnership from '../../customFields/CarOwnership';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import CustomToolbar from '../../customFields/CustomToolbar';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

import hasAccess from '../../utilities/hasAccess';

import IndividualBreadcrumb from './IndividualBreadcrumb';

import Transaction from '../transaction';
import Categorization from '../categorization';
import KycForm from '../kycForm';
import KycFormView from '../kycForm/view';

import ConflictResolution from '../conflict/ConflictResolution';

import useValidateId from '../../hooks/useValidateId';

import Information from './editTabs/Information';
import BusinessAssociation from './editTabs/kyc/BusinessAssociation';
import SPAR from './editTabs/kyc/PopulationRegister';
import Pep from './editTabs/kyc/pep';
import PepDetails from './editTabs/kyc/pep/details';
import SanctionList from './editTabs/kyc/sanction';
import SanctionDetails from './editTabs/kyc/sanction/details';
import CriminalRecord from './editTabs/kyc/CriminalRecord';
import AdverseMedia from './editTabs/kyc/adverseMedia';
import AdverseMediaDetails from './editTabs/kyc/adverseMedia/details';

import resourceTypes from '../../constants/resourceTypes';
import { entityCleaner } from '../../utilities/dataProvider/cleaner';

const customEditSx = (isCustomView: boolean): SxProps => (
  isCustomView
    ? { '& .RaEdit-card': { backgroundColor: 'transparent', boxShadow: 'none' } }
    : {}
);

const IndividualEditBody = () => {
  const { permissions } = usePermissions<Actions[]>();
  const redirect = useRedirect();
  const { '*': path } = useParams();
  const kycTab = path && isString(path) && path.split('/').length > 1 ? path.split('/')[1] : 'spar';
  const { isCustomView } = useCustomEditView();

  const handleKycTabChange = useCallback((
    _event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    redirect(`kyc-data/${newValue}`);
  }, [redirect]);

  return (
    <TabbedShowLayout
      sx={isCustomView ? { display: 'none' } : {}}
    >
      <TabbedShowLayout.Tab label="Information">
        <Labeled label={false} sx={{ display: 'flex' }}>
          <SimpleForm
            mode="all"
            toolbar={
              <CustomToolbar sx={{ marginBottom: -6, marginLeft: -4, marginRight: -4 }} />
            }
            sx={{ padding: 0 }}
            component={Box}
          >
            <Information />
          </SimpleForm>
        </Labeled>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="KYC Data"
        path={`kyc-data/${kycTab}`}
        value="pep"
      >
        <SubNavigation>
          <StyledTabs value={kycTab} onChange={handleKycTabChange}>
            <StyledTab label="Population register" value="spar" />
            <StyledTab label="Business Association" value="business-association" />
            <StyledTab label="PEP" value="pep" />
            <StyledTab label="Sanction List" value="sanction-list" />
            <StyledTab label="Criminal record" value="criminal-record" />
            <StyledTab label="Adverse media" value="adverse-media" />
          </StyledTabs>
        </SubNavigation>
        {kycTab === 'spar' && <SPAR />}
        {kycTab === 'business-association' && <BusinessAssociation />}
        {kycTab === 'pep' && <Pep />}
        {kycTab === 'sanction-list' && <SanctionList />}
        {kycTab === 'criminal-record' && <CriminalRecord />}
        {kycTab === 'adverse-media' && <AdverseMedia />}
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="KYC Form" path="form-answers">
        <KycForm />
      </TabbedShowLayout.Tab>
      {hasAccess(permissions, actions.CAR_GET) && (
        <TabbedShowLayout.Tab label="Car ownership" path="cars">
          <CarOwnership />
        </TabbedShowLayout.Tab>
      )}
      {hasAccess(permissions, actions.REQUEST_GET) && (
        <TabbedShowLayout.Tab label="Transactions" path="transactions">
          <Transaction />
        </TabbedShowLayout.Tab>
      )}
      {hasAccess(permissions, actions.ACCOUNT_ENTITY_GET) && (
        <TabbedShowLayout.Tab label="Connected Accounts" path="accounts">
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <ReferenceManyField
                  fullWidth
                  perPage={5}
                  pagination={<Pagination rowsPerPageOptions={[5, 10, 20]} />}
                  reference="account-entities"
                  target="connectedEntities.id"
                  sort={{ field: 'createdAt', order: 'DESC' }}
                >
                  <AccountEntityListBody />
                </ReferenceManyField>
              </Card>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
      )}
      {hasAccess(permissions, actions.BANK_DATA_GET_CATEGORIZATION) && (
        <TabbedShowLayout.Tab label="Categorization" path="categorization">
          <Categorization />
        </TabbedShowLayout.Tab>
      )}
      {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
        <TabbedShowLayout.Tab label="Audit Tracks" path="audit-tracks">
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <ReferenceManyField
                  reference="audit-tracks"
                  target="resource.id"
                  filter={{ 'resource.type': resourceTypes.INDIVIDUAL }}
                  sort={{ field: 'date', order: 'DESC' }}
                  pagination={<Pagination />}
                >
                  <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                    <TextField sortable={false} source="id" />
                    <ReferenceMemberField label="User" />
                    <TextField sortable={false} source="description" />
                    <DateField sortable source="date" showTime />
                  </Datagrid>
                </ReferenceManyField>
              </Card>
            </Grid>
          </Grid>
        </TabbedShowLayout.Tab>
      )}
    </TabbedShowLayout>
  );
};

const IndividualsEdit = () => {
  const { isCustomView } = useCustomEditView();

  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit
      mutationMode="pessimistic"
      redirect={false}
      sx={customEditSx(isCustomView)}
      transform={entityCleaner}
    >
      <>
        <IndividualBreadcrumb />

        {/* Custom nested routes */}
        <Routes>
          <Route path="/form-answers/:formId/:kycFormId/:formPage">
            <Route path="" Component={KycFormView} />
          </Route>
          <Route path="conflict-resolution" Component={ConflictResolution} />
          <Route path="kyc-data/pep/:pepId" Component={PepDetails} />
          <Route path="kyc-data/sanction-list/:sanctionId" Component={SanctionDetails} />
          <Route path="kyc-data/adverse-media/:adverseMediaId" Component={AdverseMediaDetails} />
          <Route
            path="*"
            element={(
              <IndividualEditBody />
            )}
          />
        </Routes>
      </>
    </Edit>
  );
};

export default IndividualsEdit;
