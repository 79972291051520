import {
  darken,
  lighten,
  Tab,
  Tabs,
  Theme,
  styled,
} from '@mui/material';

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
});

export const StyledTabWhite = styled(Tab)(({ theme }: { theme: Theme}) => ({
  '&:hover': {
    backgroundColor: darken('#e5e5e5', 0.1),
    color: darken(theme.palette.primary.main, 0.8),

  },
  '&.Mui-selected': {
    backgroundColor: 'white',
    fontWeight: 'bold',
    color: darken(theme.palette.primary.main, 0.4),
  },
  backgroundColor: '#e5e5e5',
  color: theme.palette.primary.main,
  width: 175,
}));

export const StyledTab = styled(Tab)(({ theme }: { theme: Theme}) => ({
  '&:hover': {
    backgroundColor: lighten('#0000000a', 0.4),
    color: lighten(theme.palette.primary.main, 0.4),

  },
  '&.Mui-selected': {
    backgroundColor: '#0000000a',
    color: theme.palette.primary.main,
  },
  width: 175,
  minHeight: 0,
}));

export const StyledSubTab = styled(Tab)(({ theme }: { theme: Theme}) => ({
  '&:hover': {
    color: theme.palette.primary.main,
  },
  fontSize: 11,
  '&.Mui-selected': {
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}));

export const BlueTab = styled(Tab)(() => ({
  backgroundColor: '#1F487E14',
  margin: 0,
  color: '#1F487E',
  '&:hover': {
    backgroundColor: darken('#1F487E14', 0.8),
    color: darken('#1F487E', 0.08),

  },
  '&.Mui-selected': {
    backgroundColor: '#1F487E',
    color: 'white',
  },
  width: 175,
  minHeight: 0,
}));

export const RoundBlueTab = styled(Tab)(() => ({
  backgroundColor: '#1F487E14',
  margin: 0,
  color: '#1F487E',
  '&:hover': {
    backgroundColor: darken('#1F487E14', 0.8),
    color: darken('#1F487E', 0.08),

  },
  '&.Mui-selected': {
    backgroundColor: '#1F487E',
    color: 'white',
  },
  width: 175,
  minHeight: 0,
}));
