import React, { useCallback } from 'react';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Button,
} from '@mui/material';

import {
  TextField,
  ArrayField,
  Datagrid,
  SingleFieldList,
  ChipField,
  DateField,
  useRecordContext,
} from 'react-admin';

import { Visibility } from '@mui/icons-material';

import { get } from 'lodash';
import useEntityData from '../../../../hooks/useEntityData';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import actions from '../../../../constants/actions';

import PurchaseButton from '../../../layout/button/PurchaseButton';
import NoResults from '../../../layout/NoResults';
import { BeneficialOwner } from '../../../../utilities/schemas/beneficialOwner';
import { Engagements } from '../../../../utilities/schemas/engagements';

const useBusinessAssociation = () => {
  const {
    data: benOwnerData,
    handlePurchase: handlePurchaseBenOwner,
    isLoading: isLoadingBenOwner,
  } = useEntityData<BeneficialOwner>({
    resource: 'individuals',
    source: 'beneficial-owner',
    getAction: actions.INDIVIDUAL_GET_BUSINESS_ASSOCIATION,
  });
  const {
    data: engagementData,
    handlePurchase: handlePurchaseEngagements,
    isLoading: isLoadingEngagements,
  } = useEntityData<Engagements>({
    resource: 'individuals',
    source: 'engagements',
    getAction: actions.INDIVIDUAL_GET_BUSINESS_ASSOCIATION,
  });

  return {
    benOwnerData,
    engagementData,
    handlePurchaseBenOwner,
    handlePurchaseEngagements,
    isLoading: isLoadingBenOwner || isLoadingEngagements,
  };
};

const OpenField = ({
  source,
}: {
  source: string
}) => {
  const record = useRecordContext();

  const handelClick = useCallback(() => {
    window.open(`/businesses/${get(record, [source])}`, '_blank');
  }, [record, source]);

  return (
    <Button startIcon={<Visibility />} onClick={handelClick}>
      Open
    </Button>
  );
};

const BusinessAssociation = () => {
  const {
    benOwnerData,
    engagementData,
    handlePurchaseBenOwner,
    handlePurchaseEngagements,
    isLoading,
  } = useBusinessAssociation();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Engagements"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  record={{ createdAt: engagementData?.createdAt }}
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="createdAt"
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Engagements"
                onClick={handlePurchaseEngagements}
                action={actions.INDIVIDUAL_CREATE_BUSINESS_ASSOCIATION}
              />
            )}
          />
          <Divider />
          <CardContent>
            <Card variant="outlined">
              <ArrayField record={engagementData} source="engagements">
                <Datagrid
                  empty={<NoResults />}
                  sx={boldDataGridStyle}
                  bulkActionButtons={false}
                >
                  <TextField label="Company ID" emptyText="-" source="companyId" />
                  <TextField emptyText="-" source="companyName" />
                  <TextField emptyText="-" source="commune" />
                  <ArrayField source="roles">
                    <SingleFieldList linkType={false}>
                      <ChipField source="roleName" />
                    </SingleFieldList>
                  </ArrayField>
                  <ArrayField label="From date" source="roles">
                    <SingleFieldList linkType={false}>
                      <ChipField source="fromDate" />
                    </SingleFieldList>
                  </ArrayField>
                  <OpenField source="entityId" />
                </Datagrid>
              </ArrayField>
            </Card>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader
            title="Beneficial Ownership"
            subheader={(
              <Box display="flex" alignItems="baseline">
                <Typography>Latest update:</Typography>
                <DateField
                  record={{ createdAt: benOwnerData?.createdAt }}
                  sx={{ marginLeft: 1, fontWeight: 'bold' }}
                  source="createdAt"
                  emptyText="-"
                  showTime
                />
              </Box>
            )}
            action={(
              <PurchaseButton
                loading={isLoading}
                label="Get Beneficial Ownership"
                onClick={handlePurchaseBenOwner}
                action={actions.INDIVIDUAL_CREATE_BUSINESS_ASSOCIATION}
              />
            )}
          />
          <Divider />
          <CardContent>
            <Card variant="outlined">
              <ArrayField record={benOwnerData} source="records">
                <Datagrid
                  empty={<NoResults />}
                  sx={boldDataGridStyle}
                  bulkActionButtons={false}
                >
                  <TextField label="Company ID" emptyText="-" source="association.companyId" />
                  <TextField label="Company Name" emptyText="-" source="association.companyName" />
                  <OpenField source="entityId" />
                </Datagrid>
              </ArrayField>
            </Card>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  );
};

export default BusinessAssociation;
