import React from 'react';
import {
  Datagrid,
  TextField,
  DateField,
  List,
} from 'react-admin';

import {
  Card,
} from '@mui/material';

import ReferenceResourceField from '../../customFields/ReferenceResourceField';
import ReferenceMemberField from '../../customFields/ReferenceMemberField';

import AuditFilter from './AuditFilter';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';

const AuditList = () => (
  <List
    filters={<AuditFilter />}
    sort={{ field: 'date', order: 'DESC' }}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
        <TextField sortable={false} source="id" />
        <TextField sortable={false} source="description" />
        <ReferenceResourceField label="Resource" />
        <TextField sortable={false} source="resource.type" label="Resource type" />
        <TextField sortable={false} source="authenticator.type" label="User type" emptyText="-" />
        <ReferenceMemberField sortable={false} label="User" />
        <DateField sortable source="date" showTime />
      </Datagrid>
    </Card>
  </List>
);

export default AuditList;
