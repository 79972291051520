import React, { useMemo, useEffect } from 'react';

import {
  AutocompleteArrayInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  useGetOne,
} from 'react-admin';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Card,
  Divider,
  Grid,
  Grow,
  Typography,
  Button,
} from '@mui/material';

import { useFieldArray, useWatch } from 'react-hook-form';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';

import { get, toLower } from 'lodash';
import { RequestRules, rulePartiesToCheck } from '../ruleFeatures';

import Label from '../../../customFields/Label';
import ruleTypes from '../../../constants/ruleTypes';
import { requestRoles } from '../../../constants/requestRoles';
import { RuleNames } from '../../../constants/ruleNames';
import { RequestTypes } from '../../../constants/requestTypes';

const SOURCE = 'entitiesToCheck';

type FormValues = {
  name: RuleNames;
  process: string;
  entitiesToCheck: {}[];
  ruleType: string;
}

const EntitiesToCheck = () => {
  const ruleType = useWatch<FormValues, 'ruleType'>({ name: 'ruleType' });
  const ruleName = useWatch<FormValues, 'name'>({ name: 'name' });
  const process = useWatch<FormValues, 'process'>({ name: 'process' });
  const entitiesToCheck = useWatch<FormValues, 'entitiesToCheck'>({ name: 'entitiesToCheck' });
  const { data: processData } = useGetOne<{
    id: string;
    requestType: RequestTypes;
  }>('processes', { id: process }, { enabled: !!process });

  const { fields, append, remove } = useFieldArray({
    name: SOURCE,
  });

  useEffect(() => {
    if (fields.length === 0) append({});
  }, [fields.length, append]);

  const choices = useMemo(() => {
    if (!ruleType) return [];
    if (ruleType === ruleTypes.ONGOING) {
      return [{
        id: requestRoles.ENTITY,
        name: 'Entity',
      }];
    }
    if (!processData) return [];

    const { requestType } = processData;

    const ruleParties = rulePartiesToCheck[ruleName as RequestRules];
    if (!ruleParties) return [];

    const reqParties = ruleParties[requestType].map((item) => ({
      id: item,
      name: capitalize(item),
    }));

    return reqParties;
  }, [processData, ruleName, ruleType]);

  if (ruleType === ruleTypes.REQUEST && !process) return null;

  return (
    <Grid item xs={12} md={12}>
      <Grow in>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Label
              variant="label"
              title="Entities to check"
              info="Specify which entities should be checked by this rule. It is possible to add several time the same role but with different conditions, the rule will check all of the matching entities."
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box width="100%">
                {fields.map((field, index) => (
                  <Card variant="outlined" square key={field.id}>
                    <Accordion
                      key={`Entity to Check #${index + 1}`}
                      sx={{ maxWidth: null, backgroundColor: 'transparent' }}
                      defaultExpanded
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                          }}
                          variant="description"
                          color="secondary"
                        >
                          {`Entity to Check #${index + 1}`}
                        </Typography>
                      </AccordionSummary>
                      <Divider />
                      <AccordionDetails sx={{ p: '2%' }}>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <Typography variant="h6" marginBottom={4}>
                              Role to check
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box display="flex" flexDirection="column" gap={2}>
                              <Typography variant="label">
                                Which role in the request should be checked by the rule
                              </Typography>
                              <SelectInput
                                source={`${SOURCE}.[${index}].role`}
                                fullWidth
                                choices={choices}
                                label="Role"
                                validate={required()}
                              />
                            </Box>
                          </Grid>
                          {get(entitiesToCheck, `[${index}].role`) && (
                            <>
                              <Grid item xs={12} md={12} marginTop={6} marginBottom={4}>
                                <Typography variant="h6">
                                  Group filtering
                                </Typography>
                              </Grid>
                              {choices.map((role) => (
                                <Grid item container xs={12} key={role.id} marginY={2}>
                                  <Grid item xs={12} md={12}>
                                    <Box display="flex" flexDirection="column">
                                      <Typography variant="label">
                                        Would you like to filter the
                                        {' '}
                                        {toLower(role.name)}
                                        {' '}
                                        on groups?
                                      </Typography>
                                      <RadioButtonGroupInput
                                        label={false}
                                        defaultValue={false}
                                        parse={(value) => value === 'true'}
                                        source={`${SOURCE}.[${index}].groupsFiltering.${role.id}.shouldFilterOnGroups`}
                                        choices={[
                                          { id: true, name: 'Yes' },
                                          { id: false, name: 'No' },
                                        ]}
                                        helperText={false}
                                      />
                                    </Box>
                                  </Grid>
                                  {get(entitiesToCheck, `[${index}].groupsFiltering.${role.id}.shouldFilterOnGroups`) && (
                                    <Grid item container xs={12} padding={4} sx={{ backgroundColor: 'greenGray.main', borderRadius: 2 }}>
                                      <Grid item container xs={12} marginY={1}>
                                        <Grid item xs={12} md={12}>
                                          <Box display="flex" flexDirection="column" gap={2}>
                                            <Typography variant="label">
                                              Specify the groups that the
                                              {' '}
                                              {toLower(role.name)}
                                              {' '}
                                              must be included in
                                              {' '}
                                              <b>to execute</b>
                                              {' '}
                                              the rule
                                            </Typography>
                                            <ReferenceArrayInput
                                              perPage={200}
                                              filter={{ active: true }}
                                              reference="groups"
                                              source={`${SOURCE}.[${index}].groupsFiltering.${role.id}.groupsToInclude`}
                                            >
                                              <AutocompleteArrayInput
                                                filterToQuery={(q) => ({ name: q })}
                                                source="name"
                                                label="Groups"
                                                optionText="name"
                                                helperText={false}
                                              />
                                            </ReferenceArrayInput>
                                          </Box>
                                        </Grid>
                                        {get(entitiesToCheck, `[${index}].groupsFiltering.${role.id}.groupsToInclude`, []).length > 1 && (
                                          <Grid item xs={12} md={12}>
                                            <RadioButtonGroupInput
                                              label={false}
                                              defaultValue="or"
                                              source={`${SOURCE}.[${index}].groupsFiltering.${role.id}.groupsToIncludeCondition`}
                                              choices={[
                                                { id: 'or', name: 'Any of these groups' },
                                                { id: 'and', name: 'All of these groups' },
                                              ]}
                                              helperText={false}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                      <Grid item container xs={12} marginY={1}>
                                        <Grid item xs={12} md={12}>
                                          <Box display="flex" flexDirection="column" gap={2}>
                                            <Typography variant="label">
                                              Specify the groups that the
                                              {' '}
                                              {toLower(role.name)}
                                              {' '}
                                              must be included in
                                              {' '}
                                              <b>to NOT execute</b>
                                              {' '}
                                              the rule
                                            </Typography>
                                            <ReferenceArrayInput
                                              perPage={200}
                                              filter={{ active: true }}
                                              reference="groups"
                                              source={`${SOURCE}.[${index}].groupsFiltering.${role.id}.groupsToExclude`}
                                            >
                                              <AutocompleteArrayInput
                                                filterToQuery={(q) => ({ name: q })}
                                                source="name"
                                                label="Groups"
                                                optionText="name"
                                                helperText={false}
                                              />
                                            </ReferenceArrayInput>
                                          </Box>
                                        </Grid>
                                        {get(entitiesToCheck, `[${index}].groupsFiltering.${role.id}.groupsToExclude`, []).length > 1 && (
                                          <Grid item xs={12} md={12}>
                                            <RadioButtonGroupInput
                                              label={false}
                                              defaultValue="or"
                                              source={`${SOURCE}.[${index}].groupsFiltering.${role.id}.groupsToExcludeCondition`}
                                              choices={[
                                                { id: 'or', name: 'Any of these groups' },
                                                { id: 'and', name: 'All of these groups' },
                                              ]}
                                              helperText={false}
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                              <Grid item xs={12} md={12} marginTop={6} marginBottom={4}>
                                <Typography variant="h6">
                                  Group assignment
                                </Typography>
                              </Grid>
                              {choices.map((role) => (
                                <Grid item container xs={12} key={role.id} marginY={2}>
                                  <Grid item xs={12} md={12}>
                                    <Box display="flex" flexDirection="column">
                                      <Typography variant="label">
                                        Would you like to add or remove groups for the
                                        {' '}
                                        {toLower(role.name)}
                                        {' '}
                                        when the rule is triggered?
                                      </Typography>
                                      <RadioButtonGroupInput
                                        label={false}
                                        defaultValue={false}
                                        parse={(value) => value === 'true'}
                                        source={`${SOURCE}.[${index}].groupsAssignment.${role.id}.shouldAssignGroupsOnTrigger`}
                                        choices={[
                                          { id: true, name: 'Yes' },
                                          { id: false, name: 'No' },
                                        ]}
                                        helperText={false}
                                      />
                                    </Box>
                                  </Grid>
                                  {get(entitiesToCheck, `[${index}].groupsAssignment.${role.id}.shouldAssignGroupsOnTrigger`) && (
                                    <Grid item container xs={12} padding={4} sx={{ backgroundColor: 'greenGray.main', borderRadius: 2 }}>
                                      <Grid item xs={12} md={12} marginY={1}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                          <Typography variant="label">
                                            Specify the groups to add to the
                                            {' '}
                                            {toLower(role.name)}
                                          </Typography>
                                          <ReferenceArrayInput
                                            perPage={200}
                                            filter={{ active: true }}
                                            reference="groups"
                                            source={`${SOURCE}.[${index}].groupsAssignment.${role.id}.groupsToAddOnTrigger`}
                                          >
                                            <AutocompleteArrayInput
                                              filterToQuery={(q) => ({ name: q })}
                                              source="name"
                                              label="Groups"
                                              optionText="name"
                                              helperText={false}
                                            />
                                          </ReferenceArrayInput>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={12} marginY={1}>
                                        <Box display="flex" flexDirection="column" gap={2}>
                                          <Typography variant="label">
                                            Specify the groups to remove from the
                                            {' '}
                                            {toLower(role.name)}
                                          </Typography>
                                          <ReferenceArrayInput
                                            perPage={200}
                                            filter={{ active: true }}
                                            reference="groups"
                                            source={`${SOURCE}.[${index}].groupsAssignment.${role.id}.groupsToRemoveOnTrigger`}
                                          >
                                            <AutocompleteArrayInput
                                              filterToQuery={(q) => ({ name: q })}
                                              source="name"
                                              label="Groups"
                                              optionText="name"
                                              helperText={false}
                                            />
                                          </ReferenceArrayInput>
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                              ))}
                            </>
                          )}
                        </Grid>
                        <Grid justifyContent="end" display="flex" item xs={12} md={12}>
                          <Button color="red" startIcon={<RemoveIcon />} onClick={() => remove(index)}>Remove</Button>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Card>
                ))}
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => append({})}
                >
                  Add New Entity
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );
};

export default EntitiesToCheck;
