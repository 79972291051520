import React, { useCallback } from 'react';

import { isNil, isEmpty, isString } from 'lodash';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

import {
  ReferenceInput,
  useRecordContext,
  useUpdate,
  useNotify,
  useRefresh,
  useGetIdentity,
  ReferenceField,
  FunctionField,
  Form,
  RecordContextProvider,
  required,
  AutocompleteInput,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';
import { Cancel } from '@mui/icons-material';
import useDialogStatus from '../../../hooks/useDialogStatus';

const PickMeButton = ({
  source,
  label,
}: {
  source: string;
  label: string;
}) => {
  const { setValue } = useFormContext();
  const { identity } = useGetIdentity();

  const handleAssignMe = useCallback(() => {
    if (identity?.id) {
      setValue(source, identity?.id);
    }
  }, [identity?.id, setValue, source]);

  return (
    <Button onClick={handleAssignMe} size="small">{label}</Button>
  );
};

const UnAssignButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const [update] = useUpdate();

  const handleSubmit = useCallback(() => {
    update(
      'cases',
      {
        id: `${record?.id}/unassign`,
        data: {
          unassignFrom: record?.assignment.memberId,
        },
      },
      {
        onSuccess: () => {
          notify('Case assignment have been updated');
          refresh();
          closeDialog();
        },
        onError: () => {
          notify('Could not unassign the case. This might be because the case has already been assigned to someone else.', { type: 'error' });
          refresh();
        },
      },
    );
  }, [update, record?.id, record?.assignment.memberId, notify, refresh, closeDialog]);

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Form record={{}} onSubmit={handleSubmit}>
          <DialogTitle fontWeight="bold">
            Unassign case
          </DialogTitle>
          <DialogContent>
            <Typography>
              By continuing, you will unassign
              {' '}
              <RecordContextProvider value={record}>
                <ReferenceField
                  label="Member"
                  source="assignment.memberId"
                  reference="members"
                  link={false}
                >
                  <FunctionField
                    render={(member: {firstName: string, lastName: string}) => `${member.firstName} ${member.lastName}`}
                  />
                </ReferenceField>
              </RecordContextProvider>
              {' '}
              from the case. Do you want to continue?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Unassign
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Tooltip title="Unassign">
        <IconButton
          color="primary"
          onClick={openDialog}
        >
          <PersonRemoveIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const AssignButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const { open, openDialog, closeDialog } = useDialogStatus();

  const [update] = useUpdate();

  const handleSubmit = useCallback(({
    newAssignment,
  }: { newAssignment?: string }) => {
    update(
      'cases',
      { id: `${record?.id}/assign`, data: { newAssignment } },
      {
        onSuccess: () => {
          notify('Case assignment have been updated');
          refresh();
          closeDialog();
        },
        onError: () => {
          notify('Could not assign case. This might be because someone else has already assigned the case.', { type: 'error' });
          refresh();
        },
      },
    );
  }, [update, record?.id, notify, refresh, closeDialog]);

  return (
    <>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <Form record={{}} onSubmit={handleSubmit}>
          <DialogTitle fontWeight="bold">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" fontWeight="bold">Assign case</Typography>
              <IconButton onClick={closeDialog}>
                <Cancel />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              alignItems: 'flex-end',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <PickMeButton source="newAssignment" label="Assign me" />
            <ReferenceInput source="newAssignment" reference="members" label="Member" filter={{ active: true }}>
              <AutocompleteInput
                fullWidth
                filterSelectedOptions={false}
                filterToQuery={(searchText) => ({ name: searchText })}
                optionText={(item: {firstName: string, lastName: string}) => `${item.firstName} ${item.lastName}`}
                validate={[required()]}
              />
            </ReferenceInput>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="submit">
              Assign
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
      <Tooltip title="Assign">
        <IconButton
          color="primary"
          onClick={openDialog}
        >
          <PersonAddIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const CaseAssign = ({
  label,
}: {
  label: string;
}) => {
  const record = useRecordContext();

  return (
    <Box display="flex" flexDirection="row" gap={10} alignItems="center" justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <ReferenceField emptyText="-" label={label} source="assignment.memberId" reference="members" link="edit">
          <FunctionField
            sx={{ textDecoration: 'underline' }}
            render={(member: {firstName: string, lastName: string}) => `${member.firstName} ${member.lastName}`}
          />
        </ReferenceField>
      </Box>
      <>
        {(isNil(record?.assignment?.memberId) || isEmpty(record?.assignment?.memberId))
          && <AssignButton />}
        {isString(record?.assignment?.memberId) && <UnAssignButton />}
      </>
    </Box>
  );
};

export default CaseAssign;
