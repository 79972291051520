const menuGroups = {
  HOME: 'home',
  ENTITIES: 'entities',
  RISK_MONITORING: 'risk-monitoring',
  RISK_SETUP: 'risk-setup',
  ADMINISTRATION: 'administration',
};

export const orderedMenuGroups = [
  { id: menuGroups.HOME, label: '' },
  { id: menuGroups.RISK_MONITORING, label: 'Risk Monitoring' },
  { id: menuGroups.ENTITIES, label: 'Entities' },
  { id: menuGroups.RISK_SETUP, label: 'Risk Setup' },
  { id: menuGroups.ADMINISTRATION, label: 'Administration' },
];

export default menuGroups;
