import React from 'react';

import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  NumberInput,
  maxValue,
  minValue,
  RecordContextProvider,
  ArrayField,
  Datagrid,
  TextField,
  useGetOne,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
} from '@mui/material';

import { useFieldArray, useFormState } from 'react-hook-form';

import Label from '../../customFields/Label';
import AddEntities from './components/AddEntities';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import ReferenceRiskClassField from '../../customFields/ReferenceRiskClassField';
import ReferenceScoreField from '../../customFields/ReferenceScoreField';
import Pagination from '../layout/Pagination';
import DeleteEntities from './components/DeleteEntities';

const Entities = () => {
  const { append, fields, remove } = useFieldArray({
    name: 'entities',
    keyName: 'key',
    rules: {
      required: 'This is required',
      minLength: 1,
    },

  });

  const { errors } = useFormState();
  const errorMessage = errors.entities?.root?.message as string | undefined;

  return (
    <RecordContextProvider value={{ entities: fields }}>
      <Card variant="outlined">
        <CardHeader title="Entities" action={<AddEntities append={append} />} />
        <Divider />
        <ArrayField source="entities" perPage={10}>
          <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
            <TextField source="type" />
            <ReferenceEntityField source="" label="Display name" />
            <ReferenceScoreField label="Risk score" />
            <ReferenceRiskClassField label="Risk class" />
            <DeleteEntities remove={remove} fields={fields} />
          </Datagrid>
          {errorMessage && (
            <Box paddingX={4} paddingBottom={4}>
              <Typography color="error">{errorMessage}</Typography>
            </Box>
          )}
          <Pagination disableEmptyText />
        </ArrayField>
      </Card>
    </RecordContextProvider>
  );
};

const PingCreate = ({
  caseId,
  resource,
  onSuccess,
}: {
  caseId?: string,
  resource?: string,
  onSuccess?: () => void
}) => {
  const { data } = useGetOne('cases', { id: caseId }, { enabled: !!caseId });

  if (caseId && !data) return null;

  return (
    <Create
      resource={resource}
      mutationOptions={{
        onSuccess,
      }}
    >
      <SimpleForm mode="all" defaultValues={{ caseId, entities: data.entities }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Entities />
          </Grid>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <CardHeader title="Details" />
              <Divider />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="label">Description of ping</Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextInput fullWidth multiline minRows={3} source="description" validate={required()} />
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Typography variant="label">Score of ping</Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <NumberInput source="score" validate={[required(), maxValue(100), minValue(0)]} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} md={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Label
                          variant="label"
                          title="Rule decreasing timeline"
                          info="The risk decreasing timeline is adjusting the risk of the entity based on a algorithm over the inputted time"
                        />
                      </Grid>
                      <Grid item sm={12} md={3} lg={2}>
                        <NumberInput
                          source="riskTimeline.amount"
                          fullWidth
                          label="Number"
                          validate={[required(), minValue(0)]}
                        />
                      </Grid>
                      <Grid item sm={12} md={4} lg={2}>
                        <SelectInput
                          source="riskTimeline.period"
                          choices={[{ id: 'months', name: 'months' },
                            { id: 'days', name: 'days' },
                            { id: 'years', name: 'years' }]}
                          fullWidth
                          label="Unit"
                          validate={required()}
                          helperText={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default PingCreate;
