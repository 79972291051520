import React from 'react';

import {
  Box,
  Divider,
  Paper,
} from '@mui/material';

import View from './View';
import Create from './Create';
import VersionSelect from './VersionSelect';
import Reset from './Reset';
import Publish from './Publish';
import Type from './Type';

import ruleTypes, { RuleTypes } from '../../../../constants/ruleTypes';
import { RuleViews } from '../../../../constants/ruleView';
import Statistics from './Statistics';
import Process from './Process';
import GroupsToInclude from './GroupsToInclude';
import Release from './Release';
import Deactivate from './Deactivate';

type RuleActionsInput = {
  ruleType: RuleTypes
  changeRuleType: (x: RuleTypes) => void
  view: RuleViews
  changeView: (x: RuleViews) => void
  version: string
  isDraft: boolean
  changeVersion: (x: string) => void
}

const Actions = ({
  ruleType,
  changeRuleType,
  view,
  changeView,
  version,
  isDraft,
  changeVersion,
}: RuleActionsInput) => (
  <Box>
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexWrap: 'wrap',
        gap: 1,
        padding: 1,
      }}
    >
      <VersionSelect ruleType={ruleType} changeVersion={changeVersion} version={version} />
      <Reset ruleType={ruleType} disabled={!isDraft} />
      <Publish ruleType={ruleType} disabled={!isDraft} />
      <Release disabled={isDraft} version={version} />
      <Deactivate disabled={isDraft} version={version} />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <Type changeVersion={changeVersion} ruleType={ruleType} changeRuleType={changeRuleType} />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <View view={view} changeView={changeView} />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1, display: ruleType === ruleTypes.REQUEST ? 'flex' : 'none' }} />
      { ruleType === ruleTypes.REQUEST && <Process />}
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <GroupsToInclude />
      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
      <Create ruleType={ruleType} view={view} />
      <Statistics />
    </Paper>
  </Box>
);

export default Actions;
