import React, { useState, useCallback } from 'react';

import {
  Form,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { FieldValues } from 'react-hook-form';

const EditComment = () => {
  const refresh = useRefresh();
  const record = useRecordContext();
  const notify = useNotify();
  const resource = useResourceContext();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const defaultMessage = record?.editions?.length
    ? record.editions[record.editions.length - 1].message
    : record?.message;

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [commentCreate] = useUpdate();

  const handleComment = useCallback((data: FieldValues) => {
    commentCreate(
      resource,
      {
        id: `${id}/comment`,
        data: {
          comment: data.comment,
          origin: record?._id,
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          refresh();
        },
        onError: () => {
          notify('Could not create comment', { type: 'error' });
        },
      },
    );
  }, [commentCreate, id, notify, record?._id, refresh, resource]);

  return (
    <>
      <Button
        startIcon={<Edit fontSize="inherit" />}
        onClick={handleOpen}
        size="small"
        sx={{
          minWidth: 'auto',
          color: 'grey',
          padding: 0,
          paddingLeft: '4px',
          paddingRight: '4px',
          fontSize: 'inherit',
          '.MuiButton-startIcon': {
            marginRight: '2px',
          },
        }}
      >
        Edit
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <Form values={{ comment: defaultMessage }} onSubmit={handleComment}>
          <DialogTitle>Edit comment</DialogTitle>
          <DialogContent>
            <TextInput
              source="comment"
              multiline
              margin="dense"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default EditComment;
