import React from 'react';

import {
  RaRecord,
  RecordContextProvider,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

const Status = ({ data }: { data: RaRecord }) => {
  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Status" />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle status:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordonsstatus"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle status date:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="datumForStatus"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Status reason:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="statusorsak"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      EU registration date:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="euRegbevisdatum"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      First time in traffic:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="datumITrafikForstaGangen"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      User from:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="brukareFrom"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Owner role:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="agarRoll"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Registration status:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="regstatus"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Status Date:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="statusDatum"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Period tax:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="periodskatt"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Tax payed until:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="skattbettom"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Tax table:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="skattetabell"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle tax:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fskatt"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Toll:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="saldo"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Approved inspection:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="datumGodkandBesiktning"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Approved inspection until:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="datumGodkandTillOchMed"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <CardHeader
                    sx={{ color: 'black', fontWeight: 'bold' }}
                    titleTypographyProps={{ variant: 'inherit' }}
                    title="Liabilities"
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Vehicle tax balance:
                          </Typography>
                          <TextField
                            emptyText="-"
                            source="summarization.liabilities.vehicleTaxBalance"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Vehicle tax ban:
                          </Typography>
                          <SelectField
                            choices={[
                              { id: 'J', name: 'Yes' },
                              { id: 'N', name: 'No' },
                            ]}
                            emptyText="-"
                            source="summarization.liabilities.vehicleTaxBan"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Congestion charge balance:
                          </Typography>
                          <TextField
                            emptyText="-"
                            source="summarization.liabilities.congestionChargeBalance"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Congestion charge ban:
                          </Typography>
                          <SelectField
                            choices={[
                              { id: 'J', name: 'Yes' },
                              { id: 'N', name: 'No' },
                            ]}
                            emptyText="-"
                            source="summarization.liabilities.congestionChargeBan"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Infrastructure fee balance:
                          </Typography>
                          <TextField
                            emptyText="-"
                            source="summarization.liabilities.infrastructureFeeBalance"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Infrastructure fee ban:
                          </Typography>
                          <SelectField
                            choices={[
                              { id: 'J', name: 'Yes' },
                              { id: 'N', name: 'No' },
                            ]}
                            emptyText="-"
                            source="summarization.liabilities.infrastructureFeeBan"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Parking fine balance:
                          </Typography>
                          <TextField
                            emptyText="-"
                            source="summarization.liabilities.parkingFineBalance"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Parking fine ban:
                          </Typography>
                          <SelectField
                            choices={[
                              { id: 'J', name: 'Yes' },
                              { id: 'N', name: 'No' },
                            ]}
                            emptyText="-"
                            source="summarization.liabilities.parkingFineBan"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Toll balance:
                          </Typography>
                          <TextField
                            emptyText="-"
                            source="summarization.liabilities.tollBalance"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Box>
                          <Typography variant="smallTitle">
                            Toll ban:
                          </Typography>
                          <SelectField
                            choices={[
                              { id: 'J', name: 'Yes' },
                              { id: 'N', name: 'No' },
                            ]}
                            emptyText="-"
                            source="summarization.liabilities.tollBan"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default Status;
