import { ValueOf } from '../utilities/types';

const ruleViews = {
  TREE: 'tree',
  LIST: 'list',
};

export type RuleViews = ValueOf<typeof ruleViews>;

export default ruleViews;
