import React from 'react';
import Car from './Car';

const EntityForm = ({
  entityVariant,
}: {
  entityVariant: string,
}) => (
  <Car entityVariant={entityVariant} />

);
export default EntityForm;
