import {
  upperFirst, camelCase, kebabCase,
} from 'lodash';

const specialResourceName: Record<string, string | undefined> = {
  processes: 'Process',
  'risk-classes': 'RiskClass',
  'account-entities': 'AccountEntity',
  businesses: 'Business',
};

const specialResource = (name: string) => {
  const specialKebabName = Object.keys(specialResourceName)
    .find((kebabCaseName) => specialResourceName[kebabCaseName] === name);
  return specialKebabName;
};

const getResourceName = (resource: string) => `${
  upperFirst(camelCase(!specialResourceName[resource]
    ? resource.slice(0, -1) : specialResourceName[resource]))
}`;

export const getResourceFromName = (resourceName: string) => {
  const specResource = specialResource(resourceName);
  return kebabCase(!specResource ? `${resourceName}s` : specResource);
};

export default getResourceName;
