import React from 'react';

import {
  ArrayField,
  Datagrid,
  DateField,
  SelectField,
  TextField,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useWatch } from 'react-hook-form';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import Create from './dialog/Create';
import Edit from './dialog/Edit';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';
import { transmodeTypesChoices } from '../../../../constants/transmodeTypes';
import Delete from './Delete';

const List = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const transactions = useWatch({ name: 'report.transactions' });

  return (
    <Grid container>
      {transactions?.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <ArrayField record={{ transactions }} source="transactions">
                <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                  <TextField source="transactionnumber" label="Transaction number" />
                  <DateField source="date_transaction" label="Transaction Date" />
                  <SelectField choices={transmodeTypesChoices} source="transmode_code" label="Transaction channel" />
                  <TextField source="amount_local" label="Transaction amount" />
                  <Edit />
                  <Delete />
                </Datagrid>
              </ArrayField>
            </Card>
          </Grid>
        </Grid>
      )}
      {(!transactions || transactions?.length === 0) && (
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Box padding="10%" alignItems="center" display="flex" flexDirection="column" gap={5}>
            <Typography variant="h2" color="primary">No Transactions Listed</Typography>
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Box>
        </Grid>
      )}
      <Create open={open} closeDialog={closeDialog} />
    </Grid>

  );
};

export default List;
