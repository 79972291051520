import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FormDataConsumer, RadioButtonGroupInput } from 'react-admin';
import TransactionTypeConfigurationInput from '../../../../customFields/TransactionTypeConfigurationInput';

const TransactionTypeConfiguration = () => (
  <Card variant="outlined">
    <CardHeader title="Transaction types configuration" subheader="Configure which transaction types this rule should include for detecting patterns. If no filter is applied then all transactions are included by default" />
    <CardContent>
      <Stack>
        <Typography variant="label">
          Should the rule filter transactions on transaction types?
        </Typography>
        <RadioButtonGroupInput
          source="ruleParameters.shouldFilterOnTypes"
          label={false}
          defaultValue={false}
          parse={(value) => value === 'true'}
          choices={[
            { id: true, name: 'Yes' },
            { id: false, name: 'No' },
          ]}
        />
        <FormDataConsumer>
          {({ formData }) => (formData.ruleParameters.shouldFilterOnTypes && (
            <>
              <Typography variant="label" marginBottom={2}>
                Include transactions with a transaction type that:
              </Typography>
              <TransactionTypeConfigurationInput source="ruleParameters.transactionTypeConfiguration" />
            </>
          ))}
        </FormDataConsumer>
      </Stack>
    </CardContent>
  </Card>
);

export default TransactionTypeConfiguration;
