import React from 'react';

import {
  useListContext,
  useRedirect,
} from 'react-admin';

import { Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { RuleTypes } from '../../../../constants/ruleTypes';
import { RuleViews } from '../../../../constants/ruleView';

const Create = ({ ruleType, view } : {ruleType: RuleTypes, view: RuleViews}) => {
  const { filter } = useListContext();
  const redirect = useRedirect();

  return (
    <Button
      startIcon={<AddIcon />}
      color="primary"
      disabled={!!filter?.version}
      onClick={() => redirect('create', 'rules', undefined, undefined, { record: { ruleType, view } })}
    >
      Create
    </Button>
  );
};

export default Create;
