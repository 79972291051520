import React from 'react';

import {
  General,
  ReportingPerson,
  Transactions,
} from './paths';

import { FormNavigation, Child } from '../layout/formNavigation';

import Generate from './Generate';

const STR = () => (
  <FormNavigation>
    <Child path="general" label="General">
      <General />
    </Child>
    <Child path="reporting-person" label="Reporting person">
      <ReportingPerson />
    </Child>
    <Child path="transactions" label="Transactions">
      <Transactions />
    </Child>
    <Child path="generate" label="Generate report">
      <Generate reportCode="STR" />
    </Child>
  </FormNavigation>
);

export default STR;
