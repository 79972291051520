import React from 'react';

import { Grid, Typography } from '@mui/material';
import PrimitiveInput from './base/PrimitiveInput';

const OwnerStructure = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        If the company or organization has multiple ownership levels,
        please describe its ownership and control structure.
      </Typography>
    </Grid>
    <PrimitiveInput />
  </Grid>
);

export default OwnerStructure;
