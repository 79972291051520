import React from 'react';
import {
  SaveButton,
  Toolbar,
  required,
  RecordContextProvider,
  useRecordContext,
  TextInput,
  usePermissions,
  SelectInput,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import {
  Box,
  Typography,
} from '@mui/material';

import useRequestTypesChoices from '../../hooks/useRequestTypesChoices';

import hasAccess from '../../utilities/hasAccess';

import EditDialog from '../layout/dialog/EditDialog';
import InfoPopover from '../layout/InfoPopover';

import ActivationButton from '../../customFields/ActivationButton';

import actions, { Actions } from '../../constants/actions';
import resourceTypes from '../../constants/resourceTypes';

const CustomToolbar = () => {
  const { formState: { isValid } } = useFormContext();
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Toolbar sx={{ backgroundColor: 'transparent' }}>
      <Box display="flex" width="100%" justifyContent="space-between">
        {hasAccess(permissions, actions.PROCESS_UPDATE_ACTIVATION) && (
          <Box display="flex" marginLeft={2}>
            <ActivationButton />
          </Box>
        )}
        <SaveButton
          disabled={!isValid}
          icon={null as any}
        />
      </Box>
    </Toolbar>
  );
};

const ProcessEdit = () => {
  const record = useRecordContext();

  const { choices } = useRequestTypesChoices();

  return (
    <EditDialog
      customToolbar={<CustomToolbar />}
      title="process"
      action={actions.PROCESS_UPDATE}
      resourceType={resourceTypes.PROCESS}
    >
      <RecordContextProvider value={record}>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
          <Box width="80%">
            <Box display="flex" alignItems="center">
              <Typography variant="mediumTitle">Process Name</Typography>
              <InfoPopover title="Process Name" content="Unique name for the process." />
            </Box>
            <TextInput fullWidth validate={required()} source="name" />
          </Box>
          <Box width="80%">
            <Box display="flex" alignItems="center">
              <Typography variant="mediumTitle">Request type</Typography>
              <InfoPopover title="Request type" content="Type of request allowed within the process." />
            </Box>
            <SelectInput
              source="requestType"
              label="Request Type"
              choices={choices}
              validate={required()}
            />
          </Box>
        </Box>
      </RecordContextProvider>
    </EditDialog>
  );
};

export default ProcessEdit;
