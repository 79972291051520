/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';

import { useRecordContext, SelectField, TextField } from 'react-admin';

import { get, isArray } from 'lodash';

import {
  Box, Card, CardContent, Divider, Grid, Typography,
} from '@mui/material';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { countryChoices } from '../../../../constants/countries';
import { identifierTypeChoices } from '../../../../constants/identifierTypes';

const TITLE = {
  [languages.SV]: 'Nationell idetifierare',
  [languages.EN]: 'National Identifier',
};

const IDENTIFIER_FIELD_LABEL = {
  [languages.SV]: 'Identifierare',
  [languages.EN]: 'Identifier',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  if (!language) return null;

  const value = get(record, 'value') as {country: string, identifier?: string }[] | undefined;
  const sx = get(record, 'sx', {});

  if (!isArray(value)) return null;

  return (
    <Card variant="outlined" sx={{ m: 0 }}>
      <Grid item xs={12} md={12}>
        <Grid container>
          {value.map((item, i) => (
            <Grid item xs={12} md={12}>
              <CardContent sx={{ p: 4 }}>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="label">{TITLE[language]}</Typography>
                      <SelectField
                        key={item.country}
                        record={{ item }}
                        source="item.country"
                        choices={countryChoices(language)}
                        fontWeight="bold"
                        sx={sx}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="label">{IDENTIFIER_FIELD_LABEL[language]}</Typography>
                      <Box display="flex" gap={2}>
                        <SelectField
                          record={item}
                          choices={identifierTypeChoices(language)}
                          source="type"
                        />
                        <Typography>-</Typography>
                        <TextField
                          record={item}
                          source="identifier"
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
              {(i + 1) !== value.length && <Divider />}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
};

const NationalIdentifier = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={TITLE[language]}
      source={source ?? 'nationalIdentifier'}
      datapoint={null}
      isComplexType
    >
      <ValueField language={language} />
    </Answer>
  );
};

NationalIdentifier.ValueField = ValueField;

NationalIdentifier.Nested = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  const language = record?.language as Languages ?? languages.EN;

  const countries = useMemo(
    () => get((record), `${source ?? 'nationalIdentifier'}`),
    [source, record],
  ) as {country: string, identifier?: string }[] | undefined;

  return countries?.map((item) => (
    <Grid key={item.country} item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Box gap={1} display="flex">
          <Typography variant="label">National Identifier for</Typography>
          <SelectField
            record={item}
            source="country"
            variant="label"
            choices={countryChoices(language)}
          />
        </Box>
        <Box display="flex" gap={2}>
          <SelectField
            record={item}
            choices={identifierTypeChoices(language)}
            source="type"
          />
          <Typography>-</Typography>
          <TextField
            record={item}
            source="identifier"
          />
        </Box>
      </Box>
    </Grid>
  ));
};

export default NationalIdentifier;
