import React from 'react';
import {
  Card,
  Grid,
  Typography,
  Box,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';

import {
  Datagrid,
  TextField,
  ArrayField,
  useRecordContext,
  NumberField,
  DateField,
  SelectField,
  RecordContextProvider,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
} from 'react-admin';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

import ReferenceEntityField from '../../../../customFields/ReferenceEntityField';
import ReferenceScoreField from '../../../../customFields/ReferenceScoreField';
import ReferenceLastKycRequest from '../../../../customFields/ReferenceLastKycRequest';
import { transmodeTypesChoices } from '../../../../constants/transmodeTypes';
import entityTypes from '../../../../constants/entityTypes';
import StatusField from '../../../../customFields/StatusField';

const Transaction = () => {
  const record = useRecordContext();
  if (!record) return null;

  const { extraInformation } = record;

  const reformedEntities = {
    entities: [
      {
        requestRole: 'Processor',
        ...extraInformation?.processor,
      },
      {
        requestRole: 'Recipient',
        ...extraInformation?.recipient,
      },
      {
        requestRole: 'Initiator',
        ...extraInformation?.initiator,
      },
      {
        requestRole: 'Sub-Processor',
        ...extraInformation?.subProcessor,
      },
      ...(extraInformation?.initiator?.conductors
        ? extraInformation.initiator.conductors.map(
          (conductor: { id: string, type: typeof entityTypes.INDIVIDUAL }) => ({
            requestRole: 'Conductor',
            id: conductor?.id,
            type: conductor.type,
          }),
        )
        : []
      ),
    ].filter((item) => item?.id),
  };

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Transaction Details" />
            <Divider />
            <CardContent>
              <RecordContextProvider value={extraInformation}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Amount</Typography>
                      <NumberField source="amount" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Currency</Typography>
                      <TextField source="currency" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Date</Typography>
                      <DateField showTime source="date" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Status</Typography>
                      <StatusField source="status" resource="transactions" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Transaction types</Typography>
                      <ReferenceArrayField source="transactionTypes" reference="transaction-types">
                        <SingleFieldList linkType={false} empty={'-' as any}>
                          <ChipField source="name" />
                        </SingleFieldList>
                      </ReferenceArrayField>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Text message</Typography>
                      <TextField source="textMessage" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">Reference</Typography>
                      <TextField source="reference" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">
                        Transaction channel
                      </Typography>
                      <SelectField choices={transmodeTypesChoices} source="transactionChannel" emptyText="-" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="smallTitle">
                        Transaction channel provide
                      </Typography>
                      <TextField source="transactionChannelProvider" emptyText="-" />
                    </Box>
                  </Grid>
                </Grid>
              </RecordContextProvider>
              <RecordContextProvider value={reformedEntities}>
                <Card variant="outlined">
                  <CardHeader title="Involved Entities" />
                  <Divider />
                  <ArrayField source="entities">
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField emptyText="-" source="requestRole" label="Role" />
                      <ReferenceEntityField source="" label="Name" />
                      <TextField emptyText="-" source="type" label="Type" />
                      <ReferenceLastKycRequest source="" label="Latest KYC request" />
                      <ReferenceScoreField source="" label="Risk score" />
                      <TextField source="ip" label="IP address" emptyText="-" />
                      <TextField source="transactionCountry" label="Country" emptyText="-" />
                    </Datagrid>
                  </ArrayField>
                </Card>
              </RecordContextProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Transaction;
