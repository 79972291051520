import React from 'react';
import { TextInput, NumberInputProps } from 'react-admin';
import InputIconComponent from '../InputIconComponent';

const NumberInput = ({ ...props } : NumberInputProps & { popups?: React.ReactElement[]}) => {
  const popups = [...(props.popups! ?? [])];

  return (
    <TextInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      sx={{ '& .MuiFilledInput-root': { padding: 0 } }}
      InputProps={{
        endAdornment: <InputIconComponent popups={popups} />,
      }}
    />
  );
};

export default NumberInput;
