import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  Controls,
  useOnSelectionChange,
  Edge,
  Node,
} from 'reactflow';

import 'reactflow/dist/style.css';

import {
  HttpError,
  useGetList,
  useNotify,
  useResourceContext,
} from 'react-admin';

import {
  Backdrop,
  Card,
  CircularProgress,
} from '@mui/material';

import { useParams } from 'react-router-dom';

import InfoPane from './InfoPane';
import BusinessNode from './BusinessNode';
import IndividualNode from './IndividualNode';
import Params from '../../Params';
import { TransactionFlowData, updateTree } from './utils';
import { entityTypesFromResource } from '../../../../constants/entityTypes';
import AccountNode from './AccountNode';
import FilterPane from './FilterPane';
import AppliedFiltersPane from './AppliedFiltersPane';

const nodeTypes = {
  Business: BusinessNode,
  Individual: IndividualNode,
  Account: AccountNode,
};

const SelectionChange = ({
  setSelectedNode,
  setSelectedEdge,
}: {
  setSelectedNode: React.Dispatch<React.SetStateAction<Node | undefined>>
  setSelectedEdge: React.Dispatch<React.SetStateAction<Edge | undefined>>
}) => {
  const onChange = useCallback(({ nodes, edges }: { nodes: Node[], edges: Edge[] }) => {
    const nodeSelected = nodes.at(0);
    const edgeSelected = edges.at(0);
    setSelectedNode(() => nodeSelected);
    setSelectedEdge(() => edgeSelected);
  }, [setSelectedEdge, setSelectedNode]);

  useOnSelectionChange({
    onChange,
  });

  return null;
};

const RenderFlow = ({
  data,
  params,
  isLoading,
  setParams,
}: {
  data: Omit<TransactionFlowData, 'id'>,
  params: Params,
  isLoading: boolean;
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const [selectedNode, setSelectedNode] = useState<Node>();
  const [selectedEdge, setSelectedEdge] = useState<Edge>();
  const [nodes, setNodes, onNodesChange] = useNodesState(data.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(data.edges);

  useEffect(() => {
    setNodes((currentNodes) => data.nodes.map((newNode) => {
      const existingNode = currentNodes.find((currentNode) => currentNode.id === newNode.id);
      const nodeToCopy = existingNode ?? newNode;

      return {
        ...nodeToCopy,
        data: newNode.data,
      };
    }));
  }, [data.nodes, setNodes]);

  useEffect(() => {
    setEdges((currentEdges) => data.edges.map((newEdge) => {
      const existingEdge = currentEdges.find((currentEdge) => currentEdge.id === newEdge.id);
      const edgeToCopy = existingEdge ?? newEdge;

      return {
        ...edgeToCopy,
        data: newEdge.data,
        markerStart: newEdge.markerStart,
        markerEnd: newEdge.markerEnd,
        style: newEdge.style,
        animated: edgeToCopy.id === selectedEdge?.id,
      };
    }));
  }, [data.edges, selectedEdge?.id, setEdges]);

  return (
    <Card sx={{ width: '100%', height: '720px' }} variant="outlined">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodesConnectable={false}
        fitView
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        selectNodesOnDrag={false}
        elevateEdgesOnSelect
      >
        <SelectionChange
          setSelectedEdge={setSelectedEdge}
          setSelectedNode={setSelectedNode}
        />
        <AppliedFiltersPane params={params} setParams={setParams} />
        <InfoPane
          params={params}
          selectedNode={selectedNode}
          selectedEdge={selectedEdge}
        />
        <FilterPane params={params} setParams={setParams} />
        <Background style={{ backgroundColor: '#f7f9fc' }} />
        <Controls />
        {isLoading && (
          <Backdrop
            sx={(theme) => ({
              position: 'absolute',
              zIndex: theme.zIndex.drawer + 1,
            })}
            open
          >
            <CircularProgress size={80} color="secondary" />
          </Backdrop>
        )}
      </ReactFlow>
    </Card>
  );
};

const useGraphData = ({
  params,
}: {
  params: Params
}) => {
  const { id } = useParams();
  const [edges, setEdges] = useState<TransactionFlowData['edges']>([]);
  const [nodes, setNodes] = useState<TransactionFlowData['nodes']>([]);

  const resource = useResourceContext();
  const notify = useNotify();

  const { data, isLoading } = useGetList<TransactionFlowData>(
    'requests/graph/flow',
    {
      filter: {
        entityId: id,
        entityType: entityTypesFromResource[resource],
        from: params.from,
        to: params.to,
        currency: params.currency,
        maxDepth: params.maxDepth,
        transactionTypeConfiguration: params.transactionTypeConfiguration,
        relatedEntityType: params.flowRelatedEntityType,
        relatedRoles: params.flowRelatedRoles,
      },
      sort: {
        field: params.flowSort ?? 'id',
        order: 'DESC',
      },
      pagination: {
        page: 1,
        perPage: params.flowPageSize,
      },
    },
    {
      onError: (e) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Could not get distribution data', { type: 'error' });
      },
    },
  );

  useEffect(() => {
    if (data) {
      updateTree({
        data,
        setEdges,
        setNodes,
      });
    }
  }, [data, setEdges, setNodes]);

  return {
    edges,
    nodes,
    isLoading,
  };
};

const Flow = ({
  params,
  setParams,
}: {
  params: Params
  setParams: React.Dispatch<React.SetStateAction<Params>>
}) => {
  const {
    edges,
    nodes,
    isLoading,
  } = useGraphData({ params });

  return (
    <RenderFlow
      isLoading={isLoading}
      data={{ nodes, edges }}
      params={params}
      setParams={setParams}
    />
  );
};

export default Flow;
