import { ValueOf } from '../utilities/types';

export const thresholdVariants = {
  CURRENCY: 'currency',
  COMMON: 'common',
} as const;

export type ThresholdVariants = ValueOf<typeof thresholdVariants>;

export default thresholdVariants;
