import React, { useEffect, useState, useCallback } from 'react';

import {
  useResourceDefinitions,
  useGetResourceLabel,
} from 'react-admin';

import {
  CssBaseline,
  Paper,
  Box,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';

import DashboardIcon from '@mui/icons-material/Dashboard';

import { useParams } from 'react-router-dom';
import Navbar from './components/layout/navbar/Navbar';
import Sidebar, { drawerWidth } from './components/layout/sidebar/Sidebar';
import Footer from './components/layout/Footer';

import menuGroups, { orderedMenuGroups } from './constants/menuGroups';

const Root = styled(Box)({
  display: 'flex',
  minHeight: '100vh',
});

const AppContent = styled(Box)(({ open, variant }: { open: boolean, variant: string }) => ({
  display: 'flex',
  minHeight: '100vh',
  flex: '1',
  flexDirection: 'column',
  maxWidth: open && variant !== 'temporary' ? `calc(100% - ${drawerWidth}px)` : '100%',
  ...(open && variant !== 'temporary' ? { marginLeft: drawerWidth } : {}),
}));

const MainContent = styled(Paper)(({ theme }) => ({
  flex: 1,
  background: theme.palette.background.default,
  '& .MuiPaper-root .MuiPaper-root': {
    boxShadow: 'none',
  },
  padding: 5,
  [theme.breakpoints.up('md')]: {
    padding: 12,
  },
}));

type Variant = 'permanent' | 'persistent' | 'temporary'

const Layout = ({ children } : {children?: React.ReactNode }) => {
  const path = useParams();
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const variant = isMobile ? 'temporary' : 'persistent' as Variant;
  const [open, setOpen] = useState(variant !== 'temporary');
  useEffect(() => {
    setOpen(variant !== 'temporary');
  }, [variant]);
  const resourcesDefinitions = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const resources = [
    {
      name: '',
      hasList: true,
      icon: DashboardIcon,
      options: { label: 'Dashboard', exact: true, group: menuGroups.HOME },
    },
    ...Object.keys(resourcesDefinitions).map((name) => resourcesDefinitions[name]),
  ];

  const handleDrawerToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const dashboardItems = orderedMenuGroups.map((menuGroup) => ({
    title: menuGroup.label,
    pages: resources?.filter((item) => item.hasList && item.options.group === menuGroup.id)
      .map((resource) => ({
        href: `/${resource.name}`,
        icon: resource.icon,
        title: resource.options.label || getResourceLabel(resource.name),
        exact: resource.options.exact ?? false,
      })),
  }));

  const basePath = Object.values(path)[0]?.split('/')[0];
  const isPathResourceRoute = resources.some((resource) => (resource.name === basePath || basePath === ''));

  return (
    <Root>
      <CssBaseline>
        <Sidebar
          variant={variant}
          open={isPathResourceRoute ? open : false}
          onClose={variant === 'temporary' ? handleDrawerToggle : undefined}
          items={dashboardItems}
        />
        <AppContent open={open} variant={variant}>
          {isPathResourceRoute && <Navbar onDrawerToggle={variant !== 'permanent' ? handleDrawerToggle : undefined} />}
          <MainContent>
            {children}
          </MainContent>
          {isPathResourceRoute && <Footer />}
        </AppContent>
      </CssBaseline>
    </Root>
  );
};

export default Layout;
