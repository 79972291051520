import React from 'react';

import {
  RaRecord,
  RecordContextProvider,
  TextField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
} from '@mui/material';

const Technical = ({ data }: { data: RaRecord }) => {
  if (data) {
    return (
      <RecordContextProvider value={data}>
        <Grid item xs={12} md={12}>
          <Card variant="outlined">
            <CardHeader title="Technical" />
            <Divider />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle type:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordonsslag"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Manufacture:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fabrikat"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Year model:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="arsmodell"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Color:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="farg"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Producer responsible:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="producentansvarig"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Category:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="kategori"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Person category:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="personkategori"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Manufacturing year and month:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="tillverkningsmanad"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Body code:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="karosserikod"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Body:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="kaross"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Passengers:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="antalPassagerare"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Bus class:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="bussklass"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle class:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordklass"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle category:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordKategori"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Length:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="langd"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Width:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="bredd"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Seats:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="sittande"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Seatbelt:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="baltade"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Wheelchair:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="iRullstol"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Standing:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="staende"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Airbag passenger front:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="krockkuddePassFram"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Lifting capacity:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="lyftkapacitet"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Number plate front:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="skyltFram"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Number plate back:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="skyltBak"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle variant:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordonsVariant"
                    />
                  </Box>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Box>
                    <Typography variant="smallTitle">
                      Vehicle version:
                    </Typography>
                    <TextField
                      emptyText="-"
                      source="fordonsVersion"
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </RecordContextProvider>
    );
  }
  return null;
};

export default Technical;
