import React from 'react';

import {
  ArrayField,
  Datagrid,
  TextField,
  useRecordContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { useWatch } from 'react-hook-form';
import useDialogStatus from '../../../../hooks/useDialogStatus';

import Create from './dialog/Create';
import Edit from './dialog/Edit';
import Delete from './Delete';

import { boldDataGridStyle } from '../../../../constants/style/datagridStyles';

const NameField = ({ label }: {label: string}) => {
  const record = useRecordContext();
  if (!record) return null;

  if (Object.keys(record).includes('t_person')) {
    const name = `${record?.t_person?.first_name} ${record?.t_person?.last_name}`;
    return <TextField record={{ name }} source="name" label={label} />;
  }
  if (Object.keys(record).includes('t_entity')) {
    const name = record?.t_entity.name;
    return <TextField record={{ name }} source="name" label={label} />;
  }
  return null;
};

const List = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();
  const parties = useWatch({ name: 'report.activity.report_parties' });

  return (
    <Grid container>
      {parties?.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <ArrayField record={{ parties }} source="parties">
                <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                  <TextField sortable={false} source="id" label="ID" />
                  <NameField label="Name" />
                  <Edit />
                  <Delete />
                </Datagrid>
              </ArrayField>
            </Card>
          </Grid>
        </Grid>
      )}
      {(!parties || parties?.length === 0) && (
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Box padding="10%" alignItems="center" display="flex" flexDirection="column" gap={5}>
            <Typography variant="h2" color="primary">No Parties Listed</Typography>
            <Button startIcon={<AddIcon />} onClick={openDialog} variant="outlined">Add</Button>
          </Box>
        </Grid>
      )}
      <Create open={open} closeDialog={closeDialog} />
    </Grid>
  );
};

export default List;
