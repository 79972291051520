import React from 'react';

import {
  Typography,
  ToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  Stack,
} from '@mui/material';

import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';

const ToggleButton = ({
  setValue,
  value,
}: {
  value?: boolean,
  setValue: (x: boolean) => void
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean | null,
  ) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Stack direction="row" spacing={4}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        size="small"
        color="primary"
      >
        <MuiToggleButton disabled value="" aria-label="left aligned">
          <DirectionsCarFilledIcon />
        </MuiToggleButton>
        <MuiToggleButton value>
          <Typography>Include</Typography>
        </MuiToggleButton>
        <MuiToggleButton value={false}>
          <Typography>Exclude</Typography>
        </MuiToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default ToggleButton;
