import OngoingRules from './ruleTypesForm/ongoingRules';
import RequestRules from './ruleTypesForm/requestRules';
import ruleType from '../../constants/ruleTypes';

const ruleTypeMapper = {
  [ruleType.REQUEST]: RequestRules,
  [ruleType.ONGOING]: OngoingRules,
};

export default ruleTypeMapper;
