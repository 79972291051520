import React from 'react';

import {
  DateField, RecordContextProvider, useRecordContext,
} from 'react-admin';

import {
  Box,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Link,
} from '@mui/material';

import { isEmpty } from 'lodash';

import { SanctionListMatches } from '../../../../../../utilities/schemas/sanctionListMatches';

import SanctionTitle from './SanctionTitle';

type SourceDetails = SanctionListMatches['potentialMatches'][number]['sourceDetails'][number];

const getBaseUrl = (url: string) => {
  const parsedUrl = new URL(url);
  return `${parsedUrl.protocol}//${parsedUrl.host}`;
};

const Source = () => {
  const record = useRecordContext<SourceDetails>();

  const title = isEmpty(record?.title) ? 'Unknown title' : record!.title;
  const description = isEmpty(record?.description) ? 'No description' : record!.description;

  const enableDocumentLink = !isEmpty(record?.documentLink);
  const enableLink = !isEmpty(record?.link);
  const enablePublicationDate = !isEmpty(record?.datePublished);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="baseline" gap={1}>
        <SanctionTitle>
          {title}
        </SanctionTitle>
        {enablePublicationDate && (
          <DateField
            sx={{
              fontSize: 13,
            }}
            variant="label"
            source="datePublished"
            emptyText="-"
          />
        )}
      </Box>
      <Typography variant="body2">
        {description}
      </Typography>
      {enableDocumentLink && (
        <Link
          href={record!.documentLink}
          color="grey"
          target="_blank"
          rel="noopener noreferrer"
        >
          PDF link
        </Link>
      )}
      {enableLink && (
        <Link
          href={record!.link}
          color="grey"
          target="_blank"
          rel="noopener noreferrer"
        >
          {getBaseUrl(record!.link!)}
        </Link>
      )}
    </Box>
  );
};

const SanctionSources = () => {
  const record = useRecordContext<SanctionListMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  if (isEmpty(record.sourceDetails)) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Sources" />
        <Divider />
        <CardContent>
          <Box display="flex" gap={6} flexDirection="column">
            {record.sourceDetails.map((source) => (
              <RecordContextProvider key={source.title} value={source}>
                <Source />
              </RecordContextProvider>
            ))}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SanctionSources;
