import React from 'react';

import { Grid, Typography, Grow } from '@mui/material';
import { OptionTextFunc } from 'react-admin';
import InfoPopover from '../../layout/InfoPopover';
import useRuleParent from '../../../hooks/useRuleParent';
import SelectInput from '../../layout/inputFields/SelectInput';
import { ruleNameChoices } from '../ruleFeatures';

const optionText = (option: OptionTextFunc) => {
  const ruleNameDesc = ruleNameChoices.find((rule) => rule.id === option?.name);
  return ruleNameDesc?.name ?? '';
};

const ParentRule = () => {
  const { triggeredByChoices, triggeredBy } = useRuleParent();

  return (
    <Grow in>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Nested under</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          {triggeredByChoices && (
            <SelectInput
              source="triggeredBy"
              defaultValue={triggeredBy}
              choices={triggeredByChoices}
              optionText={optionText}
              label="Select"
              fullWidth
              emptyText="None"
              popups={[
                <InfoPopover
                  key="triggered by"
                  title="Triggered By"
                  content="Rule that needs to be broken in order for the current rule to be run."
                />]}
            />
          )}
        </Grid>
      </Grid>
    </Grow>

  );
};

export default ParentRule;
