import React from 'react';

import {
  isArray,
  isEmpty,
  isNil,
} from 'lodash';

import {
  ArrayField,
  DateField,
  RecordContextProvider,
  useRecordContext,
  SelectField,
  FunctionField,
  useRedirect,
  DatagridConfigurable,
  SelectColumnsButton,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import { ArrowForwardIosOutlined } from '@mui/icons-material';

import { AdverseMediaMatches } from '../../../../../utilities/schemas/adverseMediaMatches';

import useEntityData from '../../../../../hooks/useEntityData';

import actions from '../../../../../constants/actions';
import { boldDataGridStyle } from '../../../../../constants/style/datagridStyles';
import { countryChoices } from '../../../../../constants/countries';
import languages from '../../../../../constants/languages';

import ImageField from '../../../../../customFields/ImageField';
import { ViewJSONDialog } from '../../../../../customFields/ViewJSON';
import ColoredScoreField from '../../../../../customFields/ColoredScoreField';

import NoResults from '../../../../layout/NoResults';
import PurchaseButton from '../../../../layout/button/PurchaseButton';

import EntityData from '../EntityData';

import person from '../../../../../assets/exposure/person.png';

const RedirectToEntityButton = ({ label }: { label?: string }) => {
  const redirect = useRedirect();
  const record = useRecordContext<NonNullable<AdverseMediaMatches['potentialMatches'][number]>>();
  if (isEmpty(record)) return null;
  return (
    <IconButton onClick={() => redirect(`${record._id}`)} aria-label={label}>
      <ArrowForwardIosOutlined />
    </IconButton>
  );
};

const AdverseMediaBody = ({
  data,
  handlePurchase,
  isLoading,
}: {
  data: AdverseMediaMatches | undefined,
  handlePurchase: () => void
  isLoading: boolean
}) => (
  <RecordContextProvider value={data}>
    <Card variant="outlined">
      <CardHeader
        title="Adverse media"
        subheader={(
          <Box display="flex" alignItems="baseline">
            <Typography>Latest update:</Typography>
            <DateField sx={{ marginLeft: 1, fontWeight: 'bold' }} source="createdAt" emptyText="-" showTime />
          </Box>
        )}
        action={(
          <Box display="flex" gap={4}>
            <SelectColumnsButton color="secondary" />
            <ViewJSONDialog title="Inspect" color="secondary" />
            <PurchaseButton
              loading={isLoading}
              label="Get Adverse Media"
              onClick={handlePurchase}
              empty={!isArray(data?.potentialMatches)}
              action={actions.INDIVIDUAL_CREATE_ADVERSE_MEDIA}
              icon={null}
              color="secondary"
            />
          </Box>
        )}
      />
      <Divider />
      <ArrayField source="potentialMatches">
        <DatagridConfigurable empty={<NoResults variant="h6" />} sx={{ ...boldDataGridStyle, width: '100%' }} bulkActionButtons={false}>
          <FunctionField
            label="Name"
            sx={{ whiteSpace: 'pre' }}
            render={(record: AdverseMediaMatches['potentialMatches'][number]) => {
              if (!isEmpty(record.fullName)) return record.fullName;
              const name = `${record.firstName ?? ''} ${record.lastName ?? ''}`;
              return isEmpty(name.trim()) ? '-' : name;
            }}
          />
          <ColoredScoreField source="matchProbability" label="Match %" />
          <DateField source="birthDate" label="Birth date" />
          <SelectField source="address.country" choices={countryChoices(languages.EN)} label="Country" />
          <FunctionField
            label="Type"
            sx={{ whiteSpace: 'pre' }}
            render={(record: AdverseMediaMatches['potentialMatches'][number]) => {
              if (isEmpty(record.adverseMedia)) return '-';
              const mediaTypes = record.adverseMedia
                .map((media) => media.type)
                .filter((text) => !isNil(text));
              if (isEmpty(mediaTypes)) return '-';
              return mediaTypes.join('\n');
            }}
          />
          <FunctionField
            label="Format"
            sx={{ whiteSpace: 'pre' }}
            render={(record: AdverseMediaMatches['potentialMatches'][number]) => {
              if (isEmpty(record.adverseMedia)) return '-';
              const mediaFormats = record.adverseMedia
                .map((media) => media.format)
                .filter((text) => !isNil(text));
              if (isEmpty(mediaFormats)) return '-';
              return mediaFormats.join('\n');
            }}
          />
          <ImageField label="Image" source="profileImages[0]" emptyImage={person} />
          <RedirectToEntityButton />
        </DatagridConfigurable>
      </ArrayField>
    </Card>
  </RecordContextProvider>
);

const AdverseMedia = () => {
  const {
    data,
    handlePurchase,
    isLoading,
  } = useEntityData<AdverseMediaMatches>({
    source: 'adverse-media',
    getAction: actions.INDIVIDUAL_GET_ADVERSE_MEDIA,
  });

  return (
    <Slide in direction="right" appear={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <EntityData />
        </Grid>
        <Grid item xs={12} md={12}>
          <AdverseMediaBody data={data} handlePurchase={handlePurchase} isLoading={isLoading} />
        </Grid>
      </Grid>
    </Slide>
  );
};

export default AdverseMedia;
