import React from 'react';

import {
  RecordContextProvider,
  useRecordContext,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import Entity from './Entity';
import Person from './Person';

const EntityTypeAccordionForm = ({
  entityVariant,
  direction,
}: {
  entityVariant: string,
  direction: string
}) => {
  const isMyClient = entityVariant.includes('my_client');
  const rootSource = entityVariant.split('.')![0];
  const record = useRecordContext();

  return (
    <RecordContextProvider value={{
      [`${rootSource}.${direction}_country`]: record?.country ?? 'unknown',
      [`${rootSource}.${direction}_funds_code`]: record?.transactionCarrierType,
      [`${rootSource}.${direction}_funds_comment`]: record?.transactionCarrierReference,
      [`${rootSource}.${direction}_foreign_currency`]: record?.currency ?? 'SEK',
      ...record,
    }}
    >
      <Grid>
        {entityVariant.includes('person') && <Person isMyClient={isMyClient} entityVariant={entityVariant} direction={direction} rootSource={rootSource} />}
        {entityVariant.includes('entity') && <Entity isMyClient={isMyClient} entityVariant={entityVariant} direction={direction} rootSource={rootSource} />}
        {entityVariant === '' && <Typography>Select a type</Typography>}
      </Grid>
    </RecordContextProvider>

  );
};
export default EntityTypeAccordionForm;
