import React from 'react';
import { TextField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Företagsnamn',
  [languages.EN]: 'Company name',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const CompanyName = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      datapoint={conflictFields.COMPANY_NAME}
      source="companyName"
    >
      <ValueField />
    </Answer>
  );
};

CompanyName.ValueField = ValueField;

export default CompanyName;
