import React, { useMemo } from 'react';

import { Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { LoadingPage, RecordContextProvider, useRedirect } from 'react-admin';

import { useParams } from 'react-router-dom';

import PepMatchInformation from './PepMatchInformation';

import useEntityData from '../../../../../../hooks/useEntityData';
import { PepMatches } from '../../../../../../utilities/schemas/pepMatches';
import actions from '../../../../../../constants/actions';
import PepAbout from './PepAbout';
import PepRoles from './PepRoles';
import PepNationalIdentifiers from './PepNationalIdentifiers';
import PepImages from './PepImages';
import PepSources from './PepSources';
import PepRelatedEntities from './PepRelatedEntities';

const GoBackButton = () => {
  const redirect = useRedirect();
  const path = useParams();

  return (
    <IconButton onClick={() => { redirect(`/individuals/${path.id}/kyc-data/pep`); }} size="small">
      <ArrowBackIosIcon color="inherit" fontSize="medium" />
    </IconButton>
  );
};

const PepDetails = () => {
  const {
    data,
    isLoading,
  } = useEntityData<PepMatches>({
    source: 'pep',
    getAction: actions.INDIVIDUAL_GET_PEP,
  });

  const { pepId } = useParams();

  const currentPep = useMemo(
    () => data?.potentialMatches.find((match) => match._id === pepId),
    [data, pepId],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <RecordContextProvider value={{ ...currentPep, providerName: data?.providerName }}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={12}>
          <GoBackButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={4}
            bgcolor="background.paper"
            paddingX={4}
          >
            <Grid xs={12} md={12} item>
              <PepMatchInformation />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <PepAbout />
                </Grid>
                <Grid xs={12} md={12} item>
                  <PepNationalIdentifiers />
                </Grid>
              </Grid>
              <Grid xs={12} md={12} item>
                <PepImages />
              </Grid>
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <PepRoles />
                </Grid>
                <Grid xs={12} md={12} item>
                  <PepRelatedEntities />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <PepSources />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

export default PepDetails;
