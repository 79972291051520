import { InputAdornment } from '@mui/material';
import React from 'react';

const InputIconComponent = ({ popups }: { popups?: React.ReactElement[]}) => (popups ? (
  <InputAdornment position="end">
    {popups}
  </InputAdornment>
) : null);

export default InputIconComponent;
