import React from 'react';
import {
  useRecordContext,
  TextField,
  useResourceDefinition,
} from 'react-admin';
import { getResourceFromName } from '../utilities/getResourceName';
import LinkField from '../components/layout/LinkField';

const ReferenceResourceField = ({ label }: {label: string}) => {
  const record = useRecordContext();
  const resource = getResourceFromName(record?.resource?.type);
  const resourceDef = useResourceDefinition({ resource });

  const link = (() => {
    if (resource === 'rules') {
      return `/rules/${record?.resource?.id}/summary`;
    }
    if (resourceDef.hasEdit) {
      return 'edit';
    }

    if (resourceDef.hasShow) {
      return 'show';
    }

    return false;
  })();

  return link ? (
    <LinkField label={label} sortable={false} source="id" link={link} />
  ) : (
    <TextField label={label} sortable={false} source="id" />
  );
};

export default ReferenceResourceField;
