import React, { useState, useCallback } from 'react';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  styled,
} from '@mui/material';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import { useLogout } from 'react-admin';

const IconButton = styled(MuiIconButton)({
  '& svg': {
    width: 22,
    height: 22,
  },
});

const NavbarUserDropdown = () => {
  const [anchorMenu, setAnchorMenu] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const logout = useLogout();

  const toggleMenu: React.MouseEventHandler<HTMLButtonElement> = useCallback((event) => {
    setAnchorMenu(event.currentTarget);
  }, [setAnchorMenu]);

  const closeMenu = useCallback(() => {
    setAnchorMenu(null);
  }, [setAnchorMenu]);

  const handleSignOut = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          aria-owns={anchorMenu ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <PowerSettingsNew />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={!!anchorMenu}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

export default NavbarUserDropdown;
