import React from 'react';

import {
  useAuthenticated,
  Loading,
  useGetIdentity,
} from 'react-admin';

import {
  Grid,
  Typography,
  Box,
} from '@mui/material';

import RiskClassDistribution from './components/RiskClassDistribution';
import RuleTable from './components/RuleTable';
import PingAnalytics from './components/PingsAnalytics';
import RequestAnalytics from './components/RequestsAnalytics';
import ScoreDistribution from './components/ScoreDistribution';
import EntitiesDistribution from './components/EntitiesDistribution';
import CasesAnalytics from './components/CasesAnalytics';

const Dashboard = () => {
  useAuthenticated();

  const { identity } = useGetIdentity();

  if (!identity) return <Loading />;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Box display="flex" justifyContent="space-between">
          <Grid item>
            <Typography variant="h3" gutterBottom>
              Analytics Dashboard
            </Typography>
            <Typography variant="subtitle1">
              Welcome back
              {' '}
              {identity.displayName}
              !
              {' '}
              <span role="img" aria-label="Waving Hand Sign">
                👋
              </span>
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} xl={3}>
        <CasesAnalytics />
      </Grid>
      <Grid item xs={12} md={12} xl={3}>
        <PingAnalytics />
      </Grid>
      <Grid item xs={12} md={12} xl={3}>
        <RequestAnalytics />
      </Grid>
      <Grid item xs={12} md={12} xl={3}>
        <EntitiesDistribution />
      </Grid>
      <Grid item xs={12} md={12} xl={6}>
        <RiskClassDistribution />
      </Grid>
      <Grid item xs={12} md={12} xl={6}>
        <ScoreDistribution />
      </Grid>
      <Grid item xs={12} md={12}>
        <RuleTable />
      </Grid>
    </Grid>
  );
};
export default Dashboard;
