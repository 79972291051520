import React, { useCallback } from 'react';
import {
  useNotify,
  useUpdate,
  useRefresh,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import {
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const ActivationButton = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();
  const refresh = useRefresh();

  const [update, { isLoading }] = useUpdate(
    `${resource}/activation`,
    { id: record?.id, data: { active: !record?.active } },
    {
      onSuccess: () => {
        notify('Activation status updated successfully');
        refresh();
      },
      onError: () => {
        notify('Could not update activation status', { type: 'error' });
      },
    },
  );

  const handleClick = useCallback(() => {
    update();
  }, [update]);

  if (!record) return null;

  return record.active ? (
    <Button
      onClick={handleClick}
      color="error"
      startIcon={<CloseIcon />}
      disabled={isLoading}
    >
      Deactivate
    </Button>
  ) : (
    <Button
      variant="contained"
      onClick={handleClick}
      color="secondary"
      startIcon={<AddIcon />}
      disabled={isLoading}
    >
      Activate
    </Button>
  );
};

export default ActivationButton;
