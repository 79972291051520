import ruleNames, { RuleNames } from '../../../constants/ruleNames';

import Affordability from '../ruleTypesForm/ruleSpecific/Affordability';
import AgeThreshold from '../ruleTypesForm/ruleSpecific/AgeThreshold';
import AmountThreshold from '../ruleTypesForm/ruleSpecific/AmountThreshold';
import AnomalyDetection from '../ruleTypesForm/ruleSpecific/AnomalyDetection';
import BusinessAuditor from '../ruleTypesForm/ruleSpecific/BusinessAuditor';
import BusinessSanctionList from '../ruleTypesForm/ruleSpecific/BusinessSanctionList';
import CarFreqHistoricalOwners from '../ruleTypesForm/ruleSpecific/CarFreqHistoricalOwners';
import CarValuation from '../ruleTypesForm/ruleSpecific/CarValuation';
import CashFlow from '../ruleTypesForm/ruleSpecific/CashFlow';
import CategorizationAmountThreshold from '../ruleTypesForm/ruleCommon/CategorizationAmountThreshold';
import CategorizationFrequency from '../ruleTypesForm/ruleCommon/CategorizationFrequency';
import CategorizationRollingSum from '../ruleTypesForm/ruleCommon/CategorizationRollingSum';
import Collector from '../ruleTypesForm/ruleSpecific/Collector';
import ConnectedEntities from '../ruleTypesForm/ruleCommon/ConnectedEntities';
import CountryList from '../ruleTypesForm/ruleSpecific/CountryList';
import EmploymentType from '../ruleTypesForm/ruleSpecific/EmploymentType';
import Frequency from '../ruleTypesForm/ruleCommon/Frequency';
import IndividualCriminalRecord from '../ruleTypesForm/ruleSpecific/IndividualCriminalRecord';
import IndividualPep from '../ruleTypesForm/ruleSpecific/IndividualPep';
import IndividualSanctionList from '../ruleTypesForm/ruleSpecific/IndividualSanctionList';
import IpCountryBlocklist from '../ruleTypesForm/ruleSpecific/IpCountryBlocklist';
import KycValid from '../ruleTypesForm/ruleSpecific/KycValid';
import LowIncome from '../ruleTypesForm/ruleSpecific/LowIncome';
import RelatedIndividualsCriminalRecord from '../ruleTypesForm/ruleSpecific/RelatedIndividualsCriminalRecord';
import SimpleAnomalyDetection from '../ruleTypesForm/ruleCommon/SimpleAnomalyDetection';
import SimpleAnomalyDetectionWithoutWindow from '../ruleTypesForm/ruleCommon/SimpleAnomalyDetectionWithoutWindow';
import SniCodeBlocklist from '../ruleTypesForm/ruleSpecific/SniCodeBlocklist';
import SourceOfIncome from '../ruleTypesForm/ruleSpecific/SourceOfIncome';
import SuspiciousFunds from '../ruleTypesForm/ruleSpecific/SuspiciousFunds';
import ThresholdWithKycData from '../ruleTypesForm/ruleCommon/ThresholdWithKycData';
import TimeWindow from '../ruleTypesForm/ruleSpecific/TimeWindow';
import TransactionRollingSum from '../ruleTypesForm/ruleCommon/TransactionRollingSum';
import UpdateFreqOnly from '../ruleTypesForm/ruleCommon/UpdateFreqOnly';
import ZipCodeBlocklist from '../ruleTypesForm/ruleSpecific/ZipCodeBlocklist';
import SourceOfFunds from '../ruleTypesForm/ruleSpecific/SourceOfFunds';
import CountryListNoPurchase from '../ruleTypesForm/ruleSpecific/CountryListNoPurchase';

const ruleSpecifParamsMapper: Record<RuleNames, React.ComponentType | null> = {
  [ruleNames.ADDITIONAL_DELIVERY_ADDRESS]: UpdateFreqOnly,
  [ruleNames.AFFORDABILITY]: Affordability,
  [ruleNames.AGE_THRESHOLD]: AgeThreshold,
  [ruleNames.AMOUNT_THRESHOLD]: AmountThreshold,
  [ruleNames.ANOMALY_DETECTION_INCOMING_AVERAGE]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_INCOMING_FREQUENCY]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_INCOMING_SINGLE_AMOUNT]: SimpleAnomalyDetectionWithoutWindow,
  [ruleNames.ANOMALY_DETECTION_INCOMING_VOLUME_ROLLING_SUM]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_AVERAGE]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_FREQUENCY]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_SINGLE_AMOUNT]: SimpleAnomalyDetectionWithoutWindow,
  [ruleNames.ANOMALY_DETECTION_OUTGOING_VOLUME_ROLLING_SUM]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_AVERAGE]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_FREQUENCY]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_SINGLE_AMOUNT]: SimpleAnomalyDetectionWithoutWindow,
  [ruleNames.ANOMALY_DETECTION_PROCESSING_VOLUME_ROLLING_SUM]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_TOTAL_AVERAGE]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_TOTAL_FREQUENCY]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION_TOTAL_SINGLE_AMOUNT]: SimpleAnomalyDetectionWithoutWindow,
  [ruleNames.ANOMALY_DETECTION_TOTAL_VOLUME_ROLLING_SUM]: SimpleAnomalyDetection,
  [ruleNames.ANOMALY_DETECTION]: AnomalyDetection,
  [ruleNames.BUSINESS_ACTIVE]: UpdateFreqOnly,
  [ruleNames.BUSINESS_AUDITOR]: BusinessAuditor,
  [ruleNames.BUSINESS_DATA_CHANGED]: null,
  [ruleNames.BUSINESS_EQUITY_SHARE]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }),
  [ruleNames.BUSINESS_EQUITY]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }),
  [ruleNames.BUSINESS_FINANCIAL_STATEMENT_COMMENT]: UpdateFreqOnly,
  [ruleNames.BUSINESS_FTAX]: UpdateFreqOnly,
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS_SUM]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }),
  [ruleNames.BUSINESS_PAYMENT_APPLICATIONS]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }),
  [ruleNames.BUSINESS_PAYMENT_REMARKS_SUM]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }),
  [ruleNames.BUSINESS_PAYMENT_REMARKS]: () => ThresholdWithKycData({ overrideCurrencies: ['SEK'] }), // At the moment only supports SEK
  [ruleNames.BUSINESS_PROVIDER_WARNING]: UpdateFreqOnly,
  [ruleNames.BUSINESS_SANCTION_LIST]: BusinessSanctionList,
  [ruleNames.BUSINESS_VAT]: UpdateFreqOnly,
  [ruleNames.CAR_FREQ_HISTORICAL_OWNERS]: CarFreqHistoricalOwners,
  [ruleNames.CAR_GUARANTOR_RELATED]: ConnectedEntities,
  [ruleNames.CAR_IN_CIRCULATION]: UpdateFreqOnly,
  [ruleNames.CAR_VALUATION]: CarValuation,
  [ruleNames.CASH_FLOW]: CashFlow,
  [ruleNames.CATEGORIZATION_AMOUNT_THRESHOLD]: CategorizationAmountThreshold,
  [ruleNames.CO_ADDRESS]: UpdateFreqOnly,
  [ruleNames.COLLECTOR]: Collector,
  [ruleNames.CONNECTED_BUYER_SELLER]: UpdateFreqOnly,
  [ruleNames.CONNECTED_ENTITY_PEP]: ConnectedEntities,
  [ruleNames.CONNECTED_ENTITY_SANCTION_LIST]: ConnectedEntities,
  [ruleNames.COUNTRY_CITIZENSHIP_BLACKLIST]: CountryListNoPurchase,
  [ruleNames.COUNTRY_RESIDENCE_BLACKLIST]: CountryList,
  [ruleNames.COUNTRY_RESIDENCE_WHITELIST]: CountryList,
  [ruleNames.EMPLOYMENT_TYPE]: EmploymentType,
  [ruleNames.FOREIGN_ADDRESS]: UpdateFreqOnly,
  [ruleNames.INCOMING_CATEGORIZATION_FREQUENCY]: CategorizationFrequency,
  [ruleNames.INCOMING_CATEGORIZATION_ROLLING_SUM]: CategorizationRollingSum,
  [ruleNames.INCOMING_FREQUENCY]: Frequency,
  [ruleNames.INCOMING_VOLUME_ROLLING_SUM]: TransactionRollingSum,
  [ruleNames.INDIVIDUAL_CRIMINAL_RECORD]: IndividualCriminalRecord,
  [ruleNames.INDIVIDUAL_PEP]: IndividualPep,
  [ruleNames.INDIVIDUAL_SANCTION_LIST]: IndividualSanctionList,
  [ruleNames.IP_COUNTRY_BLACKLIST]: IpCountryBlocklist,
  [ruleNames.KYC_VALID]: KycValid,
  [ruleNames.LOW_INCOME]: LowIncome,
  [ruleNames.OUTGOING_CATEGORIZATION_FREQUENCY]: CategorizationFrequency,
  [ruleNames.OUTGOING_CATEGORIZATION_ROLLING_SUM]: CategorizationRollingSum,
  [ruleNames.OUTGOING_FREQUENCY]: Frequency,
  [ruleNames.OUTGOING_VOLUME_ROLLING_SUM]: TransactionRollingSum,
  [ruleNames.PROCESSING_FREQUENCY]: Frequency,
  [ruleNames.PROCESSING_VOLUME_ROLLING_SUM]: TransactionRollingSum,
  [ruleNames.RELATED_INDIVIDUALS_CRIMINAL_RECORD]: RelatedIndividualsCriminalRecord,
  [ruleNames.SELLER_OWNS_CAR]: UpdateFreqOnly,
  [ruleNames.SNI_CODE_BLACKLIST]: SniCodeBlocklist,
  [ruleNames.SOURCE_OF_FUNDS]: SourceOfFunds,
  [ruleNames.SOURCE_OF_INCOME]: SourceOfIncome,
  [ruleNames.SPAR_CHANGED]: null,
  [ruleNames.SUSPICIOUS_FUNDS]: SuspiciousFunds,
  [ruleNames.TEST_BLOCK]: () => null,
  [ruleNames.TEST_REVIEW]: () => null,
  [ruleNames.TIME_WINDOW]: TimeWindow,
  [ruleNames.TOTAL_CATEGORIZATION_FREQUENCY]: CategorizationFrequency,
  [ruleNames.TOTAL_CATEGORIZATION_ROLLING_SUM]: CategorizationRollingSum,
  [ruleNames.TOTAL_FREQUENCY]: Frequency,
  [ruleNames.TOTAL_VOLUME_ROLLING_SUM]: TransactionRollingSum,
  [ruleNames.ZIP_CODE_BLACKLIST]: ZipCodeBlocklist,
};

export default ruleSpecifParamsMapper;
