import React from 'react';

import { SelectField, useRecordContext } from 'react-admin';

import { get } from 'lodash';

import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import { employmentStatusChoices } from '../../../../constants/employmentStatuses';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Anställning',
  [languages.EN]: 'Employment',
};

const ValueField = ({ language }: {language?: Languages}) => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  if (!language) return null;

  return <SelectField choices={employmentStatusChoices(language)} record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const EmploymentStatus = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="employmentStatus"
      datapoint={conflictFields.EMPLOYMENT_STATUS}
    >
      <ValueField language={language} />
    </Answer>
  );
};

EmploymentStatus.ValueField = ValueField;

export default EmploymentStatus;
