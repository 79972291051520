import React, { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
  DateField,
} from 'react-admin';

import { Card, Divider } from '@mui/material';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';

import hasAccess from '../../utilities/hasAccess';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';
import LinkField from '../layout/LinkField';

import TeamChip from '../../customFields/ColoredChipField';

const TagList = () => {
  const { permissions } = usePermissions<Actions[]>();

  const [activationTab, setActivationTab] = useState(0);
  const [active, setActive] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivationTab(() => newValue);
    setActive(() => newValue === 0);
  };
  return (
    <List
      filter={{ active }}
      empty={false}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={activationTab} onChange={handleChange}>
              <StyledTab label="Active " />
              <StyledTab label="Inactive" />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
          {hasAccess(permissions, actions.TAG_GET) && <LinkField sortable={false} source="id" />}
          {!hasAccess(permissions, actions.TAG_GET) && <TextField sortable={false} source="id" />}
          <DateField sortable source="createdAt" showTime />
          <TextField sortable={false} source="name" />
          <TeamChip sortable={false} label="Tag" />
        </Datagrid>
      </Card>
    </List>
  );
};

export default TagList;
