import React, { useState } from 'react';

import {
  RaRecord,
  RecordContextProvider,
  ReferenceManyField,
} from 'react-admin';

import {
  Box,
  Card,
  Slide,
} from '@mui/material';

import DetailedView from './detailedView';
import CustomButton from './CustomButton';
import Filter from './RequestFilter';

import Pagination from '../../../../layout/Pagination';

import { requestListMap } from '../../../../../constants/requestFeatures';
import { RequestTypes } from '../../../../../constants/requestTypes';

const Requests = ({
  target = '_',
  editOpen,
  handleOpenChange,
  handleCloseDialog,
  entities,
}: {
  target?: string,
  editOpen: boolean,
  handleOpenChange: () => void,
  handleCloseDialog: () => void,
  entities?: string[]
}) => {
  const [record, setRecord] = useState<RaRecord>();
  const [filter, setFilter] = useState<{
    requestType: RequestTypes;
    'custom.entity'?: string[];
  }>({ requestType: 'transaction', 'custom.entity': entities });

  const RequestListComponent = requestListMap[filter.requestType];

  return (
    <ReferenceManyField
      filter={filter}
      perPage={10}
      pagination={(
        <Pagination
          hidden={editOpen}
          rowsPerPageOptions={[10, 15, 20]}
          disableEmptyText
        />
      )}
      reference="requests"
      source="requests"
      target={target}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Box>
        <Box display={editOpen ? 'none' : 'block'}>
          <Filter filter={filter} setFilter={setFilter} />
          <Card variant="outlined">
            <RequestListComponent>
              <CustomButton
                handleOpenChange={handleOpenChange}
                setRecord={setRecord}
              />
            </RequestListComponent>
          </Card>
        </Box>
        <Slide direction="right" in={editOpen}>
          <Box display={!editOpen ? 'none' : 'block'}>
            <RecordContextProvider value={record}>
              <DetailedView
                handleCloseDialog={handleCloseDialog}
                setRecord={setRecord}
                handleOpenChange={handleOpenChange}
              />
            </RecordContextProvider>
          </Box>
        </Slide>
      </Box>
    </ReferenceManyField>
  );
};

export default Requests;
