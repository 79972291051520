import React from 'react';

import {
  Typography,
  ToggleButton as MuiToggleButton,
  ToggleButtonGroup,
  Stack,
} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import entityTypes, { EntityTypes } from '../../../../../../constants/entityTypes';

const ToggleButton = ({
  onChange,
  source,
  direction,
  entityType,
}: {
  direction: 'from' | 'to'
  entityType?: EntityTypes
  source: string
  onChange: (x: string) => void
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null,
  ) => {
    if (newValue !== null) {
      onChange(newValue);
      if (source !== '') {
        event.stopPropagation();
      }
    }
  };

  return (
    <Stack direction="row" spacing={4}>
      { (!entityType || entityType === entityTypes.INDIVIDUAL) && (
        <ToggleButtonGroup
          value={source}
          exclusive
          onChange={handleChange}
          size="small"
          color="primary"
        >
          <MuiToggleButton disabled value="" aria-label="left aligned">
            <PersonIcon />
          </MuiToggleButton>
          <MuiToggleButton value={`t_${direction}_my_client.${direction}_person`}>
            <Typography>My client</Typography>
          </MuiToggleButton>
          <MuiToggleButton value={`t_${direction}.${direction}_person`}>
            <Typography>Not my client</Typography>
          </MuiToggleButton>
        </ToggleButtonGroup>
      )}
      { (!entityType || entityType === entityTypes.BUSINESS) && (
        <ToggleButtonGroup
          value={source}
          exclusive
          onChange={handleChange}
          size="small"
          color="primary"
        >
          <MuiToggleButton disabled value="" aria-label="left aligned">
            <CorporateFareIcon />
          </MuiToggleButton>
          <MuiToggleButton value={`t_${direction}_my_client.${direction}_entity`}>
            <Typography>My client</Typography>
          </MuiToggleButton>
          <MuiToggleButton value={`t_${direction}.${direction}_entity`}>
            <Typography>Not my client</Typography>
          </MuiToggleButton>
        </ToggleButtonGroup>
      )}
    </Stack>
  );
};

export default ToggleButton;
