import React from 'react';

import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';

import { Card } from '@mui/material';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';

import SearchField from '../layout/SearchField';
import NoResults from '../layout/NoResults';
import Pagination from '../layout/Pagination';
import LinkField from '../layout/LinkField';

export const CarListBody = ({ children }: {children?: React.ReactElement}) => (
  <Datagrid bulkActionButtons={false} sx={boldDataGridStyle} empty={<NoResults variant="h6" />}>
    <LinkField sortable={false} source="id" />
    <TextField sortable={false} source="registrationNumber" />
    <TextField sortable={false} source="registrationCountry" />
    <TextField sortable={false} source="carModel" emptyText="-" />
    {children}
  </Datagrid>
);

export const CarFilters = [
  <SearchField source="registrationNumber" alwaysOn />,
  <SearchField source="carModel" />,
  <SearchField source="registrationCountry" />,
];

const CarList = () => (
  <List
    filters={CarFilters}
    empty={false}
    pagination={<Pagination disableEmptyText />}
    exporter={false}
  >
    <Card sx={{ margin: 0 }} variant="outlined">
      <CarListBody />
    </Card>
  </List>
);

export default CarList;
