import React from 'react';

import {
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  required,
  SelectArrayInput,
  SelectInput,
  useRecordContext,
} from 'react-admin';

import { Grid, Typography } from '@mui/material';

import { useWatch } from 'react-hook-form';
import { isBoolean } from 'lodash';
import { recommendationsChoices } from '../../../../constants/recommendations';
import { mapBinaryRule } from '../../ruleFeatures';
import ruleTypes from '../../../../constants/ruleTypes';
import { RuleNames } from '../../../../constants/ruleNames';

type Form = {
  name: RuleNames;
  ruleType: string;
}

const RuleResponse = () => {
  const ruleName = useWatch<Form, 'name'>({ name: 'name' });
  const record = useRecordContext();
  const ruleType = useWatch<Form, 'ruleType'>({ name: 'ruleType' });
  const createPing = useWatch({ name: 'createPing', defaultValue: isBoolean(record?.createPing) ? record?.createPing : true });
  const isAutoConfirmed = useWatch({ name: 'isAutoConfirmed', defaultValue: record?.isAutoConfirmed ?? false });

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant="label">
          If an error occurs, should a ping be created?
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <RadioButtonGroupInput
          label={false}
          source="pingOnError"
          defaultValue
          parse={(value) => value === 'true'}
          choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
          validate={required()}
        />
      </Grid>
      {ruleType === ruleTypes.REQUEST && (
        <>
          <Grid item xs={12} md={12}>
            <Typography variant="label">
              If check is positive, which recommendation should be returned?
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <SelectInput
              source="recommendation"
              fullWidth
              validate={required()}
              choices={recommendationsChoices}
              label="Recommendation"
            />
          </Grid>
        </>
      )}
      {ruleType === ruleTypes.REQUEST && (
        <>
          <Grid item xs={12} md={12}>
            <Typography variant="label">
              If check is positive, which, if any, signal(s) should be returned?
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <ReferenceArrayInput source="signals" reference="signals">
              <SelectArrayInput source="name" fullWidth />
            </ReferenceArrayInput>
          </Grid>
        </>
      )}
      <Grid item xs={12} md={12}>
        <Typography variant="label">
          If check is positive, create Ping?
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <RadioButtonGroupInput
          label={false}
          defaultValue
          parse={(value) => value === 'true'}
          source="createPing"
          choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
          validate={required()}
        />

      </Grid>
      {createPing && (
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="label">
              If Ping is created, should it be confirmed automatically?
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <RadioButtonGroupInput
              label={false}
              defaultValue={false}
              parse={(value) => value === 'true'}
              source="isAutoConfirmed"
              choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
              validate={required()}
            />
          </Grid>
        </Grid>
      )}

      {(isAutoConfirmed && createPing && mapBinaryRule[ruleName]) && (
        <Grid container>
          <Grid item xs={12} md={12}>
            <Typography variant="label">
              For how long should the system use this decision for future requests?
              (During that time pings won&apos;t be created for that rule and
              recommendation will be inferred from the decision)
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <NumberInput
              source="numberOfDaysIgnoreRule"
              label="Days"
              validate={[required(), minValue(0)]}
            />
          </Grid>
        </Grid>
      )}

    </Grid>
  );
};

export default RuleResponse;
