import { useState, useEffect } from 'react';
import { useGetIdentity, useGetOne, useRefresh } from 'react-admin';

type Member = {
  firstName?: string
  lastName?: string
  email?: string
  accountName?: string
  accountId?: string
  id?: string
  occupation?: string;
  birthDate?: Date;
  swedishSocialSecurityNumber?: string;
  residence?: string;
  foreignId?: string;
  foreignIdCountry?: string
}

const useMember = () => {
  const refresh = useRefresh();
  const [loaded, setLoaded] = useState(false);
  const [member, setMember] = useState<Member | undefined>(undefined);
  const { identity } = useGetIdentity();

  const { data } = useGetOne('members', { id: member?.id }, { enabled: !!member?.id });

  const refreshMember = () => {
    setLoaded(() => false);
    refresh();
  };

  useEffect(() => {
    if (identity) {
      setMember((prev) => ({
        ...prev,
        accountName: identity?.accountName,
        accountId: identity.accountId,
        id: identity?.id as string,
      }));
    }
    return () => {};
  }, [identity]);

  useEffect(() => {
    if (data) {
      setMember((prev) => ({
        ...prev,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        occupation: data?.occupation,
        birthDate: data?.birthDate,
        swedishSocialSecurityNumber: data?.swedishSocialSecurityNumber,
        residence: data?.residence,
        foreignId: data?.foreignId,
        foreignIdCountry: data?.foreignIdCountry,
      }));
      setLoaded(() => true);
    }
    return () => {};
  }, [data, identity, loaded]);

  return {
    member: loaded ? member : undefined,
    refreshMember,
  };
};

export default useMember;
