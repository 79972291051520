import React, { useCallback, useContext } from 'react';
import {
  Button,
  CircularProgress,
} from '@mui/material';

import {
  useLogin,
  useNotify,
  useSafeSetState,
  Form,
  TextInput,
  PasswordInput,
  required,
  HttpError,
  Link,
} from 'react-admin';

import InputGroup from '../../InputGroup';
import ButtonGroup from '../../ButtonGroup';

import LoginContext from '../LoginContext';
import SwitchLoginMethods from '../SwitchLoginMethods';

type LoginFormFields = {
  username?: string;
  password?: string;
}

const CredentialsPage = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();

  const loginContext = useContext(LoginContext);
  const alias = loginContext?.accountDetails?.alias;

  const submit = useCallback(async (data: LoginFormFields) => {
    setLoading(true);
    try {
      await login({ ...data, alias });
    } catch (error) {
      if (error instanceof HttpError) notify(error.message, { type: 'error' });
      else notify('Authentication failed', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, [login, notify, setLoading, alias]);

  return (
    <Form onSubmit={submit}>
      <InputGroup>
        <TextInput
          source="username"
          label="Username"
          disabled={loading}
          validate={[required()]}
          fullWidth
        />
        <PasswordInput
          source="password"
          label="Password"
          disabled={loading}
          validate={[required()]}
          helperText={false}
          fullWidth
        />
        <Link
          to={`/forget-password/${alias}`}
          sx={{
            marginTop: 1,
            '&.RaLink-link': {
              textDecoration: 'underline',
            },
          }}
          underline="always"
        >
          Forgot password?
        </Link>
      </InputGroup>
      <ButtonGroup sx={{ mt: 6 }}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
        >
          {loading && (
            <CircularProgress
              size={18}
              thickness={2}
            />
          )}
          Sign in
        </Button>
        <SwitchLoginMethods />
      </ButtonGroup>
    </Form>
  );
};

export default CredentialsPage;
