import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

const CircleContainer = ({
  children,
  p,
  borderColor,
  bgcolor,
  size,
}: {
  children: React.ReactElement,
  p: number,
  size?: number,
  bgcolor?: BoxProps['bgcolor'],
  borderColor?: BoxProps['borderColor']
}) => (
  <Box
    sx={{
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      // border: '2px solid',
      m: 0,
      flexShrink: 0,
      borderColor,
      borderRadius: '50%',
      p,
      bgcolor,
      height: size,
      width: size,
    }}
  >
    {children}
  </Box>
);

export default CircleContainer;
