import React from 'react';

import { RecordContextProvider, useRecordContext } from 'react-admin';

import { useFormContext, useWatch } from 'react-hook-form';

import { isBoolean } from 'lodash';

import RuleLogic from '../../components/steps/RuleLogic';
import RuleTriggers from '../../components/steps/RuleTriggers';
import RuleResponse from '../../components/steps/RuleResponse';
import RuleScore from '../../components/steps/RuleScore';
import RuleSummary from '../../components/steps/ruleSummary';

import FormWizard, { FormPath } from '../../../layout/formWizard/FormWizard';

const RequestRules = () => {
  const record = useRecordContext();
  const createPing = useWatch({ name: 'createPing', defaultValue: isBoolean(record?.createPing) ? record?.createPing : true });
  const { getValues } = useFormContext();

  return (
    <FormWizard edit={!record?.versions}>
      <FormPath path="" label="Rule logic">
        <RuleLogic />
      </FormPath>
      <FormPath path="triggers" label="Rule triggers">
        <RuleTriggers />
      </FormPath>
      <FormPath path="response" label="Rule response">
        <RuleResponse />
      </FormPath>
      <FormPath hidden={createPing !== true} path="score" label="Score calculation">
        <RuleScore />
      </FormPath>
      <FormPath path="summary" label="Summary">
        <RecordContextProvider value={getValues()}>
          <RuleSummary />
        </RecordContextProvider>
      </FormPath>
    </FormWizard>
  );
};

export default RequestRules;
