import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';

import { NumberInput, minValue, required } from 'react-admin';

import ReactApexChart from 'react-apexcharts';

import { useFormContext, useWatch } from 'react-hook-form';

import { isNumber, round } from 'lodash';

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
  Box,
  Typography,
  Fade,
  Popover,
  TextField,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

interface HeaderProps {
  handleSave: () => void;
  handleClose: () => void;
}

interface ModelSliderProps {
  type: string;
  initial: number;
  slider: number;
  handleSliderChange: (event: any) => void;
  preventHorizontalKeyboardNavigation: (event: React.KeyboardEvent) => void;
}

interface ChartProps {
  type: string;
  data: number[];
  initial: number;
  setData: React.Dispatch<React.SetStateAction<number[]>>;
  source: string;
  displayText: string;
}

interface NeutralValueProps {
  source: string;
  displayText: string;
}

interface ModelToggleProps {
  type: string;
  handleChange: (event: any) => void;
}

const calculateSlopeFromSlider = (
  sliderValue: number,
) => (sliderValue - 50) / 10;

const calculateDataFromSlope = (
  initial: number,
  slope: number,

) => [
  ...Array(11).keys(),
].map((_, index) => initial + ((slope * initial || slope) * (index - 5)));

const Header: React.FC<HeaderProps> = ({ handleSave, handleClose }) => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={6} display="flex" justifyContent="space-between">
      <Grid item xs={6} md={6}>
        <Typography variant="h3">Risk-based Threshold</Typography>
      </Grid>
      <Grid item xs={6} md={6} display="flex" justifyContent="flex-end">
        <Box display="flex">
          <Box display="flex" flexDirection="row" marginRight={2}>
            <Button variant="contained" onClick={handleSave} startIcon={<SaveIcon />}>Save</Button>
          </Box>
          <Button onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

const Chart: React.FC<ChartProps> = ({
  type,
  data,
  initial,
  setData,
  displayText,
}) => {
  const [open, setOpen] = useState(false);
  const [xValue, setXValue] = useState<number>();
  const xDataRef = useRef<number>();
  const yDataRef = useRef<number>();
  const isDownRef = useRef(false);
  const [customInput, setCustomInput] = useState(0);

  const series = [{
    name: displayText,
    // Necessary to display gradient on horizontal line
    data: data.map((item: number) => item + Math.random() * (initial / 100000)),
  }];

  useEffect(() => {
    if (xValue) setCustomInput(() => data.at(xValue) ?? 0);
  }, [data, xValue, open]);

  const handleChangeCustomInput = useCallback(() => {
    setData((prev) => prev.map((v, i) => (
      i === xValue
        ? customInput as number
        : v)));
    setOpen(false);
  }, [customInput, setData, xValue]);

  const handleMouseMove = useCallback((event: any, chartContext: any, config: any) => {
    if (type !== 'custom') return;

    if (!isDownRef.current) {
      if (config.dataPointIndex !== -1) {
        xDataRef.current = config.dataPointIndex;
      }
      yDataRef.current = event.layerY;
    }
    if (isDownRef.current) {
      const ratio = ((yDataRef.current ?? 0) - event.layerY)
      / chartContext.w.globals.gridHeight;
      setData((prev) => prev.map((item, idx) => {
        if (idx === xDataRef.current) {
          const value = item + (chartContext.w.globals.maxY - chartContext.w.globals.minY) * ratio;
          return round(value, 2);
        }
        return item;
      }));
      yDataRef.current = event.layerY;
    }
  }, [type, setData]);

  const handleMouseDown = useCallback(() => {
    isDownRef.current = true;
  }, []);
  const handleMouseUp = useCallback(() => {
    isDownRef.current = false;
  }, []);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseUp]);

  if (!isNumber(initial)) return null;

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      height: 400,
      events: {
        mouseMove: handleMouseMove,
        click(event, chartContext, config) {
          if (event.detail === 2) {
            setOpen(true);
            if (config.dataPointIndex !== -1) {
              setXValue(config.dataPointIndex);
              xDataRef.current = event.clientX;
              yDataRef.current = event.clientY;
            }
          }
        },
      },
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter(value: number) {
          return `${round(value, 2)}`;
        },
      },
      x: {
        formatter(value: number) {
          return `Risk score: ${(value - 1) * 10}`;
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: '#50C878',
            opacity: 1,
          },
          {
            offset: 50,
            color: '#E4D00A',
            opacity: 1,
          },
          {
            offset: 90,
            color: '#C70039',
            opacity: 1,
          },
        ],
      },
    },
    markers: {
      size: type === 'custom' ? 10 : 0,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100'],
      title: {
        text: 'Risk score',
      },
    },
    yaxis: {
      type: 'numeric',
      min: Math.min(...data, initial * 0.5),
      max: Math.max(...data, initial * 1.5),
      forceNiceScale: true,
      decimalsInFloat: 0,
      title: {
        text: displayText,
      },
    },
  } as ReactApexChart['props']['options'];

  return (
    <Fade in>
      <Grid item xs={11} md={11}>
        <ReactApexChart options={options} series={series} type="line" height={350} />
        <Popover
          open={open}
          onClose={handleChangeCustomInput}
          anchorReference="anchorPosition"
          anchorPosition={{ top: yDataRef.current ?? 0, left: xDataRef.current ?? 0 }}
        >
          <NumberInput
            source=""
            defaultValue={data.at(xValue ?? 0)}
            size="small"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChangeCustomInput();
              }
            }}
            label="Custom value"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCustomInput(round(parseFloat(event.target.value) ?? 0, 2));
            }}
            helperText={false}
            validate={minValue(1)}
          />
        </Popover>
      </Grid>
    </Fade>
  );
};

const ModelToggle: React.FC<ModelToggleProps> = ({ type, handleChange }) => (
  <Grid item xs={12} md={12} display="flex" justifyContent="center">
    <ToggleButtonGroup
      color="primary"
      value={type}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="linear">Linear model</ToggleButton>
      <ToggleButton value="custom">Custom model</ToggleButton>
    </ToggleButtonGroup>
  </Grid>
);

const ModelSlider: React.FC<ModelSliderProps> = ({
  slider, handleSliderChange, preventHorizontalKeyboardNavigation, type, initial,
}) => {
  if (type !== 'linear' || !isNumber(initial)) return null;

  return (
    <Grid item xs={1} md={1} display="flex" justifyContent="center" alignItems="center">
      <Box height="80%" paddingBottom="20%" display="flex" flexDirection="column" gap={2} alignItems="center">
        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: 'slider-vertical',
            },
          }}
          orientation="vertical"
          value={slider}
          onChange={handleSliderChange}
          valueLabelDisplay="off"
          onKeyDown={preventHorizontalKeyboardNavigation!}
        />
        <TextField variant="outlined" sx={{ maxWidth: 70 }} type="number" value={slider} onChange={handleSliderChange} />
      </Box>
    </Grid>
  );
};

const NeutralValue: React.FC<NeutralValueProps> = ({ source, displayText }) => (
  <Grid item xs={6} md={6}>
    <Box display="flex" gap={2} alignItems="center">
      <Typography variant="label">Neutral value:</Typography>
      <NumberInput size="small" label={displayText} source={`${source}.initial`} helperText={false} validate={[required(), minValue(1)]} />
    </Box>
  </Grid>
);

const Body = ({
  source,
  displayText,
  handleSave,
  handleChange,
  handleSliderChange,
  slider,
  initial,
  type,
  handleClose,
  data,
  setData,
}: {
  source: string;
  defaultInitial?: number;
  displayText: string;
  handleSave: () => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSliderChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  slider: number;
  initial: number;
  type: string;
  handleClose: () => void;
  data: number[];
  setData: React.Dispatch<React.SetStateAction<number[]>>
}) => {
  const [startPageShown, setStartPageShown] = useState(false);
  const preventHorizontalKeyboardNavigation = useCallback((
    event: React.KeyboardEvent,
  ) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    if (isNumber(initial)) setStartPageShown(true);
  }, [initial, startPageShown]);

  if (!isNumber(initial) && !startPageShown) {
    return (
      <Grid container spacing={6}>
        <Header handleClose={handleClose} handleSave={handleSave} />
        <Grid item xs={12} md={12} display="flex" justifyContent="center">
          <Card variant="outlined">
            <CardHeader title="Neutral Value" subheader="Select an neutral value to be used as an point of reference." />
            <CardContent>
              <NumberInput fullWidth size="small" label={displayText} source={`${source}.initial`} helperText={false} validate={[required(), minValue(1)]} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={6}>
      <Header handleClose={handleClose} handleSave={handleSave} />
      <ModelToggle handleChange={handleChange} type={type} />
      <Chart
        data={data}
        setData={setData}
        displayText={displayText}
        initial={initial}
        type={type}
        source={source}
      />
      <ModelSlider
        handleSliderChange={handleSliderChange}
        preventHorizontalKeyboardNavigation={preventHorizontalKeyboardNavigation}
        slider={slider}
        type={type}
        initial={initial}
      />
      <NeutralValue source={source} displayText={displayText} />
    </Grid>
  );
};

const RiskAdjustment = ({
  source,
  open,
  closeDialog,
  defaultSlider = 50,
  displayText,

}: {
  source: string;
  open: boolean;
  closeDialog: () => void;
  defaultSlider?: number;
  displayText: string;
}) => {
  const { setValue } = useFormContext();
  const values = useWatch({ name: source });

  const initial = values?.initial;
  const formSlider = values?.slider;
  const formType = values?.type;
  const formSlope = values?.slope;
  const formThresholds = values?.thresholds;

  const [slider, setSlider] = useState<number>(formSlider ?? defaultSlider);
  const [slope, setSlope] = useState(calculateSlopeFromSlider(slider));
  const [type, setType] = useState<string>(formType ?? 'linear');
  const [data, setData] = useState<number[]>(
    formThresholds
      ? Object.values(formThresholds)
      : calculateDataFromSlope(initial, slope),
  );

  useEffect(() => {
    setSlider(formSlider ?? defaultSlider);
  }, [defaultSlider, formSlider]);

  useEffect(() => {
    setSlope(calculateSlopeFromSlider(slider));
  }, [slider]);

  useEffect(() => {
    setType(formType ?? 'linear');
  }, [formType]);

  useEffect(() => {
    setData(formThresholds
      ? Object.values(formThresholds)
      : calculateDataFromSlope(initial, slope));
  }, [formThresholds, initial, slope]);

  useEffect(() => {
    if (type === 'linear') setData(calculateDataFromSlope(initial, slope));
  }, [type, initial, slope]);

  const handleClose = useCallback(() => {
    closeDialog();
    setType(formType ?? 'linear');
    setSlider(formSlider ?? defaultSlider);
    setSlope(formSlope ?? 0);
  }, [closeDialog, defaultSlider, formSlider, formSlope, formType]);

  const handleSave = useCallback(() => {
    closeDialog();

    const thresholds = Object.fromEntries(
      data.map((value, index) => [index * 10, value]),
    );

    setValue(source, {
      slider, slope, thresholds, initial, type,
    }, { shouldDirty: true });
  }, [closeDialog, data, initial, setValue, slider, slope, source, type]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  }, []);

  const handleSliderChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as unknown as number;
    if (value >= 0 && value <= 100) { setSlider(value); }
  }, []);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogContent>
        <Body
          source={source}
          handleSave={handleSave}
          handleChange={handleChange}
          handleSliderChange={handleSliderChange}
          initial={initial}
          displayText={displayText}
          slider={slider}
          type={type}
          handleClose={handleClose}
          data={data}
          setData={setData}
        />
      </DialogContent>
    </Dialog>
  );
};

export default RiskAdjustment;
