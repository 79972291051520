import React, { useMemo } from 'react';

import { get } from 'lodash';

import { SelectField, TextField, useRecordContext } from 'react-admin';

import { Grid, Box, Typography } from '@mui/material';

import languages, { Languages } from '../../../../constants/languages';
import controlTypes, { directControlTypeChoices, indirectControlTypeChoices } from '../../../../constants/controlTypes';

const TITLE = {
  [languages.SV]: 'På vilket sätt äger eller kontrollerar personen företaget?',
  [languages.EN]: 'How is control exercised?',
};

const OWNERSHIP_LABEL = {
  [languages.SV]: 'Ägarandel i %',
  [languages.EN]: 'Ownership in %',
};

const DIRECT_CONTROL_LABEL = {
  [languages.SV]: 'Direkt ägande',
  [languages.EN]: 'Direct control',
};

const INDIRECT_CONTROL_LABEL = {
  [languages.SV]: 'Indirekt ägande',
  [languages.EN]: 'Indirect control',
};

const ControlType = ({ source }: {source?: string, language?: Languages }) => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  const controlType = get(record, `${source ?? 'controlType'}`);
  const sx = get(record, 'sx', {});

  const shouldStateShare = useMemo(
    () => [
      controlTypes.SHAREHOLDER,
      controlTypes.VOTING_RIGHT,
    ].includes(controlType?.type),
    [controlType?.type],
  );

  return (
    <>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">{TITLE[language]}</Typography>
          <SelectField
            choices={[
              { name: DIRECT_CONTROL_LABEL[language], id: 'yes' },
              { name: INDIRECT_CONTROL_LABEL[language], id: 'no' },
            ]}
            source={`${source ?? 'controlType'}.direct`}
            emptyText="-"
            sx={sx}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">
            {TITLE[language]}
          </Typography>
          {controlType?.type !== 'other' && (
            <SelectField
              choices={controlType?.direct === 'yes'
                ? directControlTypeChoices(language)
                : indirectControlTypeChoices(language)}
              source={`${source ?? 'controlType'}.type`}
              emptyText="-"
              sx={sx}
            />
          )}
          {controlType?.type === 'other' && (
            <TextField
              sx={{
                ...sx,
                wordWrap: 'break-word',
              }}
              record={{ value: controlType?.other }}
              source="value"
              emptyText="-"
            />
          )}
        </Box>
      </Grid>
      {shouldStateShare && (
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">{OWNERSHIP_LABEL[language]}</Typography>
            <TextField
              source={`${source ?? 'controlType'}.ownership`}
              emptyText="-"
              sx={sx}
            />
          </Box>
        </Grid>
      )}
    </>
  );
};

ControlType.ValueField = ControlType;

ControlType.Nested = ControlType;

export default ControlType;
