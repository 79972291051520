import React, { useCallback } from 'react';
import {
  Button,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  useDataProvider,
  useNotify,
  useSafeSetState,
  Form,
  PasswordInput,
  required,
  useLogout,
} from 'react-admin';

import AuthLayout from '../AuthLayout';
import InputGroup from '../InputGroup';
import ButtonGroup from '../ButtonGroup';

type ResetPasswordFormFields = {
  password?: string;
}

const ResetPasswordPage = () => {
  const [loading, setLoading] = useSafeSetState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const logout = useLogout();

  const submit = useCallback(async (data: ResetPasswordFormFields) => {
    setLoading(true);

    try {
      await dataProvider.create('auth/reset-password', { data: { password: data.password, token } });
      logout(undefined, undefined, false);
      notify('New password set successfully');
    } catch {
      notify('Could not set new password. The set password link is most likely expired or invalid.', { type: 'error' });
    } finally {
      setLoading(false);
    }
  }, [dataProvider, logout, notify, setLoading, token]);

  return (
    <AuthLayout>
      <Form onSubmit={submit}>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Set new password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Choose your new password
        </Typography>
        <InputGroup>
          <PasswordInput
            source="password"
            label="New password"
            disabled={loading}
            fullWidth
            validate={[required()]}
          />
        </InputGroup>
        <ButtonGroup>
          <Button
            variant="contained"
            type="submit"
            color="primary"
          >
            Set new password
          </Button>
        </ButtonGroup>
      </Form>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
