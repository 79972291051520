import React from 'react';

import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Box,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import useDialogStatus from '../../../../../hooks/useDialogStatus';

import DetailedView from './detailedView';

const Edit = () => {
  const { open, closeDialog, openDialog } = useDialogStatus();
  return (
    <Box>
      <Button startIcon={<EditIcon />} onClick={openDialog}>Edit</Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent>
          <DetailedView handleCloseDialog={closeDialog} isEditView handleOpenChange={openDialog} />
        </DialogContent>
        <DialogActions sx={{
          backgroundColor: 'white',
        }}
        >
          <Button startIcon={<CloseIcon />} color="primary" variant="contained" onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Edit;
