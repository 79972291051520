import { ValueOf } from '../utilities/types';

const caseStatuses = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  IN_PROGRESS: 'in-progress',
  PENDING: 'pending',
} as const;

export type CaseStatuses = ValueOf<typeof caseStatuses>;

export default caseStatuses;
