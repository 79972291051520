import React from 'react';

import {
  RecordContextProvider,
} from 'react-admin';

import {
  Box,
  capitalize,
} from '@mui/material';

import ToggleButton from './ToggleButton';
import EntityTypeAccordionForm from './forms';

import Accordion from '../../../../../layout/Accordion';
import { TransactionParty } from '../../../../../request/types';
import { Currencies } from '../../../../../../constants/currencies';

const EntityPartiesAccordion = ({
  from,
  to,
  fromVariant,
  toVariant,
  fromLabel,
  toLabel,
  setFromVariant,
  setToVariant,
  currency,
}: {
  from: TransactionParty | undefined
  to: TransactionParty | undefined
  fromVariant: string
  toVariant: string
  fromLabel: string | undefined;
  toLabel: string | undefined;
  setFromVariant: (x: string) => void
  setToVariant: (x: string) => void
  currency: Currencies | undefined;
}) => (
  <Box>
    <Accordion
      label={fromLabel ? capitalize(fromLabel) : 'From'}
      actions={(
        <ToggleButton
          direction="from"
          onChange={setFromVariant}
          source={fromVariant}
          entityType={from?.type}
        />
      )}
    >
      <RecordContextProvider
        value={{
          ...from,
          currency,
        }}
      >
        <EntityTypeAccordionForm entityVariant={fromVariant} direction="from" />
      </RecordContextProvider>
    </Accordion>
    <Accordion
      label={toLabel ? capitalize(toLabel) : 'To'}
      actions={(
        <ToggleButton
          direction="to"
          onChange={setToVariant}
          source={toVariant}
          entityType={to?.type}
        />
      )}
    >
      <RecordContextProvider
        value={{
          ...to,
          currency,
        }}
      >
        <EntityTypeAccordionForm entityVariant={toVariant} direction="to" />
      </RecordContextProvider>
    </Accordion>

  </Box>

);

export default EntityPartiesAccordion;
