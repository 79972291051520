import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionField = ({
  title,
  children,
  fullWidth,
}: {
  title: string,
  children: React.ReactElement
  fullWidth?: boolean
}) => (
  <Accordion
    sx={{
      ...(fullWidth ? {} : { maxWidth: 300 }),
      backgroundColor: 'transparent',
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '1',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

export default AccordionField;
