import React from 'react';

import { Grid, Typography } from '@mui/material';
import PrimitiveInput from './base/PrimitiveInput';

const CompanyName = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Company Name
      </Typography>
    </Grid>
    <PrimitiveInput />
  </Grid>
);

export default CompanyName;
