import React from 'react';
import { get } from 'lodash';

import { useRecordContext } from 'react-admin';

import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';

import useDialogStatus from '../hooks/useDialogStatus';

const ViewJSON = ({ source }: { source?: string }) => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <JsonView
      data={source ? get(record, source, {}) : record}
      shouldExpandNode={allExpanded}
      style={defaultStyles}
    />
  );
};

export const ViewJSONDialog = ({ color, title, source }: { color?: ButtonProps['color'], title: string, source?: string }) => {
  const { open, closeDialog, openDialog } = useDialogStatus();

  return (
    <>
      <Button color={color} startIcon={<DescriptionIcon />} onClick={openDialog}>Inspect</Button>
      <Dialog open={open} fullWidth maxWidth="md" onClose={closeDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="primary" variant="h6">{title}</Typography>
            <IconButton onClick={closeDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ViewJSON source={source} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewJSON;
