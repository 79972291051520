import React from 'react';

import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import useRule from '../../hooks/useRule';

import RequestRules from './ruleTypesForm/requestRules';
import OngoingRules from './ruleTypesForm/ongoingRules';

import cleaner from '../../utilities/dataProvider/cleaner';

import ruleTypes from '../../constants/ruleTypes';

const RuleCreate = () => {
  const {
    ruleType,
    view,
    parentRule,
  } = useRule();

  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Create
      mutationOptions={{
        onSuccess: () => {
          notify('Rule created');
          redirect('list', 'rules', undefined, {}, { record: { ruleType, view } });
        },
      }}
      transform={cleaner}
    >
      <SimpleForm mode="all" toolbar={false} defaultValues={{ ruleType, parentRule }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {ruleType === ruleTypes.REQUEST && <RequestRules />}
            {ruleType === ruleTypes.ONGOING && <OngoingRules />}
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default RuleCreate;
