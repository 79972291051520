import React from 'react';
import {
  SelectArrayInput,
  minLength,
  required,
} from 'react-admin';

import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import languages from '../../../../constants/languages';
import { employmentStatusChoices } from '../../../../constants/employmentStatuses';

const EmploymentType = () => (
  <Grid item xs={12} md={12}>
    <Box marginBottom={2}>
      <Typography variant="label">Unacceptable employment statuses</Typography>
    </Box>
    <Box display="flex" flexWrap="wrap" gap={2}>
      <SelectArrayInput
        source="ruleParameters.unacceptableEmploymentTypes"
        label="Statuses"
        validate={[required(), minLength(1)]}
        choices={employmentStatusChoices(languages.EN)}
      />
    </Box>
  </Grid>
);

export default EmploymentType;
