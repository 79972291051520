import React from 'react';

import {
  Grid,
} from '@mui/material';

import General from './General';
import Summarization from './Summarization';

import LatestRelatedRequests from '../../../../customFields/LatestRelatedRequests';
import LatestRelatedCases from '../../../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../../../customFields/LatestRelatedPings';

import { requestTypes } from '../../../../constants/requestTypes';
import RelatedCustomConnections from '../../../connection/RelatedCustomConnections';

const Overview = () => (
  <Grid container spacing={6}>
    <General />
    <Grid item xs={12} lg={7} container direction="column">
      <Grid item>
        <RelatedCustomConnections />
      </Grid>
      <Grid item>
        <RelatedCustomConnections />
      </Grid>
    </Grid>
    <Summarization />
    <LatestRelatedRequests defaultType={requestTypes.CAR_PURCHASE} />
    <LatestRelatedCases />
    <LatestRelatedPings />
  </Grid>
);

export default Overview;
