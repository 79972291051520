import React from 'react';

import {
  DateInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CaseOverview = () => (
  <Grid container>
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <DateInput fullWidth source="report.from" />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={1} display="flex" justifyContent="center" alignItems="center">
      <ArrowForwardIcon sx={{ marginBottom: 2 }} fontSize="large" />
    </Grid>
    <Grid item xs={12} sm={4} md={3} lg={2}>
      <DateInput fullWidth source="report.to" />
    </Grid>
  </Grid>
);

export default CaseOverview;
