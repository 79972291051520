import React from 'react';

import {
  ReferenceManyField,
  useGetList,
  useRecordContext,
  usePermissions,
  useRedirect,
} from 'react-admin';

import { Button } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import RuleDatagrid from './components/RuleDatagrid';

import { expandedDatagridStyles } from '../../constants/style/datagridStyles';
import { RuleTypes } from '../../constants/ruleTypes';
import { Rule } from '../../utilities/schemas/rules';

import hasAccess from '../../utilities/hasAccess';
import ruleViews from '../../constants/ruleView';
import { Actions } from '../../constants/actions';

export const GetList = (triggeredBy: string, ruleType: RuleTypes, version?: string) => {
  const { total } = useGetList('rules', { filter: { ruleType, triggeredBy, version } });
  if (!total || total === 0) return false;
  return true;
};

export const OpenIconButton = ({
  isDraft,
  ruleType,
  version,
}: {
  isDraft: boolean,
  ruleType: RuleTypes,
  version: string;
}) => {
  const { permissions } = usePermissions<Actions[]>();
  const redirect = useRedirect();
  const rule = useRecordContext<Rule>();

  if (!isDraft && hasAccess(permissions, 'RuleGet')) {
    return (
      <Button
        color="secondary"
        startIcon={<VisibilityIcon />}
        onClick={() => redirect(
          'edit',
          `rules/${rule?.id}`,
          'summary',
          undefined,
          {
            record: {
              ruleType,
              view: ruleViews.LIST,
              version,
            },
          },
        )}
      >
        Show
      </Button>

    );
  }

  if (isDraft && hasAccess(permissions, 'RuleUpdate')) {
    return (
      <Button
        startIcon={<EditIcon />}
        color="secondary"
        onClick={() => redirect(
          'edit',
          `rules/${rule?.id}`,
          'summary',
          undefined,
          {
            record: {
              ruleType,
              view: ruleViews.LIST,
              version,
            },
          },
        )}
      >
        Edit
      </Button>
    );
  }

  return null;
};

const RuleExpand = ({
  ruleType,
  version,
  isDraft,
}: {
  ruleType: RuleTypes,
  version: string,
  isDraft: boolean
}) => (
  <ReferenceManyField
    filter={{ version: isDraft ? null : version }}
    reference="rules"
    source="id"
    target="triggeredBy"
  >
    <RuleDatagrid
      expand={<RuleExpand ruleType={ruleType} version={version} isDraft={isDraft} />}
      isRowExpandable={(row) => GetList(row.id, row.ruleType, row.version)}
      sx={expandedDatagridStyles}
      ruleType={ruleType}
    >
      <OpenIconButton isDraft={isDraft} version={version} ruleType={ruleType} />
    </RuleDatagrid>
  </ReferenceManyField>

);

export default RuleExpand;
