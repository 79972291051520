import React from 'react';

import {
  useRecordContext,
} from 'react-admin';

import { Chip } from '@mui/material';
import { get } from 'lodash';

const ChipStringField = ({
  source,
}: {
  source?: string;
}) => {
  const record = useRecordContext();
  const value = source ? get(record, source, {}) : record;
  return (
    <Chip label={value} />
  );
};

export default ChipStringField;
