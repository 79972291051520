export default [
  { id: 'internal', name: 'Internal' },
  { id: 'atm deposit', name: 'ATM deposit' },
  { id: 'atm withdrawal', name: 'ATM withdrawal' },
  { id: 'crypto purchase', name: 'Crypto purchase' },
  { id: 'crypto withdrawal', name: 'Crypto withdrawal' },
  { id: 'income', name: 'Income' },
  { id: 'swish sent', name: 'Swish sent' },
  { id: 'swish received', name: 'Swish received' },
];
