import { useEffect } from 'react';
import {
  useCheckAuth,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';

const useRedirectAlreadyAuthenticated = () => {
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate]);
};

export default useRedirectAlreadyAuthenticated;
