import { Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useGetList, useNotify, useRecordContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import InfoPopover from '../../layout/InfoPopover';
import SelectArrayInput from '../../layout/inputFields/SelectArrayInput';

const NotifyEmail = ({ edit } : { edit: boolean }) => {
  const notify = useNotify();
  const { getValues } = useFormContext();
  const record = useRecordContext();
  const { createPing } = getValues();
  const [emails, setEmails] = useState(new Set());

  const { data } = useGetList('members');

  useEffect(() => {
    const memberEmails = record?.notificationEmails ?? [];
    setEmails((prev) => new Set([...prev, ...memberEmails]));
  }, [record?.notificationEmails]);

  useEffect(() => {
    const memberEmails = data?.map((member) => member.email) ?? [];
    setEmails((prev) => new Set([...prev, ...memberEmails]));
  }, [data]);

  const handleCreate = useCallback(() => {
    // eslint-disable-next-line no-alert
    const newEmail = prompt('Enter a new email')?.toLowerCase();
    if (Array.from(emails).some((email) => email === newEmail)) {
      notify('Email already exist in list');
      return emails;
    }
    setEmails((prev) => new Set([...prev, newEmail]));
    return newEmail;
  }, [emails, notify]);

  if (createPing) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="label">Email for notifications</Typography>
        </Grid>
        <SelectArrayInput
          fullWidth
          disabled={!edit}
          onCreate={handleCreate}
          source="notificationEmails"
          label="Email recipients"
          choices={Array.from(emails)?.map((email) => ({ id: email, name: email }))}
          popups={[<InfoPopover key="email" title="Email list for notifications" content="Used to send email notifications to the list of recipients when a ping is created." />]}

        />
      </Grid>
    );
  }
  return null;
};

export default NotifyEmail;
