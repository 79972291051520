import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const IndividualCriminalRecord = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Criminal record parameters</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.updateFreq"
          label="Update data frequency"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.legalScore"
          label="Legal score"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover
              key="legal score"
              title="Legal Score"
              content="Specifies the criminal record score that must be reached in order to trigger the rule."
            />,
          ]}

        />
      </Grid>
    </Grid>
  </Grid>
);

export default IndividualCriminalRecord;
