import React from 'react';

import {
  General,
  ReportingPerson,
  Parties,
  Goods,
} from './paths';

import Generate from './Generate';

import { FormNavigation, Child } from '../layout/formNavigation';

const SAR = () => (
  <FormNavigation>
    <Child path="general" label="General">
      <General />
    </Child>
    <Child path="reporting-person" label="Reporting person">
      <ReportingPerson />
    </Child>
    <Child path="parties" label="Parties">
      <Parties />
    </Child>
    <Child path="goods" label="Goods">
      <Goods />
    </Child>
    <Child path="generate" label="Generate report">
      <Generate reportCode="SAR" />
    </Child>
  </FormNavigation>
);

export default SAR;
