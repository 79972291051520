import { joiResolver } from '@hookform/resolvers/joi';

import Joi from '../joiExtension';
import { countryCodes } from '../../constants/countries';

const businessSchema = Joi.object().keys({
  customId: Joi.string().allow(null).label('Custom Id'),
  name: Joi.string().allow(null).label('Name'),
  registrationNumber: Joi.when('residence', {
    is: 'SWE',
    then: Joi.orgNumber().allow(null),
    otherwise: Joi.string().allow(null),
  }).label('Registration number'),
  residence: Joi.string().valid(...countryCodes).allow(null).label('Registration Country'),
  groups: Joi.array().items(Joi.string()).label('Groups'),
  email: Joi.string().email({ tlds: false }).allow(null).label('Email'),
  phoneNumber: Joi.string().allow(null).label('Phone number'),
}).or('customId', 'registrationNumber')
  .with('registrationNumber', 'residence')
  .label('Business');

export default joiResolver(businessSchema, { abortEarly: false });
