import { useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { useFormContext, useWatch, get } from 'react-hook-form';
import thresholdVariants from '../constants/thresholdVariant';

const useThresholdVariant = ({ source }: {source: string}) => {
  const { setValue } = useFormContext();
  const record = useRecordContext();

  const thresholdVariant = useWatch({
    name: `ruleParameters.${source}.thresholdVariant`,
    defaultValue: get(record, `ruleParameters.${source}.thresholdVariant`, thresholdVariants.CURRENCY),
  });

  useEffect(() => {
    if (thresholdVariant === 'currency') { setValue(`ruleParameters.${source}.commonThreshold`, undefined); }
    if (thresholdVariant === 'common') {
      setValue(`ruleParameters.${source}.thresholds`, undefined);
      setValue(`ruleParameters.${source}.parameterPriority`, undefined);
    }
  }, [setValue, source, thresholdVariant]);

  return { thresholdVariant };
};

export default useThresholdVariant;
