import React from 'react';

import {
  DateInput,
  RecordContextProvider,
  required,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { Grid } from '@mui/material';

const Person = ({
  entityVariant,
}: {
  entityVariant: string

}) => {
  const record = useRecordContext();

  return (
    <RecordContextProvider value={{
      [`${entityVariant}.first_name`]: record?.firstName,
      [`${entityVariant}.last_name`]: record?.lastName,
      [`${entityVariant}.birthdate`]: record?.birthDate,
      [`${entityVariant}.ssn`]: record?.swedishSocialSecurityNumber,
    }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.first_name`} label="First name" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.last_name`} label="Last name" validate={required()} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextInput fullWidth source={`${entityVariant}.ssn`} label="Swedish social security number" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DateInput fullWidth source={`${entityVariant}.birthdate`} label="Birth date" />
        </Grid>
      </Grid>
    </RecordContextProvider>

  );
};

export default Person;
