export const financialDataFields = [
  { id: 'acceptedBankOverdraft', name: 'Accepted bank overdraft' },
  { id: 'agreedSeverancePay', name: 'Agreed saverance pay' },
  { id: 'bsAccountsPayable', name: 'Accounts payable - trade' },
  { id: 'bsAccountsReceivablesCorporateGroup', name: 'Accounts receivable corporate group/interest group' },
  { id: 'bsAccountsReceivablesGroup', name: 'Accounts receivable group/interest group' },
  { id: 'bsAccountsReceivablesTrade', name: 'Accounts receivable - trade' },
  { id: 'bsCapitalExpensesForResAndDev', name: 'Capitalized expenditure for research and development' },
  { id: 'bsCashAndBankBalances', name: 'Cash and bank balances' },
  { id: 'bsEqupimentToolsAndInstallations', name: 'Equipment, tools, fixture and fittings' },
  { id: 'bsGoodwill', name: 'Goodwill' },
  { id: 'bsGroupContribution', name: 'Group contribution' },
  { id: 'bsGroupShares', name: 'Group share' },
  { id: 'bsInventories', name: 'Inventories' },
  { id: 'bsLandAndBuildings', name: 'Land and buildings' },
  { id: 'bsLiabilitiesToCreditInstit', name: 'Liabilities to credit institutions' },
  { id: 'bsLoanOwners', name: 'Loan co-owners' },
  { id: 'bsLongTermBondLoan', name: 'Bond loan' },
  { id: 'bsLongTermLiabilitiesGroupAndAssocComp', name: 'Long-term liabilities to group/assiciated companies' },
  { id: 'bsLongTermLiabilitiesToCreditInstit', name: 'Long-term liabilities to credit institutions' },
  { id: 'bsMachinesInventory', name: 'Machines and inventory' },
  { id: 'bsMinorityInterests', name: 'Minority interest' },
  { id: 'bsNetIncome', name: 'Net profit/loss for year' },
  { id: 'bsOtherAccountsReceivables', name: 'Other accounts receivable' },
  { id: 'bsOtherCurrentAssets', name: 'Other current assets' },
  { id: 'bsOtherFinancialAssets', name: 'Other financial assets' },
  { id: 'bsOtherIntangibleAssets', name: 'Other intangible asses' },
  { id: 'bsOtherLongTermLiabilities', name: 'Other long-term liabilities' },
  { id: 'bsOtherMaterialDepreciation', name: 'Other material depreciation' },
  { id: 'bsOtherShareHolderEquity', name: 'Other stockholder equity' },
  { id: 'bsOtherShortTermLiabilities', name: 'Other short-term liabilities' },
  { id: 'bsOtherTangibleAssetsNonDepreciated', name: 'Other tangible assets, non depreciation' },
  { id: 'bsPatents', name: 'Patents, licenses, etc' },
  { id: 'bsPlantAndMachinery', name: 'Plant and machinery' },
  { id: 'bsProvisions', name: 'Provisions' },
  { id: 'bsRetainedEarnings', name: 'Profit or loss brought forward' },
  { id: 'bsRevaluationReserve', name: 'Revaluation reserve' },
  { id: 'bsShareCapital', name: 'Share capital ' },
  { id: 'bsSharePremiumReserve', name: 'Share premium reserve' },
  { id: 'bsShareholderContribution', name: 'Shareholders contribution' },
  { id: 'bsShortTermInvestments', name: 'Short investments' },
  { id: 'bsShortTermLiabilitiesGroupAndAssocComp', name: 'Liabilities to group/associated companies' },
  { id: 'bsSubscribedCapitalUnpaid', name: 'Subscribed capital unpaid' },
  { id: 'bsTotalAccountsReceivable', name: 'Total accounts receivable' },
  { id: 'bsTotalAssets', name: 'Total assets' },
  { id: 'bsTotalCurrentAssets', name: 'Current assets' },
  { id: 'bsTotalCurrentLiabilities', name: 'Total current liabilities' },
  { id: 'bsTotalDividendsCapacity', name: 'Total dividends capacity' },
  { id: 'bsTotalEquity', name: 'Total equity' },
  { id: 'bsTotalEquityAndLiabilities', name: 'Total equity & liabilities' },
  { id: 'bsTotalFinancialAssets', name: 'Total financial assets' },
  { id: 'bsTotalFixedAssets', name: 'Total fixed assets' },
  { id: 'bsTotalIntangibleAssets', name: 'Total intangible assets' },
  { id: 'bsTotalInventories', name: 'Total Inventories' },
  { id: 'bsTotalLongTermDebts', name: 'Total long-term debts' },
  { id: 'bsTotalTangibleAsstes', name: 'Total tangible assets' },
  { id: 'bsUntaxedReserves', name: 'Untaxed reserves' },
  { id: 'bsWorkOnContract', name: 'Work on contract' },
  { id: 'conditionalEquity', name: 'Conditional equity' },
  { id: 'depreciationOfAdministrationCosts', name: 'Depreciation of administration costs' },
  { id: 'depreciationOfOtherCosts', name: 'Depreciation of other costs' },
  { id: 'depreciationOfResearchAndDevelopmentCosts', name: 'Depreciation of research and development costs' },
  { id: 'depreciationOfSaleCosts', name: 'Depreciation of sale costs' },
  { id: 'depreciationOfSoldGoods', name: 'Depreciation of sold goods' },
  { id: 'depreciationUnspecifiedCosts', name: 'Depreciation unspecified costs' },
  { id: 'dividend', name: 'Dividend' },
  { id: 'employeesSalary', name: 'Salaries to other employees' },
  { id: 'floatingCharge', name: 'Floating charge' },
  { id: 'includingPerformanceBonusToOtherEmployees', name: 'Including performance bonus to other employees' },
  { id: 'kpiAccountsReceivablesTurnoverPercent', name: 'Accounts receivable/Turnover %' },
  { id: 'kpiCapitalTurnoverRatio', name: 'Capital turnover' },
  { id: 'kpiCurrentLiabilitiesTurnoverPercent', name: 'Current liabilities/Turnover %' },
  { id: 'kpiDegreeOfDebt', name: 'Degree of debt' },
  { id: 'kpiDuPontModelPercent', name: 'Du Pont Model %' },
  { id: 'kpiEbitda', name: 'EBITDA' },
  { id: 'kpiEbitdaMarginPercent', name: 'EBITDA Margin percent' },
  { id: 'kpiEquityRatioPercent', name: 'Equity ratio %' },
  { id: 'kpiInventoriesTurnoverPercent', name: 'Inventories/Turnover %' },
  { id: 'kpiOppResultNumberOfEmployees', name: 'Operating result / Number of employees' },
  { id: 'kpiProfitMarginPercent', name: 'Profit Margin %' },
  { id: 'kpiQuickRatioPercent', name: 'Quick ratio %' },
  { id: 'kpiReturnOnCapitalEmployedPercent', name: 'ROCE' },
  { id: 'kpiReturnOnEquityPercent', name: 'ROC' },
  { id: 'kpiReturnOnWorkingCapitPercent', name: 'Return on working capital %' },
  { id: 'kpiRiskBufferTotalCapitalPercent', name: 'Risk buffer total capital' },
  { id: 'kpiWorkingCapital', name: 'Working capital' },
  { id: 'netInterestFinance', name: 'Net interest finance' },
  { id: 'notUsedBankOverdraft', name: 'Used bank overdraft' },
  { id: 'numberOfEmployees', name: 'Number of employees' },
  { id: 'numberOfShares', name: 'Number of shares' },
  { id: 'otherAssetsPledged', name: 'Other collateral' },
  { id: 'otherContingentLiabilities', name: 'Other contingent liabilities' },
  { id: 'payrollOverhead', name: 'Payroll overhead' },
  { id: 'plAdminCosts', name: 'Admin costs' },
  { id: 'plChangeInventoryOfWorkInProgress', name: 'Change in inventories of work in progress' },
  { id: 'plCostOfGoodsSold', name: 'Cost of sold goods' },
  { id: 'plDeprAndWriteDowns', name: 'Depreciation and write-downs' },
  { id: 'plEbit', name: 'EBIT' },
  { id: 'plExternalInterestExpenditures', name: 'Interest expenses, external' },
  { id: 'plExternalInterestIncome', name: 'External interest income' },
  { id: 'plExtraordinaryIncome', name: 'Extraordinary incomes' },
  { id: 'plExtraoridinaryExpenditures', name: 'Extraordinary expenses' },
  { id: 'plFinancialInterestGroupIncome', name: 'Financial internal (group) income' },
  { id: 'plGoodsForResale', name: 'Goods for resale' },
  { id: 'plGrossProfit', name: 'Gross profit loss' },
  { id: 'plGroupContribution', name: 'Group contribute' },
  { id: 'plInternalInterestExpenditures', name: 'Interest expenses, internal (group)' },
  { id: 'plItemsAffectCompare', name: 'item affecting comparability' },
  { id: 'plMinorityInterestAndProfit', name: 'Minority interest and profit' },
  { id: 'plNetOperatingIncome', name: 'Total net operating income' },
  { id: 'plNetProfitLoss', name: 'Net i Profit/loss for the year)' },
  { id: 'plOtherAppropriations', name: 'Appropriations' },
  { id: 'plOtherExternalCosts', name: 'Other external expenses' },
  { id: 'plOtherFinancialCosts', name: 'Other financial costs' },
  { id: 'plOtherFinancialIncome', name: 'Other financial incomes' },
  { id: 'plOtherOperatingExpenses', name: 'Other operating expenses' },
  { id: 'plOtherOperatingIncome', name: 'Other operating incomes' },
  { id: 'plPersonalCosts', name: 'Personnel costs' },
  { id: 'plProfitGroupAndAssociatedCompanies', name: 'Result from participation in group/associated companies' },
  { id: 'plProfitLossAfterFinItems', name: 'Profit/loss after financial items' },
  { id: 'plRawMatAndCons', name: 'Raw material and consumables' },
  { id: 'plResearchAndDevCosts', name: 'Research and development costs' },
  { id: 'plSales', name: 'Total volume of sales Aktiebolag' },
  { id: 'plSellingExpenses', name: 'Selling expenses' },
  { id: 'plShareholderContribution', name: 'Shareholders contribution' },
  { id: 'plTaxes', name: 'Tax' },
  { id: 'plWorkPerfOwnUseCapital', name: 'Work performed by its own use and capitalized' },
  { id: 'realEstateMortgage', name: 'Real estate mortgage' },
  { id: 'salaryBoard', name: 'Salary board/CEO' },
  { id: 'tantiemBoard', name: 'Tantiem board/CEO' },
  { id: 'totalCollateral', name: 'Sum all collateral' },
  { id: 'totalContingentLiabilities', name: 'Sum all contingent liabilities' },
] as const;

export const overviewFinancialFields = [
  { id: 'plSales', name: 'Sales' },
  { id: 'kpiEbitda', name: 'EBITDA' },
  { id: 'plEbit', name: 'EBIT' },
  { id: 'bsNetIncome', name: 'Net Income' },
  { id: 'bsTotalEquity', name: 'Equity' },
  { id: 'bsTotalAssets', name: 'Total Assets' },
] as const;

export const overviewKeyRatiosFields = [
  { id: 'plSales', name: 'Sales Growth' },
  { id: 'kpiEbitdaMarginPercent', name: 'EBITDA Margin' },
  { id: 'kpiProfitMarginPercent', name: 'Profit Margin' },
  { id: 'kpiEquityRatioPercent', name: 'Equity ratio' },
  { id: 'kpiReturnOnEquityPercent', name: 'ROC' },
  { id: 'kpiReturnOnCapitalEmployedPercent', name: 'ROCE' },
] as const;
