import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const CarBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record?.registrationNumber,
    path: `/cars/${record?.id}`,
  });

  return null;
};

export default CarBreadcrumb;
