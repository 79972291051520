import React from 'react';

import {
  RaRecord,
  SimpleForm,
  useRecordContext,
} from 'react-admin';

import { useFormContext } from 'react-hook-form';

import Body from './Body';

const DetailedView = ({
  handleOpenChange,
  isCustom = false,
  isEditView = false,
  setRecord,
  handleCloseDialog,
}: {
  handleOpenChange: () => void,
  isCustom?: boolean
  isEditView?: boolean
  setRecord?: (x: RaRecord | undefined) => void
  handleCloseDialog: () => void,
}) => {
  const { setValue, getValues } = useFormContext();
  const record = useRecordContext();
  return (
    <SimpleForm record={record} mode="all" toolbar={false}>
      <Body
        setRecord={setRecord}
        isEditView={isEditView}
        getValues={getValues}
        setValue={setValue}
        handleOpenChange={handleOpenChange}
        isCustom={isCustom}
        handleCloseDialog={handleCloseDialog}
      />
    </SimpleForm>

  );
};

export default DetailedView;
