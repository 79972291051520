import { useCallback, useEffect } from 'react';
import { useAuthState, useLogout } from 'react-admin';
import { useIdleTimer } from 'react-idle-timer';
import { authTokenStore, lastAuthenticatedActivityStore } from '../../utilities/authTokenStore';
import config from '../../config';

const IDLE_TIME_BEFORE_LOGOUT = 15 * 60 * 1000; // 15 minutes

const LogoutOnIdle = () => {
  const { authenticated } = useAuthState();
  const logout = useLogout();

  useEffect(() => {
    const previousLastActivity = lastAuthenticatedActivityStore.getToken();
    const savedAuthToken = authTokenStore.getToken();
    if (savedAuthToken) {
      if (previousLastActivity) {
        const timeSinceLastActivity = Date.now() - Number.parseInt(previousLastActivity, 10);
        if (timeSinceLastActivity > IDLE_TIME_BEFORE_LOGOUT) logout();
      } else {
        logout();
      }
    }
  }, [logout]);

  const onIdle = useCallback(() => {
    logout();
  }, [logout]);

  const onAction = useCallback(() => {
    lastAuthenticatedActivityStore.setToken(Date.now().toString(10));
  }, []);

  useIdleTimer({
    name: `pingwire-${config.targetEnv}`,
    crossTab: true,
    onIdle,
    onAction,
    timeout: IDLE_TIME_BEFORE_LOGOUT,
    eventsThrottle: 500,
    debounce: 1000,
    disabled: !authenticated,
  });

  return null;
};

export default LogoutOnIdle;
