import React from 'react';

import {
  maxValue,
  minValue,
  NumberInput,
  required,
  TextInput,
} from 'react-admin';

import {
  Box,
  Typography,
} from '@mui/material';

import CreateDialog from '../layout/dialog/CreateDialog';
import InfoPopover from '../layout/InfoPopover';

import RiskClassInput from '../../customFields/RiskClassInput';

const GroupCreate = () => (
  <CreateDialog title="group">
    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width="100%">
      <Box width="80%">
        <Box display="flex" alignItems="center">
          <Typography variant="mediumTitle">Group Name</Typography>
          <InfoPopover title="Group Name" content="Unique name for the group." />
        </Box>
        <TextInput fullWidth validate={required()} source="name" />
      </Box>
      <Box width="80%">
        <Box display="flex" alignItems="center">
          <Typography variant="mediumTitle">Default risk</Typography>
          <InfoPopover title="Default risk" content="Probability of financial crime activity given which entity is in that group." />
        </Box>
        <NumberInput fullWidth validate={[required(), maxValue(99), minValue(0)]} source="score" label="Default risk (%)" />
      </Box>
      <Box width="80%">
        <Box display="flex" alignItems="center">
          <Typography variant="mediumTitle">
            Corresponding risk class
          </Typography>
          <InfoPopover title="Corresponding risk classes" content="The risk class corresponding to the default risk chosen." />
        </Box>
        <RiskClassInput source="score" />
      </Box>
    </Box>
  </CreateDialog>

);

export default GroupCreate;
