import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const Affordability = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Affordability parameters</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          validate={[required(), minValue(0)]}
          fullWidth
          source="ruleParameters.multiplier"
          label="Multiplier"
          popups={[<InfoPopover key="multiplier" title="Multiplier" content="The multiplier is used to check if the request amount times this multiplier is above than the account balance at the end of the last x months." />]}

        />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          validate={[required(), minValue(0)]}
          fullWidth
          source="ruleParameters.periodInMonths"
          label="Period in months"
          popups={[<InfoPopover key="period in months" title="Period in months" content="The period in months that the affordability will be checked against" />]}
        />
      </Grid>
    </Grid>
  </Grid>

);

export default Affordability;
