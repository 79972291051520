import React from 'react';

import {
  Box,
  TextField,
} from '@mui/material';

import entityTypes, { EntityTypes } from '../../../../../constants/entityTypes';

export interface FilterType {
  swedishSocialSecurityNumber: string
  firstName: string
  lastName: string
  swedishOrganizationNumber: string
  name: string
}

const Filter = ({
  filter,
  setFilter,
  type,
}: {
  filter?: FilterType,
  setFilter: (x: (prev?: FilterType) => FilterType) => void
  type: EntityTypes
}) => {
  const handleChange = (prop: keyof FilterType) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter((prev) => ({ ...prev!, [prop]: event.target.value }));
  };
  return (
    <Box>
      {type === entityTypes.INDIVIDUAL && (
        <Box display="flex" gap={2}>
          <TextField
            value={filter?.swedishSocialSecurityNumber}
            onChange={handleChange('swedishSocialSecurityNumber')}
            size="small"
            label="Swedish social security number"
          />
          <TextField
            value={filter?.firstName}
            onChange={handleChange('firstName')}
            size="small"
            label="First name"
          />
          <TextField
            value={filter?.lastName}
            onChange={handleChange('lastName')}
            size="small"
            label="Last name"
          />
        </Box>
      )}
      {type === entityTypes.BUSINESS && (
        <Box display="flex" gap={2}>
          <TextField
            value={filter?.swedishOrganizationNumber}
            onChange={handleChange('swedishOrganizationNumber')}
            size="small"
            label="Organization Number"
          />
          <TextField
            value={filter?.name}
            onChange={handleChange('name')}
            size="small"
            label="Name"
          />
        </Box>
      )}
    </Box>
  );
};

export default Filter;
