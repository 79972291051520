import React from 'react';

import {
  Box, Grid, Typography,
} from '@mui/material';

import { isEmpty } from 'lodash';

import {
  TextInput,
  useRecordContext,
  TextField,
  SelectArrayInput,
  RadioButtonGroupInput,
  SelectInput,
  SelectField,
  DateField,
  DateInput,
  required,
} from 'react-admin';

import { getName } from 'i18n-iso-countries';

import { useWatch } from 'react-hook-form';

import CircleContainer from '../../layout/CircleContainer';

import ArrayInput from './base/ArrayInput';

import { countryChoices } from '../../../constants/countries';
import booleanChoices from '../../../constants/booleanChoices';
import { pepRoleChoices } from '../../../constants/pepRoles';
import { pepRelationChoices } from '../../../constants/pepRelations';
import { countryConnectionChoices } from '../../../constants/countryConnections';
import languages from '../../../constants/languages';
import { NationalIdentifierInputFields } from '../../../customFields/NationalIdentifier';
import { identifierTypeChoices } from '../../../constants/identifierTypes';
import cleaner from '../../../utilities/dataProvider/cleaner';

const PEP_COUNTRY_TITLE = {
  [languages.EN]: 'In which country is the role held that entails PEP status',
  [languages.SV]: 'I vilket land innehas rollen som medför PEP status',
} as const;

const SignatoryDialogBody = () => {
  const { value } = useWatch();

  return (
    <Grid container columnSpacing={6}>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="firstName" label="First name" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="lastName" label="Last name" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="role" label="Role" />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateInput fullWidth source="dateOfBirth" label="Date of birth" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="address" label="Address" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="city" label="City" />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput fullWidth source="postalCode" label="Post code" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput choices={countryChoices('en')} fullWidth source="residence.0" label="Country" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectArrayInput choices={countryChoices('en')} fullWidth source="taxResidency" label="Tax residency" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectInput choices={countryChoices('en')} fullWidth source="citizenship[0].country" label="Citizenship" />
      </Grid>
      {!!value?.citizenship?.at(0)?.country
      && value?.citizenship?.map(({ country }: { country: string }, i: number) => (
        <Grid item xs={12} md={6}>
          <SelectArrayInput
            fullWidth
            label={`${getName(country, 'en')} connection`}
            choices={countryConnectionChoices('en')}
            source={`citizenship[${i}].connections`}
          />
        </Grid>
      ))}
      <Grid item xs={12} md={12}>
        <NationalIdentifierInputFields source="nationalIdentifier" />
      </Grid>
      <Grid item xs={12} md={12} py={4}>
        <Typography fontWeight="bold" color="primary" variant="h6">PEP</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box>
          <Typography fontWeight="bold">Is the person or someone close to them politically exposed?</Typography>
          <RadioButtonGroupInput
            label={false}
            choices={booleanChoices.en}
            source="isPep"
          />
        </Box>
      </Grid>
      {value?.isPep === 'yes' && (
        <>
          <Grid item xs={12} md={6}>
            <SelectInput
              fullWidth
              label="Position"
              choices={pepRoleChoices('en')}
              source="pep.position"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              fullWidth
              label="Relation to the politically exposed person"
              choices={pepRelationChoices('en')}
              source="pep.relation"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              fullWidth
              label={PEP_COUNTRY_TITLE.en}
              choices={countryChoices('en')}
              source="pep.country"
            />
          </Grid>
        </>
      )}
    </Grid>

  );
};

const SignatoryAccordionBody = ({ index }: {index: number}) => {
  const values = useRecordContext();

  if (!values) return null;

  const record = values?.at(index);

  if (isEmpty(record)) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">First name</Typography>
          <TextField record={record} source="firstName" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Last name</Typography>
          <TextField record={record} source="lastName" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Date of birth</Typography>
          <DateField record={record} source="dateOfBirth" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Role</Typography>
          <TextField record={record} source="Role" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Address</Typography>
          <TextField record={record} source="address" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">City</Typography>
          <TextField record={record} source="city" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Post code</Typography>
          <TextField record={record} source="postalCode" emptyText="-" />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Country</Typography>
          <TextField record={record} source="residence" emptyText="-" />
        </Box>
      </Grid>
      <>
        <Grid item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="label">Citizenship</Typography>
            <Box display="flex" gap={0}>
              {record?.citizenship?.map(
                (item: {country: string, connection: string }, i: number) => (
                  <>
                    <SelectField
                      key={item.country}
                      record={{ item }}
                      source="item.country"
                      choices={countryChoices('en')}
                    />
                    {i + 1 !== record?.citizenship?.length && <Typography mr={1}>,</Typography>}
                  </>
                ),
              )}
              {isEmpty(record?.citizenship) && <Typography>-</Typography>}
            </Box>
          </Box>
        </Grid>
        {record?.citizenship?.map((item: {country: string, connections: string[]}) => (
          <Grid key={item.country} item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Box gap={1} display="flex">
                <Typography variant="label">Connection to</Typography>
                <SelectField
                  record={{ item }}
                  source="item.country"
                  variant="label"
                  choices={countryChoices('en')}
                />
              </Box>
              {item?.connections?.map((connection, j) => (
                <Box display="flex">
                  <SelectField
                    key={connection}
                    record={{ connection }}
                    source="connection"
                    choices={countryConnectionChoices('en')}
                  />
                  {(j + 1) !== item?.connections?.length && <Typography mr={1}>,</Typography>}
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </>
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Tax residency</Typography>
          <Box display="flex" gap={1}>
            {!isEmpty(record?.taxResidency)
            && record?.taxResidency?.map((item: string, i: number) => (
              <Box display="flex">
                <Box display="flex" flexDirection="row">
                  <SelectField record={{ item }} source="item" choices={countryChoices('en')} />
                  {(i + 1) !== record.taxResidency.length && <Typography>,</Typography>}
                </Box>
              </Box>
            ))}
            {isEmpty(record?.taxResidency) && <Typography>-</Typography>}
          </Box>
        </Box>
      </Grid>
      {record?.nationalIdentifier?.map((item: {country: string, identifier: string}) => (
        <Grid key={item.country} item xs={12} md={3}>
          <Box display="flex" flexDirection="column">
            <Box gap={1} display="flex">
              <Typography variant="label">National Identifier for</Typography>
              <SelectField
                record={item}
                source="country"
                variant="label"
                choices={countryChoices(languages.EN)}
              />
            </Box>
            <Box display="flex" gap={2}>
              <SelectField
                record={item}
                choices={identifierTypeChoices(languages.EN)}
                source="type"
              />
              <Typography>-</Typography>
              <TextField
                record={item}
                source="identifier"
              />
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} md={3}>
        <Box display="flex" flexDirection="column">
          <Typography variant="label">Is the person or someone close to them politically exposed?</Typography>
          <SelectField record={record} choices={booleanChoices.en} source="isPep" emptyText="-" />
        </Box>
      </Grid>
      {record?.isPep === 'yes' && (
        <>
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Typography variant="label">PEP Position</Typography>
              <SelectField
                fullWidth
                record={record}
                label="PEP Position"
                choices={pepRoleChoices('en')}
                source="pep.position"
                emptyText="-"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Typography variant="label">Relation to PEP</Typography>
              <SelectField
                fullWidth
                record={record}
                label="Relation to PEP"
                choices={pepRelationChoices('en')}
                source="pep.relation"
                emptyText="-"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display="flex" flexDirection="column">
              <Typography variant="label">{PEP_COUNTRY_TITLE.en}</Typography>
              <SelectField
                fullWidth
                record={record}
                label={PEP_COUNTRY_TITLE.en}
                choices={countryChoices('en')}
                source="pep.country"
                emptyText="-"
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ItemLabel = ({ value } : {value: Record<string, string>}) => {
  if (isEmpty(value)) return null;

  return (
    <Box display="flex" alignItems="center" gap={5}>
      <CircleContainer borderColor="transparent" p={0} bgcolor="primary.main" size={37}>
        <Typography color="background.paper" variant="h6">
          {value.firstName?.at(0) ?? ''}
        </Typography>
      </CircleContainer>
      <Typography
        variant="h6"
        color="primary.main"
      >
        <Typography color="primary.main" variant="h6">
          {['firstName', 'lastName'].map((field) => value[field]).join(' ')}
        </Typography>
      </Typography>
    </Box>
  );
};

const Signatories = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
        marginBottom={4}
      >
        Signatories
      </Typography>
      <ArrayInput
        title="Add Signatory"
        itemNameSource="firstName"
        ItemLabel={ItemLabel}
        DialogBody={SignatoryDialogBody}
        AccordionBody={SignatoryAccordionBody}
        validate={required()}
        transformItemData={cleaner}
      />
    </Grid>
    <Grid item xs={12} md={12} justifyContent="center" display="flex">
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={4}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default Signatories;
