import React, { useCallback } from 'react';
import { Form, Button } from 'react-admin';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Cancel from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import useDialogStatus from '../../hooks/useDialogStatus';
import TransactionTypeConfigurationInput from '../../customFields/TransactionTypeConfigurationInput';

export type TransactionTypeConfiguration = {
  or: {
    and: {
      inclusion: 'included' | 'excluded',
      transactionTypeId?: string;
    }[];
  }[];
};

const AddTransactionTypeDialogButton = ({
  onConfirm,
}: {
  onConfirm: (transactionTypeConfiguration?: TransactionTypeConfiguration) => void,
}) => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  const handleSubmit = useCallback((data: {
    transactionTypeConfiguration?: {
      or: {
        and: {
          inclusion: 'included' | 'excluded',
          transactionTypeId?: string;
        }[];
      }[];
    };
  }) => {
    onConfirm(data.transactionTypeConfiguration);
    closeDialog();
  }, [closeDialog, onConfirm]);

  return (
    <>
      <Button onClick={openDialog} label="Add row"><AddIcon /></Button>
      <Dialog scroll="body" open={open} onClose={closeDialog} fullWidth maxWidth="xl">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Select transaction type configuration
            <IconButton onClick={closeDialog}>
              <Cancel />
            </IconButton>
          </Box>
        </DialogTitle>
        <Form
          values={{
            transactionTypeConfiguration: undefined,
          }}
          onSubmit={handleSubmit}
        >
          <DialogContent>
            <Paper variant="outlined" elevation={0} sx={{ overflow: 'auto' }}>
              <Box padding={4}>
                <Typography variant="mediumTitle" marginBottom={2} component="p">
                  Include any transaction that:
                </Typography>
                <TransactionTypeConfigurationInput source="transactionTypeConfiguration" />
              </Box>
            </Paper>
          </DialogContent>
          <DialogActions sx={{ paddingX: 6, paddingY: 4 }}>
            <Button type="submit" variant="contained" label="Add row" />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

export default AddTransactionTypeDialogButton;
