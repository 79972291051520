import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import SidebarNavListItem from './SidebarNavListItem';

export type Page = {
  children?: Page[]
  href: string;
  exact: boolean;
  icon: React.ElementType;
  title: string;
  badge?: unknown;
};

const SidebarNavList = ({
  pages,
  depth,
  onClose,
}: {
  depth: number,
  pages: Page[]
  onClose?: () => void
}) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  return (
    <>
      {pages.map((page) => {
        if (page.children) {
          const open = !matchPath(
            {
              path: page.href,
              end: page.exact,
            },
            currentRoute,
          );

          return (
            <SidebarNavListItem
              depth={depth}
              icon={page.icon}
              key={page.title}
              badge={page.badge}
              open={!!open}
              title={page.title}
              href={page.href}
              exact={page.exact}
              onClose={onClose}
            >
              <SidebarNavList
                onClose={onClose}
                depth={depth + 1}
                pages={page.children}
              />
            </SidebarNavListItem>
          );
        }
        return (
          <SidebarNavListItem
            depth={depth}
            href={page.href}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            title={page.title}
            exact={page.exact}
            onClose={onClose}
          />
        );
      })}
    </>
  );
};

export default SidebarNavList;
