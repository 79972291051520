import React from 'react';

import {
  FormDataConsumer,
  SelectInput,
  required,
} from 'react-admin';

import { Grid, Box } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ReferenceEntityInput from './ReferenceEntityInput';

const SelectRequestParty = ({
  role,
  resettableRoles = [],
  defaultValue,
  disabled = false,
}: {
  role: string;
  defaultValue?: string;
  disabled?: boolean;
  resettableRoles?: string[]
}) => (
  <Grid container spacing={6}>
    <Grid item xs={12} md={12} display="flex">
      <SelectInput
        source={`extraInformation.${role}.type`}
        label={role.charAt(0).toUpperCase() + role.slice(1)}
        choices={[
          { id: 'Individual', name: 'Individual' },
          { id: 'Business', name: 'Business' },
        ]}
        resettable={resettableRoles.includes(role)}
        defaultValue={defaultValue}
        disabled={disabled}
        validate={!resettableRoles.includes(role) ? required() : undefined}
      />

      <FormDataConsumer>
        {({ formData: { extraInformation } }) => (extraInformation && extraInformation[role]?.type)
        && (
          <Box display="flex" alignItems="center">
            <ArrowForwardIcon sx={{ margin: '0px 10px 15px 10px' }} fontSize="large" />
            <ReferenceEntityInput
              entityType={extraInformation[role].type}
              source={`extraInformation.${role}.id`}
              role={role}
            />
          </Box>
        )}
      </FormDataConsumer>
    </Grid>
  </Grid>
);

export default SelectRequestParty;
