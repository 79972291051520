import { useRecordContext } from 'react-admin';
import { useRegisterBreadcrumb } from '../layout/navbar/BreadcrumbsManager';

const RequestBreadcrumb = () => {
  const record = useRecordContext();
  useRegisterBreadcrumb({
    title: record?.id?.toString() ?? '',
    path: `/requests/${record?.id}`,
  });

  return null;
};

export default RequestBreadcrumb;
