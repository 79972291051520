/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  SelectArrayInput, SelectInput, TextInput,
} from 'react-admin';

import { getName } from 'i18n-iso-countries';

import { useFormContext } from 'react-hook-form';

import { countryChoices } from '../../../constants/countries';
import { countryConnectionChoices } from '../../../constants/countryConnections';

const Citizenship = () => {
  const { watch, setValue } = useFormContext();

  const value = watch();

  useEffect(() => {
    setValue('value', value?._value?.countries.map((country: string, i: number) => ({
      country,
      connection: value?._value?.connection?.at(i),
    })));
  }, [setValue, value?._value?.connection, value?._value?.countries]);

  return (
    <Grid container spacing={10}>
      <Grid item xs={12} md={12}>
        <Typography
          color="#646464"
          variant="h6"
        >
          Citizenship
        </Typography>
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <SelectArrayInput
              choices={countryChoices('en')}
              helperText={false}
              fullWidth
              source="_value.countries"
              label="New value"
            />
          </Grid>
          {value?._value?.countries?.map((country: string, i: number) => (
            <Grid item xs={12} md={6}>
              <SelectInput
                fullWidth
                label={`${getName(country, 'en')} connection`}
                choices={countryConnectionChoices('en')}
                source={`_value.connection[${i}]`}
              />
            </Grid>
          ))}

          <Grid item xs={12} md={12}>
            <TextInput
              fullWidth
              source="comment"
              multiline
              rows={5}
              helperText={false}
              placeholder="Please explain your decision."
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Citizenship;
