import React, { useState, useCallback } from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as TextFieldUi,
} from '@mui/material';
import { Recommendations } from '../constants/recommendations';

const UpdateRecommendationField = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [recommendation, setRecommendation] = useState<Recommendations>();
  const [updateType, setUpdateType] = useState<string>();
  const [comment, setComment] = useState<string>();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setUpdateType(event.currentTarget.value);
    if (event.currentTarget.value === 'Block') setRecommendation('block');
    if (event.currentTarget.value === 'Proceed') setRecommendation('proceed');
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback((
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setComment(event.currentTarget.value);
  }, []);

  const [update] = useUpdate(
    'requests',
    { id: `${record?.id}/recommendation`, data: { recommendation, comment } },
    {
      onSuccess: () => {
        refresh();
      },
      onError: () => {
        notify('Could not update recommendation', { type: 'error' });
      },
    },
  );

  const handleSubmit = useCallback(() => {
    update();
  }, [update]);

  if (record?.recommendation !== 'review') return null;

  return (
    <Box display="flex">
      <Box display="flex" marginRight={2}>
        <Button
          style={{ backgroundColor: '#880808', color: '#FFFFFF' }}
          variant="contained"
          onClick={handleClick}
          value="Block"
        >
          Block
        </Button>
      </Box>
      <Box display="flex" marginRight={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          value="Proceed"
        >
          Proceed
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{updateType}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`${updateType} `}
            by entering a comment here.
          </DialogContentText>
          <TextFieldUi
            autoFocus
            margin="dense"
            label="Comment"
            type="Comment"
            multiline
            value={comment}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {updateType}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

UpdateRecommendationField.defaultProps = {
  addLabel: false,
};
export default UpdateRecommendationField;
