import React from 'react';
import { DateField, useRecordContext } from 'react-admin';
import { get } from 'lodash';

import { Box, Grid, Typography } from '@mui/material';
import Answer from '../Answer';

import languages, { Languages } from '../../../../constants/languages';
import conflictFields from '../../../../constants/conflictFields';

const CONTENT = {
  [languages.SV]: 'Födelsedatum',
  [languages.EN]: 'Birth date',
};

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <DateField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const DateOfBirth = () => {
  const record = useRecordContext();

  const language = record?.language as Languages;

  return (
    <Answer
      label={CONTENT[language]}
      source="dateOfBirth"
      datapoint={conflictFields.DATE_OF_BIRTH}
    >
      <ValueField />
    </Answer>
  );
};

DateOfBirth.ValueField = ValueField;

DateOfBirth.Nested = ({ source }: { source?: string}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const record = useRecordContext();
  const language = record?.language as Languages;
  const sx = get(record, 'sx', {});

  return (
    <Grid item xs={12} md={3}>
      <Box display="flex" flexDirection="column">
        <Typography variant="label">{CONTENT[language]}</Typography>
        <DateField
          source={source ?? 'dateOfBirth'}
          emptyText="-"
          sx={sx}
        />
      </Box>
    </Grid>
  );
};

export default DateOfBirth;
