import React from 'react';

import {
  maxLength,
  NumberInput,
  required,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin';

import {
  Grid,
} from '@mui/material';

import Label from '../../../customFields/Label';
import currencies from '../../../constants/currencies';

const General = () => (
  <Grid container columnSpacing={6}>
    <Grid item xs={12} md={12}>
      <Label variant="h6" title="Reason for reporting" info="Describe the reason for why the report is created" />
      <TextInput
        multiline
        label="Reason"
        fullWidth
        minRows={3}
        source="report.reason"
        validate={[required(), maxLength(4000)]}
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <Label variant="h6" title="Actions taken or planned" info="Describe the actions taken or planned" />
      <TextInput
        multiline
        label="Actions"
        fullWidth
        minRows={3}
        source="report.action"
        validate={[required(), maxLength(4000)]}
      />
    </Grid>

    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Label variant="h6" title="Suspicion indicators" />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectArrayInput
            label="Select suspicion indicators"
            fullWidth
            validate={required()}
            source="report.report_indicators.suspicion"
            choices={[
              { id: 'AMTPT', name: 'Suspicion of money laundering' },
              { id: 'BMTTF', name: 'Suspicion of terrorist financing' },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={12}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Label variant="h6" title="Risk indicators" />
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectArrayInput
            label="Select risk indicators"
            fullWidth
            validate={required()}
            source="report.report_indicators.risk"
            choices={[
              { id: 'GEO01', name: 'Area or state which doesn’t have efficient systems for combating money laundering or terrorist financing.' },
              { id: 'GEO02', name: 'Area or state with considerable corruption and other relevant types of crime.' },
              { id: 'GEO03', name: 'An area where a state finances or supports terrorist activities or where terrorist organizations operate.' },
              { id: 'GEO04', name: 'Other geographical area.' },
              { id: 'INF01', name: 'Course of action, modus operandi, trends, national risk assessments.' },
              { id: 'INF02', name: 'Specific information from another reporting entity or source.' },
              { id: 'INF03', name: 'Sanctions, embargo or similar actions.' },
              { id: 'INF04', name: 'Specific information from a law enforcement agency.' },
              { id: 'INF05', name: 'Other information.' },
              { id: 'INF06', name: 'Specific information about probable proceedings of a crime' },
              { id: 'KAN01', name: 'Business relations or transactions are conducted at a distance, without the use of methods which can verify the client’s identity (anonymization).' },
              { id: 'KAN02', name: 'The payment of goods or services is done by someone unknown or someone who’s not linked to the client.' },
              { id: 'KAN03', name: 'Other distribution channels' },
              { id: 'KDK01', name: 'The client has supposedly given none or incorrect information' },
              { id: 'KDK02', name: 'The client acts supposedly on behalf of another person.' },
              { id: 'KDK03', name: 'The information from the client seems unusual or too complex in relation to its business.' },
              { id: 'KDK04', name: 'The client is new or changes it’s activity which makes a great impact on the KYC.' },
              { id: 'KDK05', name: 'The client uses a product/service or conducts a transaction in a different way than anticipated.' },
              { id: 'KDK06', name: 'The client uses a product/service or conducts a transaction in a way that deviates from other clients within the same category.' },
              { id: 'KDK07', name: 'Other information about a client.' },
              { id: 'PTT01', name: 'Changes of payment method.' },
              { id: 'PTT02', name: 'Deviating turnover.' },
              { id: 'PTT03', name: 'Unusual amounts of transactions.' },
              { id: 'PTT04', name: 'Deviating numbers of counterparts of a transaction.' },
              { id: 'PTT05', name: 'The transaction is conducted in a way which may be a part of a crime.' },
              { id: 'PTT06', name: 'Other ways of using services and transactions.' },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} sm={12} lg={4}>
      <Label variant="h6" title="Reporting Entity Branch" info="The name of the branch or business for the reporting entity" />
      <TextInput
        label="Branch"
        source="report.rentity_branch"
        fullWidth
        validate={[maxLength(255)]}
      />
    </Grid>
    <Grid item xs={12} sm={12} lg={4}>
      <Label variant="h6" title="Reporting entity ID" info="Reporting entity ID reference given by the financial police" />
      <NumberInput
        label="ID"
        source="report.rentity_id"
        fullWidth
        validate={[required(), maxLength(255)]}
      />
    </Grid>
    <Grid item xs={12} sm={12} lg={4}>
      <Label variant="h6" title="Local currency code" info="Local currency code" />
      <SelectInput
        fullWidth
        label="Currency"
        defaultValue="SEK"
        source="report.currency_code_local"
        choices={currencies.map((curr) => ({ id: curr, name: curr }))}
        validate={required()}
      />
    </Grid>
  </Grid>
);

export default General;
