import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectInput,
} from 'react-admin';

import countries from '../../constants/countries';
import cleaner from '../../utilities/dataProvider/cleaner';

const CarCreate = () => (
  <Create transform={cleaner}>
    <SimpleForm>
      <TextInput validate={required()} source="registrationNumber" />
      <SelectInput
        source="registrationCountry"
        label="Registration country"
        choices={countries}
        validate={required()}
      />
      <TextInput source="vinCode" />
      <TextInput source="carModel" />
      <ReferenceArrayInput label="Groups" source="groups" reference="groups">
        <SelectArrayInput optionText="name" label="groups" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
);

export default CarCreate;
