import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import SelectInput from '../../../layout/inputFields/SelectInput';
import InfoPopover from '../../../layout/InfoPopover';

const CarValuation = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Valuation parameters</Typography>
      </Grid>
      <Grid item xs={7} md={7}>
        <NumberInput
          source="ruleParameters.valuationDeviation"
          fullWidth
          validate={[required(), minValue(1)]}
          label="Deviation"
          popups={[
            <InfoPopover key="deviation" title="Valuation deviation" content="Used in combination with the deviation prefix. The deviation from the request car valuation is to be checked against this number." />,
          ]}
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <SelectInput
          choices={[
            { id: '%', name: '%' },
            { id: 'sek', name: 'SEK' },
          ]}
          source="ruleParameters.valuationPrefix"
          validate={required()}
          label="Prefix"
        />
      </Grid>
    </Grid>
  </Grid>
);

export default CarValuation;
