import React, { memo } from 'react';

import {
  Box, Card, Tooltip,
} from '@mui/material';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { useParams } from 'react-router-dom';

import {
  Handle,
  Position,
  NodeProps,
  useNodeId,
} from 'reactflow';

import { NodeData } from './utils';

const AccountNode = ({ data, selected }: NodeProps<NodeData>) => {
  const { id } = useParams();
  const nodeId = useNodeId();

  if (!data) return null;

  const isRoot = id === nodeId;

  const sx = selected ? {
    borderColor: 'primary.main',
  } : {};

  const primary = isRoot ? {
    backgroundColor: 'primary.main',
    color: 'white',
  } : {};

  return (
    <Box>
      <Handle type="target" position={Position.Top} draggable={false} isConnectable={false} />
      <Card
        variant="outlined"
        sx={{
          margin: 0,
          padding: 0,
          ...sx,
          ...primary,
        }}
      >
        <Tooltip title={data.label}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding={1}
            width={45}
          >
            <AccountBalanceIcon sx={{ cursor: 'pointer' }} color={isRoot ? 'inherit' : 'primary'} fontSize="large" />
          </Box>
        </Tooltip>
      </Card>
      <Handle type="source" position={Position.Bottom} draggable={false} isConnectable={false} />
    </Box>
  );
};

export default memo(AccountNode);
