import React from 'react';

import {
  DateField,
  TextField,
} from 'react-admin';

import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  Divider,
} from '@mui/material';

import ColoredScoreField from '../../../../../../customFields/ColoredScoreField';

import AdverseMediaTitle from './AdverseMediaTitle';

const AdverseMediaMatchInformation = () => (
  <Grid item xs={12} md={12}>
    <Card variant="outlined">
      <CardHeader title="Match information" />
      <Divider />
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <AdverseMediaTitle>Provider name</AdverseMediaTitle>
            <TextField source="providerName" emptyText="-" />
          </Box>
          <Box>
            <AdverseMediaTitle>Match %</AdverseMediaTitle>
            <ColoredScoreField source="matchProbability" />
          </Box>
          <Box>
            <AdverseMediaTitle>Last updated</AdverseMediaTitle>
            <DateField showTime source="createdAt" emptyText="-" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Grid>
);

export default AdverseMediaMatchInformation;
