import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';

import {
  RecordContextProvider,
  useGetList,
  useResourceContext,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';

import ArrowBackIosFilled from '@mui/icons-material/ArrowBackIos';
import HistoryIcon from '@mui/icons-material/History';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

import {
  isArray,
  isEmpty,
  isPlainObject,
} from 'lodash';

import ConflictResolutionTitle from './ConflictResolutionTitle';
import ConflictResolutionSources from './ConflictResolutionSources';

import { EntityTypes, entityTypesFromResource } from '../../constants/entityTypes';
import fieldStatus from '../../constants/fieldStatus';
import ConflictVerifiedStatus from './ConflictVerifiedStatus';
import conflictFieldsLabel from './conflictFieldsLabels';
import conflictFields, { ConflictFields } from '../../constants/conflictFields';
import languages from '../../constants/languages';
import conflictFieldsAnswerFields from './conflictFieldsAnswerField';
import useDialogStatus from '../../hooks/useDialogStatus';
import ConflictResolutionHistory from './ConflictResolutionHistory';
import ConflictResolutionManualInput from './ConflictResolutionManualInput';
import ConflictMarkAsVerified from './ConflictMarkAsVerified';

export const DEFAULT_SX = {
  wordBreak: 'break-all',
  wordWrap: 'normal',
};

export type DatapointValue = {
  id: string;
  entity: {
    id: string;
    type: EntityTypes;
  };
  selected: boolean;
  updatedAt: Date;
  createdAt: Date;
  name: ConflictFields;
  value: unknown;
  sourceNames: string[];
  maximumMatchProbability?: number;
}

const isValidDatapointName = (
  name?: string,
): name is ConflictFields => !!name && (Object.values(conflictFields) as string[]).includes(name);

const ListItem = ({
  item,
  name,
  otherSelected,
  setValueToMarkAsVerified,
  customValueActions,
}: {
  item: DatapointValue;
  name: ConflictFields;
  otherSelected: boolean;
  setValueToMarkAsVerified: (value: DatapointValue) => void;
  customValueActions?: React.ReactNode;
}) => {
  const valueIsComplex = (isArray(item.value) && item.value.every(isPlainObject))
  || isPlainObject(item.value);

  const bgcolor = item.selected ? 'greenGray.main' : 'background.grey';

  const {
    open: shouldShowSources,
    openDialog: showSources,
    closeDialog: hideSources,
  } = useDialogStatus();

  return (
    <Grid item xs={12} md={12} bgcolor={bgcolor}>
      <Grid container spacing={valueIsComplex ? 1 : 0} p={6} justifyContent="space-between" display="flex" alignItems="center">
        <Grid item xs={valueIsComplex ? 12 : 4} md={valueIsComplex ? 12 : 4}>
          <RecordContextProvider value={{ ...item, sx: DEFAULT_SX }}>
            {React.createElement(
              conflictFieldsAnswerFields[name],
              { language: languages.EN },
            )}
          </RecordContextProvider>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={4} alignItems="center">
            <Box display="flex" gap={4} justifyContent="center" alignItems="center" flexWrap="wrap">
              <Typography variant="h4" color="gray">{item.sourceNames.join(', ') }</Typography>
              {shouldShowSources
                ? (
                  <Button
                    onClick={hideSources}
                    startIcon={<VisibilityOffIcon />}
                  >
                    Hide sources
                  </Button>
                ) : (
                  <Button
                    onClick={showSources}
                    startIcon={<VisibilityIcon />}
                  >
                    View sources
                  </Button>
                )}
            </Box>
            <Box display="flex" gap={4} justifyContent="center" alignItems="center" flexWrap="wrap">
              <RecordContextProvider value={item}>
                {customValueActions}
              </RecordContextProvider>
              <ConflictVerifiedStatus
                selected={item.selected}
                otherSelected={otherSelected}
                markAsVerified={() => setValueToMarkAsVerified(item)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <RecordContextProvider value={item}>
            <Collapse in={shouldShowSources}>
              <ConflictResolutionSources />
            </Collapse>
          </RecordContextProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ValueList = ({
  data,
  name,
  setValueToMarkAsVerified,
  customValueActions,
}: {
  data?: DatapointValue[],
  name: ConflictFields,
  setValueToMarkAsVerified: (value: DatapointValue) => void;
  customValueActions?: React.ReactNode;
}) => {
  const selected = useMemo(() => data?.filter((item) => item.selected), [data]);

  return (
    <Grid item xs={12} md={12}>
      <Grid container spacing={4}>
        {isEmpty(data) && (
          <Grid item xs={12} md={12}>
            <Card
              variant="outlined"
              sx={{ m: 0 }}
            >
              <CardContent>
                <Typography>No data available</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        {data?.map((item) => (
          <Grid item xs={12} md={12} key={item.id}>
            <ListItem
              name={name}
              item={item}
              otherSelected={!isEmpty(selected?.filter((selection) => selection.id !== item.id))}
              setValueToMarkAsVerified={setValueToMarkAsVerified}
              customValueActions={customValueActions}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export const ConflictResolutionBody = ({
  name,
  entityId,
  entityType,
  info,
  customValueActions,
}: {
  name: ConflictFields;
  entityId: string;
  entityType: EntityTypes
  info?: {}
  customValueActions?: React.ReactNode;
}) => {
  const { data, isLoading } = useGetList<DatapointValue>('data-points', {
    filter: {
      name,
      entityId,
      entityType,
      identificationInfo: JSON.stringify(info),
    },
  });
  const status = useMemo(() => {
    if (!data?.length) return fieldStatus.EMPTY;

    if (data.length === 1) return fieldStatus.RESOLVED;
    if (data.some((dataItem) => dataItem.selected)) return fieldStatus.RESOLVED;

    return fieldStatus.CONFLICT;
  }, [data]);

  const [valueToMarkAsVerified, setValueToMarkAsVerified] = useState<DatapointValue>();
  const {
    open: shouldShowResolutionHistory,
    openDialog: showResolutionHistory,
    closeDialog: hideResolutionHistory,
  } = useDialogStatus();
  const {
    open: shouldShowManualInput,
    openDialog: showManualInput,
    closeDialog: hideManualInput,
  } = useDialogStatus();

  useEffect(() => {
    setValueToMarkAsVerified(undefined);
    hideResolutionHistory();
    hideManualInput();
    // Reset state when the data has changed
  }, [hideManualInput, hideResolutionHistory, data]);

  if (valueToMarkAsVerified) {
    return (
      <ConflictMarkAsVerified
        datapointValue={valueToMarkAsVerified}
        handleClose={() => setValueToMarkAsVerified(undefined)}
      />
    );
  }
  if (shouldShowManualInput) {
    return (
      <ConflictResolutionManualInput
        entityId={entityId}
        entityType={entityType}
        handleClose={hideManualInput}
        name={name}
        identificationInfo={info}
      />
    );
  }
  if (shouldShowResolutionHistory) {
    return (
      <ConflictResolutionHistory
        entityId={entityId}
        entityType={entityType}
        handleClose={hideResolutionHistory}
        name={name}
        identificationInfo={info}
      />
    );
  }

  if (isLoading) return null;

  const searchParams = new URLSearchParams();
  searchParams.append('name', name);
  if (info) searchParams.append('identificationInfo', JSON.stringify(info));

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <ConflictResolutionTitle
          label={conflictFieldsLabel[name]}
          status={status}
        />
      </Grid>
      <ValueList
        name={name}
        data={data}
        setValueToMarkAsVerified={setValueToMarkAsVerified}
        customValueActions={customValueActions}
      />
      <Grid item xs={12} md={6}>
        <Button onClick={showManualInput}>+ Add value manually</Button>
      </Grid>
      <Grid item xs={12} md={6} display="flex" justifyContent="end">
        <Button
          startIcon={<HistoryIcon />}
          onClick={showResolutionHistory}
        >
          View resolution history
        </Button>
      </Grid>
    </Grid>
  );
};

const ConflictResolution = () => {
  const resource = useResourceContext();
  const navigate = useNavigate();

  const {
    id,
  } = useParams();

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const info = searchParams.get('identificationInfo');
  const identificationInfo = info ? JSON.parse(info) : undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handelBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (
    !name
    || !id
    || !entityTypesFromResource[resource]
  ) return null;
  if (!isValidDatapointName(name)) return null;

  return (
    <Slide in direction="left">
      <Grid container gap={1}>
        <Grid item xs={12} md={12} mb={3}>
          <Box display="flex" alignItems="center">
            <IconButton size="medium" onClick={handelBack}>
              <ArrowBackIosFilled fontSize="medium" color="primary" />
            </IconButton>
            <Typography color="primary" variant="h5">Back to form</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} sx={{ backgroundColor: 'background.paper' }}>
          <Card
            variant="outlined"
            sx={{
              m: 0,
              p: 2,
              height: '100%',
              minHeight: 900,
              borderRadius: 1,
            }}
          >
            <CardContent sx={{ p: 6 }}>
              <ConflictResolutionBody
                entityId={id}
                entityType={entityTypesFromResource[resource]}
                name={name}
                info={identificationInfo}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default ConflictResolution;
