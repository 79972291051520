import React from 'react';

import {
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin';

import { Box, Grid } from '@mui/material';

import { capitalize } from 'lodash';

import entityTypes from '../../../../constants/entityTypes';

const EntitiesOverview = () => (
  <Grid container>
    <Grid item xs={12} md={12}>
      <SelectArrayInput
        sx={{ width: { sx: '100%', md: '17%' } }}
        choices={Object.values(entityTypes).map(
          (status) => ({ id: status, name: capitalize(status) }),
        )}
        fullWidth
        label="Entity types"
        source="report.entityTypes"
      />
    </Grid>
    <Grid item xs={12} md={12}>
      <ReferenceArrayInput
        source="report.groupIds"
        reference="groups"
        perPage={200}
        label="groups"
      >
        <SelectArrayInput
          label="Groups"
          sx={{ width: { sx: '100%', md: '17%' } }}
        />
      </ReferenceArrayInput>
    </Grid>
    <Grid item xs={12} md={12}>
      <ReferenceArrayInput
        source="report.riskClassIds"
        reference="risk-classes"
        perPage={200}
      >
        <SelectArrayInput
          label="Risk classes"
          sx={{ width: { sx: '100%', md: '17%' } }}
        />
      </ReferenceArrayInput>
    </Grid>
    <Grid item xs={12} md={12}>
      <Box display="flex" flexWrap="wrap" columnGap={2}>
        <DateInput label="Creation date - From" source="report.dateFrom" />
        <DateInput label="Creation date - To" source="report.dateTo" />
      </Box>
    </Grid>
  </Grid>
);

export default EntitiesOverview;
