import React from 'react';

import {
  TextField,
  useRecordContext,
  useResourceContext,
} from 'react-admin';

import {
  Box, Typography, styled,
} from '@mui/material';
import { capitalize, get, has } from 'lodash';
import theme from '../theme';

const DEFAULT_COLOR_MAP = {
  Pending: { dot: '#F3B61F', text: '#F3B61F' },
  'In-progress': { dot: '#FFA500', text: '#FFA500' },
  Review: { dot: '#F3B61F', text: '#F3B61F' },
  Resolved: { dot: theme.palette.success.main, text: theme.palette.success.main },
  Available: { dot: theme.palette.success.main, text: theme.palette.success.main },
  Closed: { dot: theme.palette.success.main, text: theme.palette.success.main },
  Proceed: { dot: theme.palette.success.main, text: theme.palette.success.main },
  True: { dot: theme.palette.success.main, text: theme.palette.success.main },
  Confirmed: { dot: '#1F487E', text: '#1F487E' },
  No: { dot: '#1F487E', text: '#1F487E' },
  None: { dot: '#1F487E', text: '#1F487E' },
  false: { dot: '#1F487E', text: '#1F487E' },
  Ignored: { dot: '#B8DBD9', text: '#B8DBD9' },
  Yes: { dot: '#DE2B2B', text: '#DE2B2B' },
  true: { dot: '#DE2B2B', text: '#DE2B2B' },
  Blocked: { dot: '#DE2B2B', text: '#DE2B2B' },
  Block: { dot: '#DE2B2B', text: '#DE2B2B' },
  Open: { dot: '#DE2B2B', text: '#DE2B2B' },
  False: { dot: '#DE2B2B', text: '#DE2B2B' },
};

const CASE_COLOR_MAP = {
  Pending: { dot: theme.palette.info.main, text: theme.palette.info.main },
  Closed: { dot: theme.palette.success.main, text: theme.palette.success.main },
  'In-progress': { dot: theme.palette.warning.main, text: theme.palette.warning.main },
  Open: { dot: theme.palette.error.main, text: theme.palette.error.main },
};

const REQUEST_COLOR_MAP = {
  Pending: { dot: theme.palette.info.main, text: theme.palette.info.main },
  Completed: { dot: theme.palette.success.main, text: theme.palette.success.main },
  Failed: { dot: theme.palette.error.main, text: theme.palette.error.main },
  Cancelled: { dot: theme.palette.grey[400], text: theme.palette.grey[400] },
};

const COLOR_MAPS = {
  cases: CASE_COLOR_MAP,
  transactions: REQUEST_COLOR_MAP,
  default: DEFAULT_COLOR_MAP,
} as Record<string, Record<string, { dot: string; text: string }>>;

const Dot = styled('span')(({ color }) => ({
  height: '6px',
  width: '6px',
  borderRadius: '50%',
  marginRight: '4px',
  backgroundColor: color,
}));

const StatusField = ({
  source,
  label,
  resource,
}: {
  source: string
  label?: string
  resource?: string;
  sortable?: boolean;
}) => {
  const contextResource = useResourceContext();
  const finalResource = resource ?? contextResource;

  const record = useRecordContext();

  if (!record || !has(record, source)) return <Typography>-</Typography>;

  const capitalizedValue = capitalize(get(record, source));

  const colors = (has(COLOR_MAPS, finalResource)
    ? get(COLOR_MAPS[finalResource], capitalizedValue)
    : get(COLOR_MAPS.default, capitalizedValue)) ?? {
    dot: theme.palette.primary.main,
    text: theme.palette.primary.main,
  };

  return (
    <Box display="flex" alignItems="center">
      <Dot color={colors.dot} />
      <TextField
        record={{
          [source]: capitalizedValue,
        }}
        label={label}
        source={source}
        color={colors.text}
      />
    </Box>
  );
};

export default StatusField;
