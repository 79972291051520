import React from 'react';

import {
  Edit,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
  usePermissions,
  SelectField,
  ArrayField,
  EditButton as RaEditButton,
  useRecordContext,
  SaveButton,
  TextInput,
  SelectInput,
  DateInput,
  SimpleForm,
} from 'react-admin';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import hasAccess from '../../utilities/hasAccess';

import useDialogStatus from '../../hooks/useDialogStatus';

import ReferenceMemberField from '../../customFields/ReferenceMemberField';
import ReferenceEntityField from '../../customFields/ReferenceEntityField';
import LatestRelatedRequests from '../../customFields/LatestRelatedRequests';
import LatestRelatedCases from '../../customFields/LatestRelatedCases';
import LatestRelatedPings from '../../customFields/LatestRelatedPings';

import Pagination from '../layout/Pagination';

import RiskManagement from '../riskManagment';
import AccountEntityBreadcrumb from './AccountEntityBreadcrumb';
import Transaction from '../transaction';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import { resourceFromEntityTypes } from '../../constants/entityTypes';
import actions, { Actions } from '../../constants/actions';
import { countryChoices } from '../../constants/countries';
import languages from '../../constants/languages';
import RelatedCustomConnections from '../connection/RelatedCustomConnections';
import { ConnectedEntity } from '../../utilities/schemas/accountEntity';
import { bankAccountTypesChoices } from '../../constants/bankAccountTypes';
import { bankAccountStatusesChoices } from '../../constants/bankAccountStatuses';
import useValidateId from '../../hooks/useValidateId';
import resourceTypes from '../../constants/resourceTypes';
import CustomToolbar from '../../customFields/CustomToolbar';
import { entityCleaner } from '../../utilities/dataProvider/cleaner';
import EditArrayInput from '../../customFields/EditArrayInput';

const ConnectedEntitiesEditButton = () => {
  const record = useRecordContext<ConnectedEntity>();

  if (!record?.type) return <Typography>-</Typography>;

  const resource = resourceFromEntityTypes[record.type];
  return <RaEditButton resource={resource} />;
};

const EditDialog = () => {
  const { closeDialog, open, openDialog } = useDialogStatus();

  return (
    <>
      <Button onClick={openDialog} startIcon={<EditIcon />}>Edit</Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogTitle color="primary">
          Edit account details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="accountName" label="Account name" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="customId" label="Custom Id" />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="bic" label="BIC" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="iban" label="IBAN" />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                choices={countryChoices(languages.EN)}
                fullWidth
                source="country"
                label="Country"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="bban" label="BBAN" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="provider" label="Provider" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput fullWidth source="product" label="Product" />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                choices={bankAccountTypesChoices}
                fullWidth
                source="accountType"
                label="Account type"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput source="opened" label="Opened Date" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput source="closed" label="Closed Date" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput source="bankgiroNumber" label="Bankgiro" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput source="plusgiroNumber" label="Plusgiro" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectInput
                choices={bankAccountStatusesChoices}
                fullWidth
                source="status"
                label="Status"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <SaveButton
            type="button"
            resource="account-entities"
            onClick={() => {
              closeDialog();
            }}

          />
        </DialogActions>
      </Dialog>
    </>
  );
};

const AccountEntityEdit = () => {
  const { permissions } = usePermissions<Actions[]>();
  const validatedId = useValidateId();
  if (!validatedId.isValid) return null;

  return (
    <Edit redirect={false} mutationMode="pessimistic" transform={entityCleaner}>
      <AccountEntityBreadcrumb />
      <TabbedShowLayout>
        <Tab label="Information">
          <SimpleForm
            mode="all"
            toolbar={
              <CustomToolbar sx={{ marginBottom: -6, marginLeft: -4, marginRight: -4 }} />
            }
            sx={{ padding: 0 }}
            component={Box}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5}>
                <Card variant="outlined">
                  <CardHeader
                    title="Account Details"
                    action={<EditDialog />}
                  />
                  <Divider />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Account Name</Typography>
                          <TextField source="accountName" label="Account Name" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <EditArrayInput showEdit={hasAccess(permissions, 'AccountEntityUpdate')} source="customIds" label="Custom IDs" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Provider</Typography>
                          <TextField source="provider" label="Provider" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Created At</Typography>
                          <DateField source="createdAt" label="Created At" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">IBAN</Typography>
                          <TextField source="iban" label="IBAN" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">BIC</Typography>
                          <TextField source="bic" label="BIC" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Country</Typography>
                          <SelectField
                            source="country"
                            label="Country"
                            emptyText="-"
                            choices={countryChoices(languages.EN)}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">BBAN</Typography>
                          <TextField source="bban" label="BBAN" emptyText="-" />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Product</Typography>
                          <TextField source="product" label="Product" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Account Type</Typography>
                          <SelectField
                            source="accountType"
                            label="Account Type"
                            emptyText="-"
                            choices={bankAccountTypesChoices}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Status</Typography>
                          <SelectField
                            source="status"
                            label="Status"
                            emptyText="-"
                            choices={bankAccountStatusesChoices}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Opened Date</Typography>
                          <DateField source="opened" label="Opened Date" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Closed Date</Typography>
                          <DateField source="closed" label="Closed Date" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Bankgiro</Typography>
                          <TextField source="bankgiroNumber" label="Bankgiro" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography variant="smallTitle">Plusgiro</Typography>
                          <TextField source="plusgiroNumber" label="Plusgiro" emptyText="-" />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <RiskManagement />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} lg={7} container direction="column">
                <Grid item>
                  <Card variant="outlined">
                    <CardHeader title="Account holders" />
                    <Divider />
                    <ArrayField source="connectedEntities">
                      <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                        <ReferenceEntityField sortable={false} source="" label="Id" />
                        <TextField sortable={false} source="type" />
                        <ConnectedEntitiesEditButton />
                      </Datagrid>
                    </ArrayField>
                  </Card>
                </Grid>
                <Grid item>
                  <RelatedCustomConnections />
                </Grid>
                <Grid item>
                  <RelatedCustomConnections />
                </Grid>
              </Grid>
              <LatestRelatedRequests />
              <LatestRelatedCases />
              <LatestRelatedPings />

            </Grid>
          </SimpleForm>
        </Tab>
        {hasAccess(permissions, actions.REQUEST_GET) && (
          <Tab label="Transactions">
            <Transaction />
          </Tab>
        )}
        {hasAccess(permissions, actions.AUDIT_TRACK_LIST) && (
          <Tab label="Audit Tracks">
            <Grid container>
              <Grid item xs={12} md={12}>
                <Card variant="outlined">
                  <ReferenceManyField
                    reference="audit-tracks"
                    target="resource.id"
                    filter={{ 'resource.type': resourceTypes.ACCOUNT_ENTITY }}
                    sort={{ field: 'date', order: 'DESC' }}
                    perPage={15}
                    pagination={<Pagination disableEmptyText />}
                  >
                    <Datagrid sx={boldDataGridStyle} bulkActionButtons={false}>
                      <TextField sortable={false} source="id" />
                      <ReferenceMemberField sortable={false} label="User" />
                      <TextField sortable={false} source="description" />
                      <DateField sortable source="date" showTime />
                    </Datagrid>
                  </ReferenceManyField>
                </Card>
              </Grid>
            </Grid>
          </Tab>
        )}
      </TabbedShowLayout>
    </Edit>
  );
};

export default AccountEntityEdit;
