import React, { useState } from 'react';

import {
  List,
  Datagrid,
  TextField,
  usePermissions,
} from 'react-admin';

import { Card, Divider } from '@mui/material';

import hasAccess from '../../utilities/hasAccess';

import RiskClassField from '../../customFields/RiskClassField';

import { SubListNavigation } from '../layout/SubNavigation';
import { StyledTab, StyledTabs } from '../layout/Tab';
import Pagination from '../layout/Pagination';
import NoResults from '../layout/NoResults';

import GroupCreate from './GroupCreate';
import GroupEdit from './GroupEdit';

import actions, { Actions } from '../../constants/actions';
import { boldDataGridStyle } from '../../constants/style/datagridStyles';

const GroupActions = () => (
  <GroupCreate />
);

const GroupList = () => {
  const [activationTab, setActivationTab] = useState(0);
  const [active, setActive] = useState(true);
  const { permissions } = usePermissions<Actions[]>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActivationTab(() => newValue);
    setActive(() => newValue === 0);
  };
  return (
    <List
      empty={false}
      filter={{ active }}
      actions={<GroupActions />}
      pagination={<Pagination disableEmptyText />}
      exporter={false}
    >
      <Card sx={{ margin: 0 }} variant="outlined">
        <Card sx={{ margin: 0 }}>
          <SubListNavigation>
            <StyledTabs value={activationTab} onChange={handleChange}>
              <StyledTab label="Active " />
              <StyledTab label="Inactive" />
            </StyledTabs>
          </SubListNavigation>
          <Divider />
        </Card>
        <Datagrid sx={boldDataGridStyle} bulkActionButtons={false} empty={<NoResults variant="h6" />}>
          {hasAccess(permissions, actions.GROUP_GET) && <GroupEdit />}
          <TextField sortable={false} source="name" />
          <TextField sortable source="score" label="Default risk" />
          <RiskClassField sortable={false} label="Corresponding risk class" source="score" />
        </Datagrid>
      </Card>
    </List>
  );
};

export default GroupList;
