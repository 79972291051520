import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './app';
import config from './config';

import '@nosferatu500/react-sortable-tree/style.css';
import './style.css'; // override style on tree.

const container = document.getElementById('app')!;

Sentry.init({
  dsn: 'https://9131443488e14f5aa61bf0ab2b05d2c5@o1043721.ingest.sentry.io/6013468',
  integrations: [new Integrations.BrowserTracing()],
  environment: config.sentryEnvironment,
  tracesSampleRate: 0.0,
  enabled: config.sentryEnabled,
  ignoreErrors: [
    'TypeError: Failed to fetch',
    'TypeError: Load failed',
  ],
});

const root = ReactDOMClient.createRoot(container);

root.render(<App />);
