import { ValueOf } from '../utilities/types';

const errorTypes = {
  MISSING_DATA_PROVIDER: 'MISSING_DATA_PROVIDER',
  NON_UNIQUE_NAME: 'NON_UNIQUE_NAME',
  MISSING_DATA_CREDENTIALS: 'MISSING_DATA_CREDENTIALS',
  MISSING_REQUIRED_DATA_FOR_PROVIDER: 'MISSING_REQUIRED_DATA_FOR_PROVIDER',
  PROVIDER_UNAVAILABLE: 'PROVIDER_UNAVAILABLE',
};

const errorMessages = {
  [errorTypes.MISSING_DATA_PROVIDER]: 'You do not have any data provider setup for this kind of information. Please contact sales if you would like to set this up',
  [errorTypes.NON_UNIQUE_NAME]: 'The name already exist',
  [errorTypes.MISSING_DATA_CREDENTIALS]: 'You do not have any credentials setup for this kind of information. Please contact sales if you would like to set this up',
  [errorTypes.MISSING_REQUIRED_DATA_FOR_PROVIDER]: 'You are missing required data on this entity to use this provider',
  [errorTypes.PROVIDER_UNAVAILABLE]: 'The data provider is experiencing issues at the moment. Please try again later. If the problem persists, reach out to our support.',
};

export type ErrorTypes = ValueOf<typeof errorTypes>;

export default errorMessages;
