import React, { useCallback } from 'react';

import {
  TextField,
  DateField,
  useRecordContext,
  useRedirect,
  SelectField,
} from 'react-admin';

import {
  Box,
  Card,
  CardHeader,
  Grid,
  CardContent,
  Typography,
  Divider,
  Button,
  styled,
} from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LaunchOutlined from '@mui/icons-material/LaunchOutlined';

import { useParams } from 'react-router-dom';

const Title = styled(Typography)({
  fontWeight: '400',
  fontSize: '13px',
  color: '#646464',
});

const Summarization = () => {
  const { id } = useParams();
  const record = useRecordContext();
  const redirect = useRedirect();

  const handleClick = useCallback(() => {
    if (record?.owner?.type === 'Business') redirect('edit', 'businesses', record?.owner?.id);
    if (record?.owner?.type === 'Individual') redirect('edit', 'individuals', record?.owner?.id);
  }, [record?.owner?.id, record?.owner?.type, redirect]);

  const handleClickSeeMore = useCallback(() => {
    redirect('edit', `cars/${id}`, 1);
  }, [id, redirect]);

  if (!record?.owner?.id) return null;
  return (
    <Grid item xs={12} md={6}>
      <Card variant="outlined">
        <CardHeader
          title="Summarization"
          action={(
            <Title>
              Latest update:
              {' '}
              <DateField source="summarization.date" showTime />
            </Title>
          )}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography variant="smallTitle">Manufacture:</Typography>
                <TextField
                  emptyText="-"
                  source="summarization.manufacture"
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography variant="smallTitle">Model name:</Typography>
                <TextField
                  emptyText="-"
                  source="summarization.modelName"
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography variant="smallTitle">Year:</Typography>
                <TextField
                  emptyText="-"
                  source="summarization.yearModel"
                />
              </Box>
            </Grid>
            <Grid item xs={3} md={3}>
              <Box>
                <Typography variant="smallTitle">Color:</Typography>
                <TextField
                  emptyText="-"
                  source="summarization.color"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Card variant="outlined">
              <CardHeader
                sx={{ color: 'black', fontWeight: 'bold' }}
                titleTypographyProps={{ variant: 'inherit' }}
                title="Owner Information"
                action={(
                  <Title>
                    <Button startIcon={<LaunchOutlined />} onClick={handleClick}>
                      Owner Profile
                    </Button>
                  </Title>
                )}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={4} md={4}>
                    <Box>
                      <Typography variant="smallTitle">Name:</Typography>
                      <TextField
                        emptyText="-"
                        source="summarization.owner.name"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box>
                      <Typography variant="smallTitle">
                        National identification number:
                      </Typography>
                      <TextField
                        emptyText="-"
                        source="summarization.owner.nationalIdentificationNumber"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box>
                      <Typography variant="smallTitle">Address:</Typography>
                      <TextField
                        emptyText="-"
                        source="summarization.owner.address"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box>
                      <Typography variant="smallTitle">Postal number:</Typography>
                      <TextField
                        emptyText="-"
                        source="summarization.owner.postnr"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Box>
                      <Typography variant="smallTitle">
                        Acquisition date:
                      </Typography>
                      <TextField
                        emptyText="-"
                        source="summarization.owner.datum"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={6}>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Insurance Company:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.insuranceCompany"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Insurance Company:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.insuranceCompany"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Insurance Acquisition Date:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.insuranceDate"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Insurance type:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.insuranceType"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Driving ban:
                  </Typography>
                  <SelectField
                    choices={[
                      { id: 'J', name: 'Yes' },
                      { id: 'N', name: 'No' },
                    ]}
                    emptyText="-"
                    source="summarization.drivingBan"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Use ban:
                  </Typography>
                  <SelectField
                    choices={[
                      { id: 'J', name: 'Yes' },
                      { id: 'N', name: 'No' },
                    ]}
                    emptyText="-"
                    source="summarization.useBan"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Vehicle status:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.vehicleStatus"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Wanted list history:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.WantedListHistory"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Tax payed until:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.taxPayedUntil"
                  />
                </Box>
              </Grid>
              <Grid item xs={3} md={3}>
                <Box>
                  <Typography variant="smallTitle">
                    Approved inspection until:
                  </Typography>
                  <TextField
                    emptyText="-"
                    source="summarization.approvedInspectionUntil"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Card variant="outlined">
                <CardHeader
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  titleTypographyProps={{ variant: 'inherit' }}
                  title="Liabilities"
                  action={(
                    <Button startIcon={<ArrowForwardIcon />} onClick={handleClickSeeMore}>
                      See more
                    </Button>
                  )}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Vehicle tax balance:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="summarization.liabilities.vehicleTaxBalance"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Vehicle tax ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="summarization.liabilities.vehicleTaxBan"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Congestion charge balance:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="summarization.liabilities.congestionChargeBalance"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Congestion charge ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="summarization.liabilities.congestionChargeBan"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Infrastructure fee balance:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="summarization.liabilities.infrastructureFeeBalance"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Infrastructure fee ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="summarization.liabilities.infrastructureFeeBan"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Parking fine balance:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="summarization.liabilities.parkingFineBalance"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Parking fine ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="summarization.liabilities.parkingFineBan"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Toll balance:
                        </Typography>
                        <TextField
                          emptyText="-"
                          source="summarization.liabilities.tollBalance"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Box>
                        <Typography variant="smallTitle">
                          Toll ban:
                        </Typography>
                        <SelectField
                          choices={[
                            { id: 'J', name: 'Yes' },
                            { id: 'N', name: 'No' },
                          ]}
                          emptyText="-"
                          source="summarization.liabilities.tollBan"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Summarization;
