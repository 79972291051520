import React, { useMemo } from 'react';
import { useRedirect } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import { FormPathProps } from './FormWizard';

const useFormWizardNav = (children: React.ReactElement<FormPathProps>[]) => {
  const { setValue } = useFormContext();
  const redirect = useRedirect();
  const { '*': path } = useParams();
  const { state } = useLocation();

  const filteredChildren = useMemo(() => children.filter(
    (child) => child.props?.hidden !== true,
  ), [children]);

  const childrenPaths = filteredChildren.map((child) => child.props.path);

  const currentPosition = useMemo(() => childrenPaths
    .findIndex((child) => child === path), [childrenPaths, path]);

  const next = () => {
    const nextPath = childrenPaths.at(currentPosition + 1) ?? path;
    redirect(nextPath!, undefined, undefined, undefined, state);
    setValue('_reValidate', true, { shouldValidate: true });
  };

  const previous = () => {
    const previousPath = childrenPaths.at(currentPosition - 1) ?? path;
    if (previousPath === '') {
      redirect(' ', undefined, undefined, undefined, state);
    } else {
      redirect(previousPath!, undefined, undefined, undefined, state);
    }
    setValue('_reValidate', true, { shouldValidate: true });
  };

  return {
    currentChild: filteredChildren.at(currentPosition),
    next,
    previous,
    isFirstPage: childrenPaths[0] === path,
    isLastPage: childrenPaths.slice(-1)[0] === path,
  };
};

export default useFormWizardNav;
