import React, { useCallback, useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useUpdate,
  useRefresh,
} from 'react-admin';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import useDialogStatus from '../hooks/useDialogStatus';
import caseStatuses, { CaseStatuses } from '../constants/caseStatuses';
import theme from '../theme';
import StatusField from './StatusField';

type CaseDoc = {
  id: string;
  status: CaseStatuses;
};

const UpdateCaseStatusField = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<CaseDoc>();
  const { id, status } = record || {};
  const [newStatus, setNewStatus] = useState<CaseDoc['status']>(record?.status || caseStatuses.OPEN);

  const { open, openDialog, closeDialog } = useDialogStatus();

  const [closed] = useUpdate();

  const onStatusChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setNewStatus(e.currentTarget.value as CaseDoc['status']);
  }, []);

  const handleSubmit = useCallback(() => {
    closed(
      'cases',
      { id: `${id}/status`, data: { status: newStatus } },
      {
        onSuccess: () => {
          notify('Case status have been updated');
          closeDialog();
          refresh();
        },
        onError: () => {
          notify('Could not update case status', { type: 'error' });
        },
      },
    );
  }, [closeDialog, closed, id, newStatus, notify, refresh]);

  return (
    <>
      <Dialog open={open} fullWidth maxWidth="xs">
        <DialogTitle fontWeight="bold">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="bold">Update Status</Typography>
            <IconButton onClick={closeDialog}>
              <Cancel />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{
          display: 'flex',
          justifyContent: 'start',
          paddingX: '18%',
        }}
        >
          <Box display="flex" gap={5} flexDirection="column">
            <Box>
              <FormLabel focused={false}>Current status</FormLabel>
              <StatusField source="status" />
            </Box>
            <FormControl>
              <FormLabel focused={false}>Change to:</FormLabel>
              <RadioGroup
                name="position"
                onChange={onStatusChange}
                value={newStatus}

              >
                <FormControlLabel
                  value={caseStatuses.OPEN}
                  control={(
                    <Radio
                      color="error"
                      sx={{
                        color: theme.palette.error.main,
                      }}
                    />
                  )}
                  label="Open"
                  sx={{
                    display: status === caseStatuses.OPEN ? 'none' : 'flex',
                    '&.Mui-checked': {
                      color: theme.palette.error.main,
                    },

                  }}
                />
                <FormControlLabel
                  value={caseStatuses.IN_PROGRESS}
                  control={(
                    <Radio
                      color="warning"
                      sx={{
                        color: theme.palette.warning.main,
                      }}
                    />
                  )}
                  label="In progress"
                  sx={{
                    display: status === caseStatuses.IN_PROGRESS ? 'none' : 'flex',
                    '&.Mui-checked': {
                      color: theme.palette.warning.main,
                    },
                  }}
                  color="warning"
                />
                <FormControlLabel
                  value={caseStatuses.PENDING}
                  control={(
                    <Radio
                      color="info"
                      sx={{
                        color: theme.palette.info.main,
                      }}
                    />
                  )}
                  label="Pending"
                  sx={{
                    display: status === caseStatuses.PENDING ? 'none' : 'flex',
                    '&.Mui-checked': {
                      color: theme.palette.info.main,
                    },
                  }}
                />
                <FormControlLabel
                  value={caseStatuses.CLOSED}
                  control={(
                    <Radio
                      color="success"
                      sx={{
                        color: theme.palette.success.main,
                      }}
                    />
                  )}
                  label="Closed"
                  sx={{
                    display: status === caseStatuses.CLOSED ? 'none' : 'flex',
                    '&.Mui-checked': {
                      color: theme.palette.success.main,
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <Box display="flex">
        <Box display="flex" marginRight={2}>
          <Button
            variant="contained"
            onClick={openDialog}
          >
            Update Status
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UpdateCaseStatusField;
