import Joi from 'joi';
import JoiOrgNumberExtension, { IJoiOrgNumberExtension } from './orgNumber';
import JoiSsnExtension, { IJoiSsnExtension } from './ssn';
import JoiColorExtension, { IJoiColorExtension } from './color';

// eslint-disable-next-line import/no-mutable-exports
let JoiExtension = Joi;

interface IJoiExtension extends
  IJoiSsnExtension,
  IJoiOrgNumberExtension,
  IJoiColorExtension
{}

const extensions = [
  (joi: Joi.Root) => JoiSsnExtension(joi),
  (joi: Joi.Root) => JoiOrgNumberExtension(joi),
  (joi: Joi.Root) => JoiColorExtension(joi),
];

extensions.forEach((extension) => { JoiExtension = extension(JoiExtension); });

export default JoiExtension as IJoiExtension;
