import ruleNames from '../../../constants/ruleNames';

const ongoingRules = [
  ruleNames.BUSINESS_DATA_CHANGED,
  ruleNames.BUSINESS_SANCTION_LIST,
  ruleNames.CONNECTED_ENTITY_PEP,
  ruleNames.CONNECTED_ENTITY_SANCTION_LIST,
  ruleNames.INDIVIDUAL_PEP,
  ruleNames.INDIVIDUAL_SANCTION_LIST,
  ruleNames.SPAR_CHANGED,
] as const;

export default ongoingRules;
