import React from 'react';

import { Grid, Typography } from '@mui/material';

import Select from './base/Select';

import { industrySectionChoices } from '../../../constants/industrySections';

const Industry = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Industry of the Business
      </Typography>
    </Grid>
    <Select choices={industrySectionChoices('en')} />
  </Grid>
);

export default Industry;
