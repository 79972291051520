import React, { useMemo } from 'react';

import { Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { LoadingPage, RecordContextProvider, useRedirect } from 'react-admin';

import { useParams } from 'react-router-dom';

import useEntityData from '../../../../../../hooks/useEntityData';

import { AdverseMediaMatches } from '../../../../../../utilities/schemas/adverseMediaMatches';

import actions from '../../../../../../constants/actions';

import AdverseMediaMatchInformation from './AdverseMediaMatchInformation';
import AdverseMediaAbout from './AdverseMediaAbout';
import AdverseMediaNationalIdentifiers from './AdverseMediaNationalIdentifiers';
import AdverseMediaImages from './AdverseMediaImages';
import AdverseMediaSources from './AdverseMediaSources';
import AdverseMediaRelatedEntities from './AdverseMediaRelatedEntities';
import AdverseMediaMedias from './AdverseMediaMedias';

const GoBackButton = () => {
  const redirect = useRedirect();
  const path = useParams();

  return (
    <IconButton onClick={() => { redirect(`/individuals/${path.id}/kyc-data/adverse-media`); }} size="small">
      <ArrowBackIosIcon color="inherit" fontSize="medium" />
    </IconButton>
  );
};

const AdverseMediaDetails = () => {
  const {
    data,
    isLoading,
  } = useEntityData<AdverseMediaMatches>({
    source: 'adverse-media',
    getAction: actions.INDIVIDUAL_GET_ADVERSE_MEDIA,
  });

  const { adverseMediaId } = useParams();

  const currentSanction = useMemo(
    () => data?.potentialMatches.find((match) => match._id === adverseMediaId),
    [data, adverseMediaId],
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <RecordContextProvider value={{ ...currentSanction, providerName: data?.providerName }}>
      <Grid container spacing={8}>
        <Grid item xs={12} md={12}>
          <GoBackButton />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={4}
            bgcolor="background.paper"
            paddingX={4}
          >
            <Grid xs={12} md={12} item>
              <AdverseMediaMatchInformation />
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <AdverseMediaAbout />
                </Grid>
                <Grid xs={12} md={12} item>
                  <AdverseMediaNationalIdentifiers />
                </Grid>
              </Grid>
              <Grid xs={12} md={12} item>
                <AdverseMediaImages />
              </Grid>
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container>
                <Grid xs={12} md={12} item>
                  <AdverseMediaMedias />
                </Grid>
                <Grid xs={12} md={12} item>
                  <AdverseMediaRelatedEntities />
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} item>
              <AdverseMediaSources />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RecordContextProvider>
  );
};

export default AdverseMediaDetails;
