import React from 'react';

import { TopToolbar, FilterButton } from 'react-admin';

const FilterActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

export default FilterActions;
