import React from 'react';

import { Grid, Typography } from '@mui/material';
import { DateInput, SelectInput, TextInput } from 'react-admin';
import booleanChoices from '../../../constants/booleanChoices';
import { pepRoleChoices } from '../../../constants/pepRoles';
import { pepRelationChoices } from '../../../constants/pepRelations';
import { countryChoices } from '../../../constants/countries';

const Pep = () => (
  <Grid container spacing={10}>
    <Grid item xs={12} md={12}>
      <Typography
        color="#646464"
        variant="h6"
      >
        Politically exposed person (PEP)
      </Typography>
    </Grid>
    <Grid item xs={12} md={10}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectInput source="value.isPep" fullWidth choices={booleanChoices.en} label="Is the person or someone close to them politically exposed?" />
        </Grid>

        <Grid item xs={12} md={4}>
          <SelectInput fullWidth source="value.pep.role" choices={pepRoleChoices('en')} label="Role" />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput fullWidth source="value.pep.relation" choices={pepRelationChoices('en')} />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectInput fullWidth source="value.pep.country" choices={countryChoices('en')} />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateInput fullWidth source="value.pep.startDate" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DateInput fullWidth source="value.pep.endDate" />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={12} md={10}>
      <TextInput
        fullWidth
        source="comment"
        multiline
        rows={5}
        helperText={false}
        placeholder="Please explain your decision."
      />
    </Grid>
  </Grid>
);

export default Pep;
