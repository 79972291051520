import React, { useCallback } from 'react';

import {
  DateField, RecordContextProvider, SelectField, useRecordContext,
  useRedirect,
} from 'react-admin';

import {
  Box,
  Grid,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
  Button,
} from '@mui/material';

import { isEmpty } from 'lodash';

import { PepMatches } from '../../../../../../utilities/schemas/pepMatches';

import { pepRelationChoices } from '../../../../../../constants/pepRelations';
import languages from '../../../../../../constants/languages';
import entityTypes from '../../../../../../constants/entityTypes';

type RelationDetails = PepMatches['potentialMatches'][number]['relatedEntities'][number];

const RelatedEntity = () => {
  const record = useRecordContext<RelationDetails>();
  const redirect = useRedirect();

  const onClickOpenEntity = useCallback(() => {
    const resource = record?.entityType === entityTypes.INDIVIDUAL ? 'individuals' : 'businesses';
    redirect(`/${resource}/${record?.entityId}`);
  }, [record, redirect]);

  if (!record) {
    return null;
  }

  const isIndividual = record.entityType === entityTypes.INDIVIDUAL;
  const isBusiness = record.entityType === entityTypes.BUSINESS;
  const enableDate = !isEmpty(record.fromDate) || !isEmpty(record.toDate);
  const enableEntity = !isEmpty(record.entityId);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="baseline" gap={1}>
          <SelectField
            sx={{
              fontSize: 13,
            }}
            fontWeight="bold"
            source="relationshipType"
            choices={pepRelationChoices(languages.EN)}
            emptyText="Unkown relation type"
          />
          {
            enableDate && (
              <Box>
                <DateField
                  sx={{
                    fontSize: 13,
                  }}
                  variant="label"
                  source="fromDate"
                  emptyText="-"
                />
                <Typography variant="body2">
                  -
                </Typography>
                <DateField
                  sx={{
                    fontSize: 13,
                  }}
                  variant="label"
                  source="toDate"
                  emptyText="-"
                />
              </Box>
            )
          }
        </Box>
        <Typography variant="body2">
          {record.relationshipDetails}
        </Typography>
        <Typography variant="body2">
          {record.entityName}
        </Typography>
      </Box>
      {enableEntity && (
        <Button
          sx={{ maxHeight: 35 }}
          variant="outlined"
          onClick={onClickOpenEntity}
        >
          {isIndividual && 'Open Individual'}
          {isBusiness && 'Open Business'}
        </Button>
      )}
    </Box>

  );
};

const PepRelatedEntities = () => {
  const record = useRecordContext<PepMatches['potentialMatches'][number]>();

  if (!record) {
    return null;
  }

  if (isEmpty(record.relatedEntities)) {
    return null;
  }

  return (
    <Grid item xs={12} md={12}>
      <Card variant="outlined">
        <CardHeader title="Related entities of interest" />
        <Divider />
        <CardContent>
          <Box display="flex" gap={6} flexDirection="column">
            {record.relatedEntities.map((entity) => (
              <RecordContextProvider key={entity.entityId} value={entity}>
                <RelatedEntity />
              </RecordContextProvider>
            ))}
          </Box>

        </CardContent>
      </Card>
    </Grid>
  );
};

export default PepRelatedEntities;
