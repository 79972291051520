import React from 'react';

import { DateTime } from 'luxon';

import {
  required,
  SelectInput,
} from 'react-admin';

import { Grid } from '@mui/material';

const START_YEAR = 2022;

const SfsaReport = () => {
  const CURRENT_YEAR = DateTime.now().year;
  const difference = CURRENT_YEAR - START_YEAR;

  const choices = [...Array(difference === 0 ? 1 : difference + 1).keys()]
    ?.map((number) => ({ id: `${START_YEAR + number}`, name: START_YEAR + number }));

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <SelectInput
          sx={{ width: { sx: '100%', md: '17%' } }}
          choices={choices}
          fullWidth
          label="Year"
          source="report.year"
          validate={required()}
        />
      </Grid>
    </Grid>
  );
};

export default SfsaReport;
