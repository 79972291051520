import { ValueOf } from '../utilities/types';

const elementTypes = {
  QUESTION: 'question',
  PARAGRAPH: 'paragraph',
} as const;

export type ElementTypes = ValueOf<typeof elementTypes>;

export default elementTypes;
