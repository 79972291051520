import React from 'react';
import { Card, CardHeader, Divider } from '@mui/material';
import { ArrayField } from 'react-admin';
import { NationalIdentifierDataGrid } from '../../../../../../customFields/NationalIdentifier';

const PepNationalIdentifiers = () => (
  <Card variant="outlined">
    <CardHeader
      title="National identifiers"
    />
    <Divider />
    <ArrayField source="nationalIdentifiers">
      <NationalIdentifierDataGrid />
    </ArrayField>
  </Card>
);

export default PepNationalIdentifiers;
