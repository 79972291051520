import React, { useCallback, useState } from 'react';

import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import Entities from './Entities';

import { StyledTabs, StyledTabWhite } from '../../../../layout/Tab';

import entityTypes from '../../../../../constants/entityTypes';

const Create = ({ open, closeDialog }: { open: boolean, closeDialog: () => void}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>(entityTypes.INDIVIDUAL);

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setEditOpen(false);
  }, []);

  const handleCloseDialog = useCallback(() => {
    closeDialog();
    setEditOpen(false);
  }, [closeDialog]);

  const handleOpenChange = useCallback(() => {
    setEditOpen((prev) => !prev);
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
    >
      <StyledTabs value={value} onChange={handleChangeTab}>
        <StyledTabWhite value={entityTypes.INDIVIDUAL} label="Individuals" />
        <StyledTabWhite value={entityTypes.BUSINESS} label="Businesses" sx={{ borderTopRightRadius: 4 }} />
      </StyledTabs>
      <DialogContent sx={{
        backgroundColor: 'white', borderTopRightRadius: 4, overflowX: 'hidden', minHeight: 725,
      }}
      >
        {value === entityTypes.INDIVIDUAL && <Entities handleCloseDialog={handleCloseDialog} resource="individuals" type={value} editOpen={editOpen} handleOpenChange={handleOpenChange} />}
        {value === entityTypes.BUSINESS && <Entities handleCloseDialog={handleCloseDialog} resource="businesses" type={value} editOpen={editOpen} handleOpenChange={handleOpenChange} />}
      </DialogContent>
      <DialogActions sx={{
        backgroundColor: 'white',
      }}
      >
        <Button startIcon={<CloseIcon />} color="red" onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Create;
