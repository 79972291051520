import React, { useState } from 'react';

import {
  RaRecord,
  RecordContextProvider,
  ListBase,
} from 'react-admin';

import {
  Box,
  Card,
  Slide,
} from '@mui/material';

import DetailedView from './detailedView';
import CustomButton from './CustomButton';
import Filter, { FilterType } from './Filter';

import Pagination from '../../../../layout/Pagination';

import { entityListMap } from '../../../../../constants/entityFeatures';
import { EntityTypes } from '../../../../../constants/entityTypes';

const Entities = ({
  resource,
  editOpen,
  type,
  handleOpenChange,
  handleCloseDialog,
}: {
  resource: string,
  editOpen: boolean,
  type: EntityTypes
  handleOpenChange: () => void,
  handleCloseDialog: () => void,
}) => {
  const [filter, setFilter] = useState<FilterType>();
  const [record, setRecord] = useState<RaRecord>();

  const EntityListBody = entityListMap[type];
  if (!EntityListBody) return null;

  return (
    <ListBase
      perPage={10}
      filter={filter}
      resource={resource}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Box>
        <Slide direction="left" in={!editOpen} appear={false}>
          <Box display={editOpen ? 'none' : 'block'}>
            <Filter type={type} filter={filter} setFilter={setFilter} />
            <Card variant="outlined">
              <EntityListBody>
                <CustomButton
                  handleOpenChange={handleOpenChange}
                  setRecord={setRecord}
                />
              </EntityListBody>
            </Card>
          </Box>
        </Slide>
        <Slide direction="right" in={editOpen}>
          <Box display={!editOpen ? 'none' : 'block'}>
            <RecordContextProvider value={record}>
              <DetailedView
                handleCloseDialog={handleCloseDialog}
                setRecord={setRecord}
                type={type}
                handleOpenChange={handleOpenChange}
              />
            </RecordContextProvider>
          </Box>
        </Slide>
      </Box>
      <Pagination hidden={editOpen} rowsPerPageOptions={[10, 15, 20]} disableEmptyText />
    </ListBase>
  );
};

export default Entities;
