const apiUrls = {
  development: 'http://localhost:3080',
  staging: 'https://api.staging.pingwire.io',
  production: 'https://api.pingwire.io',
};

const targetEnv = import.meta.env.TARGET_ENV;

if (!targetEnv) throw new Error('Missing ENV variable');

const config = {
  targetEnv,
  apiUrl: apiUrls[targetEnv],
  sentryEnvironment: targetEnv,
  sentryEnabled: !!targetEnv && targetEnv !== 'development',
};

export default config;
