import { ValueOf } from '../utilities/types';

const permissionRoles = {
  READ_ONLY: 'ReadOnly',
  READ_WRITE: 'ReadWrite',
  MANAGER: 'Manager',
} as const;

export type PermissionRoles = ValueOf<typeof permissionRoles>;

export const permissionRolesChoices = [
  { id: permissionRoles.READ_ONLY, name: 'Read Only' },
  { id: permissionRoles.READ_WRITE, name: 'Read and Write' },
  { id: permissionRoles.MANAGER, name: 'Manager' },
];

export default permissionRoles;
