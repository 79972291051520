import React from 'react';

import { TextField, useRecordContext } from 'react-admin';

import { get } from 'react-hook-form';

import Answer from '../Answer';

import conflictFields from '../../../../constants/conflictFields';

const ValueField = () => {
  const record = useRecordContext();

  const value = get(record, 'value');
  const sx = get(record, 'sx', {});

  return <TextField record={{ value }} sx={sx} source="value" emptyText="-" fontWeight="bold" />;
};

const OwnerStructure = () => (
  <Answer
    label="If the company or organization has multiple ownership levels, please describe its ownership and control structure."
    source="ownerStructure"
    datapoint={conflictFields.OWNER_STRUCTURE}
  >
    <ValueField />
  </Answer>
);

OwnerStructure.ValueField = ValueField;

export default OwnerStructure;
