import React from 'react';

import {
  ReferenceManyField,
  Datagrid,
  DateField,
  usePermissions,
  ReferenceField,
  TextField,
} from 'react-admin';

import {
  Card,
  Grid,
  CardHeader,
  Divider,
  Box,
  Slide,

} from '@mui/material';

import hasAccess from '../../utilities/hasAccess';

import NoResults from '../layout/NoResults';
import GetFormLink from './GetFormLink';
// import CreateManualKyc from './CreateManualKyc';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import actions, { Actions } from '../../constants/actions';
import KycFormView from './KycFormView';

const KycFormActions = () => {
  const { permissions } = usePermissions<Actions[]>();

  return (
    <Box>
      {hasAccess(permissions, actions.FORM_GET_LINK) && <GetFormLink />}
      {/* {hasAccess(permissions, actions.FORM_CREATE) && <CreateManualKyc />} */}
    </Box>
  );
};

const KycForm = () => (
  <Slide in direction="right" appear={false}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader title="KYC Forms" action={<KycFormActions />} />
          <Divider />
          <ReferenceManyField reference="form-answers" target="entity.id">
            <Datagrid
              sx={boldDataGridStyle}
              bulkActionButtons={false}
              empty={<NoResults />}
            >
              <ReferenceField emptyText="Manual" source="formId" reference="forms" label="Name">
                <TextField source="title" />
              </ReferenceField>
              <DateField source="createdAt" showTime label="Timestamp" />
              <KycFormView />
            </Datagrid>
          </ReferenceManyField>
        </Card>
      </Grid>
    </Grid>
  </Slide>
);

export default KycForm;
