import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  HttpError,
  Identifier,
  RaRecord, useGetList,
  useGetOne,
  useNotify,
  useResourceContext,
  useStore,
} from 'react-admin';

import { isEmpty, isNil, isNumber } from 'lodash';
import { DateTime } from 'luxon';

import ReactApexChart from 'react-apexcharts';

import {
  Grid,
  Card,
  CardHeader,
  Divider,
  CircularProgress,
  Box,
} from '@mui/material';

import useDialogStatus from '../../../hooks/useDialogStatus';

import NoResults from '../../layout/NoResults';
import DetailedTransactionTable from '../DetailedTransactionTable';

import { requestTypes } from '../../../constants/requestTypes';
import theme from '../../../theme';
import { TransactionMonitoringThresholds } from '../../../utilities/schemas/datapoints/transactionMonitoringThresholds';
import conflictFields from '../../../constants/conflictFields';
import { EntityTypes, entityTypesFromResource } from '../../../constants/entityTypes';

export type ApexChartOnClickHandler<T extends unknown> = (
  _event: unknown,
  _chartContext: unknown,
  config: {
    w: {
      config: {
        series: {
          data: {
            [x: string]: T;
          };
        }[];
      };
    };
    dataPointIndex: string | number; },
) => void;

export const round2Digits = (value: number) => Math.round(value * 100) / 100;

const useGetGraphData = <T extends unknown>(
  data: {
    id: string;
    type: string;
    name: string;
    data: {
      x: string;
      y: number;
      frequency: number;
      currency: string;
    }[]
  }[] | undefined,
  onClickGraph: ApexChartOnClickHandler<T>,
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
    transactionTypeConfiguration?: string;
  },
) => {
  const [hasData, setHasData] = useState(false);
  const [options, setOptions] = useState<ReactApexChart['props']['options']>();
  const [series, setSeries] = useState<ReactApexChart['props']['series']>();

  const [
    showExpectedBehavior,
  ] = useStore('transactionShowExpectedBehavior', false);

  const {
    entityId,
    transactionTypeConfiguration,
    currency,
    to,
  } = filter;
  const resource = useResourceContext();
  const entityType = entityTypesFromResource[resource];

  const [
    overriddenExpectedBehaviours,
  ] = useStore(`transactionOverriddenExpectedBehaviours-${entityId}`, new Map<string | undefined, TransactionMonitoringThresholds | undefined>());

  const { data: expectedTMThresholdDatapoint } = useGetOne<{
    id: string;
    status: 'resolved' | 'conflict' | 'empty';
    selectedValue: TransactionMonitoringThresholds | undefined;
  }>('data-points/status', {
    id: `${conflictFields.TRANSACTION_MONITORING_THRESHOLD}/${entityId}/${entityType}${transactionTypeConfiguration ? `/${transactionTypeConfiguration}` : ''}`,
  }, {
    enabled: showExpectedBehavior,
  });

  const expectedInfo = useMemo(() => {
    const expectedDatapointValue = overriddenExpectedBehaviours.get(transactionTypeConfiguration)
      ?? expectedTMThresholdDatapoint?.selectedValue;

    if (
      !expectedDatapointValue
      || expectedDatapointValue.currency !== currency
    ) return undefined;

    return expectedDatapointValue;
  }, [
    overriddenExpectedBehaviours,
    transactionTypeConfiguration,
    expectedTMThresholdDatapoint?.selectedValue,
    currency,
  ]);

  const transformMonthlyValues = useCallback((monthlyVolume: number) => {
    const diff = DateTime.fromISO(to).diff(DateTime.fromISO(to).minus({ days: 1 }), 'months');
    return monthlyVolume * diff.months;
  }, [to]);

  useEffect(() => {
    if (!data || isEmpty(data)) return;

    const newSeries = data as ReactApexChart['props']['series'];

    const allYValues = data.flatMap((item) => item.data.map((datapoint) => datapoint.y));

    if (showExpectedBehavior && expectedInfo) {
      if (isNumber(expectedInfo.incoming?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(expectedInfo.incoming.monthlyVolume));
      } if (isNumber(expectedInfo.outgoing?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(-expectedInfo.outgoing.monthlyVolume));
      } if (isNumber(expectedInfo.process?.monthlyVolume)) {
        allYValues?.push(transformMonthlyValues(expectedInfo.process.monthlyVolume));
      }
    }
    const minimumY = Math.min(...allYValues);
    const maximumY = Math.max(...allYValues);

    const newOptions = {
      colors: ['#388e3c', '#880808', theme.palette.info.main],
      chart: {
        redrawOnParentResize: true,
        height: 400,
        events: {
          markerClick: onClickGraph,
        },
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      annotations: {
        yaxis: showExpectedBehavior && expectedInfo ? [
          ...(isNumber(expectedInfo.incoming?.monthlyVolume) ? [{
            y: transformMonthlyValues(expectedInfo.incoming.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: '#388e3c',
            label: {
              borderColor: '#388e3c',
              text: `Expected daily incoming: ${round2Digits(transformMonthlyValues(expectedInfo.incoming.monthlyVolume))} ${currency}`,
              style: {
                background: '#388e3c59',
              },
            },
          }] : []),
          ...(isNumber(expectedInfo.outgoing?.monthlyVolume) ? [{
            y: transformMonthlyValues(-expectedInfo.outgoing.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: '#880808',
            label: {
              borderColor: '#880808',
              text: `Expected daily outgoing: ${round2Digits(transformMonthlyValues(-expectedInfo.outgoing.monthlyVolume))} ${currency}`,
              offsetY: 20,
              style: {
                background: '#88080859',
              },
            },
          }] : []),
          ...(isNumber(expectedInfo.process?.monthlyVolume) ? [{
            y: transformMonthlyValues(expectedInfo.process.monthlyVolume),
            strokeDashArray: 5,
            borderWidth: 2,
            borderColor: theme.palette.info.main,
            label: {
              borderColor: theme.palette.info.main,
              text: `Expected daily processing: ${round2Digits(transformMonthlyValues(expectedInfo.process.monthlyVolume))} ${currency}`,
              position: 'left',
              textAnchor: 'start',
              style: {
                background: `${theme.palette.info.main}59`,
              },
            },
          }] : []),
        ] : [],
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2],
      },
      fill: {
        type: 'solid',
        opacity: [0.35, 0.35, 0.35],
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        decimalsInFloat: 0,
        min: minimumY,
        max: maximumY,
        forceNiceScale: true,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter(value: number, {
            seriesIndex, dataPointIndex, w,
          }: {
            seriesIndex: number,
            dataPointIndex: number,
            w: RaRecord
          }) {
            const {
              y,
              currency: curr,
              frequency,
            } = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

            if (!isNil(y)) {
              return `${y.toFixed(0)} ${curr} (${frequency})`;
            }
            return 'NaN';
          },
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          rotate: -90,
        },
      },
    } as ReactApexChart['props']['options']; // satisfies ReactApexChart['props']['options'] when ts is upgraded;

    setHasData(true);
    setOptions(newOptions);
    setSeries(newSeries);
  }, [currency, data, expectedInfo, onClickGraph, showExpectedBehavior, transformMonthlyValues]);

  return {
    hasData,
    options,
    series,
  };
};

const DailySum = ({
  filter,
}: {
  filter: {
    from: string;
    to: string;
    currency: string;
    entityId: string;
    entityType: EntityTypes;
    requestType: typeof requestTypes.TRANSACTION;
    transactionTypeConfiguration?: string;
  }
}) => {
  const [selectedDate, setSelectedDate] = useState<string>(DateTime.now().toISODate()!);
  const { open, closeDialog, openDialog } = useDialogStatus();
  const notify = useNotify();

  const { data, isLoading } = useGetList<{
    id: string;
    type: string;
    name: string;
    data: {
      x: string;
      y: number;
      frequency: number;
      currency: string;
    }[]
  }>(
    'requests/graph/daily-sum',
    {
      filter,
    },
    {
      onError: (e) => {
        notify(e instanceof HttpError && e.status === 400 ? e.message : 'Cannot fetch transaction stats', { type: 'error' });
      },
    },
  );

  const onClickGraph = useCallback<ApexChartOnClickHandler<{
    id: Identifier;
    x: string;
    y: number;
    frequency: number;
    currency: string;
  }>>((
    _event,
    _chartContext,
    config,
  ) => {
    const dataPointFirstSeries = config.w.config.series[0].data[config.dataPointIndex];
    setSelectedDate(dataPointFirstSeries.x);
    openDialog();
  }, [openDialog]);

  const { hasData, options, series } = useGetGraphData(data, onClickGraph, filter);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={12}>
        <Card variant="outlined">
          <CardHeader title="Daily Sum of Transactions" />
          <Divider />
          {hasData && <ReactApexChart options={options} series={series} type="line" height={600} />}
          {!hasData && !isLoading && <NoResults variant="h4" />}
          {!hasData && isLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" margin={4}>
              <CircularProgress />
            </Box>
          )}
        </Card>
      </Grid>
      <DetailedTransactionTable
        open={open}
        closeDialog={closeDialog}
        from={selectedDate}
        to={selectedDate}
        currency={filter.currency}
        entityId={filter.entityId}
        transactionTypeConfiguration={filter.transactionTypeConfiguration}
      />
    </Grid>
  );
};

export default DailySum;
