import countries from 'i18n-iso-countries';

import en from 'i18n-iso-countries/langs/en.json';
import sv from 'i18n-iso-countries/langs/sv.json';

import { Languages } from './languages';

countries.registerLocale(en);
countries.registerLocale(sv);

export const countryCodes = Object.keys(
  countries.getAlpha3Codes(),
);

export const countryChoices = (language: Languages) => [
  ...countryCodes.map(((code) => ({
    id: code,
    name: countries.getName(code, language),
  }))),
];

export default [
  ...countryCodes.map(((code) => ({
    id: code,
    name: countries.getName(code, 'en'),
  }))),
];
