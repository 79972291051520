import React from 'react';

import {
  Datagrid,
  TextField,
  DateField,
  RaRecord,
  ListBase,
} from 'react-admin';

import {
  Dialog,
  DialogTitle,
  Card,
  DialogContent,
  Box,
  Typography,
} from '@mui/material';

import { isEmpty } from 'lodash';
import LabelsField from './LabelsField';

import { boldDataGridStyle } from '../../constants/style/datagridStyles';
import Pagination from '../layout/Pagination';

const NoTransactions = () => (
  <Box
    paddingTop={4}
    paddingX={2}
  >
    <Typography textAlign="center" fontWeight="bold">No transactions available for the selected date</Typography>
  </Box>
);

export const DetailedCategorizationTableBody = () => (
  <Datagrid
    sx={boldDataGridStyle}
    bulkActionButtons={false}
    empty={<NoTransactions />}
  >
    <TextField sortable source="amount" label="Amount" />
    <DateField sortable source="date" showTime label="Timestamp" />
    <TextField sortable={false} source="accountNumber" />
    <TextField sortable={false} source="description" />
    <LabelsField sortable={false} label="Labels" source="labels" />
  </Datagrid>
);

const DetailedCategorizationTable = ({
  open,
  closeDialog,
  data,
}: {
  open: boolean;
  closeDialog: () => void;
  data?: { transactions: RaRecord[] };
}) => {
  const haveData = !isEmpty(data?.transactions);
  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xl">
      <DialogTitle color="secondary">Detailed Transactions</DialogTitle>
      <DialogContent>
        {haveData && (
          <Card variant="outlined">
            <ListBase
              storeKey={false}
              filter={{ id: data!.transactions.map(({ id }) => id) }}
              perPage={10}
              resource="bank-data/transactions/ids"
              sort={{ field: 'date', order: 'DESC' }}
            >
              <DetailedCategorizationTableBody />
              <Pagination disableEmptyText rowsPerPageOptions={[5, 10, 25]} />
            </ListBase>
          </Card>
        )}
        {!haveData && <NoTransactions />}
      </DialogContent>
    </Dialog>
  );
};

export default DetailedCategorizationTable;
