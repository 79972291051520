import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import connectedEntitiesTypes from '../../../../constants/connectedEntitiesTypes';

import SelectArrayInput from '../../../layout/inputFields/SelectArrayInput';
import InfoPopover from '../../../layout/InfoPopover';
import NumberInput from '../../../layout/inputFields/NumberInput';

const RelatedIndividualsCriminalRecord = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Rule parameters</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.updateFreq"
          label="Update data frequency"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.legalScore"
          label="Legal score"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover
              key="legal score"
              title="Legal Score"
              content="Specifies the criminal record score that must be reached in order to trigger the rule."
            />,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Entities to check</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <SelectArrayInput
          source="ruleParameters.connectedEntitiesToCheck"
          fullWidth
          validate={required()}
          choices={Object.values(connectedEntitiesTypes)}
          label="Connection(s) to be checked"
          popups={[
            <InfoPopover key="connections" title="Connection(s) to be checked" content="The connections to the underlying entity which is to be checked" />,
          ]}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default RelatedIndividualsCriminalRecord;
