import {
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material';

import React, { useCallback, useState } from 'react';

import SquareIcon from '@mui/icons-material/Square';

import { ChromePicker } from 'react-color';
import { useFormContext } from 'react-hook-form';

const PickerDialog = ({
  color,
  anchorEl,
  onChange,
  handlePopoverClose,
}: {
  color: string;
  anchorEl: HTMLElement | null;
  onChange: ({ hex }: { hex: string; }) => void
  handlePopoverClose: () => void
}) => {
  const open = Boolean(anchorEl);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <ChromePicker
        onChange={onChange}
        color={color}
      />
    </Popover>
  );
};

const ColorPicker = () => {
  const { setValue, getValues } = useFormContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [color, setColor] = useState(getValues('color') ?? '#000');

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onChange = useCallback(({ hex } : {hex: string}) => {
    setColor(hex);
    setValue('color', hex, { shouldDirty: true });
  }, [setValue]);

  return (
    <>
      <TextField
        label="Color"
        size="small"
        value={color}
        onClick={handlePopoverOpen}
        sx={{ maxWidth: 200 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <SquareIcon sx={{ color }} fontSize="small" />
            </InputAdornment>),
        }}
      />
      <PickerDialog
        handlePopoverClose={handlePopoverClose}
        onChange={onChange}
        color={color}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default ColorPicker;
