import React from 'react';
import {
  minValue,
  required,
} from 'react-admin';

import {
  Grid,
  Typography,
} from '@mui/material';

import NumberInput from '../../../layout/inputFields/NumberInput';
import InfoPopover from '../../../layout/InfoPopover';

const CarFreqHistoricalOwners = () => (
  <Grid item xs={12} md={12}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Data update frequency</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <NumberInput
          source="ruleParameters.updateFreq"
          label="Days"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="update freq" title="Update frequency" content="The number of days between updates of the underlying data. Note that if the underlying data is older than the input number of days, the data will be re-purchased." />,
          ]}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="label">Car history parameters</Typography>
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.changeFreq"
          label="Frequency threshold"
          validate={[required(), minValue(0)]}
          popups={[
            <InfoPopover key="change freq" title="Change frequency threshold" content="The threshold to check against the number car owner changes." />,
          ]}

        />
      </Grid>
      <Grid item xs={6} md={6}>
        <NumberInput
          fullWidth
          source="ruleParameters.changeMonthsLimit"
          label="Period in months"
          validate={[required(), minValue(0)]}
          popups={[<InfoPopover key="period in months" title="Period in months" content="The period in months that the frequency will be checked against" />]}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default CarFreqHistoricalOwners;
