import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const pepRelations = {
  I_AM_PEP: 'iAmPEP',
  SPOUSE: 'spouse',
  REGISTERED_PARTNER: 'registeredPartner',
  COHABITANT: 'cohabitant',
  CHILD: 'child',
  CHILD_SPOUSE_OR_PARTNER: 'childSpouseOrPartner',
  PARENT: 'parent',
  KNOWN_ASSOCIATE: 'knownAssociate',
} as const;

const pepRelationEnLabels = {
  [pepRelations.I_AM_PEP]: 'I am a PEP',
  [pepRelations.SPOUSE]: 'Spouse',
  [pepRelations.REGISTERED_PARTNER]: 'Registered Partner',
  [pepRelations.COHABITANT]: 'Cohabitant',
  [pepRelations.CHILD]: 'Child',
  [pepRelations.CHILD_SPOUSE_OR_PARTNER]: "Child's Spouse or Partner",
  [pepRelations.PARENT]: 'Parent',
  [pepRelations.KNOWN_ASSOCIATE]: 'Known Associate',
} as const;

const pepRelationSvLabels = {
  [pepRelations.I_AM_PEP]: 'Jag är en PEP',
  [pepRelations.SPOUSE]: 'Make/Maka',
  [pepRelations.REGISTERED_PARTNER]: 'Registrerad Partner',
  [pepRelations.COHABITANT]: 'Sambo',
  [pepRelations.CHILD]: 'Barn',
  [pepRelations.CHILD_SPOUSE_OR_PARTNER]: 'Barns make eller partner',
  [pepRelations.PARENT]: 'Förälder',
  [pepRelations.KNOWN_ASSOCIATE]: 'Känd Medarbetare',
} as const;

const pepRelationLabels = {
  [languages.EN]: pepRelationEnLabels,
  [languages.SV]: pepRelationSvLabels,
} as const;

export const pepRelationChoices = (
  language: Languages,
) => Object.values(pepRelations).map((section) => ({
  id: section,
  name: pepRelationLabels[language][section],
}));

export type PepRelations = ValueOf<typeof pepRelations>;

export default pepRelations;
