import React, { useMemo } from 'react';

import {
  RecordContextProvider,
  RaRecord,
} from 'react-admin';

import { Box, Grid, Typography } from '@mui/material';

import { FormElement, IQuestionElement } from '../types';

import elementTypes from '../../../constants/elementTypes';
import answersComponents from './answersComponents';
import { Languages } from '../../../constants/languages';

const isQuestionElement = (
  element: FormElement,
): element is IQuestionElement => element.type === elementTypes.QUESTION
&& Object.keys(answersComponents).includes(element.id);

const RenderElements = ({
  elements,
  title,
  language,
  kycForm,
}: {
  elements: FormElement[],
  title: string,
  language: Languages
  kycForm: RaRecord
}) => {
  const questions = useMemo(
    () => elements
      .filter(isQuestionElement),
    [elements],
  );

  return (
    <Box display="flex" flexDirection="column" gap={4} my="1%">
      <Typography variant="h5" color="primary">{title}</Typography>
      <RecordContextProvider value={{ language, ...kycForm }}>
        <Grid container spacing={6}>
          {questions.map(
            (question) => React.createElement(answersComponents[question.id], {
              key: `${question.id}-${question?.extraParameters?.id ?? 'default'}`,
              extraParameters: question?.extraParameters,
              question: question.id,
            }),
          )}
        </Grid>
      </RecordContextProvider>
    </Box>
  );
};

export default RenderElements;
