import { ValueOf } from '../utilities/types';

const fieldStatus = {
  CONFLICT: 'conflict',
  EMPTY: 'empty',
  RESOLVED: 'resolved',
} as const;

export type FieldStatus = ValueOf<typeof fieldStatus>

export default fieldStatus;
