import React, { useCallback } from 'react';

import {
  Slide, Grid, IconButton, Box,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useRedirect, useNotify, useRefresh } from 'react-admin';

import PingCreate from '../../ping/PingCreate';

const AddPing = ({ id }: {id: string}) => {
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = useCallback(() => {
    redirect(`/cases/${id}`);
    refresh();
    notify('New ping attached to case', { type: 'success' });
  }, [id, notify, redirect, refresh]);

  return (
    <Slide in direction="right">
      <Grid container spacing={2} p={2}>
        <Grid item xs={12} md={12}>
          <IconButton onClick={() => redirect(`/cases/${id}`)}>
            <ArrowBackIosIcon fontSize="medium" color="primary" />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box bgcolor="white" boxShadow="rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px">
            <PingCreate caseId={id} resource="pings" onSuccess={onSuccess} />
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default AddPing;
