import { ValueOf } from '../utilities/types';
import languages, { Languages } from './languages';

const controlTypes = {
  SHAREHOLDER: 'shareholder',
  VOTING_RIGHT: 'votingRight',
  AGREEMENT_MEMBERSHIP: 'agreementMembership',
  APPOINTMENT_RIGHT: 'appointmentRight',
  TRUSTEE: 'trustee',
  BOARD_MEMBER: 'boardMember',
  BENEFICIARY: 'beneficiary',
  SETTLOR: 'settlor',
  OTHER_COMPANIES: 'otherCompanies',
  OTHER: 'other',
} as const;

const controlTypeEnLabels = {
  [controlTypes.SHAREHOLDER]: 'Shareholder or Partner',
  [controlTypes.VOTING_RIGHT]: 'Voting Right',
  [controlTypes.AGREEMENT_MEMBERSHIP]: 'Agreement, Articles of Association, Similar or Membership',
  [controlTypes.APPOINTMENT_RIGHT]: 'Right to Appoint and Dismiss More Than Half of the Board Members, etc',
  [controlTypes.TRUSTEE]: 'Trustee in a Foundation or Trust',
  [controlTypes.BOARD_MEMBER]: 'Board Member in a Foundation',
  [controlTypes.BENEFICIARY]: 'Beneficiary in a Foundation or Trust',
  [controlTypes.SETTLOR]: 'Settlor in Trust',
  [controlTypes.OTHER_COMPANIES]: 'Control through other companies',
  [controlTypes.OTHER]: 'Other',
} as const;

const controlTypeSvLabels = {
  [controlTypes.SHAREHOLDER]: 'Aktieägare eller andelsägare',
  [controlTypes.VOTING_RIGHT]: 'Rösträtt',
  [controlTypes.AGREEMENT_MEMBERSHIP]: 'Avtal, bolagsordning, liknande eller medlemskap',
  [controlTypes.APPOINTMENT_RIGHT]: 'Rätt att utse och avsätta mer än hälften av styrelseledamöterna etc',
  [controlTypes.TRUSTEE]: 'Förvaltare i stiftelse eller trust',
  [controlTypes.BOARD_MEMBER]: 'Styrelseledamot i stiftelse',
  [controlTypes.BENEFICIARY]: 'Förmånstagare i stiftelse eller trust',
  [controlTypes.SETTLOR]: 'Instiftare i trust',
  [controlTypes.OTHER_COMPANIES]: 'Kontroll genom andra bolag',
  [controlTypes.OTHER]: 'Annat',
} as const;

const controlTypeLabels = {
  [languages.EN]: controlTypeEnLabels,
  [languages.SV]: controlTypeSvLabels,
} as const;

const directControlChoices = [
  controlTypes.SHAREHOLDER,
  controlTypes.VOTING_RIGHT,
  controlTypes.AGREEMENT_MEMBERSHIP,
  controlTypes.APPOINTMENT_RIGHT,
  controlTypes.TRUSTEE,
  controlTypes.BOARD_MEMBER,
  controlTypes.BENEFICIARY,
  controlTypes.SETTLOR,
  controlTypes.OTHER,
] as const;

const indirectControlChoices = [
  controlTypes.SHAREHOLDER,
  controlTypes.VOTING_RIGHT,
  controlTypes.AGREEMENT_MEMBERSHIP,
  controlTypes.APPOINTMENT_RIGHT,
  controlTypes.TRUSTEE,
  controlTypes.BOARD_MEMBER,
  controlTypes.BENEFICIARY,
  controlTypes.SETTLOR,
  controlTypes.OTHER_COMPANIES,
  controlTypes.OTHER,
] as const;

export const directControlTypeChoices = (
  language: Languages,
) => directControlChoices.map((section) => ({
  id: section,
  name: controlTypeLabels[language][section],
}));

export const indirectControlTypeChoices = (
  language: Languages,
) => indirectControlChoices.map((section) => ({
  id: section,
  name: controlTypeLabels[language][section],
}));

export type ControlTypes = ValueOf<typeof controlTypes>;

export default controlTypes;
