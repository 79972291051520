import React from 'react';

import {
  required,
} from 'react-admin';

import { capitalize, Grid } from '@mui/material';

import TextInput from '../../../../../../layout/inputFields/TextField';
import SelectInput from '../../../../../../layout/inputFields/SelectInput';
import InfoPopover from '../../../../../../layout/InfoPopover';

import countries from '../../../../../../../constants/countries';
import { fundsTypesChoices } from '../../../../../../../constants/fundsTypes';
import currencies from '../../../../../../../constants/currencies';

const RootFields = ({
  rootSource,
  direction,
}: {
  rootSource: string
  direction: string
}) => (
  <Grid container spacing={6}>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput
        label={`${capitalize(direction!)} country`}
        fullWidth
        source={`${rootSource}.${direction}_country`}
        choices={[...countries, { id: 'unknown', name: 'Unknown' }]}
        validate={required()}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput
        choices={currencies.map((currency) => ({ id: currency, name: currency }))}
        label={`${capitalize(direction!)} currency`}
        source={`${rootSource}.${direction}_foreign_currency`}
        validate={required()}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <SelectInput
        choices={fundsTypesChoices}
        label="Carrier of transaction"
        source={`${rootSource}.${direction}_funds_code`}
        validate={required()}
        fullWidth
        popups={[<InfoPopover
          title="Carrier of transaction"
          content="Channel used to execute the transaction. When no carrier has been used, “Not relevant” should be selected."
        />]}
      />
    </Grid>
    <Grid item xs={12} sm={6} md={3}>
      <TextInput
        fullWidth
        source={`${rootSource}.${direction}_funds_comment`}
        label="Reference for Carrier of transactions"
        popups={[<InfoPopover
          title="Reference for Carrier of transactions"
          content="Could for example be: telephone number for swish, card number for card terminal, bankgiro for BankGiro transaction."
        />]}
      />
    </Grid>
  </Grid>
);

export default RootFields;
