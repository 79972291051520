import React from 'react';
import {
  ArrayField,
  useRecordContext,
  useGetOne,
  ResourceContextProvider,
} from 'react-admin';

import { Typography } from '@mui/material';

import { requestListMap } from '../../../constants/requestFeatures';
import { IRequest } from '../../request/types';

const PingRequestList = () => {
  const record = useRecordContext();
  const { data } = useGetOne<IRequest>('requests', { id: record?.request }, { enabled: !!record?.request });

  if (data) {
    const { requestType } = data;
    const RequestListComponent = requestListMap[requestType];
    return (
      <ResourceContextProvider value="requests">
        <ArrayField record={{ data: [data] }} source="data">
          <RequestListComponent />
        </ArrayField>
      </ResourceContextProvider>
    );
  }
  return <Typography display="flex" padding={2} justifyContent="center">No requests available</Typography>;
};

export default PingRequestList;
