import React from 'react';

import {
  Box,
  Typography,
  styled,
} from '@mui/material';

import { useRecordContext } from 'react-admin';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { isNil } from 'lodash';

const RiskBox = styled(Box)({
  transform: 'translateX(-50%)',
  position: 'absolute',
  top: '-1.25rem',
  left: '50%',
});

const riskLevels = [...Array(10).keys()].map((i) => 10 * (i + 1));

const getRiskColor = (riskLevel: number) => {
  if (riskLevel <= 30) return '#008000';
  if (riskLevel <= 70) return '#FFA500';
  return '#FF0000';
};

const showArrowForLevel = (riskScore: number, riskLevel: number) => {
  if (riskScore === riskLevel) return true;
  if (Math.abs(riskLevel - riskScore) < 5) return true;
  if (riskScore === 0 && riskLevel === 10) return true; // score is zero case
  return false;
};

const RiskIndicator = ({ source } : {source: string}) => {
  const record = useRecordContext();
  if (!record) return null;

  const riskScore = record[source];

  if (isNil(riskScore)) {
    return (
      <Box maxWidth="15rem" width="100%">
        <Typography fontWeight="bold" variant="smallTitle" align="center">No Risk Score Available</Typography>
      </Box>
    );
  }

  return (
    <Box maxWidth="15rem" width="100%">
      <Typography fontWeight="bold" align="center">{`Risk Score: ${riskScore}`}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        borderColor="#C2C2C2"
        flexDirection="row"
        border={1}
        borderRadius={10}
        marginTop={2}
        padding={0.75}
      >
        {riskLevels.map((riskLevel) => (
          <Box
            position="relative"
            borderRadius={4}
            paddingRight="10%"
            bgcolor={getRiskColor(riskLevel)}
            height="0.5rem"
            key={riskLevel}
          >
            {showArrowForLevel(riskScore, riskLevel) && (
              <RiskBox><ArrowDropDownIcon /></RiskBox>
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography fontWeight="bold">Low</Typography>
        <Typography fontWeight="bold" align="right">High</Typography>
      </Box>
    </Box>
  );
};

export default RiskIndicator;
