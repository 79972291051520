import { ValueOf } from '../utilities/types';

const loginMethods = {
  credentials: 'credentials',
  sso: 'sso',
} as const;

export type LoginMethods = ValueOf<typeof loginMethods>;

export default loginMethods;
