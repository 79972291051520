import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import {
  Box, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

const RegisteredField = ({ data }: { data: string | boolean }) => {
  if (data) {
    return (
      <Box display="flex" alignItems="center">
        <CheckCircleIcon color="primary" />
        <Typography>Registered</Typography>
      </Box>
    );
  }
  if (data === false) {
    return (
      <Box display="flex" alignItems="center">
        <NotInterestedIcon color="error" />
        <Typography>Unregistered</Typography>
      </Box>
    );
  }
  return (
    <Typography>-</Typography>
  );
};
export default RegisteredField;

RegisteredField.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RegisteredField.defaultProps = {
  data: '',
};
