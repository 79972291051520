import React from 'react';
import { TextInput as RaTextInput, TextInputProps } from 'react-admin';
import InputIconComponent from '../InputIconComponent';

const TextInput = ({
  popups = [],
  ...props
} : TextInputProps & { popups?: React.ReactElement[]}) => (
  <RaTextInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    sx={{ '& .MuiFilledInput-root': { padding: 0 } }}
    InputProps={{
      endAdornment: <InputIconComponent popups={popups} />,
    }}
  />
);

export default TextInput;
