import React, { useMemo } from 'react';

import {
  SimpleForm,
} from 'react-admin';

import {
  Box,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import { useParams } from 'react-router-dom';
import HorizontalLabelPositionBelowStepper from './Stepper';
import FormNavigationActions from './FormNavigationActions';

interface ChildrenProps {
  value: string
  path: string
  label?: string
}

type Props = {
  children: React.ReactElement<ChildrenProps>[]
};

const FormNavigation = ({
  children,
}: Props) => {
  const { path } = useParams();

  const Child = useMemo(
    () => children?.find((child) => child.props.path === path),
    [children, path],
  );

  const steps = useMemo(
    () => children?.map((child) => child.props.path),
    [children],
  );

  return (
    <Box minHeight="100vh" sx={{ padding: { xs: 1, sm: 3 } }}>
      <SimpleForm mode="all" toolbar={false}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <HorizontalLabelPositionBelowStepper steps={steps} />
          </Grid>
          <Grid item xs={12} lg={10}>
            <Card>
              <CardContent>
                <Box>
                  {Child!}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={10}>
            <FormNavigationActions steps={steps} />
          </Grid>
        </Grid>
      </SimpleForm>
    </Box>
  );
};

export default FormNavigation;
