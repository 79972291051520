import React, { useEffect } from 'react';

import { isNil } from 'lodash';

import {
  AutocompleteInput, Form, ReferenceInput, useGetOne, useNotify,
} from 'react-admin';

import {
  Dialog, Button, DialogContent, Typography, DialogTitle,
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';

import { useParams } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import useDialogStatus from '../../hooks/useDialogStatus';

import CopyToClipBoard from '../../customFields/CopyToClipboard';

const filterToQuery = (searchText: string) => ({ title: searchText });

const DialogBody = () => {
  const notify = useNotify();
  const { id: entityId } = useParams();
  const { _formId: formId } = useWatch();

  const { data, isError } = useGetOne(`forms/${formId}/${entityId}/link`, { id: '' }, { enabled: !isNil(formId) });

  useEffect(() => {
    if (isError) {
      notify('Could not get form link', { type: 'error' });
    }
  }, [isError, notify]);

  return (
    <DialogContent>
      <Typography variant="label">
        Select a form to get a form link.
      </Typography>

      <ReferenceInput label="Form title" source="_formId" reference="forms">
        <AutocompleteInput filterToQuery={filterToQuery} optionText="title" optionValue="id" source="title" />
      </ReferenceInput>
      <CopyToClipBoard value={data?.link ?? ''} />
    </DialogContent>
  );
};

const GetFormLink = () => {
  const { open, openDialog, closeDialog } = useDialogStatus();

  return (
    <>
      <Button startIcon={<LinkIcon color="primary" />} onClick={openDialog}>Get link</Button>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>Get a new Form Link</DialogTitle>
        <Form>
          <DialogBody />
        </Form>
      </Dialog>
    </>
  );
};

export default GetFormLink;
