import React from 'react';

import {
  Dialog,
  DialogContent,
  Button,
} from '@mui/material';

import AnalyticsIcon from '@mui/icons-material/Analytics';

import useDialogStatus from '../../../../hooks/useDialogStatus';
import RuleTable from '../../../dashboard/components/RuleTable';

const Statistics = () => {
  const { open, closeDialog, openDialog } = useDialogStatus();

  return (
    <>
      <Button startIcon={<AnalyticsIcon />} onClick={openDialog}>Statistics</Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="xl" fullWidth>
        <DialogContent>
          <RuleTable />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Statistics;
